import { createSlice } from "@reduxjs/toolkit";

const SelectedClient = createSlice({
  name: "SelectedClient",
  initialState: {
    client: "",
  },
  reducers: {
    setSelectedClient: (state, action) => {
      state.client = action.payload;
    },
  },
});

export const { setSelectedClient } = SelectedClient.actions;
export default SelectedClient.reducer;
