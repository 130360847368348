let CAMS_URL;

const CAMS_ENV = 'staging';

if (CAMS_ENV === "local") {
  CAMS_URL = "http://localhost:3000/";
}

if (CAMS_ENV === "prod") {
  CAMS_URL = "";
}

if (CAMS_ENV === "dev") {
  CAMS_URL = "";
}

if( CAMS_ENV === 'staging' ) {
    CAMS_URL = 'http://cams.digitaltriumph.co.in/'
}

export default CAMS_URL;
