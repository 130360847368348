const checkListSweet = {
    totalScore: 130,
    totalObtainedScore: 0,
    topic: "Hygiene Rating Checklist for Sweet Shop",
    checkList: [
        {
            topic: 'Basic',
            question: 'The FSSAI license/Registration and Food Safety Display Board (FSDB) both are displayed at a prominent location.',
            answer: {
                majorNC: false,
                yes: false,
                minorNC: false,
                notApplicable: false,
            },
            score: 2,
            obtainedScore: 0
        }, {
            topic: 'Basic',
            question: 'The shop located in hygienic environment. The design of shop provides adequate working space; permit maintenance & cleaning to prevent the entry of dirt, dust & pests',
            answer: {
                majorNC: false,
                yes: false,
                minorNC: false,
                notApplicable: false,
            },
            score: 2,
            obtainedScore: 0
        },
        {
            topic: 'Basic',
            question: 'The internal structure & fittings are made of non-toxic and impermeable material.',
            answer: {
                majorNC: false,
                yes: false,
                minorNC: false,
                notApplicable: false,
            },
            score: 2,
            obtainedScore: 0
        },
        {
            topic: 'Basic',
            question: 'Walls, ceilings & doors are free from flaking paint or plaster, condensation & shedding particles.',
            answer: {
                majorNC: false,
                yes: false,
                minorNC: false,
                notApplicable: false,
            },
            score: 2,
            obtainedScore: 0
        },
        {
            topic: 'Basic',
            question: 'Floors are non-absorbent, non-slippery & sloped appropriately',
            answer: {
                majorNC: false,
                yes: false,
                minorNC: false,
                notApplicable: false,
            },
            score: 2,
            obtainedScore: 0
        },
        {
            topic: 'Basic',
            question: 'Windows are kept closed & fitted with insect proof screen when opening to external environment.',
            answer: {
                majorNC: false,
                yes: false,
                minorNC: false,
                notApplicable: false,
            },
            score: 2,
            obtainedScore: 0
        },
        {
            topic: 'Basic',
            question: 'Doors are smooth and non-absorbent. Suitable precautions have been taken to prevent the entry of pests.',
            answer: {
                majorNC: false,
                yes: false,
                minorNC: false,
                notApplicable: false,
            },
            score: 2,
            obtainedScore: 0
        },
        {
            topic: 'Basic',
            question: 'Potable water (meeting standards of IS: 10500 & tested semi-annually with records maintained thereof/Municipal water) is used as product ingredient or in contact with food or food contact surface.',
            answer: {
                majorNC: false,
                yes: false,
                minorNC: false,
                notApplicable: false,
            },
            score: 4,
            obtainedScore: 0
        },
        {
            topic: 'Basic',
            question: 'Equipment and containers are made of non-toxic, impervious, non-corrosive material which is easy to clean & disinfect',
            answer: {
                majorNC: false,
                yes: false,
                minorNC: false,
                notApplicable: false,
            },
            score: 2,
            obtainedScore: 0
        },
        {
            topic: 'Basic',
            question: 'Adequate facilities for heating, cooking, cooling, refrigeration and freezing food & facilitate monitoring of temperature.',
            answer: {
                majorNC: false,
                yes: false,
                minorNC: false,
                notApplicable: false,
            },
            score: 2,
            obtainedScore: 0
        },
        {
            topic: 'Basic',
            question: 'Premise has sufficient lighting. Lighting fixtures are protected to prevent contamination on breakage.',
            answer: {
                majorNC: false,
                yes: false,
                minorNC: false,
                notApplicable: false,
            },
            score: 2,
            obtainedScore: 0
        },
        {
            topic: 'Basic',
            question: 'Adequate ventilation is provided within the premises.',
            answer: {
                majorNC: false,
                yes: false,
                minorNC: false,
                notApplicable: false,
            },
            score: 2,
            obtainedScore: 0
        },
        {
            topic: 'Basic',
            question: 'An adequate storage facility for food both hot and cold, packaging materials, chemicals, personnel items etc. is available.',
            answer: {
                majorNC: false,
                yes: false,
                minorNC: false,
                notApplicable: false,
            },
            score: 2,
            obtainedScore: 0
        },
        {
            topic: 'Basic',
            question: 'Personnel hygiene facilities are available including adequate number of hand washing facilities, toilets, and changing rooms for employees.',
            answer: {
                majorNC: false,
                yes: false,
                minorNC: false,
                notApplicable: false,
            },
            score: 2,
            obtainedScore: 0
        },
        {
            topic: 'Basic',
            question: 'Raw and finished product to be tested periodically, Check records.',
            answer: {
                majorNC: false,
                yes: false,
                minorNC: false,
                notApplicable: false,
            },
            score: 2,
            obtainedScore: 0
        },
        {
            topic: 'Control of operation: Safe Handling Practices',
            question: 'Incoming material like color, flavor, raw material like milk, oil etc. is procured as per internally laid down specification from approved vendors. Check for records (like certificate of analysis, Form E, specifications, name and address of the supplier, batch no., mfg., use by/expiry date, quantity procured etc.). Only permitted colors and flavors to be used.',
            answer: {
                majorNC: false,
                yes: false,
                minorNC: false,
                notApplicable: false,
            },
            score: 4,
            obtainedScore: 0
        },
        {
            topic: 'Control of operation: Safe Handling Practices',
            question: 'Raw materials are inspected at the time of receiving for food safety hazards. (Farm produce like flour, milk etc. must be checked for spoilage and accepted only in good condition). Ensure raw and finished products are free from visible adulteration.',
            answer: {
                majorNC: false,
                yes: false,
                minorNC: false,
                notApplicable: false,
            },
            score: 2,
            obtainedScore: 0
        },
        {
            topic: 'Control of operation: Safe Handling Practices',
            question: 'Incoming material, semi or final products are stored according to their temperature requirement in a hygienic environment to avoid deterioration and protect from contamination. FIFO & FEFO is practiced.',
            answer: {
                majorNC: false,
                yes: false,
                minorNC: false,
                notApplicable: false,
            },
            score: 2,
            obtainedScore: 0
        },
        {
            topic: 'Control of operation: Safe Handling Practices',
            question: 'Foods of animal origin are stored at a temperature less than or equal to 4oC.',
            answer: {
                majorNC: false,
                yes: false,
                minorNC: false,
                notApplicable: false,
            },
            score: 2,
            obtainedScore: 0
        },
        {
            topic: 'Control of operation: Safe Handling Practices',
            question: 'All raw materials is cleaned thoroughly before food preparation.',
            answer: {
                majorNC: false,
                yes: false,
                minorNC: false,
                notApplicable: false,
            },
            score: 2,
            obtainedScore: 0
        },
        {
            topic: 'Control of operation: Safe Handling Practices',
            question: 'Proper segregation of raw , semi processed and cooked, vegetarian and non-vegetarian food is done.',
            answer: {
                majorNC: false,
                yes: false,
                minorNC: false,
                notApplicable: false,
            },
            score: 2,
            obtainedScore: 0
        },
        {
            topic: 'Control of operation: Safe Handling Practices',
            question: 'All the equipment is adequately cleaned and sanitized before and after food preparation.',
            answer: {
                majorNC: false,
                yes: false,
                minorNC: false,
                notApplicable: false,
            },
            score: 2,
            obtainedScore: 0
        },
        {
            topic: 'Control of operation: Safe Handling Practices',
            question: ' Frozen food is thawed hygienically. No thawed food is stored for later use.',
            answer: {
                majorNC: false,
                yes: false,
                minorNC: false,
                notApplicable: false,
            },
            score: 4,
            obtainedScore: 0
        },
        {
            topic: 'Control of operation: Safe Handling Practices',
            question: 'Vegetarian items are cooked to a minimum of 60°C for 10 minutes or 65°C for 2 minutes core food temperature. Non vegetarian items are cooked for a minimum of 65°C for 10 minutes or 70°C for 2 minutes or 75°C for 15 seconds core food temperature.',
            answer: {
                majorNC: false,
                yes: false,
                minorNC: false,
                notApplicable: false,
            },
            score: 4,
            obtainedScore: 0
        },
        {
            topic: 'Control of operation: Safe Handling Practices',
            question: 'Cooked food intended for refrigeration is cooled appropriately. (High risk food is cooled from 60°C to 21°C within 2 hours or less and further cooled to 5°C within two hours or less.)',
            answer: {
                majorNC: false,
                yes: false,
                minorNC: false,
                notApplicable: false,
            },
            score: 4,
            obtainedScore: 0
        },
        {
            topic: 'Control of operation: Safe Handling Practices',
            question: 'Cutting, portioning, slicing etc. of sweet is not done directly on floor. High risk food is portioned in a refrigerated area or portioned and refrigerated within 30 minutes. When large amount of food is being portioned simultaneously it is done below 15oC. ',
            answer: {
                majorNC: false,
                yes: false,
                minorNC: false,
                notApplicable: false,
            },
            score: 2,
            obtainedScore: 0
        },
        {
            topic: 'Control of operation: Safe Handling Practices',
            question: 'Hot food intended for consumption is held at 65oC and non-vegetarian food intended for consumption is held at 70oC. Cold foods are maintained at 5oC or below and frozen products are held at -18oC or below.',
            answer: {
                majorNC: false,
                yes: false,
                minorNC: false,
                notApplicable: false,
            },
            score: 4,
            obtainedScore: 0
        },
        {
            topic: 'Control of operation: Safe Handling Practices',
            question: 'Reheating is done appropriately and no indirect of reheating such as adding hot water or reheating under bain-marie or reheating under lamp are being used. (The core temperature of food reaches 75oC and is reheated for at least 2 minutes at this temperature.)',
            answer: {
                majorNC: false,
                yes: false,
                minorNC: false,
                notApplicable: false,
            },
            score: 4,
            obtainedScore: 0
        },
        {
            topic: 'Control of operation: Safe Handling Practices',
            question: 'Oil being used is suitable for cooking/frying purposes. Visual inspection of fat and oil by checking the color, flavor, rancidity and floated elements is being done.',
            answer: {
                majorNC: false,
                yes: false,
                minorNC: false,
                notApplicable: false,
            },
            score: 4,
            obtainedScore: 0
        },
        {
            topic: 'Control of operation: Safe Handling Practices',
            question: 'Unused/fresh vegetable oil or fat with not more than 15% Total Polar Compounds (TPC) and used vegetable oil or fat with not more than 25% TPC is being used for food preparation.',
            answer: {
                majorNC: false,
                yes: false,
                minorNC: false,
                notApplicable: false,
            },
            score: 2,
            obtainedScore: 0
        },
        {
            topic: 'Control of operation: Safe Handling Practices',
            question: 'Appropriate records are maintained if oil consumption is more than 50 L/day.',
            answer: {
                majorNC: false,
                yes: false,
                minorNC: false,
                notApplicable: false,
            },
            score: 2,
            obtainedScore: 0
        },
        {
            topic: 'Control of operation: Safe Handling Practices',
            question: 'Vehicle intended for food transportation are kept clean and maintained in good repair & are maintain required temperature.',
            answer: {
                majorNC: false,
                yes: false,
                minorNC: false,
                notApplicable: false,
            },
            score: 4,
            obtainedScore: 0
        },
        {
            topic: 'Control of operation: Safe Handling Practices',
            question: 'Food and non-food products transported at same time in the same vehicle are separated adequately to avoid any risk to food.',
            answer: {
                majorNC: false,
                yes: false,
                minorNC: false,
                notApplicable: false,
            },
            score: 2,
            obtainedScore: 0
        },
        {
            topic: 'Control of operation: Safe Handling Practices',
            question: 'Cutlery, crockery used for serving and dinner accompaniments at dining service are clean and sanitized free from unhygienic matters.',
            answer: {
                majorNC: false,
                yes: false,
                minorNC: false,
                notApplicable: false,
            },
            score: 2,
            obtainedScore: 0
        },
        {
            topic: 'Control of operation: Safe Handling Practices',
            question: 'Packaging, wrapping and serving materials coming in contact with food are clean and of food grade quality. Newspaper or any such material is not being used for storing and wrapping of food.',
            answer: {
                majorNC: false,
                yes: false,
                minorNC: false,
                notApplicable: false,
            },
            score: 2,
            obtainedScore: 0
        },
        {
            topic: 'Control of operation: Safe Handling Practices',
            question: 'Labelling of food products is as per the FSSAI norms. Shelf life of the food products is indicated properly',
            answer: {
                majorNC: false,
                yes: false,
                minorNC: false,
                notApplicable: false,
            },
            score: 2,
            obtainedScore: 0
        },
        {
            topic: 'Control of operation: Safe Handling Practices',
            question: 'Sweets are properly covered during the entire process and not kept exposed. Display of sweets in required temperature conditions according to the nature of sweets.',
            answer: {
                majorNC: false,
                yes: false,
                minorNC: false,
                notApplicable: false,
            },
            score: 4,
            obtainedScore: 0
        },
        {
            topic: 'Maintenance & sanitation',
            question: 'Cleaning of equipment, food premise is done as per cleaning schedule & cleaning programme. There should be no stagnation of water in food zones.',
            answer: {
                majorNC: false,
                yes: false,
                minorNC: false,
                notApplicable: false,
            },
            score: 2,
            obtainedScore: 0
        },
        {
            topic: 'Maintenance & sanitation',
            question: 'Preventive maintenance of equipment and machinery are carried out regularly as per the instructions of the manufacturer. Check for records.',
            answer: {
                majorNC: false,
                yes: false,
                minorNC: false,
                notApplicable: false,
            },
            score: 2,
            obtainedScore: 0
        },
        {
            topic: 'Maintenance & sanitation',
            question: 'Measuring & monitoring devices are calibrated periodically.',
            answer: {
                majorNC: false,
                yes: false,
                minorNC: false,
                notApplicable: false,
            },
            score: 2,
            obtainedScore: 0
        },
        {
            topic: 'Maintenance & sanitation',
            question: 'Pest control program is available & pest control activities are carried out by trained and experienced personnel. Check for records.',
            answer: {
                majorNC: false,
                yes: false,
                minorNC: false,
                notApplicable: false,
            },
            score: 2,
            obtainedScore: 0
        },
        {
            topic: 'Maintenance & sanitation',
            question: 'No signs of pest activity or infestation in premises (eggs, flies, larvae, feces etc.)',
            answer: {
                majorNC: false,
                yes: false,
                minorNC: false,
                notApplicable: false,
            },
            score: 4,
            obtainedScore: 0
        },
        {
            topic: 'Maintenance & sanitation',
            question: 'Drains are designed to meet expected flow loads and equipped with grease and cockroach traps to capture contaminants and pests.',
            answer: {
                majorNC: false,
                yes: false,
                minorNC: false,
                notApplicable: false,
            },
            score: 2,
            obtainedScore: 0
        },
        {
            topic: 'Maintenance & sanitation',
            question: 'Food waste and other refuse are removed periodically from food handling areas to avoid accumulation.',
            answer: {
                majorNC: false,
                yes: false,
                minorNC: false,
                notApplicable: false,
            },
            score: 2,
            obtainedScore: 0
        },
        {
            topic: 'Personal Hygiene of Food Handlers',
            question: 'Annual medical examination & inoculation of food handlers against the enteric group of diseases as per recommended schedule of the vaccine is done. Check for records.',
            answer: {
                majorNC: false,
                yes: false,
                minorNC: false,
                notApplicable: false,
            },
            score: 2,
            obtainedScore: 0
        },
        {
            topic: 'Personal Hygiene of Food Handlers',
            question: 'No person suffering from a disease or illness or with open wounds or burns is involved in handling of food or materials which come in contact with food.',
            answer: {
                majorNC: false,
                yes: false,
                minorNC: false,
                notApplicable: false,
            },
            score: 2,
            obtainedScore: 0
        },
        {
            topic: 'Personal Hygiene of Food Handlers',
            question: 'Food handlers maintain personal cleanliness (clean clothes, trimmed nails &water proof bandage etc.) and personal behavior (hand washing, no loose jewellery, no smoking, no spitting etc.)',
            answer: {
                majorNC: false,
                yes: false,
                minorNC: false,
                notApplicable: false,
            },
            score: 4,
            obtainedScore: 0
        },
        {
            topic: 'Personal Hygiene of Food Handlers',
            question: 'Food handlers are equipped with suitable aprons, gloves, headgear, etc.; wherever necessary.',
            answer: {
                majorNC: false,
                yes: false,
                minorNC: false,
                notApplicable: false,
            },
            score: 2,
            obtainedScore: 0
        },
        {
            topic: 'Training & records keeping',
            question: 'Internal / External audit of the system is done periodically. Check for records.',
            answer: {
                majorNC: false,
                yes: false,
                minorNC: false,
                notApplicable: false,
            },
            score: 2,
            obtainedScore: 0
        },
        {
            topic: 'Training & records keeping',
            question: 'Food Business has an effective consumer complaints redressal mechanism.',
            answer: {
                majorNC: false,
                yes: false,
                minorNC: false,
                notApplicable: false,
            },
            score: 2,
            obtainedScore: 0
        },
        {
            topic: 'Training & records keeping',
            question: 'Food handlers have the necessary knowledge and skills & trained to handle food safely. Check for training records.',
            answer: {
                majorNC: false,
                yes: false,
                minorNC: false,
                notApplicable: false,
            },
            score: 2,
            obtainedScore: 0
        },
        {
            topic: 'Training & records keeping',
            question: 'Appropriate documentation & records are available and retained for a period of one year, whichever is more.',
            answer: {
                majorNC: false,
                yes: false,
                minorNC: false,
                notApplicable: false,
            },
            score: 4,
            obtainedScore: 0
        },
    ]

}

export default checkListSweet;