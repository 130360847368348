const checkListMeat = {
    totalScore: 96,
    totalObtainedScore: 0,
    topic: "Hygiene Rating Checklist for Meat Retail",
    checkList: [
        {
            topic: 'Basic',
            question: 'The FSSAI license/Registration and Food Safety Display Board (FSDB) both are displayed at a prominent location.',
            answer: {
                majorNC: false,
                yes: false,
                minorNC: false,
                notApplicable: false,
            },
            score: 2,
            obtainedScore: 0
        },
        {
            topic: 'Basic',
            question: 'The meat shop located away from environmental polluted area like dust, dirt, fumes, smoke and other contaminants. The premise should be of appropriate size with adequate working space having size of minimum 3 meters.',
            answer: {
                majorNC: false,
                yes: false,
                minorNC: false,
                notApplicable: false,
            },
            score: 2,
            obtainedScore: 0
        },
        {
            topic: 'Basic',
            question: 'The layout of the premise shall be such that there is a unidirectional flow of men and material to avoid cross contamination which could be achieved by operation timings.',
            answer: {
                majorNC: false,
                yes: false,
                minorNC: false,
                notApplicable: false,
            },
            score: 2,
            obtainedScore: 0
        },
        {
            topic: 'Basic',
            question: 'Internal structure & fittings are made of non-toxic, cleanable, impermeable materials which prevents the entry of dirt, dust & pests.',
            answer: {
                majorNC: false,
                yes: false,
                minorNC: false,
                notApplicable: false,
            },
            score: 2,
            obtainedScore: 0
        },
        {
            topic: 'Basic',
            question: 'Floors are impervious, non-slippery, easy to clean/washable without possibility of stagnation.',
            answer: {
                majorNC: false,
                yes: false,
                minorNC: false,
                notApplicable: false,
            },
            score: 2,
            obtainedScore: 0
        },
        {
            topic: 'Basic',
            question: 'Walls are paved with imperious glazed tiles at least 5 feet height.',
            answer: {
                majorNC: false,
                yes: false,
                minorNC: false,
                notApplicable: false,
            },
            score: 2,
            obtainedScore: 0
        },
        {
            topic: 'Basic',
            question: 'Windows & other openings are free from accumulated dirt; those which when open are fitted with insect-proof screen.',
            answer: {
                majorNC: false,
                yes: false,
                minorNC: false,
                notApplicable: false,
            },
            score: 2,
            obtainedScore: 0
        },
        {
            topic: 'Basic',
            question: 'Doors are smooth, non-absorbent surface, close fitted & Self-closing (where appropriate) with tinted glass.',
            answer: {
                majorNC: false,
                yes: false,
                minorNC: false,
                notApplicable: false,
            },
            score: 2,
            obtainedScore: 0
        },
        {
            topic: 'Basic',
            question: 'Premise is well ventilated and properly lighted. Premise hassufficient lighting of at least 220 lux in dressing or work area.',
            answer: {
                majorNC: false,
                yes: false,
                minorNC: false,
                notApplicable: false,
            },
            score: 2,
            obtainedScore: 0
        },
        {
            topic: 'Basic',
            question: 'In case of poultry meat shop, adequate space for the handling, slaughter/dressing of poultry birds at the rear portion which is tiled, well lit and ventilated. The working and storage space should be separate.',
            answer: {
                majorNC: false,
                yes: false,
                minorNC: false,
                notApplicable: false,
            },
            score: 4,
            obtainedScore: 0
        },
        {
            topic: 'Basic',
            question: 'The chopping block should be made of food grade synthetic material or a hard wooden block without chipping at the surface, to be kept neat & clean all the times.',
            answer: {
                majorNC: false,
                yes: false,
                minorNC: false,
                notApplicable: false,
            },
            score: 2,
            obtainedScore: 0
        },
        {
            topic: 'Basic',
            question: 'Facility of hot and cold water for cleaning / sanitation and sterilization of equipment and premise.',
            answer: {
                majorNC: false,
                yes: false,
                minorNC: false,
                notApplicable: false,
            },
            score: 2,
            obtainedScore: 0
        },
        {
            topic: 'Basic',
            question: 'The premise is well equipped with chilling/ freezing equipment for keeping of the meat/ carcasses and not to be kept in open and / or wrapped in a wet cloth. In addition, the cooling/ freezing cabinets shall be provided to keep the processed meat (fresh/frozen or packaged) or value added/marinated meat products with temperature measuring or recording devices.',
            answer: {
                majorNC: false,
                yes: false,
                minorNC: false,
                notApplicable: false,
            },
            score: 4,
            obtainedScore: 0
        },
        {
            topic: 'Control of operation: Safe Handling Practices',
            question: 'The duly stamped meat / carcasses or live poultry birds shall be procured from a Govt. authorized or Municipal Corporation slaughter house and poultry market respectively. ',
            answer: {
                majorNC: false,
                yes: false,
                minorNC: false,
                notApplicable: false,
            },
            score: 4,
            obtainedScore: 0
        },
        {
            topic: 'Control of operation: Safe Handling Practices',
            question: 'The duly stamped meat / carcasses or live poultry birds shall be procured from a Govt. authorized or Municipal Corporation slaughter house and poultry market respectively. If the meat is outsourced, it should be from an FSSAI licensed/registered supplier or vendor',
            answer: {
                majorNC: false,
                yes: false,
                minorNC: false,
                notApplicable: false,
            },
            score: 4,
            obtainedScore: 0
        },
        {
            topic: 'Control of operation: Safe Handling Practices',
            question: 'The live poultry birds or carcasses should be transported in an appropriate temperature controlled vehicle without inflicting cruelty to the birds or preventing contamination. The birds should be kept in the cages avoiding overcrowding with provision of feed & water.',
            answer: {
                majorNC: false,
                yes: false,
                minorNC: false,
                notApplicable: false,
            },
            score: 2,
            obtainedScore: 0
        },
        {
            topic: 'Control of operation: Safe Handling Practices',
            question: 'The temperature of the premises for dressing / minor processing is controlled & held suitably low particularly if the height of the meat shop is less than 2.5 meter.',
            answer: {
                majorNC: false,
                yes: false,
                minorNC: false,
                notApplicable: false,
            },
            score: 2,
            obtainedScore: 0
        },
        {
            topic: 'Control of operation: Safe Handling Practices',
            question: 'Area used for preparing, packing or other handling of meat is equipped with adequate facilities for cleaning / disinfecting the implements such as knives, steel, cleavers, saws etc.',
            answer: {
                majorNC: false,
                yes: false,
                minorNC: false,
                notApplicable: false,
            },
            score: 2,
            obtainedScore: 0
        },
        {
            topic: 'Control of operation: Safe Handling Practices',
            question: 'Containers used for storing inedible parts, cleaning chemicals & other hazardous substance are clearly identified, kept separately from meat or its products.',
            answer: {
                majorNC: false,
                yes: false,
                minorNC: false,
                notApplicable: false,
            },
            score: 2,
            obtainedScore: 0
        },
        {
            topic: 'Control of operation: Safe Handling Practices',
            question: 'Knives and sharpeners (mushtala) are made of stainless steel or corrosion resistant metal and sanitized and sterilized before use.',
            answer: {
                majorNC: false,
                yes: false,
                minorNC: false,
                notApplicable: false,
            },
            score: 2,
            obtainedScore: 0
        },
        {
            topic: 'Control of operation: Safe Handling Practices',
            question: 'The meat shall be packed / dispensed using food grade materials in a hygienic manner.',
            answer: {
                majorNC: false,
                yes: false,
                minorNC: false,
                notApplicable: false,
            },
            score: 2,
            obtainedScore: 0
        },
        {
            topic: 'Control of operation: Safe Handling Practices',
            question: 'The meat portioning is done in hygienic conditions. Meat is portioned in a refrigerated area or portioned and refrigerated within 30 minutes.',
            answer: {
                majorNC: false,
                yes: false,
                minorNC: false,
                notApplicable: false,
            },
            score: 2,
            obtainedScore: 0
        },
        {
            topic: 'Maintenance & sanitation',
            question: 'Clean potable water as well as supply of hot water is available in dressing /slaughter area.',
            answer: {
                majorNC: false,
                yes: false,
                minorNC: false,
                notApplicable: false,
            },
            score: 4,
            obtainedScore: 0
        },
        {
            topic: 'Maintenance & sanitation',
            question: 'Cleaning/ sterilization of equipment e.g. butcher’s knife/cleaver, hooks etc. and of premises with hot water not less than 82 degree Celsius is done daily.',
            answer: {
                majorNC: false,
                yes: false,
                minorNC: false,
                notApplicable: false,
            },
            score: 2,
            obtainedScore: 0
        },
        {
            topic: 'Maintenance & sanitation',
            question: 'Floorings are washed daily with disinfectant. Lime washing, color washing or paint washing is done at least once in 12 months.',
            answer: {
                majorNC: false,
                yes: false,
                minorNC: false,
                notApplicable: false,
            },
            score: 2,
            obtainedScore: 0
        },
        {
            topic: 'Maintenance & sanitation',
            question: 'Preventive and breakdown maintenance of equipment and machinery is carried out regularly.',
            answer: {
                majorNC: false,
                yes: false,
                minorNC: false,
                notApplicable: false,
            },
            score: 2,
            obtainedScore: 0
        },
        {
            topic: 'Maintenance & sanitation',
            question: 'Effective pest control management be there.',
            answer: {
                majorNC: false,
                yes: false,
                minorNC: false,
                notApplicable: false,
            },
            score: 2,
            obtainedScore: 0
        },
        {
            topic: 'Maintenance & sanitation',
            question: 'The entry of animals like dog, cats or mongoose into the premises should be prohibited. ',
            answer: {
                majorNC: false,
                yes: false,
                minorNC: false,
                notApplicable: false,
            },
            score: 2,
            obtainedScore: 0
        },
        {
            topic: 'Maintenance & sanitation',
            question: 'There is an efficient drainage system and all drains and gutters are properly installed / fitted with traps and screens.',
            answer: {
                majorNC: false,
                yes: false,
                minorNC: false,
                notApplicable: false,
            },
            score: 2,
            obtainedScore: 0
        },
        {
            topic: 'Maintenance & sanitation',
            question: 'Liquid waste like waste water should be drained immediately into drains or sewers and not allowed to accumulate.',
            answer: {
                majorNC: false,
                yes: false,
                minorNC: false,
                notApplicable: false,
            },
            score: 2,
            obtainedScore: 0
        },
        {
            topic: 'Maintenance & sanitation',
            question: 'Covered garbage bins with foot operated pedals should be used for waste disposal. Waste is removed from meat handling area at regular intervals & the receptacles are cleaned & disinfected immediately after use.',
            answer: {
                majorNC: false,
                yes: false,
                minorNC: false,
                notApplicable: false,
            },
            score: 2,
            obtainedScore: 0
        },
        {
            topic: 'Maintenance & sanitation',
            question: 'There shall be proper arrangement for disposal of feathers, skin, offal or waste tissues /trimmings of the carcasses arising from the dressing of poultry birds /meat preparation through the concerned agency/ local authority/ Municipality /corporation as per solid waste management laws or rules in force from time to time.',
            answer: {
                majorNC: false,
                yes: false,
                minorNC: false,
                notApplicable: false,
            },
            score: 4,
            obtainedScore: 0
        },
        {
            topic: 'Personal Hygiene of Food Handlers',
            question: 'A medical health certificate on yearly basis for the butcher/meat handlers from a Registered Medical Practitioner to ensure they are free from any infectious / contagious disease with regular updation of health cards. The persons suffering from any infectious disease shall not be permitted to work. Inoculation of butcher/meat handlers against the enteric group of diseases as per recommended schedule of the vaccine is done. Check for the records.',
            answer: {
                majorNC: false,
                yes: false,
                minorNC: false,
                notApplicable: false,
            },
            score: 4,
            obtainedScore: 0
        },
        {
            topic: 'Personal Hygiene of Food Handlers',
            question: 'Food handlers maintain personal cleanliness (clean clothes, trimmed nails, shave properly & water proof bandage etc.) and personal behavior (hand washing, no loose jewellery, no smoking, no spitting etc.)',
            answer: {
                majorNC: false,
                yes: false,
                minorNC: false,
                notApplicable: false,
            },
            score: 4,
            obtainedScore: 0
        },
        {
            topic: 'Personal Hygiene of Food Handlers',
            question: 'The food handlers should wear clean protective clothing, beard/hair net (cap) during cutting / handling of meat. The meat handlers should not wear personal effects e.g. watch, rings, chains, other loose jewellery during the work.',
            answer: {
                majorNC: false,
                yes: false,
                minorNC: false,
                notApplicable: false,
            },
            score: 2,
            obtainedScore: 0
        },
        {
            topic: 'Personal Hygiene of Food Handlers',
            question: 'No person with open wounds or burns or skin infection is involved in handling of meat or materials which come in contact with meat. Any behaviour which can potentially contaminate the meat such as eating, use of tobacco, chewing, spitting, shall be prohibited in any part of the registered unit.',
            answer: {
                majorNC: false,
                yes: false,
                minorNC: false,
                notApplicable: false,
            },
            score: 2,
            obtainedScore: 0
        },
        {
            topic: 'Personal Hygiene of Food Handlers',
            question: 'Hand washing facility be fitted in the premises. Hands should be washed thoroughly before start of the work, after use of toilet, after handling chemicals / disinfectants or after touching unclean surfaces.',
            answer: {
                majorNC: false,
                yes: false,
                minorNC: false,
                notApplicable: false,
            },
            score: 2,
            obtainedScore: 0
        },
        {
            topic: 'Training & records keeping',
            question: 'Internal/External audit of the system is done periodically. Check for records.',
            answer: {
                majorNC: false,
                yes: false,
                minorNC: false,
                notApplicable: false,
            },
            score: 2,
            obtainedScore: 0
        },
        {
            topic: 'Training & records keeping',
            question: 'Food Business has an effective consumer complaints redressal mechanism.',
            answer: {
                majorNC: false,
                yes: false,
                minorNC: false,
                notApplicable: false,
            },
            score: 2,
            obtainedScore: 0
        },
        {
            topic: 'Training & records keeping',
            question: 'The personnel engaged (butcher, meat handlers) in the meat shop shall undergo training for hygiene. Check for training records.',
            answer: {
                majorNC: false,
                yes: false,
                minorNC: false,
                notApplicable: false,
            },
            score: 2,
            obtainedScore: 0
        },
        {
            topic: 'Training & records keeping',
            question: 'Appropriate documentation and records are available and retained for a period of one year, whichever is more.',
            answer: {
                majorNC: false,
                yes: false,
                minorNC: false,
                notApplicable: false,
            },
            score: 4,
            obtainedScore: 0
        },
        

    ]

}

export default checkListMeat;