import {
  Button,
  CircularProgress,
  TextField,
  InputLabel,
  Card,
  TableContainer,
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  Checkbox,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import "../../Admin/AddRole/AddRole.css";
import Iconify from "src/components/iconify";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import USERSROUTES from "src/API_Routes/User/User_Routers";
import { FileUpload, patchData, postData } from "src/webService/webService";
import { useDispatch, useSelector } from "react-redux";
import {
  setBasicData,
  setSpecificAuditor,
  setSpecificUser,
} from "src/redux/slices/UserDetailSlice";
import EMAIL_ROUTES from "src/API_Routes/Email/Email_Routers";
import NEW_ROLE from "src/Config/templates/html/NewRole";
import Scrollbar from "../../scrollbar/Scrollbar";
import FILEROUTES from "../../../API_Routes/File/File_Router";
import SERVER_URL from "../../../utils/serverURL";

export default function AddRole({ HandlAaddRole, Role, action, GetSale }) {
  const dispatch = useDispatch();
  const specificUserData = useSelector(
    (state) => state.userDetail.SpecificUser
  );
  const specificAuditor = useSelector(
    (state) => state.userDetail.SpecificAuditor
  );
  const scheme = sessionStorage.getItem("scheme");
  const userid_val = sessionStorage.getItem("UserId");
  const USER_LIST = useSelector((state) => state.userDetail.BasicData);
  const specificUserData_id = specificUserData._id;
  const accessToken = sessionStorage.getItem("Token");
  const [name, setName] = useState("");
  const [lastname, setLastName] = useState("");
  const [EmpId, setEmpId] = useState("");
  const [Email, setEmail] = useState("");
  const [PhoneNo, setPhoneNo] = useState("");
  const [JobTitle, setJobTitle] = useState("");
  const [Password, setPassword] = useState("");
  const [BloodGroup, setBloodGroup] = useState("");
  const [Address, setAddress] = useState("");
  const [LanguagesSpoken, setLanguagesSpoken] = useState("");
  const [LanguagesSpokenArray, setLanguagesSpokenArray] = useState([]);
  const [loader, setLoader] = useState(false);
  const [showauditoradvance, setshowauditoradvance] = useState(false);
  const [Basic_id, setBasic_id] = useState();
  const [profilePicFileObjectFinal, setprofilePicFileObjectFinal] =
    useState(null);
  const [profilePicFileObject, setprofilePicFileObject] = useState(null);
  useEffect(() => {
    if (specificUserData && action !== "Add") {
      setName(specificUserData.firstName);
      setLastName(specificUserData.lastName);
      setEmpId(specificUserData.employeeId);
      setEmail(specificUserData.email);
      setPhoneNo(specificUserData.phoneNumber);
      setJobTitle(specificUserData.jobTitle);
      setPassword(specificUserData.password);
      setBloodGroup(specificUserData.bloodType);
      setAddress(specificUserData.address);
      setLanguagesSpokenArray(specificUserData.languagesSpoken);
      setCVFileObject(specificUserData.CVFileObject);
      seteducationCertificatesFileObjects(
        specificUserData.educationCertificatesFileObjects
      );
      setexperienceWorkHistory(specificUserData.experienceWorkHistory);
      setotherDocumentsFileObjects(specificUserData.otherDocumentsFileObjects);
      setprofilePicFileObject(specificUserData.profilePicFileObject);
    }
  }, [specificUserData]);

  const updateLanguages = () => {
    if (LanguagesSpoken.trim() !== "") {
      setLanguagesSpokenArray((prevArray) => [...prevArray, LanguagesSpoken]);
      setLanguagesSpoken("");
    }
  };

  const removeLanguage = (index) => {
    setLanguagesSpokenArray((prevArray) =>
      prevArray.filter((_, i) => i !== index)
    );
  };
  const disabled =
    !name &&
    !lastname &&
    !EmpId &&
    !Email &&
    !PhoneNo &&
    !JobTitle &&
    !Password &&
    !BloodGroup &&
    !Address &&
    LanguagesSpokenArray.length === 0;
  const addUser = async () => {
    setLoader(true);
    const params = {
      employeeId: EmpId,
      firstName: name,
      lastName: lastname,
      email: Email,
      bloodType: BloodGroup,
      password: Password,
      role: Role === "Sale" ? "Sales" : Role,
      phoneNumber: PhoneNo,
      jobTitle: JobTitle,
      address: Address,
      languagesSpoken: LanguagesSpokenArray,
    };
    if (scheme === "HRA") {
      params.operationManagerId = userid_val;
    } else {
      params.adminId = userid_val;
    }
    if (
      !name &&
      !EmpId &&
      !lastname &&
      !Email &&
      !PhoneNo &&
      !JobTitle &&
      !Password &&
      !BloodGroup &&
      !Address &&
      LanguagesSpokenArray.length === 0
    ) {
      toast.error("All The Filed's Are Required", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      postData(USERSROUTES.SIGNUP, params, accessToken, dispatch)
        .then((res) => {
          setLoader(false);
          if (Role === "Auditor") {
            setBasic_id(res.data.userId);
            setshowauditoradvance(true);
          } else {
            HandlAaddRole();
            const HTML_DATA = {
              Email: Email,
              Password: Password,
            };
            console.log("HTML_DATA", HTML_DATA);
            const emailRes = postData(
              EMAIL_ROUTES.SEND_EMAIL_TEXT,
              {
                to: Email,
                subject: "Log In Credentials",
                html: NEW_ROLE(HTML_DATA),
              },
              accessToken,
              dispatch
            );
            console.log("emailRes", emailRes);
            GetSale(1);
          }
        })
        .catch((error) => {
          setLoader(false);
          console.error("Error:", error);
        });
    }
  };
  const edituser = () => {
    setLoader(true);
    const params = {
      employeeId: EmpId,
      firstName: name,
      lastName: lastname,
      email: Email,
      bloodType: BloodGroup,
      password: Password,
      role: Role === "Sale" ? "Sales" : Role,
      phoneNumber: PhoneNo,
      jobTitle: JobTitle,
      address: Address,
      languagesSpoken: LanguagesSpokenArray,
      CVFileObject:
        CVFileObjectFinal === null ? CVFileObject : CVFileObjectFinal,
      educationCertificatesFileObjects: educationCertificatesFileObjects,
      experienceWorkHistory: experienceWorkHistory,
      otherDocumentsFileObjects: otherDocumentsFileObjects,
      profilePicFileObject: profilePicFileObjectFinal,
    };
    if (scheme === "HRA") {
      params.operationManagerId = userid_val;
    } else {
      params.adminId = userid_val;
    }
    if (
      !name &&
      !EmpId &&
      !lastname &&
      !Email &&
      !PhoneNo &&
      !JobTitle &&
      !Password &&
      !BloodGroup &&
      !Address &&
      LanguagesSpokenArray.length === 0
    ) {
      toast.error("All The Filed's Are Required", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      patchData(USERSROUTES.EDITUSER + specificUserData_id, params, accessToken,dispatch)
        .then((res) => {
          setLoader(false);
          if (Role === "Auditor") {
            setBasic_id(res.data.userId);
            setshowauditoradvance(true);
          } else {
            HandlAaddRole();
            GetSale(1);
          }
          const updatedResults = USER_LIST.results.map((item) => {
            if (item._id === res.data._id) {
              return res.data;
            }
            return item;
          });
          const updatedResult = { ...USER_LIST, results: updatedResults };
          dispatch(setBasicData(updatedResult));
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  };
  const RemoveSpecificUser = () => {
    dispatch(setSpecificUser(""));
  };

  //auditor specific
  const [alternateName, setalternateName] = useState("");
  const [managingOffice, setmanagingOffice] = useState("");
  const [supervisorInformation, setsupervisorInformation] = useState("");
  const [ID_Number, setID_Number] = useState("0");
  const [yearOfBirth, setyearOfBirth] = useState("");
  const [status, setstatus] = useState("");
  const [dateOfSignature, setdateOfSignature] = useState("");
  const [availability, setavailability] = useState("");
  const [languagesWritten, setlanguagesWritten] = useState("");
  const [comments, setcomments] = useState("");
  const [expirationDate, setexpirationDate] = useState("");

  // Auditor Skillset
  const [formData, setFormData] = useState({
    grade: "",
    certificationIssueDate: "",
    certificationExpiration: "",
    reminderDate: "",
    nextWitnessDate: "",
    lastWitnessDate: "",
    lastTrainingDate: "",
    comments: "",
    skillset: "",
    standards: [],
    externalQualification: [],
    isSkillSetsArchived: false,
  });

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const addadvance = () => {
    setLoader(true);
    const params = {
      alternateName: alternateName,
      managingOffice: managingOffice,
      supervisorInformation: supervisorInformation,
      ID_Number: ID_Number,
      yearOfBirth: yearOfBirth,
      status: status,
      languagesWritten: languagesWritten,
      availability: availability,
      contractInformation: {
        dateOfSignature: dateOfSignature,
        comments: comments,
        expirationDate: expirationDate,
      },
      auditorSkillsets: [
        {
          standards: formData.standards,
          externalQualification: formData.externalQualification,
          grade: formData.grade,
          certificationIssueDate: formData.certificationIssueDate,
          certificationExpiration: formData.certificationExpiration,
          reminderDate: formData.reminderDate,
          nextWitnessDate: formData.nextWitnessDate,
          lastWitnessDate: formData.lastWitnessDate,
          lastTrainingDate: formData.lastTrainingDate,
          comments: formData.comments,
          skillset: formData.skillset,
          isSkillSetsArchived: formData.isSkillSetsArchived,
        },
      ],
    };
    const isEmptyField = Object.values(params).some((value) => value === "");

    if (isEmptyField) {
      toast.error("All fields are required", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoader(false);
    } else {
      postData(
        USERSROUTES.AUDITORSIGNUP(Basic_id),
        params,
        accessToken,
        dispatch
      )
        .then((res) => {
          setLoader(false);
          if (Role === "Auditor") {
            setshowauditoradvance(false);
            HandlAaddRole();
            GetSale(1);
          }
        })
        .catch((error) => {
          setLoader(false);
          console.error("Error:", error);
        });
    }
  };

  const editadvance = () => {
    setLoader(true);
    const params = {
      alternateName: alternateName,
      managingOffice: managingOffice,
      supervisorInformation: supervisorInformation,
      ID_Number: ID_Number,
      yearOfBirth: yearOfBirth,
      status: status,
      languagesWritten: languagesWritten,
      availability: availability,
      contractInformation: {
        dateOfSignature: dateOfSignature,
        comments: comments,
        expirationDate: expirationDate,
      },
    };
    const isEmptyField = Object.values(params).some((value) => value === "");

    if (isEmptyField) {
      toast.error("All fields are required", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoader(false);
    } else {
      patchData(
        USERSROUTES.AUDITOREDIT(specificAuditor.userBasicId),
        params,
        accessToken,
        dispatch
      )
        .then((res) => {
          setLoader(false);
          HandlAaddRole();
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  };
  useEffect(() => {
    const getTodayDate = () => {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, "0");
      const day = String(today.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    };
    setyearOfBirth(getTodayDate());
    setdateOfSignature(getTodayDate());
    setexpirationDate(getTodayDate());
  }, []);
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  useEffect(() => {
    if (
      (showauditoradvance && specificAuditor && action !== "Add") ||
      action === "View"
    ) {
      setalternateName(specificAuditor.alternateName);
      setmanagingOffice(specificAuditor.managingOffice);
      setsupervisorInformation(specificAuditor.supervisorInformation);
      setID_Number(specificAuditor.ID_Number);
      setyearOfBirth(formatDate(specificAuditor.yearOfBirth));
      setstatus(specificAuditor.status);
      setdateOfSignature(
        formatDate(specificAuditor.contractInformation?.dateOfSignature)
      );
      setcomments(specificAuditor.contractInformation?.comments);
      setexpirationDate(
        formatDate(specificAuditor.contractInformation?.expirationDate)
      );
      setavailability(specificAuditor.availability);
      setlanguagesWritten(specificAuditor?.languagesWritten);
    }
  }, [showauditoradvance]);

  const [addnewAuditorskillset, setaddnewAuditorskillset] = useState(false);
  const AddAuditorSkillset = () => {
    setaddnewAuditorskillset(true);
  };
  const updateAuditorSkillset = (data) => {
    const updatedAuditorSkillsets = specificAuditor.auditorSkillsets.map(
      (skillset, index) => {
        if (index === editingRow) {
          // If it's an edit, update the existing skillset
          return {
            ...skillset,
            standards: formData.standards,
            externalQualification: formData.externalQualification,
            grade: formData.grade,
            isSkillSetsArchived: formData.isSkillSetsArchived,
            certificationIssueDate: formData.certificationIssueDate,
            certificationExpiration: formData.certificationExpiration,
            reminderDate: formData.reminderDate,
            nextWitnessDate: formData.nextWitnessDate,
            lastWitnessDate: formData.lastWitnessDate,
            lastTrainingDate: formData.lastTrainingDate,
            comments: formData.comments,
            skillset: formData.skillset,
          };
        } else {
          // Otherwise, return the existing skillset
          return skillset;
        }
      }
    );
    const newAuditorSkillset = {
      grade: formData.grade,
      standards: formData.standards,
      externalQualification: formData.externalQualification,
      isSkillSetsArchived: formData.isSkillSetsArchived,
      certificationIssueDate: formData.certificationIssueDate,
      certificationExpiration: formData.certificationExpiration,
      reminderDate: formData.reminderDate,
      nextWitnessDate: formData.nextWitnessDate,
      lastWitnessDate: formData.lastWitnessDate,
      lastTrainingDate: formData.lastTrainingDate,
      comments: formData.comments,
      skillset: formData.skillset,
    };
    const AddNewAuditorSkillsets = [
      ...specificAuditor.auditorSkillsets, // Existing auditorSkillsets
      newAuditorSkillset, // New auditorSkillset
    ];

    // Make API call to update the auditor skillsets
    let params;
    if (data === "Edit") {
      params = {
        auditorSkillsets: updatedAuditorSkillsets,
      };
    } else {
      params = {
        auditorSkillsets: AddNewAuditorSkillsets,
      };
    }

    patchData(
      USERSROUTES.AUDITOREDIT(specificAuditor.userBasicId),
      params,
      accessToken,
      dispatch
    )
      .then((res) => {
        setEditingRow(null);
        setLoader(false);
        setaddnewAuditorskillset(false);
        dispatch(setSpecificAuditor(res.data));
        setFormData({
          grade: "",
          certificationIssueDate: "",
          certificationExpiration: "",
          reminderDate: "",
          nextWitnessDate: "",
          lastWitnessDate: "",
          lastTrainingDate: "",
          comments: "",
          skillset: "",
          standards: [],
          externalQualification: [],
          isSkillSetsArchived: false,
        });
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const [editingRow, setEditingRow] = useState(null); // State to track which row is being edited
  const handleEditClick = (index) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setaddnewAuditorskillset(true);
    const selectedRow = specificAuditor.auditorSkillsets[index];
    setFormData({
      ...formData,
      grade: selectedRow.grade || "",
      certificationIssueDate:
        formatDate(selectedRow.certificationIssueDate) || "",
      certificationExpiration:
        formatDate(selectedRow.certificationExpiration) || "",
      reminderDate: formatDate(selectedRow.reminderDate) || "",
      nextWitnessDate: formatDate(selectedRow.nextWitnessDate) || "",
      lastWitnessDate: formatDate(selectedRow.lastWitnessDate) || "",
      lastTrainingDate: formatDate(selectedRow.lastTrainingDate) || "",
      comments: selectedRow.comments || "",
      skillset: selectedRow.skillset || "",
      standards: selectedRow.standards || [],
      externalQualification: selectedRow.externalQualification || [],
      isSkillSetsArchived: selectedRow.isSkillSetsArchived || false,
    });
    // Set the editingRow state to the index of the selected row
    setEditingRow(index);
  };
  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setFormData({
      ...formData,
      [name]: checked,
    });
  };
  const [CVFileObject, setCVFileObject] = useState(null);
  const [CVFileObjectFinal, setCVFileObjectFinal] = useState(null);
  const [CVFile, SetCVFile] = useState("");
  const [EducationCertificate, setEducationCertificate] = useState("");
  const [profilePic, SetprofilePic] = useState("");
  const [otherDocuments, setotherDocuments] = useState("");
  const [
    educationCertificatesFileObjects,
    seteducationCertificatesFileObjects,
  ] = useState([]);
  const [otherDocumentsFileObjects, setotherDocumentsFileObjects] = useState(
    []
  );
  const [experienceWorkHistory, setexperienceWorkHistory] = useState([]);
  const [WorkHistory, setWorkHistory] = useState("");

  const handleFileChange = (event, data) => {
    const file = event.target.files[0];
    if (data === "CV") {
      SetCVFile(file);
    } else if (data === "Education") {
      setEducationCertificate(file);
    } else if (data === "Profile") {
      SetprofilePic(file);
    } else {
      setotherDocuments(file);
    }
    event.target.value = null;
  };
  const removeFile = (type, index) => {
    switch (type) {
      case "CV":
        setCVFileObject(null);
        break;
      case "Education":
        seteducationCertificatesFileObjects((prevState) =>
          prevState.filter((_, i) => i !== index)
        );
        break;
      case "Other":
        setotherDocumentsFileObjects((prevState) =>
          prevState.filter((_, i) => i !== index)
        );
        break;
      case "Work":
        setexperienceWorkHistory((prevState) =>
          prevState.filter((_, i) => i !== index)
        );
        break;
      default:
        break;
    }
  };
  const updateWorkHistory = () => {
    if (WorkHistory.trim() !== "") {
      setexperienceWorkHistory((prevArray) => [...prevArray, WorkHistory]);
      setWorkHistory("");
    }
  };
  useEffect(() => {
    const uploadFile = async (
      file,
      setFileObject,
      setFileObjectVal,
      setFile
    ) => {
      if (file) {
        try {
          const formData = new FormData();
          formData.append("file", file);
          const res = await FileUpload(
            FILEROUTES.UPLOAD + userid_val,
            formData,
            dispatch
          );
          setFileObject(res.data);
          setFileObjectVal(res.data);
          setFile("");
        } catch (error) {
          setFile("");
          console.error("Error:", error);
        }
      }
    };
    uploadFile(
      profilePic,
      setprofilePicFileObjectFinal,
      setprofilePicFileObject,
      SetprofilePic
    );
    uploadFile(CVFile, setCVFileObjectFinal, setCVFileObject, SetCVFile);
  }, [profilePic, CVFile]);
  useEffect(() => {
    const uploadFile = async (file, setFileObjects) => {
      if (file) {
        try {
          const formData = new FormData();
          formData.append("file", file);
          const res = await FileUpload(
            FILEROUTES.UPLOAD + userid_val,
            formData,
            dispatch
          );
          setFileObjects((prevState) => [...prevState, res.data]);
          if (file === EducationCertificate) setEducationCertificate("");
          else setotherDocuments("");
        } catch (error) {
          if (file === EducationCertificate) setEducationCertificate("");
          else setotherDocuments("");
          console.error("Error:", error);
        }
      }
    };

    if (EducationCertificate) {
      uploadFile(EducationCertificate, seteducationCertificatesFileObjects);
    }

    if (otherDocuments) {
      uploadFile(otherDocuments, setotherDocumentsFileObjects);
    }
  }, [EducationCertificate, otherDocuments]);
  return (
    <>
      <ToastContainer />
      <div className="add-border-outline p-5 mt-5">
        <div className="px-2 add-title">
          <span className="">
            {action} {Role}
          </span>
        </div>
        {!showauditoradvance ? (
          <div className="d-flex flex-wrap justify-content-start">
            <div className="col-4 px-2 py-3">
              <TextField
                className="w-100"
                name="Name"
                label="First Name"
                value={name}
                size="small"
                disabled={action === "View" ? true : false}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </div>
            <div className="col-4 px-2 py-3">
              <TextField
                className="w-100"
                name="lastname"
                label="Last Name"
                value={lastname}
                size="small"
                disabled={action === "View" ? true : false}
                onChange={(e) => setLastName(e.target.value)}
                required
              />
            </div>
            <div className="col-4 px-2 py-3">
              <TextField
                className="w-100"
                name="EmpId"
                label="EmpId"
                value={EmpId}
                size="small"
                disabled={action === "View" ? true : false}
                onChange={(e) => setEmpId(e.target.value)}
                required
              />
            </div>
            <div className="col-4 px-2 py-3">
              <TextField
                className="w-100"
                name="Email"
                label="Email"
                value={Email}
                size="small"
                disabled={action === "View" ? true : false}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="col-4 px-2 py-3">
              <TextField
                className="w-100"
                name="PhoneNo"
                label="PhoneNo"
                value={PhoneNo}
                size="small"
                disabled={action === "View" ? true : false}
                onChange={(e) => setPhoneNo(e.target.value)}
                required
              />
            </div>
            <div className="col-4 px-2 py-3">
              <TextField
                className="w-100"
                name="JobTitle"
                label="JobTitle"
                value={JobTitle}
                size="small"
                disabled={action === "View" ? true : false}
                onChange={(e) => setJobTitle(e.target.value)}
                required
              />
            </div>
            {action !== "View" && (
              <div className="col-4 px-2 py-3">
                <TextField
                  className="w-100"
                  name="Password"
                  label={action === "Add" ? "Password" : "New Password"}
                  value={Password}
                  size="small"
                  disabled={action === "View" ? true : false}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </div>
            )}
            <div className="col-4 px-2 py-3">
              <TextField
                className="w-100"
                name="BloodGroup"
                label="BloodGroup"
                value={BloodGroup}
                size="small"
                disabled={action === "View" ? true : false}
                onChange={(e) => setBloodGroup(e.target.value)}
                required
              />
            </div>
            <div className="col-12 px-2 py-3">
              <TextField
                className="w-100"
                name="Address"
                label="Address"
                value={Address}
                size="small"
                disabled={action === "View" ? true : false}
                onChange={(e) => setAddress(e.target.value)}
              />
            </div>
            <div className="col-4 px-2 py-3 position-relative overflow-hidden">
              <TextField
                className="w-100"
                name="LanguagesSpoken"
                label="LanguagesSpoken"
                value={LanguagesSpoken}
                size="small"
                disabled={action === "View" ? true : false}
                onChange={(e) => setLanguagesSpoken(e.target.value)}
                required
              />
              {LanguagesSpoken === "" ? (
                ""
              ) : (
                <div className="verify-button" onClick={updateLanguages}>
                  <Iconify className="" icon={"teenyicons:tick-outline"} />
                </div>
              )}
              {LanguagesSpokenArray.length > 0 && (
                <ul className="d-flex flex-wrap list-language">
                  {LanguagesSpokenArray.map((language, index) => (
                    <li key={index} className="me-2">
                      {language}
                      {action !== "View" ? (
                        <Iconify
                          className="delete-button"
                          onClick={() => removeLanguage(index)}
                          icon={"streamline:delete-1-solid"}
                        />
                      ) : (
                        ""
                      )}
                    </li>
                  ))}
                </ul>
              )}
            </div>
            <>
              <div className="d-flex flex-wrap">
                <div className="col-4 px-5 py-3">
                  {action === "Edit" ? (
                    <div className="inputs">
                      <label
                        htmlFor="file-input"
                        className="d-flex justify-content-between w-100"
                      >
                        Add CV <span>+</span>
                      </label>
                      <input
                        id="file-input"
                        type="file"
                        className="Upload-file"
                        onChange={(event) => handleFileChange(event, "CV")}
                      />
                    </div>
                  ) : (
                    <h5 className="sub-title">CV</h5>
                  )}
                  {CVFileObject ? (
                    <>
                      <a
                        rel="noreferrer"
                        href={CVFileObject ? CVFileObject.uri : ""}
                        target="_blank"
                      >
                        {CVFileObject.uri.split("/").pop()}
                      </a>
                      {action === "Edit" && (
                        <Iconify
                          style={{
                            height: "10px",
                            width: "10px",
                            color: "red",
                            cursor: "pointer",
                            margin: "0px 3px",
                          }}
                          className="ms-2"
                          onClick={() => removeFile("CV")}
                          icon={"streamline:delete-1-solid"}
                        />
                      )}
                    </>
                  ) : (
                    <p>No Data</p>
                  )}
                </div>
                <div className="col-4 px-5 py-3">
                  {action === "Edit" ? (
                    <div className="inputs">
                      <label
                        htmlFor="file-input-1"
                        className="d-flex justify-content-between w-100"
                      >
                        Add Education certificate <span>+</span>
                      </label>
                      <input
                        id="file-input-1"
                        type="file"
                        className="Upload-file"
                        onChange={(event) =>
                          handleFileChange(event, "Education")
                        }
                      />
                    </div>
                  ) : (
                    <h5 className="sub-title">Education certificate</h5>
                  )}
                  {educationCertificatesFileObjects.length > 0 ? (
                    <ul>
                      {educationCertificatesFileObjects.map((data, index) => (
                        <li>
                          <a
                            key={index}
                            rel="noreferrer"
                            href={data ? `${SERVER_URL}/${data.uri} ` : "#"}
                            target="_blank"
                          >
                            {data.uri.split("/").pop()}
                          </a>

                          {action === "Edit" && (
                            <Iconify
                              style={{
                                height: "10px",
                                width: "10px",
                                color: "red",
                                cursor: "pointer",
                                margin: "0px 3px",
                              }}
                              className="ms-2"
                              onClick={() => removeFile("Education", index)}
                              icon={"streamline:delete-1-solid"}
                            />
                          )}
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <p>No Data</p>
                  )}
                </div>
                <div className="col-4 px-5 py-3 position-relative">
                  {action === "Edit" ? (
                    <>
                      <TextField
                        name="workExperience"
                        label="Add Work Experience "
                        value={WorkHistory}
                        size="small"
                        className="w-100 mb-3"
                        onChange={(e) => setWorkHistory(e.target.value)}
                      />
                      {WorkHistory === "" ? (
                        ""
                      ) : (
                        <div
                          className="verify-button"
                          onClick={updateWorkHistory}
                        >
                          <Iconify
                            className=""
                            icon={"teenyicons:tick-outline"}
                          />
                        </div>
                      )}
                    </>
                  ) : (
                    <h5 className="sub-title">Work Experience</h5>
                  )}
                  {experienceWorkHistory.length > 0 ? (
                    <ul>
                      {experienceWorkHistory.map((data, index) => (
                        <li key={index} className="me-2">
                          {data}
                          {action === "Edit" && (
                            <Iconify
                              style={{
                                height: "10px",
                                width: "10px",
                                color: "red",
                                cursor: "pointer",
                                margin: "0px 3px",
                              }}
                              className="ms-2"
                              onClick={() => removeFile("Work", index)}
                              icon={"streamline:delete-1-solid"}
                            />
                          )}
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <p>No Data</p>
                  )}
                </div>
                <div className="col-4 px-5 py-3">
                  {action === "Edit" ? (
                    <div className="inputs">
                      <label
                        htmlFor="file-input-2"
                        className="d-flex justify-content-between w-100"
                      >
                        Other Document <span>+</span>
                      </label>
                      <input
                        id="file-input-2"
                        type="file"
                        className="Upload-file"
                        onChange={(event) => handleFileChange(event, "Other")}
                      />
                    </div>
                  ) : (
                    <h5 className="sub-title"> Other Document</h5>
                  )}
                  {otherDocumentsFileObjects.length > 0 ? (
                    <ul>
                      {otherDocumentsFileObjects.map((data, index) => (
                        <li>
                          <a
                            key={index}
                            rel="noreferrer"
                            href={data ? data.uri : "#"}
                            target="_blank"
                          >
                            {data.uri.split("/").pop()}
                          </a>
                          {action === "Edit" && (
                            <Iconify
                              style={{
                                height: "10px",
                                width: "10px",
                                color: "red",
                                cursor: "pointer",
                                margin: "0px 3px",
                              }}
                              className="ms-2"
                              onClick={() => removeFile("Other", index)}
                              icon={"streamline:delete-1-solid"}
                            />
                          )}
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <p>No Data</p>
                  )}
                </div>
              </div>
            </>
          </div>
        ) : (
          ""
        )}

        {/* auditor list */}

        {(Role === "Auditor" && showauditoradvance) ||
        (Role === "Auditor" && action === "View") ? (
          <>
            {action !== "View" && (
              <div className="d-flex justify-content-end">
                <Button className="btn-green" onClick={AddAuditorSkillset}>
                  Add Auditor Skillset
                </Button>
              </div>
            )}
            {addnewAuditorskillset && (
              <div
                className="d-flex flex-wrap justify-content-start p-3 border"
                style={{ borderRadius: "10px" }}
              >
                {addnewAuditorskillset && (
                  <>
                    <div className="col-4 px-2 py-3">
                      <TextField
                        name="grade"
                        label="Grade"
                        value={formData.grade}
                        onChange={handleInputChange}
                        fullWidth
                        margin="normal"
                      />
                    </div>
                    <div className="col-4 px-2 py-3">
                      <TextField
                        name="standards"
                        label="Standards"
                        value={formData.standards}
                        onChange={handleInputChange}
                        fullWidth
                        margin="normal"
                      />
                    </div>
                    <div className="col-4 px-2 py-3">
                      <TextField
                        name="externalQualification"
                        label="External Qualification"
                        value={formData.externalQualification}
                        onChange={handleInputChange}
                        fullWidth
                        margin="normal"
                      />
                    </div>
                    <div className="col-4 px-2 py-3">
                      <TextField
                        name="certificationIssueDate"
                        label="Certification Issue Date"
                        type="date"
                        value={formData.certificationIssueDate}
                        onChange={handleInputChange}
                        fullWidth
                        margin="normal"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>
                    <div className="col-4 px-2 py-3">
                      <TextField
                        name="certificationExpiration"
                        label="Certification Expiration"
                        type="date"
                        value={formData.certificationExpiration}
                        onChange={handleInputChange}
                        fullWidth
                        margin="normal"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>

                    <div className="col-4 px-2 py-3">
                      <TextField
                        name="reminderDate"
                        label="Reminder Date"
                        type="date"
                        value={formData.reminderDate}
                        onChange={handleInputChange}
                        fullWidth
                        margin="normal"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>
                    <div className="col-4 px-2 py-3">
                      <TextField
                        name="nextWitnessDate"
                        label="Next Witness Date"
                        type="date"
                        value={formData.nextWitnessDate}
                        onChange={handleInputChange}
                        fullWidth
                        margin="normal"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>
                    <div className="col-4 px-2 py-3">
                      <TextField
                        name="lastWitnessDate"
                        label="Last Witness Date"
                        type="date"
                        value={formData.lastWitnessDate}
                        onChange={handleInputChange}
                        fullWidth
                        margin="normal"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>

                    <div className="col-4 px-2 py-3">
                      <TextField
                        name="lastTrainingDate"
                        label="Last Training Date"
                        type="date"
                        value={formData.lastTrainingDate}
                        onChange={handleInputChange}
                        fullWidth
                        margin="normal"
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>
                    <div className="col-4 px-2 py-3">
                      <TextField
                        name="comments"
                        label="Comments"
                        multiline
                        rows={4}
                        value={formData.comments}
                        onChange={handleInputChange}
                        fullWidth
                        margin="normal"
                      />
                    </div>
                    <div className="col-4 px-2 py-3">
                      <TextField
                        name="skillset"
                        label="Skillset"
                        value={formData.skillset}
                        onChange={handleInputChange}
                        fullWidth
                        margin="normal"
                      />
                    </div>
                    {editingRow !== null && (
                      <div className="col-4 px-2 py-3">
                        <Checkbox
                          name="isSkillSetsArchived"
                          checked={formData.isSkillSetsArchived}
                          onChange={handleCheckboxChange}
                        />
                        Archive Skill Sets
                      </div>
                    )}
                  </>
                )}
                {addnewAuditorskillset && action === "Edit" ? (
                  <div className="d-flex w-100 justify-content-end">
                    <Button
                      variant="contained"
                      className="btn-green"
                      onClick={() => {
                        if (
                          action === "Edit" &&
                          addnewAuditorskillset &&
                          editingRow !== null
                        ) {
                          updateAuditorSkillset("Edit");
                        } else {
                          updateAuditorSkillset("Add");
                        }
                      }}
                    >
                      {loader ? <CircularProgress /> : "Submit"}
                    </Button>
                  </div>
                ) : (
                  ""
                )}
              </div>
            )}

            <div className="d-flex flex-wrap justify-content-start">
              <div className="col-4 px-2 py-3">
                <TextField
                  className="w-100"
                  name="alternateName"
                  label="Alternate Name"
                  value={alternateName}
                  size="small"
                  disabled={action === "View" ? true : false}
                  onChange={(e) => setalternateName(e.target.value)}
                  required
                />
              </div>
              <div className="col-4 px-2 py-3">
                <TextField
                  className="w-100"
                  name="managingOffice"
                  label="Managing office"
                  value={managingOffice}
                  size="small"
                  disabled={action === "View" ? true : false}
                  onChange={(e) => setmanagingOffice(e.target.value)}
                  required
                />
              </div>
              <div className="col-4 px-2 py-3">
                <TextField
                  className="w-100"
                  name="supervisorInformation"
                  label="SupervisorInformation"
                  value={supervisorInformation}
                  size="small"
                  disabled={action === "View" ? true : false}
                  onChange={(e) => setsupervisorInformation(e.target.value)}
                  required
                />
              </div>
              <div className="col-4 px-2 py-3">
                <TextField
                  className="w-100"
                  type="date"
                  name="yearOfBirth"
                  label="Year Of Birth"
                  value={yearOfBirth}
                  size="small"
                  onChange={(e) => setyearOfBirth(e.target.value)}
                  required
                />
              </div>
              <div className="col-4 px-2 py-3">
                <TextField
                  type="number"
                  className="w-100"
                  name="ID_Number"
                  label="ID Number"
                  value={ID_Number}
                  size="small"
                  disabled={action === "View" ? true : false}
                  onChange={(e) => setID_Number(e.target.value)}
                  required
                />
              </div>
              <div className="col-4 px-2 py-3">
                <TextField
                  type="text"
                  className="w-100"
                  name="status"
                  label="Status"
                  value={status}
                  size="small"
                  disabled={action === "View" ? true : false}
                  onChange={(e) => setstatus(e.target.value)}
                  required
                />
              </div>
              <div className="col-4 px-2 py-3">
                <TextField
                  type="number"
                  className="w-100"
                  name="availability"
                  label="Availability"
                  value={availability}
                  size="small"
                  disabled={action === "View" ? true : false}
                  onChange={(e) => setavailability(e.target.value)}
                  required
                />
              </div>
              <div className="col-4 px-2 py-3">
                <TextField
                  type="text"
                  className="w-100"
                  name="languagesWritten"
                  label="LanguagesWritten"
                  value={languagesWritten}
                  size="small"
                  disabled={action === "View" ? true : false}
                  onChange={(e) => setlanguagesWritten(e.target.value)}
                  required
                />
              </div>
              {/* <div className="col-4 px-2 py-3">
              <TextField
                type="text"
                className="w-100"
                name="contractInformation"
                label="ContractInformation"
                value={contractInformation}
                size="small"
                disabled={action === "View" ? true : false}
                onChange={(e) => setcontractInformation(e.target.value)}
                required
              />
            </div> */}
              <div className="col-12 px-2">
                <p className="fw-bold">Contract Information</p>
              </div>
              <div className="col-4 px-2 py-3">
                <TextField
                  className="w-100"
                  type="date"
                  name="dateOfSignature"
                  label="Date Of Signature"
                  value={dateOfSignature}
                  size="small"
                  onChange={(e) => setdateOfSignature(e.target.value)}
                  required
                />
              </div>
              <div className="col-4 px-2 py-3">
                <TextField
                  className="w-100"
                  type="date"
                  name="expirationDate"
                  label="Expiration Date"
                  value={expirationDate}
                  size="small"
                  onChange={(e) => setexpirationDate(e.target.value)}
                  required
                />
              </div>
              <div className="col-4 px-2 py-3">
                <TextField
                  type="text"
                  className="w-100"
                  name="comments"
                  label="Comment"
                  value={comments}
                  size="small"
                  disabled={action === "View" ? true : false}
                  onChange={(e) => setcomments(e.target.value)}
                  required
                />
              </div>
            </div>
            {action !== "Add" && (
              <Card className="my-5">
                <h5 className="p-3"> Auditor Skillset List </h5>
                <Scrollbar>
                  <TableContainer sx={{ minWidth: 800 }}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell className="cl-green text-dark">
                            Standards
                          </TableCell>
                          <TableCell className="cl-green text-dark">
                            Grade
                          </TableCell>
                          <TableCell className="cl-green text-dark">
                            External Qualification
                          </TableCell>
                          <TableCell className="cl-green text-dark">
                            Certification Issue Date
                          </TableCell>
                          <TableCell className="cl-green text-dark">
                            Certification Expiration
                          </TableCell>
                          <TableCell className="cl-green text-dark">
                            Reminder Date
                          </TableCell>
                          <TableCell className="cl-green text-dark">
                            Next Witness Date
                          </TableCell>
                          <TableCell className="cl-green text-dark">
                            Last Witness Date
                          </TableCell>
                          <TableCell className="cl-green text-dark">
                            Last Training Date
                          </TableCell>
                          <TableCell className="cl-green text-dark">
                            Comments
                          </TableCell>
                          <TableCell className="cl-green text-dark">
                            Skillset
                          </TableCell>
                          {/* <TableCell className="cl-green text-dark">
                            SkillSets Archived
                          </TableCell> */}
                          {action !== "View" && (
                            <TableCell className="cl-green text-dark">
                              Action
                            </TableCell>
                          )}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {specificAuditor &&
                          specificAuditor.auditorSkillsets &&
                          specificAuditor.auditorSkillsets
                            .filter((row) => !row.isSkillSetsArchived)
                            .map((row, index) => (
                              <TableRow key={index}>
                                <TableCell align="left">
                                  {/* {row.standards && row.standards.length > 0
                                    ? row.standards.map((standard, index) => (
                                        <span key={index}>{standard}</span>
                                      ))
                                    : ""} */}
                                    {row.standards}
                                </TableCell>
                                <TableCell align="left">{row.grade}</TableCell>
                                <TableCell align="left">
                                  {/* {row.externalQualification &&
                                  row.externalQualification.length > 0
                                    ? row.externalQualification.map(
                                        (external, index) => (
                                          <span key={index}>{external}</span>
                                        )
                                      )
                                    : ""} */}
                                    {row.externalQualification}
                                </TableCell>
                                <TableCell align="left">
                                  {formatDate(row.certificationIssueDate)}
                                </TableCell>
                                <TableCell align="left">
                                  {formatDate(row.certificationExpiration)}
                                </TableCell>
                                <TableCell align="left">
                                  {formatDate(row.reminderDate)}
                                </TableCell>
                                <TableCell align="left">
                                  {formatDate(row.nextWitnessDate)}
                                </TableCell>
                                <TableCell align="left">
                                  {formatDate(row.lastWitnessDate)}
                                </TableCell>
                                <TableCell align="left">
                                  {formatDate(row.lastTrainingDate)}
                                </TableCell>
                                <TableCell align="left">
                                  {row.comments}
                                </TableCell>
                                <TableCell align="left">
                                  {row.skillset}
                                </TableCell>
                                {/* <TableCell>{row.isSkillSetsArchived}</TableCell> */}
                                {action !== "View" && (
                                  <TableCell>
                                    {/* Show edit button only if not in edit mode */}
                                    {!editingRow && (
                                      <Button
                                        onClick={() => handleEditClick(index)}
                                      >
                                        Edit
                                      </Button>
                                    )}
                                  </TableCell>
                                )}
                              </TableRow>
                            ))}
                      </TableBody>
                    </Table>
                  </TableContainer>{" "}
                </Scrollbar>
              </Card>
            )}
            {action !== "Add" && (
              <Card>
                <h5 className="p-3"> Archived Auditor Skillset List </h5>
                <Scrollbar>
                  <TableContainer sx={{ minWidth: 800 }}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell className="cl-green text-dark">
                            Standards
                          </TableCell>
                          <TableCell className="cl-green text-dark">
                            Grade
                          </TableCell>
                          <TableCell className="cl-green text-dark">
                            External Qualification
                          </TableCell>
                          <TableCell className="cl-green text-dark">
                            Certification Issue Date
                          </TableCell>
                          <TableCell className="cl-green text-dark">
                            Certification Expiration
                          </TableCell>
                          <TableCell className="cl-green text-dark">
                            Reminder Date
                          </TableCell>
                          <TableCell className="cl-green text-dark">
                            Next Witness Date
                          </TableCell>
                          <TableCell className="cl-green text-dark">
                            Last Witness Date
                          </TableCell>
                          <TableCell className="cl-green text-dark">
                            Last Training Date
                          </TableCell>
                          <TableCell className="cl-green text-dark">
                            Comments
                          </TableCell>
                          <TableCell className="cl-green text-dark">
                            Skillset
                          </TableCell>
                          {/* <TableCell className="cl-green text-dark">
                            SkillSets Archived
                          </TableCell> */}
                          {action !== "View" && (
                            <TableCell className="cl-green text-dark">
                              Action
                            </TableCell>
                          )}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {specificAuditor &&
                          specificAuditor.auditorSkillsets &&
                          specificAuditor.auditorSkillsets
                            .filter((row) => row.isSkillSetsArchived)
                            .map((row, index) => (
                              <TableRow key={index}>
                                <TableCell align="left">
                                  {/* {row.standards && row.standards.length > 0
                                    ? row.standards.map((standard, index) => (
                                        <span key={index}>{standard}</span>
                                      ))
                                    : ""} */}
                                    {row.standards}
                                </TableCell>
                                <TableCell align="left">{row.grade}</TableCell>
                                <TableCell align="left">
                                  {/* {row.externalQualification &&
                                  row.externalQualification.length > 0
                                    ? row.externalQualification.map(
                                        (external, index) => (
                                          <span key={index}>{external}</span>
                                        )
                                      )
                                    : ""} */}
                                    {row.externalQualification}
                                </TableCell>
                                <TableCell align="left">
                                  {formatDate(row.certificationIssueDate)}
                                </TableCell>
                                <TableCell align="left">
                                  {formatDate(row.certificationExpiration)}
                                </TableCell>
                                <TableCell align="left">
                                  {formatDate(row.reminderDate)}
                                </TableCell>
                                <TableCell align="left">
                                  {formatDate(row.nextWitnessDate)}
                                </TableCell>
                                <TableCell align="left">
                                  {formatDate(row.lastWitnessDate)}
                                </TableCell>
                                <TableCell align="left">
                                  {formatDate(row.lastTrainingDate)}
                                </TableCell>
                                <TableCell align="left">
                                  {row.comments}
                                </TableCell>
                                <TableCell align="left">
                                  {row.skillset}
                                </TableCell>
                                {/* <TableCell>{row.isSkillSetsArchived}</TableCell> */}
                                {action !== "View" && (
                                  <TableCell>
                                    {/* Show edit button only if not in edit mode */}
                                    {!editingRow && (
                                      <Button
                                        onClick={() => handleEditClick(index)}
                                      >
                                        Edit
                                      </Button>
                                    )}
                                  </TableCell>
                                )}
                              </TableRow>
                            ))}
                      </TableBody>
                    </Table>
                  </TableContainer>{" "}
                </Scrollbar>
              </Card>
            )}
          </>
        ) : (
          ""
        )}

        {action !== "View" ? (
          <div className="d-flex flex-wrap justify-content-end">
            {showauditoradvance ? (
              <Button
                variant="contained"
                className={
                  disabled ? "btn-green-disable my-3" : "btn-green my-3"
                }
                disabled={disabled}
                onClick={action === "Add" ? addadvance : editadvance}
              >
                {loader ? <CircularProgress /> : "Submit"}
              </Button>
            ) : (
              <>
                <Button
                  variant="contained"
                  className="btn-orange"
                  onClick={() => {
                    HandlAaddRole();
                    RemoveSpecificUser();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  className={
                    disabled ? "btn-green-disable my-3" : "btn-green my-3"
                  }
                  disabled={disabled}
                  onClick={action === "Add" ? addUser : edituser}
                >
                  {loader ? <CircularProgress /> : "Submit"}
                </Button>
              </>
            )}
          </div>
        ) : (
          <div className="d-flex justify-content-end">
            <Button
              variant="contained"
              className="btn-orange my-3"
              onClick={() => {
                HandlAaddRole();
                RemoveSpecificUser();
              }}
            >
              Close
            </Button>
          </div>
        )}
      </div>
    </>
  );
}
