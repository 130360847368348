import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { getData } from "../../webService/webService";
import { Card } from "@mui/material";

const FeedbackChart = () => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [data, setData] = useState({
    evaluation_checkList_veryDissatisfied_count: 0,
    evaluation_checkList_dissatisfied_count: 0,
    evaluation_checkList_satisfied_count: 0,
    evaluation_checkList_verySatisfied_count: 0,
    commercial_checkList_veryDissatisfied_count: 0,
    commercial_checkList_dissatisfied_count: 0,
    commercial_checkList_satisfied_count: 0,
    commercial_checkList_verySatisfied_count: 0,
    complaintsAndAppeals_checkList_veryDissatisfied_count: 0,
    complaintsAndAppeals_checkList_dissatisfied_count: 0,
    complaintsAndAppeals_checkList_satisfied_count: 0,
    complaintsAndAppeals_checkList_verySatisfied_count: 0,
    overallSatisfaction_checkList_veryDissatisfied_count: 0,
    overallSatisfaction_checkList_dissatisfied_count: 0,
    overallSatisfaction_checkList_satisfied_count: 0,
    overallSatisfaction_checkList_verySatisfied_count: 0,
  });

  const accessToken = sessionStorage.getItem("Token");

  const GetCount = async (date) => {
    try {
      const params = {
        date: date.toISOString().slice(0, 10), // Format the date as YYYY-MM-DD
      };
      const response = await getData(
        `feedbackform/satisfaction/analysis/monthly/CB`,
        params,
        accessToken
      );
      // Update status count based on API response
      if (response && response.data) {
        setData(response.data);
      } else {
        console.error("No data received from API");
      }
      console.log("API Response:", response);
    } catch (error) {
      console.error("Error fetching status counts:", error);
    }
  };

  useEffect(() => {
    GetCount(selectedDate);
  }, [selectedDate]);

  const categories = [
    "evaluation_checkList",
    "commercial_checkList",
    "complaintsAndAppeals_checkList",
    "overallSatisfaction_checkList",
  ];

  const options = {
    chart: {
      type: "bar",
      height: 350,
    },
    plotOptions: {
      bar: {
        horizontal: true,
        dataLabels: {
          position: "top",
        },
      },
    },
    dataLabels: {
      enabled: true,
      offsetX: -6,
      style: {
        fontSize: "12px",
        colors: ["#fff"],
      },
    },
    xaxis: {
      categories: [
        "Very Dissatisfied",
        "Dissatisfied",
        "Satisfied",
        "Very Satisfied",
      ],
    },
    legend: {
      position: "bottom",
    },
  };

  const series = categories.map((category) => ({
    name: category.replace(/_/g, " "),
    data: [
      data[`${category}_veryDissatisfied_count`] || 0,
      data[`${category}_dissatisfied_count`] || 0,
      data[`${category}_satisfied_count`] || 0,
      data[`${category}_verySatisfied_count`] || 0,
    ],
  }));

  console.log("Series Data:", series);

  return (
    <Card className="p-3 mb-3">
      <div className="d-flex justify-content-between mb-4 align-items-center">
        <h5 className="fw-bold">CheckList</h5>
        <div className="d-flex align-items-center">
          <h5 className="fw-bold pe-3 mb-0">Select Month and Year</h5>
          <DatePicker
            selected={selectedDate}
            onChange={(date) => setSelectedDate(date)}
            dateFormat="yyyy-MM"
            showMonthYearPicker
            className="form-control"
          />
        </div>
      </div>
      <div className="row">
        {categories.map((category, index) => (
          <div className="col-12 col-md-6 mb-4" key={index}>
            <div className="border p-3" style={{ borderRadius: "5px" }}>
              <h6 className="text-capitalize fw-bold p-3 text-green">
                {category.replace(/_/g, " ")}
              </h6>
              <Chart
                options={options}
                series={[series[index]]}
                type="bar"
                height={350}
              />
            </div>
          </div>
        ))}
      </div>
    </Card>
  );
};

export default FeedbackChart;
