import React, { useEffect, useState } from "react";
import {
  TextField, Radio, RadioGroup, InputLabel,
  FormControlLabel, FormControl, FormLabel,
  Typography, List, ListItem, ListItemText, Link,
  Button, Stack, Checkbox
}
  from '@mui/material';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";

import { getDataWithoutSession, patchData, FileUpload } from "src/webService/webService";
import FEEDBACK_FORM_ROUTES from "src/API_Routes/FeedbackForm/FeedbackForm_Routers";
import FILEROUTES from "src/API_Routes/File/File_Router";
import SERVER_URL from "src/utils/serverURL";

export default function HRA_FeedbackForm() {
  const dispatch = useDispatch();
  const [feedBackFormData, setFeedbackFormData] = useState(null);
  const [queryToken, setQueryToken] = useState(null);

  const location = useLocation();

  const [buttonCount, setButtonCount] = useState(1);

  const handleAddButton = () => {
    setButtonCount(prevCount => prevCount + 1);
  };

  const handleDeclarationChange = (event) => {
    if (event?.target?.checked) {
      setFeedbackFormData({
        ...feedBackFormData,
        [event?.target?.name]: 'I declare that the details provided above are true to best of my knowledge.',
      });
    }
    else {
      setFeedbackFormData({
        ...feedBackFormData,
        [event?.target?.name]: '',
      });
    }
  };

  const handleFileInputChange = async (event) => {
    const file = event?.target?.files[0];
    const formData = new FormData();
    formData.append("file", file);
    try {
      const response = await FileUpload(
        `${FILEROUTES.UPLOAD}${feedBackFormData?.clientName}`,
        formData,
        dispatch
      );
      console.log("File upload successful:", response);

      feedBackFormData
        ?.complaintsAndGrievances
        ?.evidencesInSupportForTheComplaintFileObjects
        ?.push(response?.data);
      const copyFeedbackFormData = { ...feedBackFormData }
      setFeedbackFormData(copyFeedbackFormData);
    }
    catch (error) {
      console.error("Sign And Seal File upload error:", error);
      toast.error("Signature And Seal File upload error", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handleInputDetailsOfTheComplaintChange = (event) => {
    feedBackFormData
      .complaintsAndGrievances
      .detailsOfTheComplaint = event?.target?.value;
    // new copy created to make the state change observed in useEffect
    const feedBackFormDataCopy = { ...feedBackFormData }
    setFeedbackFormData(feedBackFormDataCopy);
  };

  const handleRadioChange = (category, answer, indexFromRadioButton) => {

    feedBackFormData[category].forEach((item, index) => {
      if (index === indexFromRadioButton) {
        item.yesOrNo = answer;
      }
    })

    // new copy created to make the state change observed in useEffect
    const feedBackFormDataCopy = { ...feedBackFormData }
    setFeedbackFormData(feedBackFormDataCopy);
  };

  useEffect(() => {
    console.log('feedBackFormData', feedBackFormData)
  }, [feedBackFormData])


  const getFeedbackForm = async () => {
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get("token");
    // Now `token` contains the value of the 'token' query parameter
    console.log(token);
    setQueryToken(token);
    const feedBackFormData = await getDataWithoutSession(
      FEEDBACK_FORM_ROUTES.GET_FEEDBACK_FORM_BY_SCHEME_TOKEN(
        'hra',
        token
      )
    );
    console.log(feedBackFormData)
    if (feedBackFormData?.status === 404) {
      toast.error("feedback form doesn't exist", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    setFeedbackFormData(feedBackFormData?.data)
  }
  useEffect(() => { 
    getFeedbackForm() 
  }, [location.search]);


  const handleSubmit = async (event) => {

    event.preventDefault();
    try {
      feedBackFormData.feedbackDate = new Date().toLocaleDateString();
      const response = await patchData(
        FEEDBACK_FORM_ROUTES.UPDATE_FEEDBACK_FORM_BY_SCHEME_TOKEN(
          'HRA',
          queryToken
        ),
        feedBackFormData,
        null,
        dispatch
      )
      switch (response?.data?.status || response?.status) {
        case 200:
          toast.success("Feedback Form Submitted Successfully", {
            position: toast.POSITION.TOP_RIGHT,
          });
          break;
        case 500:
          toast.error("Error while submitting feedback form in server", {
            position: toast.POSITION.TOP_RIGHT,
          });
          break;
        case 400:
          toast.error("feedback form data error", {
            position: toast.POSITION.TOP_RIGHT,
          });
          break;
        case 404:
          toast.error("feedback form not found", {
            position: toast.POSITION.TOP_RIGHT,
          });
          break;
        default:
          toast.error("Unexpected Error occurred", {
            position: toast.POSITION.TOP_RIGHT,
          });
      }
    }
    catch (error) {
      console.error(error);
      toast.error("Unexpected Error occurred", {
        position: toast.POSITION.TOP_RIGHT,
      });
    };
  }

  return (
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="center"
      spacing={2}
    >
      <ToastContainer />
      <form onSubmit={handleSubmit}>

        <Typography variant="h3">Feedback Form</Typography>
        <div style={{ padding: 10 + 'px' }}>
          <InputLabel>Client Name</InputLabel>
          <TextField
            name="clientName"
            value={feedBackFormData?.clientName}
            disabled
          />
        </div>

        <div style={{ padding: 10 + 'px' }}>
          <InputLabel>Client Representative Name</InputLabel>
          <TextField
            name="clientRepresentativeName"
            value={feedBackFormData?.clientRepresentativeName}
            disabled
          />
        </div>

        <div style={{ padding: 10 + 'px' }}>
          <InputLabel>Contact Number</InputLabel>
          <TextField
            name="contactNumber"
            value={feedBackFormData?.contactNumber}
            disabled
          />
        </div>

        <div style={{ padding: 10 + 'px' }}>
          <InputLabel>Email</InputLabel>
          <TextField
            name="email"
            value={feedBackFormData?.email}
            disabled
          />
        </div>

        <div style={{ padding: 10 + 'px' }}>
          <InputLabel>Date Of Audit</InputLabel>
          <TextField
            name="dateOfAudit"
            value={
              feedBackFormData?.dateOfAudit ? feedBackFormData?.dateOfAudit : new Date().toLocaleDateString()
            }
            disabled
          />
        </div>

        <div style={{ padding: 10 + 'px' }}>
          <InputLabel>Auditors Name</InputLabel>
          <TextField
            name="auditorsName"
            value={feedBackFormData?.auditorsName}
            disabled
          />
        </div>

        <div style={{ padding: 10 + 'px' }}>
          <InputLabel>Client Address</InputLabel>
          <Typography variant="h7">{feedBackFormData?.clientAddress}</Typography>
        </div>

        {/* Complaints And Grievances */}
        <div style={{ padding: 10 + 'px' }}>
          <Typography variant="h3">Complaints And Grievances</Typography>
          <InputLabel>Details Of The Complaint</InputLabel>
          <TextField
            name="detailsOfTheComplaint"
            value={feedBackFormData?.complaintsAndGrievances?.detailsOfTheComplaint}
            onChange={handleInputDetailsOfTheComplaintChange}
            multiline
          />
        </div>

        <div style={{ padding: 10 + 'px' }}>
          <InputLabel>Evidences In Support For The Complaint</InputLabel>
          {/* Plus Icon Button */}
          <div style={{ padding: 10 + 'px' }}>
            <Button
              variant="text"
              startIcon={<AddOutlinedIcon />}
              onClick={handleAddButton}
            >
              Add More Evidences
            </Button>
          </div>
          <br clear="left"></br>
          {/* Render multiple buttons based on buttonCount state */}

          {[...Array(buttonCount)].map((_, index) => (
            <React.Fragment key={index}>
              <div style={{ padding: 10 + 'px' }}>
                <Button
                  className="text-dark"
                  variant="outlined"
                  size="large"
                  color="success"
                  component="label"
                  startIcon={<FileUploadOutlinedIcon />}
                >
                  {/* Hidden input field for file upload */}
                  <input
                    type="file"
                    style={{ display: 'none' }} // Hide the input visually
                    onChange={handleFileInputChange}
                  />
                  Upload Evidence {index + 1} Here
                </Button>
                {index !== buttonCount - 1 && <br />} {/* Add <br /> unless it's the last button */}
              </div>
            </React.Fragment>
          ))}

          <div style={{ padding: 10 + 'px' }}>
            <List>
              {feedBackFormData
                ?.complaintsAndGrievances
                ?.evidencesInSupportForTheComplaintFileObjects
                ?.map((fileObject, index) => (
                  <ListItem key={index}>
                    {fileObject?.uri && (
                      <ListItemText>
                        Evidence {index} :
                        <Link href={`${SERVER_URL}${fileObject?.uri}`}
                          target="_blank" rel="noopener noreferrer">
                          {fileObject?.filename || `File ${index + 1}`}
                        </Link>
                      </ListItemText>
                    )}
                  </ListItem>
                ))}
            </List>
          </div>
        </div>

        {/* Evaluation */}
        <Typography variant="h3">Evaluation</Typography>
        {feedBackFormData && feedBackFormData?.evaluation.map((item, index) => (
          <div style={{ padding: 10 + 'px' }}>
            <FormControl component="fieldset">
              <FormLabel component="legend" id={"evaluation" + index}>{item?.particulars}</FormLabel>
              <RadioGroup
                aria-labelledby={"evaluation" + index}
                onChange={(event) => handleRadioChange('evaluation', event?.target?.value, index)}
              >
                <FormControlLabel value={true} control={<Radio />} label="Yes" />
                <FormControlLabel value={false} control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
          </div>
        ))}

        <div style={{ padding: 10 + 'px' }}>
          <Typography variant="h3">Signature And Seal</Typography>
          <img
            src={`${SERVER_URL}${feedBackFormData?.signatureAndSeal[0]?.uri}`}
            style={{ maxWidth: '50%', maxHeight: '100px', marginBottom: '10px' }}
          />
        </div>

        <div style={{ padding: 10 + 'px' }}>
          <Checkbox onChange={handleDeclarationChange} name="declaration" />
          I declare that the details provided above are true to best of my knowledge.
        </div>
        <Button
          variant="contained"
          className="btn-orange"
          size="large"
          type="submit"
        >
          Submit
        </Button>

      </form>
    </Stack >
  );
}
