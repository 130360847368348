import { Helmet } from "react-helmet-async";
// @mui
import { Container } from "@mui/material";

import AssignAudit from "src/components/Process-Associate-MIS/Audit/Audit";

// ----------------------------------------------------------------------

export default function ProcessAssociateAudit({Role}) {
  return (
    <>
      <Helmet>
        <title> Audit </title>
      </Helmet>

      <Container maxWidth="xl">
        <AssignAudit Role="Assign Audit" />
      </Container>
    </>
  );
}
