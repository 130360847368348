import { TextField } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import SERVER_URL from "../../utils/serverURL";

function ClientBasicandAdvance() {
  const specificClientData = useSelector(
    (state) => state.Upcoming.UpcomingClient
  );
  console.log("specificClientData", specificClientData);
  const scheme_session = sessionStorage.getItem("scheme");
  const imsData =
    scheme_session === "CB"
      ? specificClientData && specificClientData.Client_IMS_Details[0]
      : null;

  const fsmsData =
    scheme_session === "CB"
      ? specificClientData && specificClientData.Client_FSMS_Details[0]
      : null;
  const HRAData =
    scheme_session === "HRA"
      ? specificClientData && specificClientData.Client_HRA_Details[0]
      : null;
  const renderTextField = (label, value) => (
    <div className="col-4 px-2 py-3">
      <TextField className="w-100" label={label} value={value} size="small" />
    </div>
  );
  return (
    <div className="clientbasicadvance-border p-5">
      <div className="px-2 add-title">
        <span className="">View Client</span>
      </div>
      <h6 className="my-3 fw-bold">Basic Details</h6>
      <div className="d-flex flex-wrap">
        <div className="col-4 px-2 py-3">
          <TextField
            className="w-100"
            name="companyName"
            label="company Name"
            value={specificClientData?.Client_Basic_Details[0]?.companyName}
            size="small"
          />
        </div>
        <div className="col-4 px-2 py-3">
          <TextField
            className="w-100"
            name="email"
            label="Email"
            value={specificClientData?.Client_Basic_Details[0]?.email}
            size="small"
          />
        </div>
        <div className="col-4 px-2 py-3">
          <TextField
            className="w-100"
            name="mobileNumber"
            label="Mobile Number"
            value={specificClientData?.Client_Basic_Details[0]?.phoneNumber}
            size="small"
          />
        </div>
        <div className="col-4 px-2 py-3">
          <TextField
            className="w-100"
            name="clientId"
            label="ClientId"
            value={specificClientData?.Client_Basic_Details[0]?.clientId}
            size="small"
          />
        </div>
        {scheme_session !== "HRA" && (
          <>
            <div className="col-4 px-2 py-3">
              <TextField
                className="w-100"
                name="technicalCode"
                label="Technical Main Code"
                value={
                  specificClientData?.Client_Basic_Details[0]?.technicalCode
                    ?.mainCode
                }
                size="small"
              />
            </div>
            <div className="col-4 px-2 py-3">
              <TextField
                className="w-100"
                name="technicalCode"
                label="Technical Sub Code"
                value={
                  specificClientData?.Client_Basic_Details[0]?.technicalCode
                    ?.subCode
                }
                size="small"
              />
            </div>
          </>
        )}
        <div className="col-4 px-2 py-3">
          <TextField
            className="w-100"
            name="scheme"
            label="Scheme"
            value={
              specificClientData?.Client_Basic_Details[0]?.standardsSchemeType
            }
            size="small"
          />
        </div>
        <div className="col-12 px-2 py-3">
          <TextField
            className="w-100"
            name="address"
            label="Address"
            value={specificClientData?.Client_Basic_Details[0]?.address}
            size="small"
          />
        </div>
        <div className="col-4 px-2 py-3">
          <TextField
            className="w-100"
            name="state"
            label="State"
            value={specificClientData?.Client_Basic_Details[0]?.state}
            size="small"
          />
        </div>
        <div className="col-4 px-2 py-3">
          <TextField
            className="w-100"
            name="country"
            label="Country"
            value={specificClientData?.Client_Basic_Details[0]?.country}
            size="small"
          />
        </div>
        <div className="col-4 px-2 py-3">
          <TextField
            className="w-100"
            name="NoOfSites"
            label="No Of Sites"
            value={specificClientData?.Client_Basic_Details[0]?.noOfSites}
            size="small"
          />
        </div>
        <div className="col-4 px-2 py-3">
          <TextField
            className="w-100"
            name="contactPersonName"
            label="Contact Person Name"
            value={specificClientData?.Client_Basic_Details[0]?.contactPerson}
            size="small"
          />
        </div>
      </div>

      {scheme_session === "CB" ? (
        <>
          <h6 className="my-3 fw-bold">Account Information</h6>

          <div className="d-flex flex-wrap justify-content-start">
            {renderTextField(
              "proposalDate",
              imsData
                ? imsData.proposalDate
                : fsmsData
                  ? fsmsData.proposalDate
                  : "No Data"
            )}
            {renderTextField(
              "Title",
              imsData ? imsData.title : fsmsData ? fsmsData.title : "No Data"
            )}
            {renderTextField(
              "City",
              imsData ? imsData.city : fsmsData ? fsmsData.city : "No Data"
            )}
            {renderTextField(
              "Post Code",
              imsData
                ? imsData.postCode
                : fsmsData
                  ? fsmsData.postCode
                  : "No Data"
            )}
            {imsData
              ? renderTextField(
                "Billing Contact Name",
                imsData.billingContact
                  ? imsData.billingContact.name
                  : fsmsData.billingContact
                    ? fsmsData.billingContact.name
                    : "No Data"
              )
              : ""}

            {imsData
              ? renderTextField(
                "Billing Contact Designation",
                imsData.billingContact
                  ? imsData.billingContact.designation
                  : fsmsData.billingContact
                    ? fsmsData.billingContact.designation
                    : "No Data"
              )
              : ""}
            {renderTextField(
              "Exp Date Of Audit Planned",
              imsData
                ? imsData.expectedDateOfAuditPlanned
                : fsmsData
                  ? fsmsData.expectedDateOfAuditPlanned
                  : "No Data"
            )}
            {renderTextField(
              "PAN",
              imsData ? imsData.PAN : fsmsData ? fsmsData.PAN : "No Data"
            )}
            {renderTextField(
              "GST Number",
              imsData
                ? imsData.GSTNumber
                : fsmsData
                  ? fsmsData.GSTNumber
                  : "No Data"
            )}
            {renderTextField(
              "Local Resource",
              imsData
                ? imsData.localResource
                : fsmsData
                  ? fsmsData.localResource
                  : "No Data"
            )}
          </div>
          <h6 className="my-3 fw-bold">Proposal Information</h6>
          <div className="d-flex flex-wrap justify-content-start">
            {renderTextField(
              "standards",
              imsData
                ? imsData.standards[0]
                : fsmsData
                  ? fsmsData.standards[0]
                  : "No Data"
            )}
            {renderTextField(
              "Certification Body",
              imsData
                ? imsData.certificationBody
                : fsmsData
                  ? fsmsData.certificationBody
                  : "No Data"
            )}
            {renderTextField(
              "CAUSSA Contracting Office",
              imsData
                ? imsData.CAUSSA_ContractingOffice
                : fsmsData
                  ? fsmsData.CAUSSA_ContractingOffice
                  : "No Data"
            )}
            {renderTextField(
              "Accreditations",
              imsData
                ? imsData.accreditations[0]
                : fsmsData
                  ? fsmsData.accreditations[0]
                  : "No Data"
            )}
            {renderTextField(
              "Scope Of Certification",
              imsData
                ? imsData.scopeOfCertification
                : fsmsData
                  ? fsmsData.scopeOfCertification
                  : "No Data"
            )}
            {fsmsData
              ? renderTextField("No of HACCP Study", fsmsData.NoOfHACCP_study)
              : ""}

            {renderTextField(
              "Total No Of Employees",
              imsData
                ? imsData.totalNoOfEmployees
                : fsmsData
                  ? fsmsData.totalNoOfEmployees
                  : "No Data"
            )}
            {renderTextField(
              "No Of Effective FTE",
              imsData
                ? imsData.noOfEffectiveFTE
                : fsmsData
                  ? fsmsData.noOfEffectiveFTE
                  : "No Data"
            )}
            {renderTextField(
              "No Of Shifts",
              imsData
                ? imsData.noOfShifts
                : fsmsData
                  ? fsmsData.noOfShifts
                  : "No Data"
            )}
            {renderTextField(
              "General Shift - FTE",
              imsData
                ? imsData.shift?.general
                : fsmsData
                ? fsmsData.shift?.general
                : "No Data"

            )}
            {renderTextField(
              "1st Shift - FTE",
              imsData
                ? imsData.shift?.first
                : fsmsData
                ? fsmsData.shift?.first
                : "No Data"

            )}
            {renderTextField(
              "2nd Shift - FTE",
              imsData
                ? imsData.shift?.second
                : fsmsData
                ? fsmsData.shift?.second
                : "No Data"

            )}
            {renderTextField(
              "3rd Shift - FTE",
              imsData
                ? imsData.shift?.third
                : fsmsData
                ? fsmsData.shift?.third
                : "No Data"

            )}
            {renderTextField(
              "Location",
              imsData
                ? imsData.locationMultiSiteInfo
                : fsmsData
                  ? fsmsData.locationMultiSiteInfo
                  : "No Data"
            )}
            {renderTextField(
              "HO/CentralOffice",
              imsData
                ? imsData.HO_or_CentralOffice
                : fsmsData
                  ? fsmsData.HO_or_CentralOffice
                  : "No Data"
            )}
            {renderTextField(
              "Surveillance Frequency",
              imsData
                ? imsData.surveillanceFrequency
                : fsmsData
                  ? fsmsData.surveillanceFrequency
                  : "No Data"
            )}
          </div>

          <h6 className="my-3 fw-bold">
            Cifa :
            {/* {imsData ? (
                imsData?.approvedCIFA_FileObject ? (
                  <a
                    href={`${SERVER_URL}/${imsData.approvedCIFA_FileObject.uri}`}
                    target="_blank"
                  >
                    Cifa.pdf
                  </a>
                ) : (
                  "No Data"
                )
              ) : fsmsData?.approvedCIFA_FileObject ? (
                <a
                  href={`${SERVER_URL}/${fsmsData.approvedCIFA_FileObject.uri}`}
                  target="_blank"
                >
                  Cifa.pdf
                </a>
              ) : (
                "No Data"
              )} */}
            {imsData
              ? imsData.CIFA_FileObjects && imsData.CIFA_FileObjects.length > 0
                ? imsData.CIFA_FileObjects.map((fileObject, index) => (
                  <p>
                    <a
                      key={`ims_${index}`}
                      href={`${SERVER_URL}/${fileObject.uri}`}
                      target="_blank"
                    >
                      {`Cifa(${index + 1})`}
                    </a>
                  </p>
                ))
                : fsmsData
                  ? fsmsData.CIFA_FileObjects &&
                    fsmsData.CIFA_FileObjects.length > 0
                    ? fsmsData.CIFA_FileObjects.map((fileObject, index) => (
                      <p>
                        <a
                          key={`fsms_${index}`}
                          href={`h${SERVER_URL}/${fileObject.uri}`}
                          target="_blank"
                        >
                          {`Cifa(${index + 1})`}
                        </a>
                      </p>
                    ))
                    : "No Data"
                  : "No Data"
              : "No Data"}
          </h6>
          <h6 className="my-3 fw-bold">
            proposal and agreement -{" "}
            {imsData ? (
              imsData?.proposalAndAgreementFileObject ? (
                <a
                  href={`h${SERVER_URL}/${imsData.proposalAndAgreementFileObject.uri}`}
                  target="_blank"
                >
                  proposal&agreement .pdf
                </a>
              ) : (
                "No Data"
              )
            ) : fsmsData?.proposalAndAgreementFileObject ? (
              <a
                href={`${SERVER_URL}/${fsmsData.proposalAndAgreementFileObject.uri}`}
                target="_blank"
              >
                proposal&agreement .pdf
              </a>
            ) : (
              "No Data"
            )}
          </h6>
        </>
      ) : (
        <>
          <h6 className="my-3 fw-bold">Account Information</h6>
          <div className="d-flex flex-wrap justify-content-start">
            {renderTextField("ccode", HRAData ? HRAData.cCode : "No Data")}
            {renderTextField(
              "MainProductionOrService",
              HRAData ? HRAData.mainProductionOrService : "No Data"
            )}
            {renderTextField(
              "AuditType",
              HRAData ? HRAData.auditType : "No Data"
            )}
            {renderTextField("Kob", HRAData ? HRAData.kob : "No Data")}
            {renderTextField(
              "No Of FoodHandlers",
              HRAData ? HRAData.noOfFoodHandlers : "No Data"
            )}
            {renderTextField(
              "FoSTaC_TrainedFoodSafetySupervisors",
              HRAData ? HRAData.FoSTaC_TrainedFoodSafetySupervisors : "No Data"
            )}
            {renderTextField(
              "uniqueCertificateNoAndCourseName",
              HRAData ? HRAData.uniqueCertificateNoAndCourseName : "No Data"
            )}
            {renderTextField(
              "FSSAI_LicenseNumber",
              HRAData ? HRAData.FSSAI_LicenseNumber : "No Data"
            )}
            {renderTextField(
              "ValidityOfLicense",
              HRAData ? HRAData.validityOfLicense : "No Data"
            )}
            {renderTextField(
              "GSTNumber",
              HRAData ? HRAData.GSTNumber : "No Data"
            )}
            {renderTextField("Remark", HRAData ? HRAData.remarks : "No Data")}
            {renderTextField(
              "Total No Of Employees",
              HRAData ? HRAData.totalNoOfEmployees : "No Data"
            )}
            {renderTextField(
              "Head Office",
              HRAData ? HRAData.headOffice : "No Data"
            )}
          </div>
          {HRAData.reviewPointsAndDocuments.map((review, index) => (
            <div key={index} className="mb-3">
              <h6>{review.reviewQuestion}</h6>
              <p className="mb-0">
                Answer: {review.reviewAnswer ? "Yes" : "No"}
              </p>
              {review.reviewEvidenceDocumentFileObject && (
                <a
                  href={`${SERVER_URL}/${review.reviewEvidenceDocumentFileObject.uri}`}
                  target="_blank"
                >
                  {review.reviewEvidenceDocumentFileObject.filename}
                </a>
              )}
            </div>
          ))}
          <h6 className="my-3 fw-bold">
            Proposal -{" "}
            {HRAData?.proposalFileObject ? (
              <a
                href={`${SERVER_URL}/${HRAData.proposalFileObject.uri}`}
                target="_blank"
              >
                Proposal.pdf
              </a>
            ) : (
              "No Data"
            )}
          </h6>
          <h6 className="my-3 fw-bold">
            Agreement -{" "}
            {HRAData?.agreementFileObject ? (
              <a
                href={`${SERVER_URL}/${HRAData.agreementFileObject.uri}`}
                target="_blank"
              >
                Agreement.pdf
              </a>
            ) : (
              "No Data"
            )}
          </h6>
        </>
      )}
    </div>
  );
}

export default ClientBasicandAdvance;
