import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../FeedbackForm/FeedbackForm-list.css";
import {
  Button,
  Card,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Iconify from "../../iconify/Iconify";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { getData } from "src/webService/webService";

import FEEDBACK_FORM_ROUTES from "src/API_Routes/FeedbackForm/FeedbackForm_Routers";
import FeedbackChart from "../../ChartDashboard/FeedbackChart";

export default function FeedbackFormList() {
  const isAuthenticated = sessionStorage.getItem("isAuthenticated");
  const [FeedbackFormData, SetFeedbackFormData] = useState(null);
  const navigate = useNavigate();
  const accessToken = sessionStorage.getItem("Token");

  // Table Conditions
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit, setLimit] = useState(5);

  const handleViewFeedbackForm = (value) => {
    console.log("event?.target?.value", value);
    navigate(`/feedbackForm/cb?id=${value}`);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };
  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const handleLimitChange = (event) => {
    setCurrentPage(1);
    setLimit(event.target.value);
  };
  // Table Conditions
  const getFeedbackForms = async (page) => {
    const params = {
      page: page,
      limit: limit,
    };
    try {
      const res = await getData(
        FEEDBACK_FORM_ROUTES.GET_FEEDBACK_FORMS_CB("CB"),
        params,
        accessToken
      );
      if (res.status === 200) {
        SetFeedbackFormData(res?.data?.results);
        console.log("res?.data?.results", res?.data?.results);
        setTotalPages(res?.data?.totalPages);
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("An error occurred. Please try again later.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  useEffect(() => {
    getFeedbackForms(currentPage);
  }, [currentPage, limit]);

  return (
    <>
      <ToastContainer />
      {isAuthenticated !== "Admin" ? (
        <>
          <div className="cso-feedbackformlist-border p-5">
            <div className="px-2 list-title">
              <span className="">Feedback form List</span>
            </div>
            <FeedbackChart />
            <Card>
              <h5 class="ps-3 mt-3">Feedback form List</h5>
              <TableContainer sx={{ minWidth: 800 }} className="mt-4">
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell className="cl-green text-dark">
                        Client Name
                      </TableCell>
                      <TableCell className="cl-green text-dark">
                        Name Of The Contact
                      </TableCell>
                      <TableCell className="cl-green text-dark">
                        Position
                      </TableCell>
                      <TableCell className="cl-green text-dark">
                        Standard Applied
                      </TableCell>
                      <TableCell className="cl-green text-dark">
                        Feedback Date
                      </TableCell>
                      <TableCell className="cl-green text-dark">
                        Action
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {FeedbackFormData &&
                      FeedbackFormData?.map((row) => {
                        const {
                          _id,
                          clientName,
                          nameOfTheContact,
                          position,
                          standardApplied,
                          feedbackDate,
                        } = row;
                        return (
                          <TableRow key={_id}>
                            <TableCell>
                              {clientName ? clientName : "#"}
                            </TableCell>
                            <TableCell>
                              {nameOfTheContact ? nameOfTheContact : "#"}
                            </TableCell>
                            <TableCell>{position ? position : "#"}</TableCell>
                            <TableCell>
                              {standardApplied ? standardApplied : "#"}
                            </TableCell>
                            <TableCell>
                              {feedbackDate
                                ? new Date(feedbackDate)?.toLocaleDateString()
                                : "#"}
                            </TableCell>
                            <TableCell>
                              <Button
                                className="cl-white text-black"
                                onClick={() => handleViewFeedbackForm(_id)}
                                startIcon={<Iconify icon={"raphael:view"} />}
                              ></Button>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <div className="d-flex flex-wrap justify-content-end m-3">
                <FormControl variant="outlined" size="small">
                  <InputLabel id="limit-label">Limit</InputLabel>
                  <Select
                    labelId="limit-label"
                    id="limit-select"
                    value={limit}
                    onChange={handleLimitChange}
                  >
                    <MenuItem value={5}>5</MenuItem>
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={15}>15</MenuItem>
                  </Select>
                </FormControl>
                <Button onClick={handlePrevPage} disabled={currentPage === 1}>
                  <Iconify icon={"grommet-icons:previous"} />
                </Button>
                <Button
                  onClick={handleNextPage}
                  disabled={currentPage === totalPages}
                >
                  <Iconify icon={"grommet-icons:next"} />
                </Button>
              </div>
            </Card>
          </div>
        </>
      ) : (
        <>
          <FeedbackChart />
          <Card className="mb-4">
            <h5 class="ps-3 mt-3">Feedback form List</h5>
            <TableContainer sx={{ minWidth: 800 }} className="mt-4">
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className="cl-green text-dark">
                      Client Name
                    </TableCell>
                    <TableCell className="cl-green text-dark">
                      Name Of The Contact
                    </TableCell>
                    <TableCell className="cl-green text-dark">
                      Position
                    </TableCell>
                    <TableCell className="cl-green text-dark">
                      Standard Applied
                    </TableCell>
                    <TableCell className="cl-green text-dark">
                      Feedback Date
                    </TableCell>
                    <TableCell className="cl-green text-dark">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {FeedbackFormData &&
                    FeedbackFormData?.map((row) => {
                      const {
                        _id,
                        clientName,
                        nameOfTheContact,
                        position,
                        standardApplied,
                        feedbackDate,
                      } = row;
                      return (
                        <TableRow key={_id}>
                          <TableCell>{clientName ? clientName : "#"}</TableCell>
                          <TableCell>
                            {nameOfTheContact ? nameOfTheContact : "#"}
                          </TableCell>
                          <TableCell>{position ? position : "#"}</TableCell>
                          <TableCell>
                            {standardApplied ? standardApplied : "#"}
                          </TableCell>
                          <TableCell>
                            {feedbackDate
                              ? new Date(feedbackDate)?.toLocaleDateString()
                              : "#"}
                          </TableCell>
                          <TableCell>
                            <Button
                              className="cl-white text-black"
                              onClick={() => handleViewFeedbackForm(_id)}
                              startIcon={<Iconify icon={"raphael:view"} />}
                            ></Button>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <div className="d-flex flex-wrap justify-content-end m-3">
              <FormControl variant="outlined" size="small">
                <InputLabel id="limit-label">Limit</InputLabel>
                <Select
                  labelId="limit-label"
                  id="limit-select"
                  value={limit}
                  onChange={handleLimitChange}
                >
                  <MenuItem value={5}>5</MenuItem>
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={15}>15</MenuItem>
                </Select>
              </FormControl>
              <Button onClick={handlePrevPage} disabled={currentPage === 1}>
                <Iconify icon={"grommet-icons:previous"} />
              </Button>
              <Button
                onClick={handleNextPage}
                disabled={currentPage === totalPages}
              >
                <Iconify icon={"grommet-icons:next"} />
              </Button>
            </div>
          </Card>
        </>
      )}
    </>
  );
}
