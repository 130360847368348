import CLIENTROUTES from "../API_Routes/Client/Client_Routers";
import {
  setReCertification,
  setTransferRAData,
  setTransferSA1Data,
  setTransferSA2Data,
} from "../redux/slices/FeeSlice";
import { setAdvance } from "../redux/slices/SpecificClient";
import { getData } from "../webService/webService";

const SpecificUserAdvance = (clientBasicId, dispatch) => {
  const accessToken = sessionStorage.getItem("Token");
  getData(
    `${CLIENTROUTES.GETSPECIFICUSERADVANCE}${clientBasicId}/advanced`,
    null,
    accessToken
  )
    .then((res) => {
      dispatch(setAdvance(res.data));

      const auditTypeName =
        res.data.auditCertificationActivitiesAndFees.auditTypeStates[0].name;
      const auditData = res.data.auditCertificationActivitiesAndFees;

      switch (auditTypeName) {
        case "Transfer Certification in SA1":
          dispatch(setTransferSA1Data(auditData));
          break;
        case "Transfer Certification in SA2":
          dispatch(setTransferSA2Data(auditData));
          break;
        case "Transfer Certification in Re-Certification":
          dispatch(setTransferRAData(auditData));
          break;
        case "Re-Certification":
          dispatch(setReCertification(auditData));
          break;
        default:
          break;
      }
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};

export default SpecificUserAdvance;
