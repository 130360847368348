let SERVER_URL;

const SERVER_ENV = "dev";

if (SERVER_ENV === "local") {
  SERVER_URL = "http://localhost:5000/";
}

if (SERVER_ENV === "prod") {
  SERVER_URL = "";
}

if (SERVER_ENV === "dev") {
  SERVER_URL = "http://207.148.74.195:5000/";
}

if (SERVER_ENV === "staging") {
  SERVER_URL = "";
}

export default SERVER_URL;
