import { Helmet } from "react-helmet-async";

import HRA_FeedbackForm from "../../components/FeedbackForm/HRA_FeedbackForm";

export default function HRAFeedbackFormPage() {
  return (
    <>
      <Helmet>
        <title> Feedback Form </title>
      </Helmet>
        <HRA_FeedbackForm />
    </>
  );
}
