import React, { useEffect, useState } from "react";
import "../AddClient/Addclient.css";
import html2pdf from "html2pdf.js";
import {
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Popover,
  Select,
  Stack,
  TableContainer,
  TablePagination,
  TextField,
  Tooltip,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Checkbox,
} from "@mui/material";
import Iconify from "src/components/iconify";
import AddClientForm from "../Form/Form";
import Scrollbar from "src/components/scrollbar/Scrollbar";
import { useSelector, useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CLIENTROUTES from "src/API_Routes/Client/Client_Routers";
import {
  FileUpload,
  deleteData,
  getData,
  patchData,
  postData,
} from "src/webService/webService";
import { setBasicandAdvance } from "src/redux/slices/ClientSlice";
import {
  setAdvance,
  setBasic,
  setClientBasicId,
} from "src/redux/slices/SpecificClient";
import FILEROUTES from "src/API_Routes/File/File_Router";
import IMS from "src/Config/IMS";
import FSMS from "src/Config/FSMS";
import HRA_Agreement from "src/Config/HRA_Agreement";
import HRA_Proposal from "src/Config/HRA_proposal";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import SalesFilter from "../../../Filter/SalesFilter";
import REVIEWERROUTES from "../../../../API_Routes/Reviewer/Reviewer_Router";
import CommonEmail from "../../../../Config/templates/html/CommonEmailTem";
import EMAIL_ROUTES from "../../../../API_Routes/Email/Email_Routers";
import {
  setReCertification,
  setTransferRAData,
  setTransferSA1Data,
  setTransferSA2Data,
} from "../../../../redux/slices/FeeSlice";
import TransferSA1Table from "../../../../Config/TransferSA1";
import TransferSA2Table from "../../../../Config/TransferSA2";
import TransferRATable from "../../../../Config/TransferRA";
import ReCertificationTable from "../../../../Config/Re-Certification";
import ClientStatusTrackerBasic from "../../../../CommonFunction/clientStatusTrackerBasic";
import GetAllSales, {
  fetchAllSales,
} from "../../../../CommonFunction/GetAllSales";
import SERVER_URL from "../../../../utils/serverURL";
import { getFormattedIndiaTime } from "../../../../utils/timeutils";
import AuditApplicationForm from "src/Config/templates/html/AuditApplicationForm";

export default function AddclientHRA({ tab }) {
  console.log(tab, "tab");
  const isAuthenticated = sessionStorage.getItem("isAuthenticated");
  const SalesNameList = useSelector((state) => state?.SalesList.SalesNameList);
  const scheme_session = sessionStorage.getItem("scheme");
  const [signData, setsignData] = useState(null);
  const [row, setRow] = useState("")
  const convertToPDFAndSendToAPI = async (htmlContent) => {
    try {
      const options = {
        margin: [10, 10, 10, 10], // Adjust margins if needed
        filename: "generated.pdf",
        html2canvas: { scale: 2 },
        jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
      };

      const pdfBlob = await html2pdf()
        .from(htmlContent)
        .set(options)
        .output("blob");
      return pdfBlob;
    } catch (error) {
      console.error("Error while converting PDF or sending it to API:", error);
      throw error;
    }
  };

  const UserId = sessionStorage.getItem("UserId");
  const dispatch = useDispatch();
  const accessToken = sessionStorage.getItem("Token");
  const SpecificClientBasicIdForProposal = useSelector(
    (state) => state?.SpecificClient.ClientBasicId
  );
  const ClientList_ARRAY = useSelector(
    (state) => state?.client?.BasicandAdvance[0]
  );
  const ClientList = useSelector(
    (state) => state?.client?.BasicandAdvance[0]?.paginatedResults
  );
  const [ClientBasicid, setClientBasicid] = useState();
  // dynamic updates
  const [action, setAction] = useState("");
  const [id, setId] = useState("");
  const [selectedId, setSelectedId] = useState(null);

  // Table Conditions
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit, setLimit] = useState(5);
  const [open, setOpen] = useState(null);
  const [clientcurrentstatus, setclientcurrentstatus] = useState();
  const handleOpenMenu = (event, id, status, row) => {
    setOpen(event.currentTarget);
    setSelectedId(id);
    setclientcurrentstatus(status);
    setRow(row)
  };
  const MailSendClient = async (data, clientStatusTracker) => {
    const selectedMailid = ClientList.find((client) => client._id === data);
    const HTML_DATA = {
      ClientName: selectedMailid.companyName,
      ContactPerson: selectedMailid.contactPerson,
      ClientID: selectedMailid.clientId,
      Message:
        "Attached to this email, you will find the proposal and agreement documents we discussed. Please take some time to review these documents at your earliest convenience.",
    };
    ClientStatusTrackerBasic(
      data,
      "1",
      "Proposal sent to client",
      clientStatusTracker
    );
    const proposal = await postData(
      EMAIL_ROUTES.SEND_EMAIL_FILE,
      {
        // to: GetSpecificUser.email,
        to: selectedMailid.email,
        subject: "Proposal",
        html: CommonEmail(HTML_DATA),
        attachments: [
          {
            filename:
              selectedMailid.ClientFormIMS_Data[0]
                .proposalAndAgreementFileObject.filename ||
              selectedMailid.ClientFormFSMS_Data[0]
                .proposalAndAgreementFileObject.filename,
            path:
              selectedMailid.ClientFormIMS_Data[0]
                .proposalAndAgreementFileObject.path ||
              selectedMailid.ClientFormFSMS_Data[0]
                .proposalAndAgreementFileObject.path,
          },
        ],
      },
      accessToken
    );
  };
  const handleCloseMenu = () => {
    setOpen(null);
  };
  const handleLimitChange = (event) => {
    setCurrentPage(1);
    setLimit(event.target.value);
  };
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };
  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  // Table Conditions
  const [addClient, setAddClient] = useState(true);
  const HandleAddClient = () => {
    setAddClient(!addClient);
    setId("");
    dispatch(setBasic(""));
    dispatch(setAdvance(""));
    ClientBasicandadvance(currentPage);
    if (ClientBasicid) {
      setClientBasicid("");
    }
  };
  const ClientBasicandadvance = (page) => {
    const params = {
      scheme: scheme_session === "HRA" ? "HRA" : "CB",
      page: page,
      limit: limit,
    };
    const USER_ID = sessionStorage.getItem("UserId");
    if (isAuthenticated === "Admin") {
      getData(
        CLIENTROUTES.GET_CLIENT_BASIC_AND_ADVANCED_ALL,
        params,
        accessToken
      )
        .then((res) => {
          dispatch(setBasicandAdvance(res.data));
          setTotalPages(res?.data[0]?.totalPages);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } else {
      const url = CLIENTROUTES.GET_CLIENT_BASIC_AND_ADVANCE(USER_ID);
      getData(url, params, accessToken)
        .then((res) => {
          dispatch(setBasicandAdvance(res.data));
          setTotalPages(res?.data[0]?.totalPages);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  };
  useEffect(() => {
    ClientBasicandadvance(currentPage);
  }, [currentPage, limit]);
  const SpecificUserBasic = () => {
    getData(
      `${CLIENTROUTES.GETSPECIFICUSERBASIC}${id}/basic`,
      null,
      accessToken
    )
      .then((res) => {
        dispatch(setBasic(res.data));
        setClientBasicid(res.data._id);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const SpecificUserAdvance = () => {
    getData(
      `${CLIENTROUTES.GETSPECIFICUSERADVANCE}${ClientBasicid}/advanced`,
      null,
      accessToken
    )
      .then((res) => {
        dispatch(setAdvance(res.data));
        if (
          res.data.auditCertificationActivitiesAndFees.auditTypeStates[0]
            .name === "Transfer Certification in SA1"
        ) {
          dispatch(
            setTransferSA1Data(res.data.auditCertificationActivitiesAndFees)
          );
        }
        if (
          res.data.auditCertificationActivitiesAndFees.auditTypeStates[0]
            .name === "Transfer Certification in SA2"
        ) {
          dispatch(
            setTransferSA2Data(res.data.auditCertificationActivitiesAndFees)
          );
        }
        if (
          res.data.auditCertificationActivitiesAndFees.auditTypeStates[0]
            .name === "Transfer Certification in Re-Certification"
        ) {
          dispatch(
            setTransferRAData(res.data.auditCertificationActivitiesAndFees)
          );
        }
        if (
          res.data.auditCertificationActivitiesAndFees.auditTypeStates[0]
            .name === "Re-Certification"
        ) {
          dispatch(
            setReCertification(res.data.auditCertificationActivitiesAndFees)
          );
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const DeleteId = (val) => {
    const confirmDelete = window.confirm("Are you sure you want to delete?");
    if (confirmDelete) {
      deleteData(`${CLIENTROUTES.GETCLIENTBASIC}/${val}/basic`, accessToken)
        .then((res) => {
          setOpen(null);
          ClientBasicandadvance(currentPage);
        })
        .catch((error) => {
          setOpen(null);
          console.error("Error:", error);
        });
    }
  };
  useEffect(() => {
    if (ClientBasicid) {
      SpecificUserAdvance();
    }
  }, [ClientBasicid]);
  useEffect(() => {
    if (id !== "") {
      SpecificUserBasic();
    }
  }, [id]);

  const [signedUploadFile, setSignedUploadFile] = useState();

  const handleSignedFileUpload = async (event, data) => {
    event.preventDefault();
    try {
      const file = event.target.files[0];
      const formDataSignedFileUpload = new FormData();
      formDataSignedFileUpload.append("file", file);
      const Signed_uploadRes = await FileUpload(
        `${FILEROUTES.UPLOAD}${UserId}`,
        formDataSignedFileUpload,
        dispatch
      );
      // const params = {
      //   accounts: {
      //     activityType: {
      //       name: "INITIAL_ACCREDITATION_AND_ADMIN_COST",
      //     },
      //   },
      // };
      const params = {
        accounts: [
          {
            year: 1,
            activityType: [
              {
                name: "INITIAL_ACCREDITATION_AND_ADMIN_COST",
                paymentStatus: "INPROGRESS",
              },
            ],
          },
        ],
      };

      await patchData(`clients/client/${data}/basic`, params, accessToken, dispatch);

      const salesOrder = new FormData();
      salesOrder.salesOrderConfirmationFileObject = Signed_uploadRes?.data;
      await patchData(
        `clients/client/${data}/advanced`,
        salesOrder,
        accessToken,
        dispatch
      );
      ClientBasicandadvance(currentPage);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSignedFileUploadHRA = async (event, data, fileType) => {
    event.preventDefault();
    try {
      const file = event.target.files[0];
      const formData = new FormData();
      formData.append("file", file);
      const uploadRes = await FileUpload(
        `${FILEROUTES.UPLOAD}${UserId}`,
        formData,
        dispatch
      );

      // Determine which field to set based on the fileType parameter
      const fieldName =
        fileType === "proposal"
          ? "signedProposalFileObject"
          : "signedAgreementFileObject";

      // const patchDataPayload = new FormData();
      // patchDataPayload.append(fieldName, uploadRes?.data);

      const patchDataPayload = {
        [fieldName]: uploadRes?.data,
      };

      await patchData(
        `clients/client/${data}/advanced`,
        patchDataPayload,
        accessToken,
        dispatch
      );
    } catch (error) {
      console.log(error);
    }
  };

  // console.log('signedUploadFile', signedUploadFile)

  const handleCIFAFileUpload = async (event, clientBasicIdParams, status) => {
    const GetSpecificClient = ClientList.find(
      (item) => item._id === clientBasicIdParams
    );
    event.preventDefault();
    dispatch(setClientBasicId(clientBasicIdParams));
    try {
      const file = event.target.files[0];
      const formDataCIFAUpload = new FormData();
      formDataCIFAUpload.append("file", file);
      const CIFA_uploadRes = await FileUpload(
        `${FILEROUTES.UPLOAD}${UserId}`,
        formDataCIFAUpload,
        dispatch
      );
      // updating the client advanced with file object
      // const formDataCIFA_Advance = new FormData();
      // formDataCIFA_Advance.CIFA_FileObject = CIFA_uploadRes?.data;
      // await patchData(
      //   `${CLIENTROUTES.GETCLIENTADVANCE}${clientBasicIdParams}/advanced`,
      //   formDataCIFA_Advance,
      //   accessToken
      // );

      // Fetching existing CIFA_FileObjects array from the backend response or initialize an empty array
      let existingCIFAFileObjects;
      if (GetSpecificClient.ClientFormIMS_Data) {
        existingCIFAFileObjects =
          GetSpecificClient?.ClientFormIMS_Data?.[0]?.CIFA_FileObjects || [];
      } else {
        existingCIFAFileObjects =
          GetSpecificClient?.ClientFormFSMS_Data?.[0]?.CIFA_FileObjects || [];
      }

      // // Modify the first object in the array or add a new object if the array is empty
      // if (existingCIFAFileObjects.length > 0) {
      //   existingCIFAFileObjects[0] = CIFA_uploadRes?.data;
      // } else {
      //   existingCIFAFileObjects.push(CIFA_uploadRes?.data);
      // }
      let updatedCIFAFileObjects;
      if (existingCIFAFileObjects.length > 0) {
        updatedCIFAFileObjects = [
          ...existingCIFAFileObjects,
          CIFA_uploadRes?.data,
        ];
      } else {
        updatedCIFAFileObjects = [CIFA_uploadRes?.data];
      }

      // Update formDataCIFA_Advance with the modified array
      // formDataCIFA_Advance.CIFA_FileObjects = existingCIFAFileObjects;
      const sendparams = {
        CIFA_FileObjects: updatedCIFAFileObjects,
      };

      await patchData(
        `${CLIENTROUTES.GETCLIENTADVANCE}${clientBasicIdParams}/advanced`,
        sendparams,
        accessToken,
        dispatch
      );
      let description = "CIFA File Uploaded";
      if (status === "need revision") {
        description = "Revision CIFA File Uploaded";
      }


      if (status === "need revision") {
        const formattedDate = getFormattedIndiaTime();
        const sendparams = {
          status: {
            type: "revision resolved",
            updatedDate: formattedDate,
          },
        };
        await patchData(
          `${CLIENTROUTES.GETCLIENTBASIC}/${clientBasicIdParams}/basic`,
          sendparams,
          accessToken,
          dispatch
        );
        if (GetSpecificClient.reviewerId) {
          const ReviwerId = GetSpecificClient.reviewerId;
          const user = ReviewerList.find((user) => user._id === ReviwerId);
          const specificClientReviwerId = user.email;
          const HTML_DATA = {
            ClientName: GetSpecificClient.companyName,
            ContactPerson: GetSpecificClient.contactPerson,
            ClientID: GetSpecificClient.clientId,
            AuditType: "INITIAL_ACCREDITATION_AND_ADMIN_COST",
            Message: "The above document revision resolved.",
          };
          await postData(
            EMAIL_ROUTES.SEND_EMAIL_TEXT,
            {
              to: specificClientReviwerId,
              subject: "Need Revision Resolved",
              html: CommonEmail(HTML_DATA),
            },
            accessToken
          );
        }
      }

      setCifeFileSignpopup(true);
      dispatch(setClientBasicId(clientBasicIdParams));
    } catch (error) {
      console.log(error);
    }
  };
  //cifa additional info
  const [CifeFileSignpopup, setCifeFileSignpopup] = useState(false);
  const [SalePersonSign, setSalePersonSign] = useState(null);
  const [AuthorizedPersonSign, setAuthorizedPersonSign] = useState(null);
  const [AuthorizedPersonName, setAuthorizedPersonName] = useState("");
  const [AuthorizedPersonDesignation, setAuthorizedPersonDesignation] =
    useState("");
  const [DDMonthYear, setDDMonthYear] = useState("");
  const CifaAdditionInfoClose = async () => {
    if (
      !SalePersonSign &&
      !AuthorizedPersonSign &&
      !AuthorizedPersonName.trim() &&
      !AuthorizedPersonDesignation.trim() &&
      !DDMonthYear.trim()
    ) {
      toast.error("Update All The Required Field", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      const cifaFormData = localStorage.getItem("cifaFormData");
      const parsedFormData = JSON.parse(cifaFormData);
      const GetSpecificUser = ClientList.find(
        (item) => item._id === SpecificClientBasicIdForProposal
      );
      const GetClientFormIMS_Data = GetSpecificUser.ClientFormIMS_Data[0];
      const GetClientFormFSMS_Data = GetSpecificUser.ClientFormFSMS_Data[0];
      let htmlString;
      const imsAuditTypeName =
        GetSpecificUser.ClientFormIMS_Data?.[0]
          ?.auditCertificationActivitiesAndFees?.auditTypeStates?.[0]?.name;
      const fsmsAuditTypeName =
        GetSpecificUser.ClientFormFSMS_Data?.[0]
          ?.auditCertificationActivitiesAndFees?.auditTypeStates?.[0]?.name;

      if (
        GetSpecificUser.standardsSchemeType === "IMS" &&
        imsAuditTypeName === "Initial Certification"
      ) {
        htmlString = IMS({
          GetSpecificUser,
          GetClientFormIMS_Data,
          parsedFormData,
        });
      } else if (
        imsAuditTypeName === "Transfer Certification in SA1" ||
        fsmsAuditTypeName === "Transfer Certification in SA1"
      ) {
        htmlString = TransferSA1Table({
          GetSpecificUser,
          GetClientFormIMS_Data,
          parsedFormData,
          GetClientFormFSMS_Data,
        });
      } else if (
        imsAuditTypeName === "Transfer Certification in SA2" ||
        fsmsAuditTypeName === "Transfer Certification in SA2"
      ) {
        htmlString = TransferSA2Table({
          GetSpecificUser,
          GetClientFormIMS_Data,
          parsedFormData,
          GetClientFormFSMS_Data,
        });
      } else if (
        imsAuditTypeName === "Transfer Certification in Re-Certification" ||
        fsmsAuditTypeName === "Transfer Certification in Re-Certification"
      ) {
        htmlString = TransferRATable({
          GetSpecificUser,
          GetClientFormIMS_Data,
          parsedFormData,
          GetClientFormFSMS_Data,
        });
      } else if (
        imsAuditTypeName === "Re-Certification" ||
        fsmsAuditTypeName === "Re-Certification"
      ) {
        htmlString = ReCertificationTable({
          GetSpecificUser,
          GetClientFormIMS_Data,
          parsedFormData,
          GetClientFormFSMS_Data,
        });
      } else if (
        GetSpecificUser.standardsSchemeType === "FSMS" &&
        fsmsAuditTypeName === "Initial Certification"
      ) {
        htmlString = FSMS({
          GetSpecificUser,
          GetClientFormFSMS_Data,
          parsedFormData,
        });
      } else {
        console.log("No Data");
      }
      const pdfBlob = await convertToPDFAndSendToAPI(htmlString);
      const formDataProbAndAgr = new FormData();
      formDataProbAndAgr.append("file", pdfBlob, "generated.pdf");
      const ProposalAndAgreement = await FileUpload(
        FILEROUTES.UPLOAD + UserId,
        formDataProbAndAgr,
        dispatch
      );
      // updating the client advanced with proposalAndAgreement file object
      const formDataproposalAndAgreement = new FormData();
      formDataproposalAndAgreement.proposalAndAgreementFileObject =
        ProposalAndAgreement?.data;
      await patchData(
        `${CLIENTROUTES.GETCLIENTADVANCE}${SpecificClientBasicIdForProposal}/advanced`,
        formDataproposalAndAgreement,
        accessToken,
        dispatch
      );


      setCifeFileSignpopup(false);
      localStorage.removeItem("cifaFormData");
      ClientBasicandadvance(currentPage);
    }
  };
  const CifafileSignUpload = (event, data) => {
    event.preventDefault();
    const file = event.target.files[0];
    const CIFASignUpload = new FormData();
    CIFASignUpload.append("file", file);
    if (file) {
      const reader = new FileReader();

      reader.onload = function (event) {
        const base64String = event.target.result;
        console.log(base64String);

        if (data === "Sale") {
          setSalePersonSign(base64String);
        } else if (data === "HRA") {
          setCAUSSAsign(base64String);
        } else {
          setAuthorizedPersonSign(base64String);
        }
      };

      reader.onerror = function (error) {
        console.error("Error: ", error);
      };

      reader.readAsDataURL(file);
    }
    // FileUpload(`${FILEROUTES.UPLOAD}${UserId}`, CIFASignUpload)
    //   .then((response) => {
    //     if (data === "Sale") {
    //       setSalePersonSign(response.data);
    //     } else if (data === "HRA") {
    //       setCAUSSAsign(response.data);
    //     } else {
    //       setAuthorizedPersonSign(response.data);
    //     }
    //   })
    //   .catch((error) => {
    //     console.error("Error:", error);
    //   });
  };

  // Function to store data in local session
  const storeDataInLocalStorage = () => {
    const dataToStore = {
      SalePersonSign,
      // AuthorizedPersonSign,
      AuthorizedPersonName,
      AuthorizedPersonDesignation,
      DDMonthYear,
    };

    // Convert data to JSON string
    const jsonData = JSON.stringify(dataToStore);

    // Store JSON string in local session
    localStorage.setItem("cifaFormData", jsonData);
  };

  // Call the function to store data in local session
  storeDataInLocalStorage();
  useEffect(() => {
    const cifaFormData = localStorage.getItem("cifaFormData");
    if (cifaFormData) {
      const parsedFormData = JSON.parse(cifaFormData);
      setsignData(parsedFormData);
    }
  }, [CifeFileSignpopup]);

  //HRA PROPOSAL INFO
  const [HRApopup, setHRApopup] = useState(false);
  const [proposalfor, setproposalfor] = useState("");
  const [ourservices, setourservices] = useState("");
  const [HygieneRatingAudit, setHygieneRatingAudit] = useState("");
  const [GrandTotal, setGrandTotal] = useState("");
  const [GST, setGST] = useState("");
  // HRA AGREEMENT INFO
  const [FBOADDRESS, setFBOADDRESS] = useState("");
  const [CAUSSAName, setCAUSSAName] = useState("");
  const [CAUSSADesignation, setCAUSSADesignation] = useState("");
  const [CAUSSAPlace, setCAUSSAPlace] = useState("");
  const [CAUSSAsign, setCAUSSAsign] = useState("");

  const [FBOName, setFBOName] = useState("");
  const [FBODesignation, setFBODesignation] = useState("");
  const [FBOPlace, setFBOPlace] = useState("");
  const [HRASelectedid, setHRASelectedId] = useState("");
  const HRApopupOpen = (data) => {
    setHRASelectedId(data);
    setHRApopup(true);
  };
  const HRApopupClose = async () => {
    // Define an array of required fields
    const requiredFields = [
      "proposalfor",
      "ourservices",
      "HygieneRatingAudit",
      "GrandTotal",
      "GST",
      "FBOADDRESS",
      "CAUSSAName",
      "CAUSSADesignation",
      "CAUSSAPlace",
      "CAUSSAsign",
      "FBOName",
      "FBODesignation",
      "FBOPlace",
    ];

    // Check if all required fields are filled
    const allFieldsFilled = requiredFields.every((field) => !!field);

    if (allFieldsFilled) {
      const HRAData = JSON.parse(localStorage.getItem("HRAData"));
      const GetSpecificUser = ClientList.find(
        (item) => item._id === HRASelectedid
      );
      const GetClientFormHRA_Data = GetSpecificUser.ClientFormHRA_Data[0];

      let htmlString, htmlStringAgreement;

      if (GetSpecificUser.standardsSchemeType === "HRA") {
        htmlString = HRA_Proposal({
          GetSpecificUser,
          GetClientFormHRA_Data,
          parsedFormDataHRA: HRAData,
        });

        htmlStringAgreement = HRA_Agreement({
          GetSpecificUser,
          GetClientFormHRA_Data,
          parsedFormDataHRA: HRAData,
        });
      }

      const pdfBlob = await convertToPDFAndSendToAPI(htmlString);
      const pdfBlobAgreement = await convertToPDFAndSendToAPI(
        htmlStringAgreement
      );

      const uploadFile = async (file) => {
        const formData = new FormData();
        formData.append("file", file, "generated.pdf");
        const uploadedFile = await FileUpload(
          FILEROUTES.UPLOAD + UserId,
          formData,
          dispatch
        );
        return uploadedFile?.data;
      };

      const proposalFileObject = await uploadFile(pdfBlob);
      const agreementFileObject = await uploadFile(pdfBlobAgreement);

      // Patching data for proposal and agreement files
      const patchAdvancedData = async (fileObject, route) => {
        await patchData(
          `${CLIENTROUTES.GETCLIENTADVANCE}${HRASelectedid}/advanced`,
          fileObject,
          accessToken,
          dispatch
        );
      };

      await Promise.all([
        patchAdvancedData({ proposalFileObject }, "proposalFileObject"),
        patchAdvancedData({ agreementFileObject }, "agreementFileObject"),
      ]);
      ClientBasicandadvance(currentPage);
      setHRApopup(false);
    } else {
      toast.error("Update All The Required Field", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  // Function to store data in local session
  const HRALocalStorage = () => {
    const dataToStore = {
      proposalfor,
      ourservices,
      HygieneRatingAudit,
      GrandTotal,
      GST,
      FBOADDRESS,
      CAUSSAName,
      CAUSSADesignation,
      CAUSSAPlace,
      CAUSSAsign,
      FBOName,
      FBODesignation,
      FBOPlace,
    };
    const jsonData = JSON.stringify(dataToStore);
    localStorage.setItem("HRAData", jsonData);
  };
  HRALocalStorage();

  // Export data
  // Function to handle export action
  const handleExport = () => {
    const headers = [
      "Client Id",
      "Company Name",
      "Location",
      "Accredited / Unaccredited",
      "Scope",
      "Scheme",
      "Technical Main codes",
      "Technical Sub codes",
      "No. & name of site",
      "No. of MD for each scheme",
      "Order value",
      "Date - CIFA received from  client",
      "Date - CIFA sent to CSO for review",
      "Date - Proposal sent to client",
      "Date - Order conformation",
      "Lead generated by",
      "Sales co-ordinator",
      "Initial payment status",
      "Contact person details - mail / name & mobile",
    ].filter(Boolean);
    // Filter checked items
    const checkedItems = ClientList.filter((item) => isChecked[item._id]);
    let data;

    if (scheme_session !== "HRA") {
      data = checkedItems.map((item) => {
        const getSalesPersonNameById = (id, salesList) => {
          const person = salesList.find(
            (salesPerson) => salesPerson._id === id
          );
          if (person) {
            return { firstName: person.firstName, lastName: person.lastName };
          } else {
            return null; // Or handle the case when the person is not found
          }
        };
        const clientStatusTracker = item.clientStatusTracker;
        let proposalSentDate;
        let ReviewerAssignDate;

        if (clientStatusTracker && clientStatusTracker.length > 0) {
          for (const status of clientStatusTracker) {
            if (status.status === "Proposal sent to client") {
              proposalSentDate = status.date;
            } else if (status.status === "Reviewer Assigned") {
              ReviewerAssignDate = status.date;
            }
          }
        }

        const formatDate = (dateString) => {
          const date = new Date(dateString);
          // Check if the date is invalid
          if (isNaN(date.getTime())) {
            return "-";
          }
          return date.toLocaleDateString("en-GB");
        };
        const SaleFind = getSalesPersonNameById(item.salesId, SalesNameList);
        const SaleName = SaleFind.firstName + SaleFind.lastName;
        const Accredited =
          item.ClientFormIMS_Data?.[0]?.auditCertificationActivitiesAndFees
            ?.auditTypeStates?.[0]?.name === "Initial Certification" ||
            item.ClientFormFSMS_Data?.[0]?.auditCertificationActivitiesAndFees
              ?.auditTypeStates?.[0]?.name === "Initial Certification"
            ? "Unaccredited"
            : "Accredited";

        const calculateOverallTotalManDays = (item) => {
          let totalManDays = 0;
          let itemScheme = {};

          if (item.ClientFormIMS_Data && item.ClientFormIMS_Data[0]) {
            itemScheme =
              item.ClientFormIMS_Data[0].auditCertificationActivitiesAndFees ||
              {};
          } else if (item.ClientFormFSMS_Data && item.ClientFormFSMS_Data[0]) {
            itemScheme =
              item.ClientFormFSMS_Data[0].auditCertificationActivitiesAndFees ||
              {};
          }

          // Calculate total man-days from surveillance audits
          if (itemScheme.surveillanceAudit) {
            itemScheme.surveillanceAudit.forEach((audit) => {
              if (audit.manDay && audit.manDay.totalManDays) {
                totalManDays += audit.manDay.totalManDays;
              }
            });
          }

          // Calculate total man-days from re-certifications
          if (itemScheme.re_Certification) {
            itemScheme.re_Certification.forEach((certification) => {
              if (certification.manDay && certification.manDay.totalManDays) {
                totalManDays += certification.manDay.totalManDays;
              }
            });
          }

          // Calculate total man-days from transfer certifications
          if (itemScheme.transferCertification) {
            itemScheme.transferCertification.forEach((certification) => {
              if (certification.totalManDays) {
                totalManDays += certification.totalManDays;
              }
            });
          }
          // Check for Initial Certification and add stage 1, stage 2, sa1, and sa2 man-days
          const hasInitialCertification =
            itemScheme.auditTypeStates &&
            itemScheme.auditTypeStates.some(
              (state) => state.name === "Initial Certification"
            );

          if (hasInitialCertification) {
            if (itemScheme.stage) {
              itemScheme.stage.forEach((stage) => {
                if (stage.manDay) {
                  totalManDays +=
                    (stage.manDay.onSite || 0) + (stage.manDay.offSite || 0);
                }
              });
            }

            if (itemScheme.surveillanceAudit) {
              itemScheme.surveillanceAudit.forEach((audit) => {
                if (audit.manDay) {
                  totalManDays +=
                    (audit.manDay.onSite || 0) + (audit.manDay.offSite || 0);
                }
              });
            }
          }

          return totalManDays;
        };

        const contactPerson =
          item.email + " / " + item.contactPerson + " & " + item.email;

        return [
          item.clientId,
          item.companyName,
          item.address,
          Accredited,
          item.ClientFormIMS_Data?.[0]?.scopeOfCertification ||
          item.ClientFormFSMS_Data?.[0]?.scopeOfCertification ||
          "-",
          item.standardsSchemeType === "IMS"
            ? item.ClientFormIMS_Data[0]?.standards?.[0] || "-"
            : item.ClientFormFSMS_Data[0]?.standards?.[0] || "-",
          item.technicalCode.mainCode,
          item.technicalCode.subCode,
          item.noOfSites,
          calculateOverallTotalManDays(item),
          item.ClientFormIMS_Data?.[0]?.auditCertificationActivitiesAndFees
            ?.totalContractFee ||
          item.ClientFormFSMS_Data?.[0]?.auditCertificationActivitiesAndFees
            ?.totalContractFee ||
          "-",
          formatDate(
            item.ClientFormIMS_Data?.[0]?.CIFA_FileObjects?.[0]?.createdAt ||
            item.ClientFormFSMS_Data?.[0]?.CIFA_FileObjects?.[0]?.createdAt ||
            "-"
          ),
          formatDate(ReviewerAssignDate),
          formatDate(proposalSentDate),
          formatDate(
            item.ClientFormIMS_Data?.[0]?.salesOrderConfirmationFileObject
              ?.createdAt ||
            item.ClientFormFSMS_Data?.[0]?.salesOrderConfirmationFileObject
              ?.createdAt ||
            "-"
          ),
          item.leadGeneratedBy,
          SaleName,
          item?.accounts?.[0]?.activityType?.[0]?.paymentStatus ?? "-", // Using the nullish coalescing operator to handle null or undefined values
          contactPerson,
        ];
      });
    }

    const worksheet = XLSX.utils.aoa_to_sheet([headers, ...data]);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Client List Data");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    saveAs(
      new Blob([excelBuffer], { type: "application/octet-stream" }),
      "client_list_data.xlsx"
    );
  };

  const [isChecked, setIsChecked] = useState({});
  const [selectAllChecked, setSelectAllChecked] = useState(false);

  // Function to handle checkbox change
  const handleCheckboxChange = (event, id) => {
    setIsChecked({ ...isChecked, [id]: event.target.checked });
  };
  // Function to handle select all checkbox change
  const handleSelectAllCheckboxChange = (event) => {
    const { checked } = event.target;
    const updatedIsChecked = {};

    // Update isChecked object with all checkboxes' states
    ClientList.forEach((row) => {
      updatedIsChecked[row._id] = checked;
    });

    setIsChecked(updatedIsChecked);
    setSelectAllChecked(checked);
  };

  //Filter
  const [filter, SetFilter] = useState(false);
  const ChangeFilter = () => {
    SetFilter(!filter);
  };
  useEffect(() => {
    if (!filter) {
      ClientBasicandadvance(currentPage);
    }
  }, [filter]);

  //Get Reviewer List
  const [ReviewerList, setReviewerList] = useState();
  const GETReviewer = async () => {
    try {
      const res = await getData(
        REVIEWERROUTES.GETREVIEWERLIST,
        null,
        accessToken
      );
      if (res.status === 200) {
        setReviewerList(res.data);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  useEffect(() => {
    GETReviewer();
    fetchAllSales(dispatch, accessToken);
  }, []);


  const review = row?.ClientFormHRA_Data?.[0]?.reviewPointsAndDocuments;

  const data = {
    CompanyName: row?.companyName,
    CCOde: row?.ClientFormHRA_Data?.[0]?.cCode || "",
    ClientAddress: row?.address,
    MainProductionOrService: row?.ClientFormHRA_Data?.[0]?.mainProductionOrService,
    typeOfServices: row?.ClientFormHRA_Data?.[0]?.typeOfServices,
    ContactNo: row?.phoneNumber,
    Email: row?.email,
    ContactPerson: row?.contactPerson,
    SiteLocation: row?.ClientFormHRA_Data?.[0]?.site?.[0]?.location,
    NoOfEmployees: row?.ClientFormHRA_Data?.[0]?.totalNoOfEmployees,
    FoodHandlers: row?.ClientFormHRA_Data?.[0]?.noOfFoodHandlers,
    HeadOffice: row?.ClientFormHRA_Data?.[0]?.headOffice,
    Site: row?.noOfSites,
    uniqueCertificateNoAndCourseName: row?.ClientFormHRA_Data?.[0]?.uniqueCertificateNoAndCourseName,
    FSSAI_LicenseNumber: row?.ClientFormHRA_Data?.[0]?.FSSAI_LicenseNumber,
    validityOfLicense: row?.ClientFormHRA_Data?.[0]?.validityOfLicense,
    GST: row?.ClientFormHRA_Data?.[0]?.GSTNumber,
    Supervisors: row?.ClientFormHRA_Data?.[0]?.FoSTaC_TrainedFoodSafetySupervisors,
    ClientID: '12345',
    AuditType: row?.ClientFormHRA_Data?.[0]?.auditType,
    Remarks: row?.ClientFormHRA_Data?.[0]?.remarks,
    kob: row?.ClientFormHRA_Data?.[0]?.kob,
    DoorNo: row?.doorNo,
    District: row?.district,
    City: row?.city,
    PinCode: row?.pincode,
    StreetName: row?.streetName,
    uniqueCertificateNo: row?.ClientFormHRA_Data?.[0]?.uniqueCertificateNo,
    courseCode: row?.ClientFormHRA_Data?.[0]?.courseCode,
    Message: 'Please review the attached audit document.'
  };


  const convertToPDFAndDownload = async (htmlContent, fileName) => {

    try {
      const element = document.createElement('div');
      element.innerHTML = htmlContent;

      const opt = {
        margin: [13, 12], // Adjust margins as needed
        filename: fileName,
        image: { type: 'jpeg', quality: 0.98 },
        // html2canvas: { scale: 2 },
        // jsPDF: { unit: 'in', format: 'A4', orientation: 'portrait' }
      };

      await html2pdf().from(element).set(opt).save();
    } catch (error) {
      console.error('Error during PDF download:', error);
    }
  };



  return (
    <>
      <ToastContainer />
      <Stack direction="row" alignItems="center" justifyContent="end">
        <Dialog
          open={CifeFileSignpopup}
          onClose={CifaAdditionInfoClose}
          BackdropProps={{ onClick: (event) => event.stopPropagation() }}
        >
          <DialogTitle>Additional Info </DialogTitle>
          <DialogContent className="d-flex flex-wrap justify-content-between">
            <div className="d-flex flex-wrap col-12">
              <div className="col-6">
                <div
                  className="border w-100 my-2 me-1 px-3"
                  style={{ borderRadius: "7px" }}
                >
                  <label
                    htmlFor="file-input"
                    className="my-2 text-center me-4"
                    style={{ cursor: "pointer" }}
                  >
                    Sale's Signature
                    <Iconify className="ms-3" icon={"uil:upload-alt"} />
                  </label>
                  <input
                    id="file-input"
                    style={{ display: "none" }}
                    type="file"
                    accept="image/*"
                    onChange={(event) => CifafileSignUpload(event, "Sale")}
                  />
                </div>
                {SalePersonSign && (
                  <div className="d-flex justify-content-start w-100 my-2">
                    <img
                      src={SalePersonSign}
                      style={{
                        width: "100px",
                        height: "100px",
                        borderRadius: "10px",
                        objectFit: "contain",
                      }}
                    />
                  </div>
                )}
              </div>
              {/* <div className="col-6">
                <div
                  className="border w-100 my-2 ms-1 px-1"
                  style={{ borderRadius: "7px" }}
                >
                  <label
                    htmlFor="file-input-1"
                    className="my-2 col-12 px-0 text-center"
                    style={{ cursor: "pointer" }}
                  >
                    Authorized Person Signature
                    <Iconify className="ms-2" icon={"uil:upload-alt"} />
                  </label>
                  <input
                    id="file-input-1"
                    className="col-5"
                    style={{ display: "none" }}
                    type="file"
                    accept="image/*"
                    onChange={(event) =>
                      CifafileSignUpload(event, "Authorized")
                    }
                  />
                </div>
                {AuthorizedPersonSign && (
                  <div className="d-flex justify-content-start w-100 my-2">
                    <img
                      src={AuthorizedPersonSign}
                      style={{
                        width: "100px",
                        height: "100px",
                        borderRadius: "10px",
                        objectFit: "contain",
                      }}
                    />
                  </div>
                )}
              </div> */}
            </div>

            <TextField
              className="w-50 pe-2 my-2"
              name="AuthorizedPersonName"
              label="Authorized Person Name"
              value={AuthorizedPersonName}
              size="small"
              onChange={(e) => setAuthorizedPersonName(e.target.value)}
              required
            />
            <TextField
              className="w-50 ps-2 my-2"
              name="AuthorizedPersonDesignation"
              label="Authorized Person Designation"
              value={AuthorizedPersonDesignation}
              size="small"
              onChange={(e) => setAuthorizedPersonDesignation(e.target.value)}
              required
            />
            <TextField
              className="w-50 my-3"
              name="DDMonthYear"
              label="Audit Date"
              value={DDMonthYear}
              size="small"
              onChange={(e) => setDDMonthYear(e.target.value)}
              required
            />
          </DialogContent>
          <DialogActions>
            <div className="d-flex mt-5">
              <Button
                variant="contained"
                className="mx-3"
                onClick={CifaAdditionInfoClose}
              >
                Submit
              </Button>
            </div>
          </DialogActions>
        </Dialog>
      </Stack>

      {/* HRA */}
      <Stack direction="row" alignItems="center" justifyContent="end">
        <Dialog
          open={HRApopup}
          onClose={HRApopupClose}
          BackdropProps={{ onClick: (event) => event.stopPropagation() }}
        >
          <DialogTitle>Additional Info </DialogTitle>
          <DialogContent className="d-flex flex-wrap justify-content-start pt-3">
            <div className="col-6 p-1">
              <TextField
                className="my-1 w-100"
                name=""
                label="Proposal For "
                value={proposalfor}
                size="small"
                onChange={(e) => setproposalfor(e.target.value)}
              />
            </div>
            <div className="col-6 p-1">
              <TextField
                className="my-1 w-100"
                name=""
                label="Our Services"
                value={ourservices}
                size="small"
                onChange={(e) => setourservices(e.target.value)}
              />
            </div>
            <div className="col-4 ">
              <TextField
                type="number"
                className="m-1"
                name=""
                label="Hygiene Rating Audit"
                value={HygieneRatingAudit}
                size="small"
                onChange={(e) => setHygieneRatingAudit(e.target.value)}
              />
            </div>
            <div className="col-4 ">
              <TextField
                type="number"
                className="m-1"
                name=""
                label="GST 18%"
                value={GST}
                size="small"
                onChange={(e) => setGST(e.target.value)}
              />
            </div>
            <div className="col-4 ">
              <TextField
                type="number"
                className="m-1"
                name=""
                label="Grand Total"
                value={GrandTotal}
                size="small"
                onChange={(e) => setGrandTotal(e.target.value)}
              />
            </div>
            <div className="col-12 ">
              <TextField
                className="m-1 w-100"
                name=""
                label="FBO ADDRESS"
                value={FBOADDRESS}
                size="small"
                onChange={(e) => setFBOADDRESS(e.target.value)}
              />
            </div>
            <div className="col-4 ">
              <TextField
                className="m-1"
                name=""
                label="CAUSSA Name"
                value={CAUSSAName}
                size="small"
                onChange={(e) => setCAUSSAName(e.target.value)}
              />
            </div>
            <div className="col-4 ">
              <TextField
                className="m-1"
                name=""
                label="CAUSSA Designation"
                value={CAUSSADesignation}
                size="small"
                onChange={(e) => setCAUSSADesignation(e.target.value)}
              />
            </div>
            <div className="col-4 ">
              <TextField
                className="m-1"
                name=""
                label="CAUSSA Place"
                value={CAUSSAPlace}
                size="small"
                onChange={(e) => setCAUSSAPlace(e.target.value)}
              />
            </div>
            <div className="col-4 ">
              <TextField
                className="m-1"
                name=""
                label="FBO Name"
                value={FBOName}
                size="small"
                onChange={(e) => setFBOName(e.target.value)}
              />
            </div>
            <div className="col-4 ">
              <TextField
                className="m-1"
                name=""
                label="FBO Designation"
                value={FBODesignation}
                size="small"
                onChange={(e) => setFBODesignation(e.target.value)}
              />
            </div>
            <div className="col-4 ">
              <TextField
                className="m-1"
                name=""
                label="FBO Place"
                value={FBOPlace}
                size="small"
                onChange={(e) => setFBOPlace(e.target.value)}
              />
            </div>
            <div className="col-6">
              <div
                className="border w-100 my-2 me-1 px-3"
                style={{ borderRadius: "7px" }}
              >
                <label
                  htmlFor="file-input-1"
                  className="my-2 text-center me-4"
                  style={{ cursor: "pointer" }}
                >
                  CASSA Signature
                  <Iconify className="ms-3" icon={"uil:upload-alt"} />
                </label>
                <input
                  id="file-input-1"
                  style={{ display: "none" }}
                  type="file"
                  accept="image/*"
                  onChange={(event) => CifafileSignUpload(event, "HRA")}
                />
              </div>
              {CAUSSAsign && (
                <div className="d-flex justify-content-start w-100 my-2">
                  <img
                    src={`${SERVER_URL}/${CAUSSAsign.uri}`}
                    style={{
                      width: "100px",
                      height: "100px",
                      borderRadius: "10px",
                      objectFit: "contain",
                    }}
                  />
                </div>
              )}
            </div>
          </DialogContent>
          <DialogActions>
            <div className="d-flex mt-5">
              <Button
                variant="contained"
                className="mx-3"
                onClick={HRApopupClose}
              >
                Submit
              </Button>
            </div>
          </DialogActions>
        </Dialog>
      </Stack>
      {addClient ? (
        <>
          <div className="d-flex flex-wrap  justify-content-end mb-4">
            {tab === "clientList" ? (
              <>
                {isAuthenticated !== "Admin" && (
                  <Button
                    variant="contained"
                    className="btn-green text-white"
                    onClick={() => {
                      HandleAddClient();
                      setOpen(null);
                      setAction("Add");
                    }}
                  >
                    + Add Client
                  </Button>
                )}

                {scheme_session !== "HRA" && (
                  <>
                    <Button
                      variant="contained"
                      className="btn-orange text-dark"
                      onClick={handleExport}
                    >
                      Export
                    </Button>
                    <Button
                      className="btn-green text-white"
                      onClick={ChangeFilter}
                    >
                      {filter ? "Close Filter" : "Show Filter"}
                    </Button>
                  </>
                )}
              </>
            ) : (
              ""
            )}
            <Button
              variant="contained"
              className="btn-green text-white"
              onClick={() => {
                HandleAddClient();
                setOpen(null);
                setAction("Add");
              }}
            >
              + Add Client
            </Button>
          </div>
          {filter && <SalesFilter />}

          <Card>
            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      {tab === "clientList" && (
                        <TableCell
                          className="cl-green text-dark"
                          style={{ padding: "5px" }}
                        >
                          <Checkbox
                            checked={selectAllChecked}
                            onChange={handleSelectAllCheckboxChange}
                          />
                        </TableCell>
                      )}

                      <TableCell className="cl-green text-dark">
                        Client Id
                      </TableCell>
                      <TableCell className="cl-green text-dark">
                        Company Name
                      </TableCell>
                      {scheme_session !== "HRA" && (
                        <>
                          <TableCell className="cl-green text-dark">
                            Technical Main Code
                          </TableCell>
                          <TableCell className="cl-green text-dark">
                            Technical Sub Code
                          </TableCell>
                        </>
                      )}
                      <TableCell className="cl-green text-dark">
                        Scheme
                      </TableCell>
                      {scheme_session !== "HRA" && (
                        <TableCell className="cl-green text-dark">
                          Standards
                        </TableCell>
                      )}
                      <TableCell className="cl-green text-dark">
                        Location
                      </TableCell>
                      {scheme_session !== "HRA" && (
                        <TableCell className="cl-green text-dark">
                          Category
                        </TableCell>
                      )}
                      <TableCell className="cl-green text-dark text-center">
                        Status
                      </TableCell>
                      <TableCell className="cl-green text-dark">
                        Action
                      </TableCell>
                      {tab === "clientList" ? (
                        <>
                          <TableCell className="cl-green text-dark">
                            Initial Payment Status
                          </TableCell>
                          <TableCell className="cl-green text-dark">
                            Action
                          </TableCell>
                        </>
                      ) : tab === "ApprovedCifa" ? (
                        <>
                          <TableCell className="cl-green text-dark">
                            Cifa
                          </TableCell>
                          <TableCell className="cl-green text-dark">
                            Revision Cifa
                          </TableCell>
                          <TableCell
                            align="center"
                            className="cl-green text-dark"
                          >
                            Status
                          </TableCell>
                        </>
                      ) : tab === "Proposal" ? (
                        <>
                          {" "}
                          <TableCell
                            align="center"
                            className="cl-green text-dark"
                          >
                            Agreement / Proposal
                          </TableCell>
                          <TableCell
                            align="center"
                            className="cl-green text-dark"
                          >
                            Send an email to the client
                          </TableCell>
                        </>
                      ) : tab === "Signed Agreement & Proposal" ? (
                        <>
                          <TableCell className="cl-green text-dark">
                            Upload File
                          </TableCell>
                          <TableCell
                            align="center"
                            className="cl-green text-dark"
                          >
                            Status
                          </TableCell>
                        </>
                      ) : (
                        ""
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {ClientList && ClientList.length !== 0 ? (
                      ClientList.map((row) => {
                        const {
                          _id,
                          clientId,
                          companyName,
                          technicalCode,
                          standardsSchemeType,
                          state,
                          country,
                          status,
                          ClientFormIMS_Data,
                          ClientFormFSMS_Data,
                          ClientFormHRA_Data,
                          clientStatusTracker,
                          accounts,
                        } = row;
                        return (
                          <TableRow key={_id}>
                            {tab === "clientList" && (
                              <TableCell padding="checkbox">
                                <Checkbox
                                  checked={isChecked[_id] || false}
                                  onChange={(event) =>
                                    handleCheckboxChange(event, _id)
                                  }
                                />
                              </TableCell>
                            )}

                            <TableCell>{clientId}</TableCell>
                            <TableCell>{companyName}</TableCell>
                            {scheme_session !== "HRA" && (
                              <>
                                <TableCell>{technicalCode.mainCode}</TableCell>
                                <TableCell>{technicalCode.subCode}</TableCell>
                              </>
                            )}
                            <TableCell>{standardsSchemeType}</TableCell>
                            {scheme_session !== "HRA" && (
                              <TableCell>
                                {ClientFormIMS_Data && ClientFormIMS_Data[0]
                                  ? ClientFormIMS_Data[0]?.standards.length > 0
                                    ? ClientFormIMS_Data[0]?.standards[0]
                                    : "-"
                                  : ClientFormFSMS_Data[0]?.standards.length > 0
                                    ? ClientFormFSMS_Data[0]?.standards[0]
                                    : "-"}
                              </TableCell>
                            )}
                            <TableCell>
                              {state} / {country}
                            </TableCell>
                            {scheme_session !== "HRA" && (
                              <TableCell>
                                {ClientFormIMS_Data &&
                                  ClientFormIMS_Data[0] &&
                                  ClientFormIMS_Data[0]
                                    ?.auditCertificationActivitiesAndFees
                                    ?.auditTypeStates?.length > 0
                                  ? ClientFormIMS_Data[0]
                                    .auditCertificationActivitiesAndFees
                                    .auditTypeStates[0].name
                                  : ClientFormFSMS_Data &&
                                    ClientFormFSMS_Data[0] &&
                                    ClientFormFSMS_Data[0]
                                      ?.auditCertificationActivitiesAndFees
                                      ?.auditTypeStates?.length > 0
                                    ? ClientFormFSMS_Data[0]
                                      .auditCertificationActivitiesAndFees
                                      .auditTypeStates[0].name
                                    : "-"}
                              </TableCell>
                            )}
                            <TableCell align="center">
                              {typeof status === "object"
                                ? status.type
                                : status}
                            </TableCell>
                            <TableCell align="left">
                              <IconButton
                                size="large"
                                color="inherit"
                                onClick={(event) =>
                                  handleOpenMenu(event, _id, status.type, row)
                                }
                              >
                                <Iconify icon={"eva:more-vertical-fill"} />
                              </IconButton>
                            </TableCell>
                            {tab === "clientList" ? (
                              <>
                                <TableCell>
                                  {accounts.length !== 0
                                    ? accounts[0].activityType?.[0]
                                      .paymentStatus
                                    : "-"}
                                </TableCell>

                                <TableCell align="left">
                                  <IconButton
                                    size="large"
                                    color="inherit"
                                    onClick={(event) =>
                                      handleOpenMenu(event, _id, status.type)
                                    }
                                  >
                                    <Iconify icon={"eva:more-vertical-fill"} />
                                  </IconButton>
                                </TableCell>
                              </>
                            ) : tab === "ApprovedCifa" &&
                              scheme_session !== "HRA" ? (
                              <>
                                <TableCell>
                                  {ClientFormIMS_Data &&

                                    ClientFormIMS_Data[0] &&
                                    (status.type === "waiting for reviewer" ||
                                      status === "waiting for reviewer") ? (
                                    ClientFormIMS_Data.length > 0 ? (
                                      <div key={_id} id={`${_id}`}>
                                        <label
                                          htmlFor={`fileInput${_id}`}
                                          className="text-success p-4 fw-bold"
                                          style={{ cursor: "pointer" }}
                                        >
                                          Upload
                                        </label>
                                        <input
                                          id={`fileInput${_id}`}
                                          style={{ display: "none" }}
                                          type="file"
                                          onChange={(event) =>
                                            handleCIFAFileUpload(
                                              event,
                                              _id,
                                              status.type
                                            )
                                          }
                                        />
                                      </div>
                                    ) : (
                                      <Tooltip
                                        title={"Fill The Advance Details"}
                                      >
                                        <Button
                                          sx={{ m: 1 }}
                                          className="text-danger"
                                        >
                                          Can't Upload
                                        </Button>
                                      </Tooltip>
                                    )
                                  ) : ClientFormFSMS_Data.length > 0 ? (
                                    <div key={_id} id={`${_id}`}>
                                      <label
                                        htmlFor={`fileInput${_id}`}
                                        className="text-success p-4 fw-bold"
                                        style={{ cursor: "pointer" }}
                                      >
                                        Upload
                                      </label>
                                      <input
                                        id={`fileInput${_id}`}
                                        style={{ display: "none" }}
                                        type="file"
                                        onChange={(event) =>
                                          handleCIFAFileUpload(
                                            event,
                                            _id,
                                            status.type
                                          )
                                        }
                                      />
                                    </div>
                                  ) : (
                                    <Tooltip title={"Fill The Advance Details"}>
                                      <Button
                                        sx={{ m: 1 }}
                                        className="text-danger"
                                      >
                                        Can't Upload
                                      </Button>
                                    </Tooltip>
                                  )}
                                </TableCell>

                                <TableCell align="center">
                                  {status.type === "need revision" ||
                                    status === "need revision" ? (
                                    ClientFormIMS_Data &&
                                      ClientFormIMS_Data[0] ? (
                                      ClientFormIMS_Data.length > 0 ? (
                                        <div key={_id} id={`${_id}`}>
                                          <label
                                            htmlFor={`fileInput${_id}`}
                                            className="text-success p-4 fw-bold"
                                            style={{ cursor: "pointer" }}
                                          >
                                            Upload
                                          </label>
                                          <input
                                            id={`fileInput${_id}`}
                                            style={{ display: "none" }}
                                            type="file"
                                            onChange={(event) =>
                                              handleCIFAFileUpload(
                                                event,
                                                _id,
                                                status.type
                                              )
                                            }
                                          />
                                        </div>
                                      ) : (
                                        <Tooltip
                                          title={"Fill The Advance Details"}
                                        >
                                          <Button
                                            sx={{ m: 1 }}
                                            className="text-danger"
                                          >
                                            Can't Upload
                                          </Button>
                                        </Tooltip>
                                      )
                                    ) : ClientFormFSMS_Data.length > 0 ? (
                                      <div key={_id} id={`${_id}`}>
                                        <label
                                          htmlFor={`fileInput${_id}`}
                                          className="text-success p-4 fw-bold"
                                          style={{ cursor: "pointer" }}
                                        >
                                          Upload
                                        </label>
                                        <input
                                          id={`fileInput${_id}`}
                                          style={{ display: "none" }}
                                          type="file"
                                          onChange={(event) =>
                                            handleCIFAFileUpload(
                                              event,
                                              _id,
                                              status.type
                                            )
                                          }
                                        />
                                      </div>
                                    ) : (
                                      <Tooltip
                                        title={"Fill The Advance Details"}
                                      >
                                        <Button
                                          sx={{ m: 1 }}
                                          className="text-danger"
                                        >
                                          Can't Upload
                                        </Button>
                                      </Tooltip>
                                    )
                                  ) : (
                                    "-"
                                  )}
                                </TableCell>

                                <TableCell align="center">
                                  {ClientFormIMS_Data &&
                                    ClientFormIMS_Data[0] ? (
                                    ClientFormIMS_Data[0].CIFA_FileObjects &&
                                      ClientFormIMS_Data[0].CIFA_FileObjects
                                        .length > 0 ? (
                                      <a
                                        rel="noreferrer"
                                        href={`${SERVER_URL}/${ClientFormIMS_Data[0]
                                          .CIFA_FileObjects[
                                          ClientFormIMS_Data[0]
                                            .CIFA_FileObjects.length - 1
                                        ].uri
                                          }`}
                                        target="_blank"
                                      >
                                        View
                                      </a>
                                    ) : (
                                      <p>No View</p>
                                    )
                                  ) : ClientFormFSMS_Data[0] &&
                                    ClientFormFSMS_Data[0].CIFA_FileObjects &&
                                    ClientFormFSMS_Data[0].CIFA_FileObjects
                                      .length > 0 ? (
                                    <a
                                      rel="noreferrer"
                                      href={`${SERVER_URL}/${ClientFormFSMS_Data[0].CIFA_FileObjects[
                                        ClientFormFSMS_Data[0]
                                          .CIFA_FileObjects.length - 1
                                      ].uri
                                        }`}
                                    >
                                      View
                                    </a>
                                  ) : (
                                    <p>No View</p>
                                  )}

                                  {/* <Iconify icon={"eva:eye-fill"} /> */}
                                </TableCell>
                              </>
                            ) : tab === "Signed Agreement & Proposal" ? (
                              <>
                                <TableCell>
                                  {ClientFormIMS_Data &&
                                    ClientFormIMS_Data[0] ? (
                                    ClientFormIMS_Data.length > 0 ? (
                                      <div key={_id} id={`${_id}`}>
                                        <label
                                          htmlFor={`fileInput${_id}`}
                                          className="text-success p-4 fw-bold"
                                          style={{ cursor: "pointer" }}
                                        >
                                          Upload
                                        </label>
                                        <input
                                          id={`fileInput${_id}`}
                                          style={{ display: "none" }}
                                          type="file"
                                          onChange={(event) =>
                                            handleSignedFileUpload(event, _id)
                                          }
                                        />
                                      </div>
                                    ) : (
                                      <Tooltip
                                        title={"Fill The Advance Details"}
                                      >
                                        <Button
                                          sx={{ m: 1 }}
                                          className="text-danger"
                                        >
                                          Can't Upload
                                        </Button>
                                      </Tooltip>
                                    )
                                  ) : ClientFormFSMS_Data.length > 0 ? (
                                    <div key={_id} id={`${_id}`}>
                                      <label
                                        htmlFor={`fileInput${_id}`}
                                        className="text-success p-4 fw-bold"
                                        style={{ cursor: "pointer" }}
                                      >
                                        Upload
                                      </label>
                                      <input
                                        id={`fileInput${_id}`}
                                        style={{ display: "none" }}
                                        type="file"
                                        onChange={(event) =>
                                          handleSignedFileUpload(event, _id)
                                        }
                                      />
                                    </div>
                                  ) : (
                                    <Tooltip title={"Fill The Advance Details"}>
                                      <Button
                                        sx={{ m: 1 }}
                                        className="text-danger"
                                      >
                                        Can't Upload
                                      </Button>
                                    </Tooltip>
                                  )}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  className="demo123323"
                                >
                                  {ClientFormIMS_Data &&
                                    ClientFormIMS_Data[0] ? (
                                    ClientFormIMS_Data[0]
                                      .salesOrderConfirmationFileObject ? (
                                      <a
                                        rel="noreferrer"
                                        href={`${SERVER_URL}/${ClientFormIMS_Data[0]?.salesOrderConfirmationFileObject?.uri}`}
                                        target="_blank"
                                      >
                                        View
                                      </a>
                                    ) : (
                                      <p>No View</p>
                                    )
                                  ) : ClientFormFSMS_Data[0] &&
                                    ClientFormFSMS_Data[0]
                                      .salesOrderConfirmationFileObject ? (
                                    <a
                                      rel="noreferrer"
                                      href={`${SERVER_URL}/${ClientFormFSMS_Data[0]?.salesOrderConfirmationFileObject?.uri}`}
                                      target="_blank"
                                    >
                                      View
                                    </a>
                                  ) : (
                                    <p>No View</p>
                                  )}
                                  {/* <Iconify icon={"eva:eye-fill"} /> */}
                                </TableCell>
                              </>
                            ) : tab === "Proposal" ? (
                              <>
                                <TableCell align="center">
                                  {(ClientFormIMS_Data &&
                                    ClientFormIMS_Data.length > 0 &&
                                    ClientFormIMS_Data[0]
                                      .proposalAndAgreementFileObject) ||
                                    (ClientFormFSMS_Data &&
                                      ClientFormFSMS_Data.length > 0 &&
                                      ClientFormFSMS_Data[0]
                                        .proposalAndAgreementFileObject) ? (
                                    <a
                                      rel="noreferrer"
                                      href={`${SERVER_URL}/${ClientFormIMS_Data &&
                                        ClientFormIMS_Data.length > 0
                                        ? ClientFormIMS_Data[0]
                                          .proposalAndAgreementFileObject
                                          ?.uri
                                        : ClientFormFSMS_Data[0]
                                          .proposalAndAgreementFileObject
                                          ?.uri
                                        }`}
                                      target="_blank"
                                    >
                                      view
                                    </a>
                                  ) : (
                                    <p>No view</p>
                                  )}
                                </TableCell>
                                <TableCell align="center">
                                  {(ClientFormIMS_Data &&
                                    ClientFormIMS_Data.length > 0 &&
                                    ClientFormIMS_Data[0]
                                      .proposalAndAgreementFileObject) ||
                                    (ClientFormFSMS_Data &&
                                      ClientFormFSMS_Data.length > 0 &&
                                      ClientFormFSMS_Data[0]
                                        .proposalAndAgreementFileObject) ? (
                                    <Button
                                      onClick={() =>
                                        MailSendClient(_id, clientStatusTracker)
                                      }
                                    >
                                      {" "}
                                      <Iconify icon={"quill:send"} />
                                    </Button>
                                  ) : (
                                    <p>-</p>
                                  )}
                                </TableCell>
                              </>
                            ) : (
                              ""
                            )}
                          </TableRow>
                        );
                      })
                    ) : (
                      <TableRow align="center">
                        <h6 className="my-3">No Data</h6>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Scrollbar>
            {ClientList && (
              <div className="d-flex flex-wrap justify-content-end m-3">
                <FormControl variant="outlined" size="small">
                  <InputLabel id="limit-label">Limit</InputLabel>
                  <Select
                    labelId="limit-label"
                    id="limit-select"
                    value={limit}
                    onChange={handleLimitChange}
                  >
                    <MenuItem value={5}>5</MenuItem>
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={15}>15</MenuItem>
                  </Select>
                </FormControl>
                <Button onClick={handlePrevPage} disabled={currentPage === 1}>
                  <Iconify icon={"grommet-icons:previous"} />
                </Button>
                <Button
                  onClick={handleNextPage}
                  disabled={currentPage === totalPages}
                >
                  <Iconify icon={"grommet-icons:next"} />
                </Button>
              </div>
            )}
          </Card>
        </>
      ) : (
        <AddClientForm HandleAddClient={HandleAddClient} action={action} />
      )}
      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            "& .MuiMenuItem-root": {
              px: 1,
              typography: "body2",
              borderRadius: 0.75,
            },
          },
        }}
      >
        {clientcurrentstatus === "waiting for reviewer" ||
          clientcurrentstatus === "pending" ||
          clientcurrentstatus === "need revision" || clientcurrentstatus === "client advance completed" ||
          isAuthenticated === "Admin" ? (
          <MenuItem
            onClick={() => {
              HandleAddClient();
              setOpen(null);
              setAction("Edit");
              setId(selectedId);
            }}
          >
            <Iconify icon={"eva:edit-fill"} sx={{ mr: 2 }} />
            Edit
          </MenuItem>
        ) : (
          ""
        )}

        <MenuItem
          onClick={() => {
            HandleAddClient();
            setAction("View");
            setOpen(null);
            setId(selectedId);
          }}
        >
          <Iconify icon={"raphael:view"} sx={{ mr: 2 }} />
          View
        </MenuItem>
        <MenuItem
          onClick={() => convertToPDFAndDownload(AuditApplicationForm(data, review), 'AuditApplicationForm.pdf', setOpen(null))}
          disabled={clientcurrentstatus === "pending"}
        >
          <Iconify icon={"material-symbols:download"} sx={{ mr: 2 }} />
          download
        </MenuItem>
        <MenuItem
          sx={{ color: "error.main" }}
          onClick={() => {
            DeleteId(selectedId);
          }}
        >
          <Iconify icon={"eva:trash-2-outline"} sx={{ mr: 2 }} />
          Delete
        </MenuItem>
        {isAuthenticated === "Admin" && (
          <MenuItem
            sx={{ color: "error.main" }}
            onClick={() => {
              DeleteId(selectedId);
            }}
          >
            <Iconify icon={"eva:trash-2-outline"} sx={{ mr: 2 }} />
            Delete
          </MenuItem>
        )}
      </Popover>
    </>
  );
}
