const AuditConfirmationEmail = (data) => {
  return `<!DOCTYPE html>
  <html lang="en">
  <head>
      <meta charset="UTF-8">
      <meta name="viewport" content="width=device-width, initial-scale=1.0">
      <title>Audit Confirmation Email</title>
      <style>
          body {
              font-family: Arial, sans-serif;
              margin: 0;
              padding: 0;
              background-color: #f4f4f4;
          }
          .container {
              max-width: 600px;
              margin: 20px auto;
              padding: 20px;
              background-color: #fff;
              border-radius: 5px;
              box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          }
          h2 {
              color: #333;
          }
          p {
              margin-bottom: 10px;
          }
          .info {
              border: 1px solid #ccc;
              border-radius: 5px;
              padding: 10px;
              background-color: #f9f9f9;
          }
      </style>
  </head>
  <body>
      <div class="container">
          <p style="text-align:end">Date: ${data.date}</p>
          <div class="info">
              <p><strong>Client Name:</strong> ${data.ClientName}</p>
              <p><strong>Contact Person:</strong> ${data.ContactPerson}</p>
              <p><strong>Client ID:</strong> ${data.ClientID}</p>
              <p><strong>Audit Type:</strong> ${data.AuditType}</p>
          </div>
          <p>Dear ${data.ClientName},</p>
          <p>This correspondence is to confirm that the audit activity has been finalized by Lead auditor. The Attached is the draft report for the audit conducted at your facility</p>
          <p>We would like to thank you for using CAUSATEC as your certification body and hope you have been pleased with the process</p>
          <p>We welcome your feedback for our continual improvement</p>
          <p>Should you wish to further expand your knowledge around management systems and certifications, please do access our resources and training WEBSITE which is <a href="https://caussatec.com" target="_blank">https://caussatec.com</a></p>
          <p>This is an automatically-generated customer service response from caussatec. Please do not reply to this email. If you require further assistance please contact your local caussatec office</p>
      </div>
  </body>
  </html>
  `;
};
export default AuditConfirmationEmail;
