import React, { useEffect, useState } from "react";
import "../../components/Document/Document.css";
import { getData } from "src/webService/webService";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import COMMONDOCUMENTSROUTES from "src/API_Routes/CommonDocument/CommonDocument";
import SERVER_URL from "../../utils/serverURL";

export default function Document({ Role }) {
  const [Document, setDocument] = useState();
  const accessToken = sessionStorage.getItem("Token");

  const GetReferenceDocument = async () => {
    try {
      const endpoint = Role === "Sales" ? "Sale" : Role;
      const res = await getData(
        `${COMMONDOCUMENTSROUTES.GETFILE}${endpoint}`,
        null,
        accessToken
      );
      const apiResponse = res.data;

      const sopData = apiResponse.filter(
        (item) => item.referenceDocumentType === "SOP"
      );

      const formData = apiResponse.filter(
        (item) => item.referenceDocumentType === "FORM"
      );

      const filteredData = {
        SOP: sopData,
        FORM: formData,
      };

      setDocument(filteredData);
    } catch (error) {
      console.error("Error:", error);
      toast.error("An error occurred. Please try again later.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  console.log(Document, "Document");
  useEffect(() => {
    GetReferenceDocument();
  }, []);
  // Function to convert bytes to kilobytes
  const bytesToKB = (bytes) => {
    return (bytes / 1024).toFixed(2); // Fixed to 2 decimal places
  };

  return (
    <>
      <ToastContainer />
      <div className="Document-border p-5">
        <div className="px-2 add-title">
          <span className="">References</span>
        </div>
        <div className="mt-3">
          <h6 className="fw-bold mb-3 text-underline">SOP</h6>
          {Document?.SOP?.map((data, index) => (
            <p key={index}>
              <a
                id={index}
                rel="noreferrer"
                href={`${SERVER_URL}/${data.referenceDocumentsFileObjects[0]?.uri}`}
                target="_blank"
              >
                {
                  data.referenceDocumentsFileObjects[0]?.uri
                    .split("/")
                    .slice(-1)[0]
                }
              </a>{" "}
              <span className="fw-bold">
                {" "}
                - {bytesToKB(
                  data.referenceDocumentsFileObjects[0]?.size
                )} Kb{" "}
              </span>
            </p>
          ))}
          {Document?.SOP.length === 0 ? <p className="ps-5">No Document</p> : ""}
        </div>
        <div className="mt-3">
          <h6 className="fw-bold mb-3">FORM</h6>
          {Document?.FORM?.map((data, index) => (
            <p key={index}>
              <a
                id={index}
                rel="noreferrer"
                href={`${SERVER_URL}/${data.referenceDocumentsFileObjects[0]?.uri}`}
                target="_blank"
              >
                {
                  data.referenceDocumentsFileObjects[0]?.uri
                    .split("/")
                    .slice(-1)[0]
                }
              </a>{" "}
              <span className="fw-bold">
                {" "}
                - {bytesToKB(
                  data.referenceDocumentsFileObjects[0]?.size
                )} Kb{" "}
              </span>
            </p>
          ))}
          {Document?.FORM.length === 0 ? <p className="ps-5">No Document</p> : ""}
        </div>
      </div>
    </>
  );
}
