import React, { useEffect, useState } from "react";
import {
  TextField, Radio, RadioGroup, InputLabel,
  FormControlLabel, FormControl, FormLabel,
  Typography, List, ListItem, ListItemText, Link,
  Stack, Checkbox
}
  from '@mui/material';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation } from "react-router-dom";

import { getData } from "src/webService/webService";
import FEEDBACK_FORM_ROUTES from "src/API_Routes/FeedbackForm/FeedbackForm_Routers";
import SERVER_URL from "src/utils/serverURL";

export default function HRA_FeedbackFormById() {

  const [feedBackFormData, setFeedbackFormData] = useState(null);
  const accessToken = sessionStorage.getItem("Token");

  const location = useLocation();

  useEffect(() => {
    console.log('feedBackFormData', feedBackFormData)
  }, [feedBackFormData])

  const getFeedbackForm = async () => {
    const searchParams = new URLSearchParams(location.search);
    const feedBackFormId = searchParams.get("id");
    // Now `id` contains the value of the 'id' query parameter
    console.log(feedBackFormId);
    const feedBackFormData = await getData(
      FEEDBACK_FORM_ROUTES.GET_FEEDBACK_FORM_BY_SCHEME_ID(
        'hra',
        feedBackFormId
      ),
      '',
      accessToken
    );
    if (feedBackFormData?.status === 404) {
      toast.error("feedback form doesn't exist", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    setFeedbackFormData(feedBackFormData?.data)
  }
  useEffect(() => { 
    getFeedbackForm() 
  }, [location.search]);

  return (
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="center"
      spacing={2}
    >
      <ToastContainer />
      <form>

        <Typography variant="h3">Feedback Form</Typography>
        <div style={{ padding: 10 + 'px' }}>
          <InputLabel>Client Name</InputLabel>
          <TextField
            name="clientName"
            value={feedBackFormData?.clientName}
            disabled
          />
        </div>

        <div style={{ padding: 10 + 'px' }}>
          <InputLabel>Client Representative Name</InputLabel>
          <TextField
            name="clientRepresentativeName"
            value={feedBackFormData?.clientRepresentativeName}
            disabled
          />
        </div>

        <div style={{ padding: 10 + 'px' }}>
          <InputLabel>Contact Number</InputLabel>
          <TextField
            name="contactNumber"
            value={feedBackFormData?.contactNumber}
            disabled
          />
        </div>

        <div style={{ padding: 10 + 'px' }}>
          <InputLabel>Email</InputLabel>
          <TextField
            name="email"
            value={feedBackFormData?.email}
            disabled
          />
        </div>

        <div style={{ padding: 10 + 'px' }}>
          <InputLabel>Date Of Audit</InputLabel>
          <TextField
            name="dateOfAudit"
            value={
              feedBackFormData?.dateOfAudit ? new Date(feedBackFormData?.dateOfAudit).toLocaleDateString() : ''
            }
            disabled
          />
        </div>

        <div style={{ padding: 10 + 'px' }}>
          <InputLabel>Auditors Name</InputLabel>
          <TextField
            name="auditorsName"
            value={feedBackFormData?.auditorsName}
            disabled
          />
        </div>

        <div style={{ padding: 10 + 'px' }}>
          <InputLabel>Client Address</InputLabel>
          <Typography variant="h7">{feedBackFormData?.clientAddress}</Typography>
        </div>

        {/* Complaints And Grievances */}
        <div style={{ padding: 10 + 'px' }}>
          <Typography variant="h3">Complaints And Grievances</Typography>
          <InputLabel>Details Of The Complaint</InputLabel>
          <TextField
            name="detailsOfTheComplaint"
            value={feedBackFormData?.complaintsAndGrievances?.detailsOfTheComplaint}
            multiline
          disabled
          />
        </div>

        <div style={{ padding: 10 + 'px' }}>
          <InputLabel>Evidences In Support For The Complaint</InputLabel>

          <div style={{ padding: 10 + 'px' }}>
            <List>
              {feedBackFormData
                ?.complaintsAndGrievances
                ?.evidencesInSupportForTheComplaintFileObjects
                ?.map((fileObject, index) => (
                  <ListItem key={index}>
                    {fileObject?.uri && (
                      <ListItemText>
                        Evidence {index} :
                        <Link href={`${SERVER_URL}${fileObject?.uri}`}
                          target="_blank" rel="noopener noreferrer">
                          {fileObject?.filename || `File ${index + 1}`}
                        </Link>
                      </ListItemText>
                    )}
                  </ListItem>
                ))}
            </List>
          </div>
        </div>

        {/* Evaluation */}
        <Typography variant="h3">Evaluation</Typography>
        {feedBackFormData && feedBackFormData?.evaluation.map((item, index) => (
          <div style={{ padding: 10 + 'px' }}>
            <FormControl component="fieldset">
              <FormLabel component="legend" id={"evaluation" + index}>{item?.particulars}</FormLabel>
              <RadioGroup
                aria-labelledby={"evaluation" + index}
                value={item?.yesOrNo}
              >
                <FormControlLabel value={true} control={<Radio />} label="Yes" disabled />
                <FormControlLabel value={false} control={<Radio />} label="No" disabled />
              </RadioGroup>
            </FormControl>
          </div>
        ))}

        <div style={{ padding: 10 + 'px' }}>
          <Typography variant="h3">Signature And Seal</Typography>
          <img
            src={`${SERVER_URL}${feedBackFormData?.signatureAndSeal[0]?.uri}`}
            style={{ maxWidth: '50%', maxHeight: '100px', marginBottom: '10px' }}
          />
        </div>

        <div style={{ padding: 10 + 'px' }}>
          <Checkbox checked={!!feedBackFormData?.declaration} name="declaration" disabled />
          I declare that the details provided above are true to best of my knowledge.
        </div>
      </form>
    </Stack >
  );
}
