import { setAuditorNameList } from "../redux/slices/AuditorListSlice";
import { getData } from "../webService/webService";
export const fetchAllAuditor = async (dispatch, accessToken) => {
  try {
    const res = await getData(
      "masters/user/basic/nameandid/Auditor",
      null,
      accessToken
    );
    if (res.status === 200) {
      dispatch(setAuditorNameList(res.data));
      console.log(res.data);
    }
  } catch (error) {
    console.error("Error:", error);
  }
};
