import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Chart from "react-apexcharts";
import { getData } from "../../webService/webService";
import { Card } from "@mui/material";
import { ToastContainer } from "react-toastify";

const FeedbackChartHRA = () => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [data, setData] = useState({
    evaluation_yesOrNo_yes_count: 0,
    evaluation_yesOrNo_no_count: 0,
  });

  const accessToken = sessionStorage.getItem("Token");

  const GetCount = async (date) => {
    try {
      const params = {
        date: date.toISOString().slice(0, 10), // Format the date as YYYY-MM-DD
      };
      const response = await getData(
        `feedbackform/satisfaction/analysis/monthly/HRA`,
        params,
        accessToken
      );
      // Log the response to see what is being returned
      console.log("API Response:", response);
      // Update status count based on API response
      if (response && response.data) {
        setData(response.data);
      } else {
        console.error("No data received from API");
      }
    } catch (error) {
      console.error("Error fetching status counts:", error);
    }
  };

  useEffect(() => {
    GetCount(selectedDate);
  }, [selectedDate]);

  // Determine if both counts are zero
  const isDataZero =
    data.evaluation_yesOrNo_yes_count === 0 &&
    data.evaluation_yesOrNo_no_count === 0;

  // Data for the ApexCharts pie chart
  const chartData = {
    series: isDataZero
      ? [1]
      : [data.evaluation_yesOrNo_yes_count, data.evaluation_yesOrNo_no_count],
    options: {
      chart: {
        type: "pie",
      },
      labels: isDataZero ? ["No data"] : ["Yes", "No"],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
      fill: {
        colors: isDataZero ? ["#000000"] : undefined, // Set the color to white if no data
      },
      legend: {
        show: !isDataZero, // Hide the legend if no data
      },
    },
  };

  return (
    <Card className="p-3 mb-3">
      <ToastContainer />
      <div>
        <h5 className="fw-bold">CheckList</h5>
        <div className="d-flex align-items-center">
          <p className="fw-bold pe-3 mb-0">Select Month and Year</p>
          <DatePicker
            selected={selectedDate}
            onChange={(date) => setSelectedDate(date)}
            dateFormat="yyyy-MM"
            showMonthYearPicker
            className="form-control"
          />
        </div>

        <Chart
          options={chartData.options}
          series={chartData.series}
          type="pie"
          width="380"
        />
        {isDataZero && (
          <p className="text-center">
            No data available for the selected date.
          </p>
        )}
      </div>
    </Card>
  );
};

export default FeedbackChartHRA;
