import React, { useEffect, useState } from "react";
import "../Client/Client-list.css";
import {
  Button,
  Card,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Popover,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@mui/material";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import SendIcon from "@mui/icons-material/Send";
import Iconify from "../../iconify/Iconify";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useDispatch, useSelector } from "react-redux";
import { setClientBasicandAdvance } from "src/redux/slices/CSOSlice";

import {
  FileUpload,
  getData,
  patchData,
  postData,
} from "src/webService/webService";

import CSOROUTES from "src/API_Routes/CSO/CSO_Routers";
import REVIEWERROUTES from "src/API_Routes/Reviewer/Reviewer_Router";
import CLIENTROUTES from "src/API_Routes/Client/Client_Routers";
import FEEDBACK_FORM_ROUTES from "src/API_Routes/FeedbackForm/FeedbackForm_Routers";
import EMAIL_ROUTES from "src/API_Routes/Email/Email_Routers";

import FEEDBACK_EMAIL_HTML from "src/Config/templates/html/Feedback_Email";

import { CB_FEEDBACK_FORM_DATA } from "src/Config/feedbackForm/index";
import EVENT_TRACKING_ROUTES from "../../../API_Routes/EventTracking/EventTracking_Routers";
import CLIENT_ROUTES from "../../../API_Routes/Client/Client_Routers";
import CommonEmail from "../../../Config/templates/html/CommonEmailTem";
import CSOFilter from "../../Filter/CSOFilter";
import SpecificUserBasic from "../../../CommonFunction/GetClientBasic";
import SpecificUserAdvance from "../../../CommonFunction/GetClientAdvance";
import AddClientForm from "../../Sales/Clients-list/Form/Form";
import ClientStatusTrackerBasic from "../../../CommonFunction/clientStatusTrackerBasic";
import ClientBasicAdvance from "../../ClientBasic_Advance/ClientBasic_Advance";
import { getFormattedIndiaTime } from "../../../utils/timeutils";
import EventDialog from "../../Events/Events";
import FILEROUTES from "../../../API_Routes/File/File_Router";

export default function ClientList() {
  const dispatch = useDispatch();
  const UserId = sessionStorage.getItem("UserId");
  const CSOVIEWCLIENTLIST = useSelector(
    (state) => state?.CSO?.ClientBasicandAdvance[0]?.paginatedResults
  );
  const accessToken = sessionStorage.getItem("Token");
  const [open, setOpen] = useState(null);
  const handleCloseMenu = () => {
    setOpen(null);
  };
  // Table Conditions
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit, setLimit] = useState(5);
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };
  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const handleLimitChange = (event) => {
    setCurrentPage(1);
    setLimit(event.target.value);
  };
  // Table Conditions
  const GETCSO = async (page) => {
    const params = {
      page: page,
      limit: limit,
      scheme: "CB",
    };
    try {
      const res = await getData(
        CSOROUTES.GETCSOCLIENTBASICANDADVANCE,
        params,
        accessToken
      );
      if (res.status === 200) {
        dispatch(setClientBasicandAdvance(res.data));
        setTotalPages(res.data[0].totalPages);
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("An error occurred. Please try again later.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  const [selectedValues, setSelectedValues] = useState({});
  useEffect(() => {
    GETCSO(currentPage);
  }, [currentPage, limit, selectedValues]);

  //assign Reviewer
  const handleChange = async (event, data, StatusTracker) => {
    event.preventDefault();

    // Update selected values state
    const selectedValue = event.target.value;
    setSelectedValues((prevState) => ({
      ...prevState,
      [data]: selectedValue,
    }));

    // Prepare data for patch request
    const formattedDate = getFormattedIndiaTime();
    const newStatusTracking = {
      year: "1",
      status: "Reviewer Assigned",
      date: formattedDate,
    };
    const updatedClientStatusTracker = [...StatusTracker, newStatusTracking];
    const Params = {
      reviewerId: selectedValue,
      status: {
        type: "reviewer assigned",
        updatedDate: formattedDate,
      },
      clientStatusTracker: updatedClientStatusTracker,
    };

    // Make the patch request
    const endpoint = `${CLIENTROUTES.GETCLIENTADVANCE}${data}/basic`;
    patchData(endpoint, Params, accessToken, dispatch);
    const specificClient = CSOVIEWCLIENTLIST.find((item) => item._id === data);
    //Mail Send To Reviewer
    const ReviewerMailId = ReviewerList.find(
      (item) => item._id === selectedValue
    );
    const email = ReviewerMailId ? ReviewerMailId.email : null;
    const HTML_DATA = {
      ClientName: specificClient.companyName,
      ContactPerson: specificClient.contactPerson,
      ClientID: specificClient.clientId,
      AuditType: "INITIAL_ACCREDITATION_AND_ADMIN_COST",
      Message:
        "The above client’s CIFA & proposal are attached for the contract review.",
    };
    await postData(
      EMAIL_ROUTES.SEND_EMAIL_TEXT,
      {
        to: email,
        subject: "Reviewer Assigned",
        html: CommonEmail(HTML_DATA),
      },
      accessToken,
      dispatch
    );

    await postData(
      EVENT_TRACKING_ROUTES.CREATE_EVENT_HISTORY(data),
      {
        description: "Reviewer Assigned For Verifying Client Data",
        performedBy: `${sessionStorage.getItem(
          "firstName"
        )} ${sessionStorage.getItem("lastName")}`,
      },
      accessToken,
      dispatch
    );
    findUserById(selectedValue);
    setSelectedValues({});
  };

  //Get Reviewer List
  const [ReviewerList, setReviewerList] = useState();
  const GETReviewer = async () => {
    try {
      const res = await getData(
        REVIEWERROUTES.GETREVIEWERLIST,
        null,
        accessToken
      );
      if (res.status === 200) {
        setReviewerList(res.data);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  useEffect(() => {
    GETReviewer();
  }, []);

  // find assigned reviewer
  const findUserById = (id) => {
    if (ReviewerList && id) {
      const user = ReviewerList.find((user) => user._id === id);
      if (user) {
        return { firstName: user.firstName, lastName: user.lastName };
      }
    }
    return { firstName: "-", lastName: "" }; // Return empty values if no match found
  };
  const updateClientStatus = async (rowData) => {
    const formattedDate = getFormattedIndiaTime();
    const newStatusTracking = {
      year: 0,
      status: `FeedBack Send To Be Client`,
      date: formattedDate,
    };
    const updatedClientStatusTracker = [
      ...rowData.clientStatusTracker,
      newStatusTracking,
    ];
    const params = {
      clientStatusTracker: updatedClientStatusTracker,
    };
    await patchData(
      `clients/client/${rowData._id}/basic`,
      params,
      accessToken,
      dispatch
    );
  };

  const handleSendFeedbackForm = async (rowData) => {
    try {
      // ClientStatusTrackerBasic(
      //   rowData._id,
      //   "0",
      //   "FeedBack Send To Be Client",
      //   rowData.clientStatusTracker
      // );
      updateClientStatus(rowData);
      console.log("Client Data", rowData);
      const feedbackFormData = CB_FEEDBACK_FORM_DATA({
        clientName: rowData?.companyName,
        nameOfTheContact: rowData?.contactPerson,
        standardApplied: rowData?.standardsSchemeType,
      });
      const feedbackFormCreadtedResponse = await postData(
        FEEDBACK_FORM_ROUTES.CREATE_FEEDBACK_FORM("CB"),
        feedbackFormData,
        accessToken,
        dispatch
      );
      console.log("Feed back form data response", feedbackFormCreadtedResponse);

      const HTML_DATA = {
        clientName: rowData?.companyName,
        contactPerson: rowData?.contactPerson,
        clientId: rowData?.clientId,
        link: FEEDBACK_FORM_ROUTES.GET_FEEDBACK_FORM_CB_PAGE(
          feedbackFormCreadtedResponse?.data?.token
        ),
      };
      console.log("HTML_DATA", HTML_DATA);
      const emailRes = await postData(
        EMAIL_ROUTES.SEND_EMAIL_TEXT,
        {
          to: rowData?.email,
          subject: "Feedback form for your recent Audit at CAUSSA",
          html: FEEDBACK_EMAIL_HTML(HTML_DATA),
        },
        accessToken,
        dispatch
      );
      console.log("emailRes", emailRes);
    } catch (err) {
      console.error(err);
    }
  };

  //Filter
  const [filter, SetFilter] = useState(false);
  const ChangeFilter = () => {
    SetFilter(!filter);
  };
  useEffect(() => {
    if (!filter) {
      GETCSO(currentPage);
    }
  }, [filter]);

  // Checkbox
  const [isChecked, setIsChecked] = useState({});
  const [selectAllChecked, setSelectAllChecked] = useState(false);

  // Function to handle checkbox change
  const handleCheckboxChange = (event, id) => {
    setIsChecked({ ...isChecked, [id]: event.target.checked });
  };
  // Function to handle select all checkbox change
  const handleSelectAllCheckboxChange = (event) => {
    const { checked } = event.target;
    const updatedIsChecked = {};

    // Update isChecked object with all checkboxes' states
    CSOVIEWCLIENTLIST.forEach((row) => {
      updatedIsChecked[row._id] = checked;
    });

    setIsChecked(updatedIsChecked);
    setSelectAllChecked(checked);
  };

  function calculateDaysAgo(updatedDate) {
    // Parse the updatedDate string to a Date object
    const updatedDateObj = new Date(updatedDate);

    // Get the current date
    const currentDate = new Date();

    // Calculate the difference in milliseconds
    const differenceMs = currentDate - updatedDateObj;

    // Convert milliseconds to days
    const differenceDays = Math.floor(differenceMs / (1000 * 60 * 60 * 24));

    // Return the difference in days
    return `${differenceDays} Days ago`;
  }

  // Export data
  // Function to handle export action
  const handleExport = () => {
    const headers = [
      "Client Id",
      "Company Name",
      "Location",
      "Accredited / Unaccredited",
      "Scope",
      "Scheme",
      "Technical Main Code",
      "Technical Sub Code",
      "No. & name of site",
      "No. of MD",
      "Order value",
      "Contract Reviewer Name",
      "Contract Reviewer Assigned Date",
      "Contract Review Approval Status",
      "Report Review Audit ",
      "Report Review Assigned Name ",
      "Report Review Assigned Date",
      "Report Review Approval Status",
      "Certification Decision Status",
      "Certificate Issue To Client - Date",
      "Feedback Send Client",
      "Contact Person Details - Mail / Name & Mobile",
    ].filter(Boolean);
    // Filter checked items
    const checkedItems = CSOVIEWCLIENTLIST.filter(
      (item) => isChecked[item._id]
    );
    let data;
    data = checkedItems.map((item) => {
      const reviewerName = item.reviewerId
        ? `${findUserById(String(item.reviewerId)).firstName} ${
            findUserById(String(item.reviewerId)).lastName
          }`
        : "-";
      const clientStatusTracker = item.clientStatusTracker;
      let ReviewerAssignDate;
      if (clientStatusTracker && clientStatusTracker.length > 0) {
        for (const status of clientStatusTracker) {
          if (status.status === "Reviewer Assigned") {
            ReviewerAssignDate = status.date;
          }
        }
      }
      const reviewerStatuses =
        clientStatusTracker &&
        clientStatusTracker.length > 0 &&
        clientStatusTracker.filter((item) =>
          item.status.startsWith("Reviewer")
        );

      // Extract the last status entry based on date
      // const finalReviewerReport = reviewerStatuses.reduce((prev, current) => {
      //   return new Date(prev.date) > new Date(current.date) ? prev : current;
      // });
      let finalReviewerReport;
      if (Array.isArray(reviewerStatuses) && reviewerStatuses.length > 0) {
        finalReviewerReport = reviewerStatuses.reduce((prev, current) => {
          return new Date(prev.date) > new Date(current.date) ? prev : current;
        });
        console.log(finalReviewerReport);
      } else {
        console.log("No reviewer statuses found.");
      }

      const Accredited =
        item.ClientFormIMS_Data?.[0]?.auditCertificationActivitiesAndFees
          ?.auditTypeStates?.[0]?.name === "Initial Certification" ||
        item.ClientFormFSMS_Data?.[0]?.auditCertificationActivitiesAndFees
          ?.auditTypeStates?.[0]?.name === "Initial Certification"
          ? "Unaccredited"
          : "Accredited";
      const calculateOverallTotalManDays = (item) => {
        let totalManDays = 0;
        let itemScheme = {};

        if (item.ClientFormIMS_Data && item.ClientFormIMS_Data[0]) {
          itemScheme =
            item.ClientFormIMS_Data[0].auditCertificationActivitiesAndFees ||
            {};
        } else if (item.ClientFormFSMS_Data && item.ClientFormFSMS_Data[0]) {
          itemScheme =
            item.ClientFormFSMS_Data[0].auditCertificationActivitiesAndFees ||
            {};
        }

        // Calculate total man-days from surveillance audits
        if (itemScheme.surveillanceAudit) {
          itemScheme.surveillanceAudit.forEach((audit) => {
            if (audit.manDay && audit.manDay.totalManDays) {
              totalManDays += audit.manDay.totalManDays;
            }
          });
        }

        // Calculate total man-days from re-certifications
        if (itemScheme.re_Certification) {
          itemScheme.re_Certification.forEach((certification) => {
            if (certification.manDay && certification.manDay.totalManDays) {
              totalManDays += certification.manDay.totalManDays;
            }
          });
        }

        // Calculate total man-days from transfer certifications
        if (itemScheme.transferCertification) {
          itemScheme.transferCertification.forEach((certification) => {
            if (certification.totalManDays) {
              totalManDays += certification.totalManDays;
            }
          });
        }
        // Check for Initial Certification and add stage 1, stage 2, sa1, and sa2 man-days
        const hasInitialCertification =
          itemScheme.auditTypeStates &&
          itemScheme.auditTypeStates.some(
            (state) => state.name === "Initial Certification"
          );

        if (hasInitialCertification) {
          if (itemScheme.stage) {
            itemScheme.stage.forEach((stage) => {
              if (stage.manDay) {
                totalManDays +=
                  (stage.manDay.onSite || 0) + (stage.manDay.offSite || 0);
              }
            });
          }

          if (itemScheme.surveillanceAudit) {
            itemScheme.surveillanceAudit.forEach((audit) => {
              if (audit.manDay) {
                totalManDays +=
                  (audit.manDay.onSite || 0) + (audit.manDay.offSite || 0);
              }
            });
          }
        }

        return totalManDays;
      };

      const lastReview = item.reviewHistory[item.reviewHistory.length - 1];
      const lastCertificate =
        item.Certificates_Data.length !== 0
          ? item.Certificates_Data[item.Certificates_Data.length - 1]
          : "";
      const certificateDivisionStatus = lastCertificate
        ? lastCertificate.certificateDivisionStatus
        : "-";
      const certificateIssuingDate = lastCertificate
        ? lastCertificate.certificateIssuingDate
        : "-";
      const feedbackSent = item.clientStatusTracker.some(
        (item) => item.status === "FeedBack Send To Be Client"
      );
      const contactPerson =
        item.email + " / " + item.contactPerson + " & " + item.phoneNumber;
      return [
        item.clientId,
        item.companyName,
        item.address,
        Accredited,
        item.ClientFormIMS_Data?.[0]?.scopeOfCertification ||
          item.ClientFormFSMS_Data?.[0]?.scopeOfCertification ||
          "-",
        item.standardsSchemeType === "IMS"
          ? item.ClientFormIMS_Data[0]?.standards?.[0] || "-"
          : item.ClientFormFSMS_Data[0]?.standards?.[0] || "-",
        item.technicalCode.mainCode,
        item.technicalCode.subCode,
        item.noOfSites,
        calculateOverallTotalManDays(item),
        item.ClientFormIMS_Data?.[0]?.auditCertificationActivitiesAndFees
          ?.totalContractFee ||
          item.ClientFormFSMS_Data?.[0]?.auditCertificationActivitiesAndFees
            ?.totalContractFee ||
          "-",
        reviewerName,
        ReviewerAssignDate,
        finalReviewerReport ? finalReviewerReport.status : "-",
        lastReview ? lastReview.reviewType : "-",
        lastReview ? lastReview.reviewerName : "-",
        lastReview ? lastReview.reviewAssignedOn : "-",
        lastReview ? lastReview.reviewStatus : "-",
        certificateDivisionStatus,
        certificateIssuingDate,
        feedbackSent ? "sended" : "not sended",
        contactPerson,
      ];
    });

    const worksheet = XLSX.utils.aoa_to_sheet([headers, ...data]);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Client List Data");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    saveAs(
      new Blob([excelBuffer], { type: "application/octet-stream" }),
      "client_list_data.xlsx"
    );
  };

  const getLastReviewerAssignedDate = (clientStatusTracker) => {
    const reviewerAssignedStatuses = clientStatusTracker.filter(
      (item) => item.status === "Reviewer Assigned"
    );

    if (reviewerAssignedStatuses.length === 0) {
      return "-"; // No "Reviewer Assigned" status found
    }

    const latestReviewerAssigned = reviewerAssignedStatuses.reduce(
      (prev, current) =>
        new Date(prev.date) > new Date(current.date) ? prev : current,
      {}
    );

    // Parse the date and format it to "DD-MM-YYYY"
    const dateObject = new Date(latestReviewerAssigned.date);
    const formattedDate = `${dateObject.getDate()}-${
      dateObject.getMonth() + 1
    }-${dateObject.getFullYear()}`;

    return formattedDate;
  };
  const [viewClient, setViewClient] = useState(false);
  const handleViewClientdata = (data) => {
    SpecificUserBasic(data, dispatch);
    SpecificUserAdvance(data, dispatch);
    setViewClient(true);
  };
  const CloseclientView = () => {
    setViewClient(false);
  };
  const isAuthenticated = sessionStorage.getItem("isAuthenticated");

  //specific client id
  const [specificClientId, setSpecificClientId] = useState(null);
  //pass the specific client id data to view client
  const [specificClientData, setSpecificClientData] = useState(null);
  useEffect(() => {
    if (specificClientId !== null) {
      // Find the specific client data based on specificClientId
      const client = CSOVIEWCLIENTLIST.find(
        (client) => client._id === specificClientId
      );
      // If client is found, set it in the state
      if (client) {
        setSpecificClientData(client);
      }
    }
  }, [specificClientId]);
  const handleViewClient = () => {
    setSpecificClientData(null);
    setSpecificClientId(null);
    GETCSO(currentPage);
  };

  // Events
  const [Eventopen, setEventOpen] = useState(false);
  const [Events, setEvents] = useState();
  const handleEventClickOpen = async (id) => {
    const res = await getData(
      EVENT_TRACKING_ROUTES.CREATE_EVENT_HISTORY(id),
      null,
      accessToken
    );
    setEvents(res.data);
    setEventOpen(true);
  };
  const handleEventClose = () => {
    setEventOpen(false);
  };

  const [scopeextension, setScopeExtension] = useState("No");
  // scopeextension
  const [scopeextensionID, setScopeExtensionID] = useState();
  const [isScopeExtensionDialogOpen, setScopeExtensionDialogOpen] =
    useState(false);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [selectedScopeExtension, setSelectedScopeExtension] = useState("");
  const handleScopeExtensionChange = (event, id) => {
    setScopeExtensionID(id);
    const value = event.target.value;
    setSelectedScopeExtension(value);
    if (value === "Yes") {
      setScopeExtensionDialogOpen(true);
    }
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append("file", file);
    const uploadRes = await FileUpload(
      `${FILEROUTES.UPLOAD}${UserId}`,
      formData,
      dispatch
    );
    setUploadedFile(uploadRes.data);
  };

  const handleDialogClose = () => {
    setScopeExtensionDialogOpen(false);
  };

  const handleDialogConfirm = async () => {
    setScopeExtensionDialogOpen(false);
    // client basic
    const Params = {
      isScopeExtension: "true",
    };
    const endpoint = `${CLIENTROUTES.GETCLIENTADVANCE}${scopeextensionID}/basic`;
    await patchData(endpoint, Params, accessToken, dispatch);
    // client advance
    const body = {
      ScopeExtension_CIFA_FileObject: uploadedFile,
    };
    const ClientAdvancedUpdateRes = await patchData(
      CLIENT_ROUTES.UPDATE_CLIENT_ADVANCED(scopeextensionID),
      body,
      accessToken,
      dispatch
    );
    GETCSO(currentPage);
  };

  // const handleScopeExtensionChange = (event, id) => {
  //   const userConfirmed = window.confirm(
  //     "Are you sure you want to scope of Audit?"
  //   );
  //   if (userConfirmed) {
  //     // setScopeExtension(event.target.value);
  //     const Params = {
  //       isScopeExtension: event.target.value === "Yes" ? "true" : "false",
  //     };
  //     const endpoint = `${CLIENTROUTES.GETCLIENTADVANCE}${id}/basic`;
  //     patchData(endpoint, Params, accessToken, dispatch);
  //     GETCSO(currentPage);
  //   }
  // };

  return (
    <>
      <Dialog open={isScopeExtensionDialogOpen} onClose={handleDialogClose}>
        <DialogTitle>Scope Extension</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please upload the required file for scope extension.
          </DialogContentText>
          <TextField type="file" onChange={handleFileChange} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Cancel</Button>
          <Button onClick={handleDialogConfirm} disabled={!uploadedFile}>
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <EventDialog
        open={Eventopen}
        handleClose={handleEventClose}
        events={Events}
      />
      <ToastContainer />
      {specificClientData !== null && (
        <>
          <div className="d-flex justify-content-end">
            <Button
              variant="contained"
              className="btn-orange text-dark me-0 my-2"
              onClick={() => {
                setSpecificClientData(null);
                setSpecificClientId(null);
              }}
            >
              Close
            </Button>
          </div>
          <ClientBasicAdvance
            handleViewClient={handleViewClient}
            specificClientData={specificClientData}
          />
        </>
      )}
      {specificClientData === null && (
        <div className="cso-clientlist-border p-3">
          <div className="px-2 add-title">
            <span className="">Client List</span>
          </div>

          {!viewClient && (
            <div className="d-flex justify-content-end mb-3">
              <Button
                variant="contained"
                className="btn-orange text-dark me-0"
                onClick={handleExport}
              >
                Export
              </Button>
              <Button className="btn-green text-white" onClick={ChangeFilter}>
                {filter ? "Close Filter" : "Show Filter"}
              </Button>
            </div>
          )}

          {filter && <CSOFilter />}
          {viewClient ? (
            <>
              <div className="d-flex justify-content-end">
                <Button
                  onClick={CloseclientView}
                  variant="contained"
                  className="btn-orange"
                >
                  Close
                </Button>
              </div>
              <AddClientForm action="View" />
            </>
          ) : (
            <Card>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        className="cl-green text-dark"
                        style={{ padding: "5px" }}
                      >
                        <Checkbox
                          checked={selectAllChecked}
                          onChange={handleSelectAllCheckboxChange}
                        />
                      </TableCell>

                      <TableCell className="cl-green text-dark">
                        Client Id
                      </TableCell>
                      <TableCell className="cl-green text-dark">
                        Company Name
                      </TableCell>
                      {/* <TableCell className="cl-green text-dark">
                    Technical Main Code
                  </TableCell>
                  <TableCell className="cl-green text-dark">
                    Technical Sub Code
                  </TableCell> */}
                      <TableCell className="cl-green text-dark">
                        Scheme
                      </TableCell>
                      <TableCell className="cl-green text-dark">
                        Location
                      </TableCell>
                      {/* <TableCell className="cl-green text-dark">S1</TableCell>
                  <TableCell className="cl-green text-dark">S2</TableCell>
                  <TableCell className="cl-green text-dark">SA1</TableCell>
                  <TableCell className="cl-green text-dark">SA2</TableCell> */}

                      <TableCell className="cl-green text-dark" align="center">
                        Standard
                      </TableCell>
                      <TableCell
                        style={{ width: "30%" }}
                        className="cl-green text-dark"
                        align="center"
                      >
                        Status
                      </TableCell>
                      <TableCell className="cl-green text-dark" align="center">
                        Reviewer Name
                      </TableCell>
                      <TableCell
                        className="cl-green text-dark"
                        align="center"
                        style={{ width: "20%" }}
                      >
                        Reviewer Assigned Date
                      </TableCell>
                      <TableCell className="cl-green text-dark" align="center">
                        Reviewer List
                      </TableCell>
                      <TableCell className="cl-green text-dark" align="center">
                        FeedBack Form
                      </TableCell>
                      <TableCell className="cl-green text-dark" align="center">
                        Scope Extension
                      </TableCell>
                      <TableCell className="cl-green text-dark" align="center">
                        view
                      </TableCell>
                      <TableCell className="cl-green text-dark" align="center">
                        Events
                      </TableCell>
                      {isAuthenticated === "Admin" && (
                        <TableCell
                          className="cl-green text-dark"
                          align="center"
                        >
                          Review Client
                        </TableCell>
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {CSOVIEWCLIENTLIST &&
                      CSOVIEWCLIENTLIST.map((row) => {
                        const {
                          _id,
                          reviewerId,
                          companyName,
                          technicalCode,
                          standardsSchemeType,
                          state,
                          country,
                          clientStatusTracker,
                          ClientFormIMS_Data,
                          ClientFormFSMS_Data,
                          status,
                          clientId,
                          isScopeExtension,
                        } = row;
                        const lastReviewerAssignedDate =
                          getLastReviewerAssignedDate(clientStatusTracker);
                        return (
                          <TableRow key={_id}>
                            <TableCell padding="checkbox">
                              <Checkbox
                                checked={isChecked[_id] || false}
                                onChange={(event) =>
                                  handleCheckboxChange(event, _id)
                                }
                              />
                            </TableCell>
                            <TableCell>{clientId}</TableCell>
                            <TableCell>{companyName}</TableCell>
                            {/* <TableCell>{technicalCode.mainCode}</TableCell>
                        <TableCell>{technicalCode.subCode}</TableCell> */}
                            <TableCell>{standardsSchemeType}</TableCell>
                            <TableCell>
                              {state}/{country}
                            </TableCell>
                            {/* {ClientFormIMS_Data && ClientFormIMS_Data[0] ? (
                          <>
                            <TableCell>
                              {
                                ClientFormIMS_Data[0]
                                  ?.auditCertificationActivitiesAndFees
                                  ?.stage[0]?.manDay?.fee
                              }
                            </TableCell>
                            <TableCell>
                              {
                                ClientFormIMS_Data[0]
                                  ?.auditCertificationActivitiesAndFees
                                  ?.stage[1]?.manDay?.fee
                              }
                            </TableCell>
                            <TableCell>
                              {
                                ClientFormIMS_Data[0]
                                  ?.auditCertificationActivitiesAndFees
                                  ?.surveillanceAudit[0]?.manDay?.fee
                              }
                            </TableCell>
                            <TableCell>
                              {
                                ClientFormIMS_Data[0]
                                  ?.auditCertificationActivitiesAndFees
                                  ?.surveillanceAudit[1]?.manDay?.fee
                              }
                            </TableCell>
                          </>
                        ) : (
                          <>
                            <TableCell>
                              {
                                ClientFormFSMS_Data[0]
                                  ?.auditCertificationActivitiesAndFees
                                  ?.stage[0]?.manDay?.fee
                              }
                            </TableCell>
                            <TableCell>
                              {
                                ClientFormFSMS_Data[0]
                                  ?.auditCertificationActivitiesAndFees
                                  ?.stage[1]?.manDay?.fee
                              }
                            </TableCell>
                            <TableCell>
                              {
                                ClientFormFSMS_Data[0]
                                  ?.auditCertificationActivitiesAndFees
                                  ?.surveillanceAudit[0]?.manDay?.fee
                              }
                            </TableCell>
                            <TableCell>
                              {
                                ClientFormFSMS_Data[0]
                                  ?.auditCertificationActivitiesAndFees
                                  ?.surveillanceAudit[1]?.manDay?.fee
                              }
                            </TableCell>
                          </>
                        )} */}

                            <TableCell>
                              {ClientFormIMS_Data &&
                              ClientFormIMS_Data[0] &&
                              ClientFormIMS_Data[0].standards &&
                              ClientFormIMS_Data[0].standards.length > 0
                                ? ClientFormIMS_Data[0].standards[0]
                                : ClientFormFSMS_Data &&
                                  ClientFormFSMS_Data[0] &&
                                  ClientFormFSMS_Data[0].standards &&
                                  ClientFormFSMS_Data[0].standards.length > 0
                                ? ClientFormFSMS_Data[0].standards[0]
                                : null}
                            </TableCell>

                            <TableCell align="center">
                              {typeof status === "object"
                                ? status.type
                                : status}
                            </TableCell>
                            <TableCell align="center">
                              {status !== "pending" ? (
                                <>
                                  {findUserById(String(reviewerId)).firstName}{" "}
                                  {findUserById(String(reviewerId)).lastName}
                                </>
                              ) : (
                                "-"
                              )}
                            </TableCell>
                            <TableCell align="center">
                              {/* {status === "reviewer assigned" ||
                          status.type === "reviewer assigned" ? (
                            status.updatedDate ? (
                              <span style={{ color: "red" }}>
                                {calculateDaysAgo(status.updatedDate)}
                              </span>
                            ) : null
                          ) : (
                            "-"
                          )} */}
                              {lastReviewerAssignedDate}
                            </TableCell>
                            {/* <TableCell align="center">
                            {
                               ClientFormFSMS_Data[0]?.CIFA_FileObjects ||
                               ClientFormIMS_Data[0]?.CIFA_FileObjects ? (
                                status === "client details filled" ||
                            status.type === "client details filled" ||
                            status === "reviewer assigned" ||
                            status.type === "reviewer assigned" ? (
                              <FormControl fullWidth size="small">
                                <InputLabel id={_id}>Assign</InputLabel>
                                <Select
                                  labelId={_id}
                                  id={`demo-simple-select-${_id}`}
                                  value={selectedValues[_id] || ""}
                                  label="Reviewer List"
                                  onChange={(e) =>
                                    handleChange(e, _id, clientStatusTracker)
                                  }
                                  MenuProps={{
                                    PaperProps: {
                                      style: { maxHeight: "200px" },
                                    },
                                  }}
                                >
                                  {ReviewerList &&
                                    ReviewerList.map((item) => (
                                      <MenuItem key={item._id} value={item._id}>
                                        {item.firstName} {item.lastName}
                                      </MenuItem>
                                    ))}
                                </Select>
                              </FormControl>
                               ) : ("")
                            }
                           
                          </TableCell> */}
                            <TableCell align="center">
                              {(ClientFormFSMS_Data?.[0]?.CIFA_FileObjects ||
                                ClientFormIMS_Data?.[0]?.CIFA_FileObjects) &&
                              (status === "client details filled" ||
                                status?.type === "client details filled" ||
                                status === "reviewer assigned" ||
                                status?.type === "reviewer assigned") ? (
                                <FormControl fullWidth size="small">
                                  <InputLabel id={`select-label-${_id}`}>
                                    Assign
                                  </InputLabel>
                                  <Select
                                    labelId={`select-label-${_id}`}
                                    id={`demo-simple-select-${_id}`}
                                    value={selectedValues[_id] || ""}
                                    label="Reviewer List"
                                    onChange={(e) =>
                                      handleChange(e, _id, clientStatusTracker)
                                    }
                                    MenuProps={{
                                      PaperProps: {
                                        style: { maxHeight: "200px" },
                                      },
                                    }}
                                  >
                                    {ReviewerList?.map((item) => (
                                      <MenuItem key={item._id} value={item._id}>
                                        {item.firstName} {item.lastName}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              ) : (
                                ""
                              )}
                            </TableCell>

                            <TableCell align="center">
                              <Button
                                className="text-dark"
                                variant="outlined"
                                size="small"
                                color="success"
                                endIcon={<SendIcon />}
                                onClick={() => handleSendFeedbackForm(row)}
                              >
                                {" "}
                                Send{" "}
                              </Button>
                            </TableCell>
                            <TableCell align="center">
                              <Select
                                value={
                                  isScopeExtension ? "Yes" : scopeextension
                                }
                                sx={{ minWidth: 80, fontSize: 14 }}
                                onChange={(e) =>
                                  handleScopeExtensionChange(e, _id)
                                }
                                disabled={isScopeExtension}
                              >
                                <MenuItem value="Yes">Yes</MenuItem>
                                <MenuItem value="No">No</MenuItem>
                              </Select>
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                handleViewClientdata(_id);
                              }}
                            >
                              <Iconify icon={"raphael:view"} sx={{ mr: 2 }} />
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                handleEventClickOpen(_id);
                              }}
                            >
                              <Iconify icon={"raphael:view"} sx={{ mr: 2 }} />
                            </TableCell>
                            {isAuthenticated === "Admin" &&
                              ((ClientFormFSMS_Data?.[0]?.CIFA_FileObjects ||
                                ClientFormIMS_Data?.[0]?.CIFA_FileObjects) &&
                              (status === "client details filled" ||
                                status?.type === "client details filled" ||
                                status === "reviewer assigned" ||
                                status?.type === "reviewer assigned" ||
                                status === "revision resolved" ||
                                status?.type === "revision resolved") ? (
                                <TableCell
                                  align="center"
                                  onClick={() => setSpecificClientId(_id)}
                                >
                                  <Iconify
                                    icon={"raphael:view"}
                                    sx={{ mr: 2 }}
                                  />
                                </TableCell>
                              ) : (
                                <TableCell align="center">-</TableCell>
                              ))}
                          </TableRow>
                        );
                      })}
                    {CSOVIEWCLIENTLIST &&
                      CSOVIEWCLIENTLIST.length === 0 &&
                      "No Data"}
                  </TableBody>
                </Table>
              </TableContainer>
              <div className="d-flex flex-wrap justify-content-end m-3">
                <FormControl variant="outlined" size="small">
                  <InputLabel id="limit-label">Limit</InputLabel>
                  <Select
                    labelId="limit-label"
                    id="limit-select"
                    value={limit}
                    onChange={handleLimitChange}
                  >
                    <MenuItem value={5}>5</MenuItem>
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={15}>15</MenuItem>
                  </Select>
                </FormControl>
                <Button onClick={handlePrevPage} disabled={currentPage === 1}>
                  <Iconify icon={"grommet-icons:previous"} />
                </Button>
                <Button
                  onClick={handleNextPage}
                  disabled={currentPage === totalPages}
                >
                  <Iconify icon={"grommet-icons:next"} />
                </Button>
              </div>
            </Card>
          )}

          <Popover
            open={Boolean(open)}
            anchorEl={open}
            onClose={handleCloseMenu}
            anchorOrigin={{ vertical: "top", horizontal: "left" }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            PaperProps={{
              sx: {
                p: 1,
                width: 140,
                "& .MuiMenuItem-root": {
                  px: 1,
                  typography: "body2",
                  borderRadius: 0.75,
                },
              },
            }}
          >
            <MenuItem>
              <Iconify icon={"eva:edit-fill"} sx={{ mr: 2 }} />
              Edit
            </MenuItem>
            <MenuItem>
              <Iconify icon={"raphael:view"} sx={{ mr: 2 }} />
              View
            </MenuItem>

            <MenuItem sx={{ color: "error.main" }}>
              <Iconify icon={"eva:trash-2-outline"} sx={{ mr: 2 }} />
              Delete
            </MenuItem>
          </Popover>
        </div>
      )}
    </>
  );
}
