import {
  Button,
  CircularProgress,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  List,
  ListItem,
  ListItemText,
  Link,
} from "@mui/material";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { patchData, postData } from "src/webService/webService";
import CERTIFICATEROUTES from "src/API_Routes/Certificate/Certificate_Routers";
import { useDispatch, useSelector } from "react-redux";
import { setSpecificCertificate } from "src/redux/slices/CertificateSlice";
import FILEROUTES from "src/API_Routes/File/File_Router";
import SERVER_URL from "src/utils/serverURL";
import { CircularLoaderPopup } from "src/utils/popup/loader";
import { getData, FileUpload } from "src/webService/webService";
import EVENT_TRACKING_ROUTES from "../../API_Routes/EventTracking/EventTracking_Routers";
import CSOToClientMail from "../../Config/templates/html/CSOToClientMail";
import EMAIL_ROUTES from "../../API_Routes/Email/Email_Routers";
import { getFormattedIndiaTime } from "../../utils/timeutils";
import AUDITROUTES from "../../API_Routes/Audit/Audit_routes";

export default function CertificateForm({
  HandlAaddCertificate,
  auditData,
  Role,
  action,
  GetSale,
  currentPage,
}) {
  console.log(action, "action");
  console.log(auditData, "auditData1234");
  const scheme = sessionStorage.getItem("scheme");
  const UserId = sessionStorage.getItem("UserId");

  const SPECIFIC_CERTIFICATE_LIST = useSelector(
    (state) => state.certificate.SpecificCertificate
  );
  const CERTIFICATE_ID = useSelector(
    (state) => state.certificate.SpecificCertificate._id
  );
  const formattedDate = getFormattedIndiaTime();

  const [certificateNumber, setCertificateNumber] = useState();
  const [accreditationBodies, setAccreditationBodies] = useState();
  const [managingOffice, setManagingOffice] = useState();
  const [contractedCompany, setContractedCompany] = useState();
  const [sites, setSites] = useState();
  const [sitesArray, setSitesArray] = useState([]);
  const [standards, setStandards] = useState();
  const [standardsArray, setStandardsArray] = useState([]);
  const [status, setStatus] = useState();
  const [certificateDivisionStatus, setcertificateDivisionStatus] = useState();
  const [certificateIssuingDate, setCertificateIssuingDate] = useState("");
  const [certificateExpiryDate, setCertificateExpiryDate] = useState("");
  const [uploadedFile, setUploadedFile] = useState(null);
  const [email, setEmail] = useState();
  const [clientList, setClientList] = useState();
  const [selectedClient, setSelectedClient] = useState(null);
  const accessToken = sessionStorage.getItem("Token");
  const [clientBasicid, setClientBasicid] = useState();
  const [CertificateDocumentFileObject, setCertificateDocumentFileObject] =
    useState([]);
  const [clientName, setClientName] = useState();
  const [clientIdNew, setClientIdNew] = useState();
  const [getallClient, setGetAllClient] = useState();
  const [loading, setLoading] = useState(null);
  const [buttonCount, setButtonCount] = useState(1);

  const handleAddButton = () => {
    setButtonCount((prevCount) => prevCount + 1);
  };

  const dispatch = useDispatch();

  console.log("CERTICATE_ID".CERTIFICATE_ID);

  useEffect(() => {
    console.log("auditData", auditData);
    const getTodayDate = () => {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, "0");
      const day = String(today.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    };
    setCertificateIssuingDate(getTodayDate());
    setCertificateExpiryDate(getTodayDate());
  }, []);
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  useEffect(() => {
    if (SPECIFIC_CERTIFICATE_LIST && action === "View") {
      console.log("SPECIFIC_CERTIFICATE_LIST", SPECIFIC_CERTIFICATE_LIST);
      setCertificateNumber(SPECIFIC_CERTIFICATE_LIST?.certificateNumber);
      setAccreditationBodies(SPECIFIC_CERTIFICATE_LIST.accreditationBodies);
      setManagingOffice(SPECIFIC_CERTIFICATE_LIST.managingOffice);
      setContractedCompany(SPECIFIC_CERTIFICATE_LIST.contractedCompany);
      setSitesArray(SPECIFIC_CERTIFICATE_LIST.sites);
      setStandardsArray(SPECIFIC_CERTIFICATE_LIST.standards);
      setStatus(SPECIFIC_CERTIFICATE_LIST.status);
      setcertificateDivisionStatus(
        SPECIFIC_CERTIFICATE_LIST.certificateDivisionStatus
      );
      setCertificateIssuingDate(
        formatDate(SPECIFIC_CERTIFICATE_LIST.certificateIssuingDate)
      );
      setCertificateExpiryDate(
        formatDate(SPECIFIC_CERTIFICATE_LIST.certificateExpiryDate)
      );
      setCertificateDocumentFileObject(
        SPECIFIC_CERTIFICATE_LIST.certificateFileObjects
      );
      setEmail(SPECIFIC_CERTIFICATE_LIST.email);
      setClientName(SPECIFIC_CERTIFICATE_LIST.clientId);
      setClientIdNew(SPECIFIC_CERTIFICATE_LIST.clientId);
      setSelectedClient(SPECIFIC_CERTIFICATE_LIST.clientBasicId);
    }

    if (SPECIFIC_CERTIFICATE_LIST && action === "Edit") {
      console.log("SPECIFIC_CERTIFICATE_LIST", SPECIFIC_CERTIFICATE_LIST);
      setCertificateNumber(SPECIFIC_CERTIFICATE_LIST?.certificateNumber);
      setAccreditationBodies(SPECIFIC_CERTIFICATE_LIST.accreditationBodies);
      setManagingOffice(SPECIFIC_CERTIFICATE_LIST.managingOffice);
      setContractedCompany(SPECIFIC_CERTIFICATE_LIST.contractedCompany);
      setSitesArray(SPECIFIC_CERTIFICATE_LIST.sites);
      setStandardsArray(SPECIFIC_CERTIFICATE_LIST.standards);
      setStatus(SPECIFIC_CERTIFICATE_LIST.status);
      setcertificateDivisionStatus(
        SPECIFIC_CERTIFICATE_LIST.certificateDivisionStatus
      );
      setCertificateIssuingDate(
        formatDate(SPECIFIC_CERTIFICATE_LIST.certificateIssuingDate)
      );
      setCertificateExpiryDate(
        formatDate(SPECIFIC_CERTIFICATE_LIST.certificateExpiryDate)
      );
      setCertificateDocumentFileObject([
        ...SPECIFIC_CERTIFICATE_LIST.certificateFileObjects,
      ]);
      setEmail(SPECIFIC_CERTIFICATE_LIST.email);
      setClientName(SPECIFIC_CERTIFICATE_LIST.clientId);
      setClientIdNew(SPECIFIC_CERTIFICATE_LIST.clientId);
      setSelectedClient(SPECIFIC_CERTIFICATE_LIST.clientBasicId);
    }
  }, [SPECIFIC_CERTIFICATE_LIST]);

  const removeFile = (file) => {
    const updatedUploadedFiles = CertificateDocumentFileObject.filter(
      (uploadedFile) => uploadedFile.filename !== file.filename
    );
    setCertificateDocumentFileObject(updatedUploadedFiles);
  };

  useEffect(() => {
    console.log("CertificateDocumentFileObject", CertificateDocumentFileObject);
    setTimeout(() => setLoading("false"), 1000);
  }, [CertificateDocumentFileObject]);

  const handleFileInputChange = async (event) => {
    const file = event?.target?.files[0];
    const formData = new FormData();
    formData.append("file", file);
    try {
      setLoading(true);
      const response = await FileUpload(
        `${FILEROUTES.UPLOAD}${UserId}`,
        formData,
        dispatch
      );
      console.log("Document upload successful:", response);

      CertificateDocumentFileObject?.push(response?.data);
      const copyCertificateDocumentFileObject = [
        ...CertificateDocumentFileObject,
      ];
      setCertificateDocumentFileObject(copyCertificateDocumentFileObject);
    } catch (error) {
      console.error("Document upload error:", error);
      toast.error("Document upload error", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const addUser = () => {
    const params = {
      // certificateNumber: certificateNumber,
      accreditationBodies: auditData?.ClientFormIMS_Data[0]?.accreditations[0]
        ? auditData?.ClientFormIMS_Data[0]?.accreditations[0]
        : auditData?.ClientFormFSMS_Data[0]?.accreditations[0],
      managingOffice: auditData?.ClientFormIMS_Data[0]?.HO_or_CentralOffice
        ? auditData?.ClientFormIMS_Data[0]?.HO_or_CentralOffice
        : auditData?.ClientFormFSMS_Data[0]?.HO_or_CentralOffice,
      contractedCompany: auditData?.ClientBasic_Data[0]?.companyName,
      sites: auditData?.ClientBasic_Data[0]?.noOfSites,
      standards: auditData?.ClientFormIMS_Data[0]?.standards
        ? auditData?.ClientFormIMS_Data[0]?.standards
        : auditData?.ClientFormFSMS_Data[0]?.standards,
      // status: status,
      certificateDivisionStatus: certificateDivisionStatus,
      certificateIssuingDate: certificateIssuingDate,
      certificateExpiryDate: certificateExpiryDate,
      certificateFileObjects: CertificateDocumentFileObject,
      clientId: auditData?.ClientBasic_Data[0]?.clientId,
      auditId: auditData?._id,
      clientBasicId: auditData?.ClientBasic_Data[0]?._id,
      email: auditData?.ClientBasic_Data[0]?.email,
    };
    console.log("params", params);
    postData(CERTIFICATEROUTES.ADDCERTIFICATE, params, accessToken, dispatch)
      .then((res) => {
        console.log("api res", res);
        HandlAaddCertificate();
        //Audit Flow updates
        const params = {
          reportStatus: {
            name: "Activity Completed",
            date: formattedDate,
          },
          activityStep: "10",
        };
        const endpoint = patchData(
          `${AUDITROUTES.SCHEDULEPATCH(auditData?._id)}`,
          params,
          accessToken,
          dispatch
        );
        // Event
        postData(
          EVENT_TRACKING_ROUTES.CREATE_EVENT_HISTORY(
            auditData?.ClientBasic_Data[0]?._id
          ),
          {
            description: `${auditData.auditType} Certificate has been Added`,
            performedBy: `${sessionStorage.getItem(
              "firstName"
            )} ${sessionStorage.getItem("lastName")}`,
          },
          accessToken,
          dispatch
        );
        GetSale(currentPage);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const edituser = () => {
    const params = {
      certificateNumber: certificateNumber,
      accreditationBodies: accreditationBodies,
      managingOffice: managingOffice,
      contractedCompany: contractedCompany,
      sites: sitesArray,
      standards: standardsArray,
      // status: status,
      certificateDivisionStatus: certificateDivisionStatus,
      certificateIssuingDate: certificateIssuingDate,
      certificateExpiryDate: certificateExpiryDate,
      certificateFileObjects: CertificateDocumentFileObject,
      clientId: clientIdNew,
      clientBasicId: selectedClient,
      email: email,
    };
    patchData(`certificate/${CERTIFICATE_ID}`, params, accessToken,dispatch)
      .then((res) => {
        console.log("id", CERTIFICATE_ID);
        // Event
        postData(
          EVENT_TRACKING_ROUTES.CREATE_EVENT_HISTORY(selectedClient),
          {
            description: `Certificate Number: ${certificateNumber} has been Edited`,
            performedBy: `${sessionStorage.getItem(
              "firstName"
            )} ${sessionStorage.getItem("lastName")}`,
          },
          accessToken,
          dispatch
        );
        GetSale(currentPage);
        HandlAaddCertificate();
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  console.log(clientBasicid);

  const getclient = async () => {
    try {
      let res;
      if (scheme === "CB") {
        const getapi = await getData(
          `clients/client/basic/advanced/all?scheme=CB&page=1&limit=5`,
          null,
          accessToken
        );
        const totalcount = getapi.data[0].totalCount.totalItems;
        res = await getData(
          `clients/client/basic/advanced/all?scheme=CB&page=1&limit=${totalcount}`,
          null,
          accessToken
        );
        setGetAllClient(res.data[0].paginatedResults);
      } else {
        res = await getData(
          `masters/audit/client/companynames?status=approved&scheme=HRA`,
          null,
          accessToken
        );
      }

      if (res.status === 200) {
        if (scheme === "CB") {
          setClientList(res.data[0].paginatedResults);
        } else {
          setClientList(res.data);
        }
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("An error occurred. Please try again later.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  // useEffect(() => {
  //   getclient();
  //   console.log("action", action);
  // }, []);

  const RemoveSpecificCertificate = () => {
    dispatch(setSpecificCertificate(""));
  };

  const sendMailClient = async () => {
    let attachments = [];
    CertificateDocumentFileObject.forEach((item) => {
      attachments.push({
        filename: item.filename,
        path: item.path,
      });
    });
    await postData(
      EMAIL_ROUTES.SEND_EMAIL_FILE,
      {
        to: SPECIFIC_CERTIFICATE_LIST.email,
        subject: "Certificate for Audit",
        html: CSOToClientMail,
        attachments: attachments,
      },
      accessToken,
      dispatch
    );
    HandlAaddCertificate();
    GetSale(currentPage);
  };

  const isAuthenticated = sessionStorage.getItem("isAuthenticated");

  return (
    <>
      <ToastContainer />
      <CircularLoaderPopup loading={loading} />
      <div className="add-border-outline p-5 mt-5">
        <div className="px-2 add-title">
          <span className="">{`${action} Certificate`}</span>
        </div>

        <div className="d-flex flex-wrap justify-content-start cerification-model">
          <div className="col-12 px-2 py-3 position-relative">
            <label htmlFor="auditType">
              Audit Type: {auditData?.auditType}
            </label>
          </div>
          <div className="col-4 px-2 py-3">
            {clientIdNew ? (
              <>
                <p>
                  <span className="fw-bold">Client Id :</span> {clientIdNew}
                </p>
              </>
            ) : (
              <FormControl className="w-100">
                <label htmlFor="ClientID<">Client ID</label>
                <input
                  type="text"
                  className="w-100"
                  name="clientId<"
                  id="clientId"
                  value={auditData?.ClientBasic_Data[0]?.clientId}
                  size="large"
                  disabled={
                    action === "View" || action === "Edit" ? true : false
                  }
                  required
                />
              </FormControl>
            )}
          </div>
          {action === "View" || action === "Edit" ? (
            <div className="col-4 px-2 py-3">
              <label htmlFor="accreditationBodies">Accreditation Bodies</label>
              <input
                type="text"
                className="w-100"
                name="accreditationBodies"
                id="accreditationBodies"
                value={accreditationBodies}
                size="small"
                disabled={action === "View" || action === "Edit" ? true : false}
                onChange={(e) => setAccreditationBodies(e.target.value)}
                required
              />
            </div>
          ) : (
            <div className="col-4 px-2 py-3">
              <label htmlFor="accreditationBodies">Accreditation Bodies</label>
              <input
                type="text"
                className="w-100"
                name="accreditationBodies"
                id="accreditationBodies"
                value={
                  auditData?.ClientFormIMS_Data[0]?.accreditations[0]
                    ? auditData?.ClientFormIMS_Data[0]?.accreditations[0]
                    : auditData?.ClientFormFSMS_Data[0]?.accreditations[0]
                }
                size="small"
                disabled={action === "View" || action === "Edit" ? true : false}
                required
              />
            </div>
          )}

          {action === "View" || action === "Edit" ? (
            <div className="col-4 px-2 py-3">
              <label htmlFor="managingOffice">Managing Office</label>
              <input
                type="text"
                className="w-100"
                name="managingOffice"
                id="managingOffice"
                value={managingOffice}
                size="small"
                disabled={action === "View" || action === "Edit" ? true : false}
                onChange={(e) => setManagingOffice(e.target.value)}
                required
              />
            </div>
          ) : (
            <div className="col-4 px-2 py-3">
              <label htmlFor="managingOffice">Managing Office</label>
              <input
                type="text"
                className="w-100"
                name="managingOffice"
                id="managingOffice"
                value={
                  auditData?.ClientFormIMS_Data[0]?.HO_or_CentralOffice
                    ? auditData?.ClientFormIMS_Data[0]?.HO_or_CentralOffice
                    : auditData?.ClientFormFSMS_Data[0]?.HO_or_CentralOffice
                }
                size="small"
                disabled={action === "View" || action === "Edit" ? true : false}
                onChange={(e) =>
                  setManagingOffice(
                    auditData?.ClientFormIMS_Data[0]?.HO_or_CentralOffice
                      ? auditData?.ClientFormIMS_Data[0]?.HO_or_CentralOffice
                      : auditData?.ClientFormFSMS_Data[0]?.HO_or_CentralOffice
                  )
                }
                required
              />
            </div>
          )}

          {action === "View" || action === "Edit" ? (
            <div className="col-4 px-2 py-3">
              <label htmlFor="contractedCompany">Contracted Company</label>
              <input
                type="text"
                className="w-100"
                name="contractedCompany"
                id="contractedCompany"
                value={contractedCompany}
                size="small"
                disabled={action === "View" || action === "Edit" ? true : false}
                onChange={(e) => setContractedCompany(e.target.value)}
                required
              />
            </div>
          ) : (
            <div className="col-4 px-2 py-3">
              <label htmlFor="contractedCompany">Contracted Company</label>
              <input
                type="text"
                className="w-100"
                name="contractedCompany"
                id="contractedCompany"
                value={auditData?.ClientBasic_Data[0]?.companyName}
                size="small"
                disabled={action === "View" || action === "Edit" ? true : false}
                onChange={(e) =>
                  setContractedCompany(
                    auditData?.ClientBasic_Data[0]?.companyName
                  )
                }
                required
              />
            </div>
          )}

          {action === "View" || action === "Edit" ? (
            <div className="col-4 px-2 py-3 position-relative">
              <label htmlFor="sites">Sites</label>
              <input
                type="text"
                className="w-100"
                name="sites"
                id="sites"
                value={sitesArray}
                disabled={true}
                size="small"
                onChange={(e) => setSites(e.target.value)}
                required
              />
              {/* {sitesArray} */}
            </div>
          ) : (
            <div className="col-4 px-2 py-3 position-relative">
              <label htmlFor="sites">No of Sites</label>
              <input
                type="text"
                className="w-100"
                name="sites"
                id="sites"
                value={auditData?.ClientBasic_Data[0]?.noOfSites}
                disabled={true}
                size="small"
                onChange={(e) =>
                  setSites(auditData?.ClientBasic_Data[0]?.noOfSites)
                }
                required
              />
              {/* {sitesArray} */}
            </div>
          )}

          {action === "View" || action === "Edit" ? (
            <div className="col-4 px-2 py-3">
              <label htmlFor="email">Email</label>
              <input
                type="text"
                className="w-100"
                name="email"
                id="email"
                value={email}
                size="small"
                disabled={action !== "Add"}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
          ) : (
            <div className="col-4 px-2 py-3">
              <label htmlFor="email">Email</label>
              <input
                type="text"
                className="w-100"
                name="email"
                id="email"
                value={auditData?.ClientBasic_Data[0]?.email}
                size="small"
                disabled={action !== "Add"}
                onChange={(e) =>
                  setEmail(auditData?.ClientBasic_Data[0]?.email)
                }
                required
              />
            </div>
          )}
          {action === "View" || action === "Edit" ? (
            <div className="col-4 px-2 py-3 position-relative">
              <label htmlFor="standards">Standards</label>
              <input
                type="text"
                className="w-100"
                name="standards"
                id="standards"
                value={standardsArray}
                size="small"
                disabled={true}
                onChange={(e) => setStandards(e.target.value)}
                required
              />
            </div>
          ) : (
            <div className="col-4 px-2 py-3 position-relative">
              <label htmlFor="standards">Standards</label>
              <input
                type="text"
                className="w-100"
                name="standards"
                id="standards"
                value={
                  auditData?.ClientFormIMS_Data[0]?.standards
                    ? auditData?.ClientFormIMS_Data[0]?.standards
                    : auditData?.ClientFormFSMS_Data[0]?.standards
                }
                size="small"
                disabled={true}
                onChange={(e) =>
                  setStandards(
                    auditData?.ClientFormIMS_Data[0]?.standards
                      ? auditData?.ClientFormIMS_Data[0]?.standards
                      : auditData?.ClientFormFSMS_Data[0]?.standards
                  )
                }
                required
              />
            </div>
          )}
          {/* <div className="col-4 px-2 py-3">
            {action === "View" ? (
              <>
                <p className="fw-bold">Certificate Decision Status </p>
                <p>{certificateDivisionStatus}</p>
              </>
            ) : (
              <>
                <FormControl fullWidth>
                  <InputLabel id="certificateDivisionStatusLabel">
                    Certificate Decision Status
                  </InputLabel>
                  <Select
                    labelId="certificateDivisionStatusLabel"
                    id="certificateDivisionStatus"
                    value={certificateDivisionStatus}
                    disabled={action === "View"}
                    onChange={(e) =>
                      setcertificateDivisionStatus(e.target.value)
                    }
                    required
                  >
                    <MenuItem value="Approved">Approved</MenuItem>
                    <MenuItem value="Inprogress">In Progress</MenuItem>
                    <MenuItem value="Rejected">Rejected</MenuItem>
                  </Select>
                </FormControl>
              </>
            )}
          </div> */}

          <div className="col-4 px-2 py-3">
            <label htmlFor="certificateIssuingDate">
              Certificate Issuing Date
            </label>
            <input
              type="date"
              className="w-100"
              name="certificateIssuingDate"
              id="certificateIssuingDate"
              value={certificateIssuingDate}
              size="small"
              disabled={action === "View" ? true : false}
              onChange={(e) => setCertificateIssuingDate(e.target.value)}
              required
            />
          </div>
          <div className="col-4 px-2 py-3">
            <label htmlFor="certificateExpiryDate">
              Certificate Expiry Date
            </label>
            <input
              type="date"
              className="w-100"
              name="certificateExpiryDate"
              id="certificateExpiryDate"
              value={certificateExpiryDate}
              size="small"
              disabled={action === "View" ? true : false}
              onChange={(e) => setCertificateExpiryDate(e.target.value)}
              required
            />
          </div>

          {action !== "View" && (
            <div style={{ padding: 10 + "px" }}>
              <InputLabel>Documents</InputLabel>
              {/* Plus Icon Button */}
              {/* <div style={{ padding: 10 + "px" }}>
                <Button
                  variant="text"
                  startIcon={<AddOutlinedIcon />}
                  onClick={handleAddButton}
                >
                  Add More Documents
                </Button>
              </div> */}
              {/* <br clear="left"></br> */}
              {/* Render multiple buttons based on buttonCount state */}

              {[...Array(buttonCount)].map((_, index) => (
                <React.Fragment key={index}>
                  <div style={{ padding: 10 + "px" }}>
                    <Button
                      className="text-dark"
                      variant="outlined"
                      size="large"
                      color="success"
                      component="label"
                      startIcon={<FileUploadOutlinedIcon />}
                    >
                      {/* Hidden input field for file upload */}
                      <input
                        type="file"
                        style={{ display: "none" }} // Hide the input visually
                        onChange={handleFileInputChange}
                      />
                      Upload Document
                    </Button>
                    {index !== buttonCount - 1 && <br />}{" "}
                    {/* Add <br /> unless it's the last button */}
                  </div>
                </React.Fragment>
              ))}
            </div>
          )}

          {CertificateDocumentFileObject ? (
            <div style={{ padding: 10 + "px" }}>
              <List>
                {CertificateDocumentFileObject?.map((fileObject, index) => (
                  <ListItem key={index}>
                    {fileObject?.uri && (
                      <ListItemText>
                        Document {index + 1} :
                        <Link
                          href={`${SERVER_URL}${fileObject?.uri}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {fileObject?.filename || `File ${index + 1}`}
                        </Link>
                        {action !== "View" && (
                          <Button
                            color="error"
                            onClick={() => removeFile(fileObject)}
                          >
                            <DeleteIcon />
                          </Button>
                        )}
                      </ListItemText>
                    )}
                  </ListItem>
                ))}
              </List>
            </div>
          ) : (
            <div></div>
          )}
        </div>

        {action !== "View" ? (
          <div className="d-flex flex-wrap justify-content-end">
            <Button
              variant="contained"
              className="btn-orange"
              onClick={() => {
                HandlAaddCertificate();
                RemoveSpecificCertificate();
              }}
            >
              Cancel
            </Button>
            {action === "Add" && (
              <Button variant="contained" onClick={addUser}>
                Submit
              </Button>
            )}

            {action === "Edit" && (
              <Button variant="contained" onClick={edituser}>
                Update
              </Button>
            )}

            {/* {action === "Edit" && (
              <Button
                variant="contained"
                className="btn-green"
                onClick={() => {
                  sendMailClient();
                }}
              >
                Send Mail to Client
              </Button>
            )} */}
          </div>
        ) : (
          <div className="d-flex justify-content-end">
            <Button
              variant="contained"
              className="btn-orange"
              onClick={() => {
                HandlAaddCertificate();
                RemoveSpecificCertificate();
              }}
            >
              Close
            </Button>
          </div>
        )}
      </div>
    </>
  );
}
