import { createSlice } from "@reduxjs/toolkit";

const SalesNameList = createSlice({
  name: "SalesNameList",
  initialState: {
    SalesNameList: "",
  },
  reducers: {
    setSalesNameList: (state, action) => {
      state.SalesNameList = action.payload;
    },
  },
});

export const { setSalesNameList } = SalesNameList.actions;
export default SalesNameList.reducer;
