const NEW_ROLE = (data) => {
  return `
    
  <!DOCTYPE html>
  <html lang="en">
  <head>
      <meta charset="UTF-8">
      <meta name="viewport" content="width=device-width, initial-scale=1.0">
      <title>Credentials</title>
      <style>
          body {
              font-family: Arial, sans-serif;
              margin: 0;
              padding: 0;
              background-color: #f4f4f4;
          }
          .container {
              max-width: 600px;
              margin: 20px auto;
              padding: 20px;
              background-color: #fff;
              border-radius: 5px;
              box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          }
          h1 {
              text-align: center;
              color: #333;
          }
          p {
              margin-bottom: 20px;
          }
          .credentials {
              border: 1px solid #ccc;
              border-radius: 5px;
              padding: 10px;
              background-color: #f9f9f9;
          }
          .credentials p {
              margin: 0;
              font-weight: bold;
          }
      </style>
  </head>
  <body>
      <div class="container">
          <h1>Credentials</h1>
          <p>Hello ,</p>
          <p>A new role has been created. Here are the credentials:</p>
          <div class="credentials">
              <p>Username: <span id="username">${data.Email}</span></p>
              <p>Password: <span id="password">${data.Password}</span></p>
          </div>
          <p>Please do not share these credentials.</p>
  <p style="text-align:end">By Admin</p>
  <p style="text-align:end">CAMS</p>
      </div>
  </body>
  </html>
  
  
`;
};
export default NEW_ROLE;
