import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
} from "@mui/material";
import { Add, Edit } from "@mui/icons-material";
import { FileUpload, patchData } from "../../webService/webService";
import FILEROUTES from "../../API_Routes/File/File_Router";
import SERVER_URL from "../../utils/serverURL";
import { useDispatch } from "react-redux";

const activityTypes = [
  "INITIAL_ACCREDITATION_AND_ADMIN_COST",
  "STAGE_1",
  "STAGE_2",
  "SA_1",
  "SA_2",
  "RA",
  "TRANSFER_AUDIT",
  "CERTIFICATE_COMPLETED",
];

const paymentStatuses = ["NOT PAID", "PAID", "INPROGRESS", "REJECTED"];
const invoiceStatuses = [
  "INVOICE_GENERATED",
  "INVOICE_SENT_TO_CLIENT",
  "PENDING_SENT_TO_CLIENT",
];

const years = [1, 2, 3];

const initialFormState = {
  year: "",
  activityType: [
    {
      name: "",
      paymentStatus: "",
      invoice: null,
      invoiceStatus: "",
      amount: "",
    },
  ],
  remark: "",
};

const TableData = ({ selectedId, specificClient }) => {
  const dispatch = useDispatch();
  console.log(specificClient, "specificClient");
  const accessToken = sessionStorage.getItem("Token");
  const UserId = sessionStorage.getItem("UserId");
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState(initialFormState);
  const [editIndex, setEditIndex] = useState(null);

  useEffect(() => {
    setData(specificClient?.accounts || []);
  }, [specificClient]);

  const handleOpen = (index = null) => {
    if (index !== null) {
      setEditIndex(index);
      setFormData(data[index]);
    } else {
      setEditIndex(null);
      setFormData(initialFormState);
    }
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleActivityChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      activityType: [
        {
          ...prevFormData.activityType[0],
          [name]: value,
        },
      ],
    }));
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append("file", file);
    const uploadRes = await FileUpload(
      `${FILEROUTES.UPLOAD}${UserId}`,
      formData,
      dispatch
    );
    setFormData((prevFormData) => ({
      ...prevFormData,
      activityType: [
        {
          ...prevFormData.activityType[0],
          invoice: uploadRes.data,
        },
      ],
    }));
  };

  const handleSubmit = async () => {
    const newEntry = {
      year: formData.year,
      activityType: formData.activityType,
      remark: formData.remark,
    };

    let updatedData;
    if (editIndex !== null) {
      updatedData = [...data];
      updatedData[editIndex] = newEntry;
    } else {
      updatedData = [...data, newEntry];
    }

    const params = {
      accounts: updatedData,
    };

    try {
      const response = await patchData(
        `clients/client/${selectedId}/basic`,
        params,
        accessToken,
        dispatch
      );
      setData(response.data.accounts);
    } catch (error) {
      console.error("Error submitting data:", error);
    }

    handleClose();
  };

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        className="mb-3"
        startIcon={<Add />}
        onClick={() => handleOpen()}
      >
        Add Account
      </Button>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className="cl-green text-dark">Year</TableCell>
              <TableCell className="cl-green text-dark">
                Activity Type
              </TableCell>
              <TableCell className="cl-green text-dark">
                Payment Status
              </TableCell>
              <TableCell className="cl-green text-dark">
                Invoice Status
              </TableCell>
              <TableCell className="cl-green text-dark">Invoice</TableCell>
              <TableCell className="cl-green text-dark">Amount</TableCell>
              <TableCell className="cl-green text-dark">Remark</TableCell>
              <TableCell className="cl-green text-dark">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.length === 0 ? (
              <h6 className="text-center my-3">No Data</h6>
            ) : (
              data.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{row.year}</TableCell>
                  <TableCell>{row.activityType[0].name}</TableCell>
                  <TableCell>{row.activityType[0].paymentStatus}</TableCell>
                  <TableCell>{row.activityType[0].invoiceStatus}</TableCell>
                  <TableCell>
                    {row.activityType[0].invoice ? (
                      <a
                        rel="noreferrer"
                        href={`${SERVER_URL}/${row.activityType[0].invoice.uri}`}
                        target="_blank"
                      >
                        View
                      </a>
                    ) : (
                      ""
                    )}
                  </TableCell>
                  <TableCell>{row.activityType[0].amount}</TableCell>
                  <TableCell>{row.remark}</TableCell>
                  <TableCell>
                    <IconButton onClick={() => handleOpen(index)}>
                      <Edit />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          {editIndex !== null ? "Edit Account" : "Add Account"}
        </DialogTitle>
        <DialogContent>
          <FormControl fullWidth margin="normal">
            <InputLabel id="year-label">Year</InputLabel>
            <Select
              labelId="year-label"
              name="year"
              value={formData.year}
              onChange={handleChange}
            >
              {years.map((year) => (
                <MenuItem key={year} value={year}>
                  {year}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth margin="normal">
            <InputLabel id="activityType-name-label">Activity Type</InputLabel>
            <Select
              labelId="activityType-name-label"
              name="name"
              value={formData.activityType[0].name}
              onChange={handleActivityChange}
            >
              {activityTypes.map((type) => (
                <MenuItem key={type} value={type}>
                  {type}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth margin="normal">
            <InputLabel id="paymentStatus-label">Payment Status</InputLabel>
            <Select
              labelId="paymentStatus-label"
              name="paymentStatus"
              value={formData.activityType[0].paymentStatus}
              onChange={handleActivityChange}
            >
              {paymentStatuses.map((status) => (
                <MenuItem key={status} value={status}>
                  {status}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth margin="normal">
            <InputLabel id="invoiceStatus-label">Invoice Status</InputLabel>
            <Select
              labelId="invoiceStatus-label"
              name="invoiceStatus"
              value={formData.activityType[0].invoiceStatus}
              onChange={handleActivityChange}
            >
              {invoiceStatuses.map((status) => (
                <MenuItem key={status} value={status}>
                  {status}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth margin="normal">
            <TextField
              name="remark"
              label="Remark"
              multiline
              rows={4}
              value={formData.remark}
              onChange={handleChange}
            />
          </FormControl>

          <FormControl fullWidth margin="normal">
            <TextField
              name="amount"
              label="Amount"
              value={formData.activityType[0].amount}
              onChange={handleActivityChange}
            />
          </FormControl>

          <FormControl fullWidth margin="normal">
            <Button variant="contained" component="label">
              Upload Invoice
              <input type="file" hidden onChange={handleFileChange} />
            </Button>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default TableData;
