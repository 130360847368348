import { Helmet } from "react-helmet-async";
// @mui
import { Container } from "@mui/material";

import Addclient from "src/components/Sales/Clients-list/AddClient/Addclient";
import AddclientHRA from "src/components/Sales/Clients-list/AddClient/AddclientHRA";
// ----------------------------------------------------------------------

export default function SalesHRAClients() {
  return (
    <>
      <Helmet>
        <title> Clients </title>
      </Helmet>

      <Container maxWidth="xl">

        {/* <ClientList /> */}
        <AddclientHRA />
      </Container>
    </>
  );
}
