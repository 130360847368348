import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Button,
  Card,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Iconify from "../../components/iconify";

import { getData, deleteData, patchData } from "../../webService/webService";
import { setBasicandAdvance } from "src/redux/slices/ClientSlice";
import { setSelectedClient } from "../../redux/slices/SelectedClientSlice";

import CLIENTROUTES from "../../API_Routes/Client/Client_Routers";
import ClientDataView from "./ClientDataView";
import ClientDataEdit from "./ClientDataEdit";
import ClientDataAdd from "./ClientDataAdd";

import { DeleteConfirmationDialog } from "src/utils/popup/loader";

import { ClientTemplateData } from "src/Config/HRA/ClientData";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import AUDITROUTES from "src/API_Routes/Audit/Audit_routes";
import USERSROUTES from "src/API_Routes/User/User_Routers";

function OperationManagerClients() {
  const dispatch = useDispatch();
  const CLIENT_LIST = useSelector(
    (state) => state?.client?.BasicandAdvance[0]?.paginatedResults
  );
  const accessToken = sessionStorage.getItem("Token");
  const [dialogOpen, setDialogOpen] = useState(null);
  const [dialogAddOpen, setDialogAddOpen] = useState(null);
  const [dialogEditOpen, setDialogEditOpen] = useState(null);

  // Table Conditions
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit, setLimit] = useState(5);
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedClientType, setSelectedClientType] = useState("notsent");
  const [schedule, setSchedule] = useState();
  const [auditDates, setAuditDates] = useState();
  const [auditorName, setAuditorName] = useState();
  const [mandayType, setMandayType] = useState();
  const [auditorDesignation, setAuditorDesignation] = useState();

  // const [pdf, setPdf] = useState({
  //   name: auditorName,
  //   mandayType: mandayType,
  //   auditorDesignation: auditorDesignation
  // })

  // console.log(pdf, "pdf");

  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false);
  };

  const handleDeleteButtonClick = (row) => {
    setSelectedRow(row);
    setDeleteDialogOpen(true);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleLimitChange = (event) => {
    setCurrentPage(1);
    setLimit(event.target.value);
  };


  const handleMailStatus = async (event, id) => {
    const newStatus = event.target.value === "sent" ? "Mail sent" : "not sent";
    setSelectedClientType((prevState) => ({
      ...prevState,
      [id]: event.target.value,
    }));

    const params = {
      clientStatusTracker: [
        {
          status: newStatus,
        },
      ],
    };

    try {
      await patchData(`clients/client/${id}/basic`, params, accessToken, dispatch);
      toast.POSITION.TOP_RIGHT(`Status updated successfully`);
    } catch (error) {
      console.error(`Error updating status for client ID: ${id}`, error);
    }
  };





  const handleDelete = async () => {
    console.log("handleDelete selectedRow", selectedRow);
    const confirmDelete = window.confirm("Are you sure you want to delete?");
    if (confirmDelete) {
      try {
        const DELETE_CLIENT_ADVANCED_RES = await deleteData(
          CLIENTROUTES.DELETE_CLIENT_ADVANCED(selectedRow?._id),
          accessToken
        );
        if (DELETE_CLIENT_ADVANCED_RES.status === 200) {
          toast.success(DELETE_CLIENT_ADVANCED_RES.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }

        const DELETE_CLIENT_BASIC_RES = await deleteData(
          CLIENTROUTES.DELETE_CLIENT_BASIC(selectedRow?._id),
          accessToken
        );
        if (DELETE_CLIENT_BASIC_RES.status === 200) {
          toast.success(DELETE_CLIENT_BASIC_RES.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
        setDeleteDialogOpen(false);
      } catch (error) {
        console.error("Error:", error);
        toast.error("An error occurred. Please try again later.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }
  };

  const handleClose = () => {
    setDialogOpen(false);
  };

  const handleAddClose = () => {
    setDialogAddOpen(false);
  };

  const handleEditClose = () => {
    setDialogEditOpen(false);
  };

  // Table Conditions
  const GET_ALL_CLIENTS = async (page) => {
    const params = {
      page: page,
      limit: limit,
      scheme: "HRA",
    };
    try {
      const res = await getData(
        CLIENTROUTES.GET_CLIENT_BASIC_AND_ADVANCED_ALL,
        params,
        accessToken
      );
      if (res.status === 200) {
        toast.success(res.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        dispatch(setBasicandAdvance(res.data));
        setTotalPages(res.data[0].totalPages);
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("An error occurred. Please try again later.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  useEffect(() => {
    GET_ALL_CLIENTS(currentPage);
  }, [currentPage, limit, selectedClientType]);

  const handleOpenViewDialog = (event, row) => {
    console.log("event.currentTarget", row);
    dispatch(setSelectedClient(row));
    setDialogOpen(true);
  };

  const handleOpenAddDialog = () => {
    setDialogAddOpen(true);
  };

  const handleOpenEditDialog = async (event, row, auditorId) => {
    const rowData = JSON.parse(JSON.stringify(row));
    if (rowData.ClientFormHRA_Data.length === 0) {
      rowData.ClientFormHRA_Data = ClientTemplateData.ClientFormHRA_Data;
    }
    console.log("event.currentTarget", rowData);
    dispatch(setSelectedClient(rowData));
    setDialogEditOpen(true);

    const res = await getData(
      `${AUDITROUTES.SCHEDULEGET(auditorId)}`,
      null,
      accessToken
    );
    const audit = await getData(
      `users/basic/user/${res?.data?.scheduledAuditorsWithDates[0]?.auditorId}`,
      null,
      accessToken
    );

    console.log(res.data.scheduledAuditorsWithDates, "resssss");
    console.log(audit.data.auditDates, "audit");
    console.log(audit.data.firstName, "firstname");
    setAuditorName(audit.data.firstName, "firstname");
    setAuditorDesignation(res.data.scheduledAuditorsWithDates[0].auditorDesignation)

    const foundManDayType = audit.data.auditDates?.find(date => date?.date === res.data.scheduledAuditorsWithDates[0]?.scheduledDate);

    console.log(foundManDayType.manDayType, "foundManDayType");
    setMandayType(foundManDayType.manDayType)


  };


  // const foundManDayType = auditDatess?.find(date => date?.date === schedules[0]?.scheduledDate);

  // console.log(foundManDayType, "foundManDayType");

  const AddButtonStyle = {
    container: {
      display: "flex",
      justifyContent: "flex-end",
      padding: "10px",
    },
    button: {
      padding: "10px 20px",
      backgroundColor: "#89b72b",
      color: "white",
      border: "none",
      borderRadius: "5px",
      cursor: "pointer",
      fontSize: "16px",
    },
  };

  const [isChecked, setIsChecked] = useState({});
  const [selectAllChecked, setSelectAllChecked] = useState(false);

  // Function to handle checkbox change
  const handleCheckboxChange = (event, id) => {
    setIsChecked({ ...isChecked, [id]: event.target.checked });
  };
  // Function to handle select all checkbox change
  const handleSelectAllCheckboxChange = (event) => {
    const { checked } = event.target;
    const updatedIsChecked = {};

    // Update isChecked object with all checkboxes' states
    CLIENT_LIST.forEach((row) => {
      updatedIsChecked[row._id] = checked;
    });

    setIsChecked(updatedIsChecked);
    setSelectAllChecked(checked);
  };

  console.log(CLIENT_LIST, "clientlist");

  const handleExport = () => {
    const headers = [
      "Client Id",
      "Company Name",
      "Location",
      "State",
      "Status",
      "PhoneNumber",
      "Email",
      "Address",
      "Scheme",
      "FSSAI_LicenseNumber",
      "FoSTaC_TrainedFoodSafetySupervisors",
      "GSTNumber",
      "auditType",
      "clientType",
      "headOffice",
      "kob",
      "mainProductionOrService",
      "noOfFoodHandlers",
      "remarks",
      "totalNoOfEmployees",
      "uniqueCertificateNoAndCourseName",
      "validityOfLicense",
      "contact person",
      "lead generated by"

    ].filter(Boolean);
    // Filter checked items
    const checkedItems = CLIENT_LIST.filter(
      (item) => isChecked[item._id]

    );
    let data;
    data = checkedItems.map((item) => {
      return [
        item?.clientId,
        item?.companyName,
        item?.address || item?.city,
        item?.state,
        item?.status?.type,
        item?.phoneNumber,
        item?.email,
        item?.address || item?.streetName,
        item?.standardsSchemeType,
        item?.ClientFormHRA_Data[0]?.FSSAI_LicenseNumber,
        item?.ClientFormHRA_Data[0]?.FoSTaC_TrainedFoodSafetySupervisors,
        item?.ClientFormHRA_Data[0]?.GSTNumber,
        item?.ClientFormHRA_Data[0]?.auditType,
        item?.ClientFormHRA_Data[0]?.clientType,
        item?.ClientFormHRA_Data[0]?.headOffice,
        item?.ClientFormHRA_Data[0]?.kob,
        item?.ClientFormHRA_Data[0]?.mainProductionOrService || item?.ClientFormHRA_Data[0]?.mainProduction,
        item?.ClientFormHRA_Data[0]?.noOfFoodHandlers,
        item?.ClientFormHRA_Data[0]?.remarks,
        item?.ClientFormHRA_Data[0]?.totalNoOfEmployees,
        item?.ClientFormHRA_Data[0]?.uniqueCertificateNoAndCourseName || item?.ClientFormHRA_Data[0]?.courseName,
        item?.ClientFormHRA_Data[0]?.validityOfLicense,
        item?.contactPerson,
        item?.leadGeneratedBy
      ]
    });

    const worksheet = XLSX.utils.aoa_to_sheet([headers, ...data]);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Client List Data");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    saveAs(
      new Blob([excelBuffer], { type: "application/octet-stream" }),
      "client_list_data.xlsx"
    );

  }

  return (
    <>
      <ToastContainer />
      <DeleteConfirmationDialog
        open={isDeleteDialogOpen}
        onClose={handleDeleteDialogClose}
        onConfirm={handleDelete}
      />

      <Dialog
        fullScreen={true}
        open={dialogOpen}
        onClose={handleClose}
        aria-labelledby="client-view-dialog"
        aria-describedby="client-view-dialog"
      >
        <DialogTitle id="client-view-dialog">View Client</DialogTitle>
        <DialogContent>
          <ClientDataView />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullScreen={true}
        open={dialogAddOpen}
        onClose={handleAddClose}
        aria-labelledby="client-add-dialog"
        aria-describedby="client-add-dialog"
      >
        <DialogTitle id="client-add-dialog">Add Client</DialogTitle>
        <DialogContent>
          <ClientDataAdd />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAddClose} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullScreen={true}
        open={dialogEditOpen}
        onClose={handleEditClose}
        aria-labelledby="client-edit-dialog"
        aria-describedby="client-edit-dialog"
      >
        <DialogTitle id="client-edit-dialog">Edit Client</DialogTitle>
        <DialogContent>
          <ClientDataEdit auditorName={auditorName} mandayType={mandayType} auditorDesignation={auditorDesignation} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleEditClose} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Card>
        <div style={AddButtonStyle.container}>
          <Button
            variant="contained"
            className="btn-orange text-dark"
            onClick={handleExport}
          >
            Export
          </Button>
          <Button style={AddButtonStyle.button} onClick={handleOpenAddDialog}>
            {" "}
            Add Client{" "}
          </Button>
        </div>
        <TableContainer sx={{ minWidth: 800 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  className="cl-green text-dark"
                  style={{ padding: "5px" }}
                >
                  <Checkbox
                    checked={selectAllChecked}
                    onChange={handleSelectAllCheckboxChange}
                  />
                </TableCell>
                <TableCell className="cl-green text-dark">Client Id</TableCell>
                <TableCell className="cl-green text-dark">
                  Company Name
                </TableCell>

                <TableCell className="cl-green text-dark">Scheme</TableCell>
                <TableCell className="cl-green text-dark">Location</TableCell>
                <TableCell
                  style={{ width: "10%" }}
                  className="cl-green text-dark"
                  align="center"
                >
                  Status
                </TableCell>
                <TableCell className="cl-green text-dark" align="center">Mail status</TableCell>
                <TableCell className="cl-green text-dark" align="center">
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {CLIENT_LIST &&
                CLIENT_LIST.map((row) => {
                  return (
                    <TableRow key={row._id}>
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isChecked[row._id] || false}
                          onChange={(event) =>
                            handleCheckboxChange(event, row._id)
                          }
                        />
                      </TableCell>
                      <TableCell>{row.clientId}</TableCell>
                      <TableCell>{row.companyName}</TableCell>
                      <TableCell>{row.standardsSchemeType}</TableCell>
                      <TableCell>
                        {row.state}/{row.country}
                      </TableCell>
                      <TableCell align="center">
                        {typeof row.status === "object"
                          ? row.status.type
                          : row.status}
                      </TableCell>
                      <TableCell align="center">
                        <div className="d-flex align-items-center justify-content-around">
                          <p className="mb-0 mr-2">{row?.clientStatusTracker[0]?.status}</p>
                          <Select

                            key={row._id}
                            name="clientType"
                            id={`clientType-select-${row._id}`}
                            value={selectedClientType}
                            onChange={(e) => handleMailStatus(e, row._id)}
                          >
                            <MenuItem>none</MenuItem>
                            <MenuItem value="sent">sent</MenuItem>
                            <MenuItem value="notsent">notsent</MenuItem>
                          </Select>
                        </div>
                      </TableCell>
                      <TableCell>
                        <MenuItem
                          onClick={(event) => handleOpenEditDialog(event, row, row?.reviewHistory[0]?.reviewerName)}
                        >
                          <Iconify icon={"eva:edit-fill"} sx={{ mr: 2 }} />
                        </MenuItem>
                        <MenuItem
                          onClick={(event) => handleOpenViewDialog(event, row)}
                        >
                          <Iconify icon={"raphael:view"} sx={{ mr: 2 }} />
                        </MenuItem>
                        <MenuItem
                          sx={{ color: "error.main" }}
                          onClick={() => handleDeleteButtonClick(row)}
                        >
                          <Iconify
                            icon={"eva:trash-2-outline"}
                            sx={{ mr: 2 }}
                          />
                        </MenuItem>
                      </TableCell>
                    </TableRow>
                  );
                })}

              {CLIENT_LIST && CLIENT_LIST.length === 0 && "No Data"}
            </TableBody>
          </Table>
        </TableContainer>
        <div className="d-flex flex-wrap justify-content-end m-3">
          <FormControl variant="outlined" size="small">
            <InputLabel id="limit-label">Limit</InputLabel>
            <Select
              labelId="limit-label"
              id="limit-select"
              value={limit}
              onChange={handleLimitChange}
            >
              <MenuItem value={5}>5</MenuItem>
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={15}>15</MenuItem>
            </Select>
          </FormControl>
          <Button onClick={handlePrevPage} disabled={currentPage === 1}>
            <Iconify icon={"grommet-icons:previous"} />
          </Button>
          <Button
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
          >
            <Iconify icon={"grommet-icons:next"} />
          </Button>
        </div>
      </Card>
    </>
  );
}

export default OperationManagerClients;
