import { createSlice } from "@reduxjs/toolkit";

const ClientSlice = createSlice({
  name: "Client",
  initialState: {
    BasicandAdvance: "",
  },
  reducers: {
    setBasicandAdvance: (state, action) => {
      state.BasicandAdvance = action.payload;
    },
  },
});

export const { setBasicandAdvance } = ClientSlice.actions;
export default ClientSlice.reducer;
