import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { postData } from "../../../webService/webService";
// @mui
import { Stack, IconButton, InputAdornment, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
// components
import Iconify from "../../../components/iconify";
import { setToken } from "src/redux/slices/LoginSlice";
import { useDispatch } from "react-redux";
import USERSROUTES from "src/API_Routes/User/User_Routers";
// ----------------------------------------------------------------------

export default function LoginForm({ onLogin }) {
  const dispatch = useDispatch();
  const [mail, setMail] = useState("");
  const [pwd, setPwd] = useState("");
  const [role, setRole] = useState("None");
  const [scheme, setScheme] = useState("None");
  const [username, setUsername] = useState("");
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const extractUsername = () => {
    const parts = mail.split("@"); // Split the email address by '@'
    if (parts.length === 2) {
      setUsername(parts[0]); // Set the username state to the first part of the split array
    } else {
      setUsername(""); // Set username to empty if email is invalid
    }
  };
  useEffect(() => {
    extractUsername(mail);
  }, [mail]);

  const handleClick = () => {
    const LoginParams = {
      email: mail.trim(),
      password: pwd.trim(),
      role: role,
    };
    if (mail === "" && pwd === "" && role === "None") {
      toast.error("All The Filed's Are Required", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      postData(USERSROUTES.LOGIN, LoginParams, null, dispatch)
        .then((res) => {
          switch (res.status) {
            case 200:
              sessionStorage.setItem("UserMail", mail);
              sessionStorage.setItem("UserName", username);
              sessionStorage.setItem("firstName", res?.data?.firstName);
              sessionStorage.setItem("lastName", res?.data?.lastName);
              sessionStorage.setItem("Token", res?.data?.sessionToken);
              sessionStorage.setItem("UserId", res?.data?.userId);
              sessionStorage.setItem("scheme", scheme);
              dispatch(setToken(res?.data?.sessionToken));
              toast.success("User logged in successfully", {
                position: toast.POSITION.TOP_RIGHT,
              });
              onLogin(role);
              switch (role) {
                case "Admin":
                  navigate("/admin/dashboard", { replace: true });
                  break;
                case "Sales":
                  navigate("/sale/skillset", { replace: true });
                  break;
                case "CSO":
                  navigate("/cso/skillset", { replace: true });
                  break;
                case "Reviewer":
                  navigate("/reviewer/skillset", { replace: true });
                  break;
                case "Auditor":
                  navigate("/auditor/skillset", { replace: true });
                  break;
                case "Planner":
                  navigate("/planner/skillset", { replace: true });
                  break;
                case "Account":
                  navigate("/account/skillset", { replace: true });
                  break;
                case "Operation-Manager":
                  navigate("/operationmanager/dashboard", { replace: true });
                  break;
                case "Sales-HRA":
                  navigate("/saleshra/skillset", { replace: true });
                  break;
                case "Process-Associate-MIS":
                  navigate("/processassociate/skillset", { replace: true });
                  break;
                case "Subject-Matter-Expert":
                  navigate("/subjectmatterexpert/skillset", { replace: true });
                  break;
                default:
                  console.log("No Routes");
                  break;
              }
              break;
            case 500:
              toast.error("Error while logging in: Server error", {
                position: toast.POSITION.TOP_RIGHT,
              });
              break;
            case 400:
              toast.error("Incorrect Password", {
                position: toast.POSITION.TOP_RIGHT,
              });
              break;
            case 404:
              toast.error("User Not Found", {
                position: toast.POSITION.TOP_RIGHT,
              });
              break;
            default:
              toast.error("Unexpected Error occurred", {
                position: toast.POSITION.TOP_RIGHT,
              });
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  };

  const handleChangeRole = (event) => {
    setRole(event.target.value);
  };
  const handleChangeScheme = (event) => {
    setScheme(event.target.value);
  };
  return (
    <>
      <ToastContainer />
      <Stack spacing={3}>
        <h4 className="text-center fw-bold">Log In</h4>
        <TextField
          name="email"
          label="Email address"
          value={mail}
          size="small"
          onChange={(e) => setMail(e.target.value)}
          autoComplete="off"
          required
        />
        <TextField
          autoComplete="off"
          size="small"
          name="password"
          label="Password"
          required
          type={showPassword ? "text" : "password"}
          value={pwd}
          onChange={(e) => setPwd(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  <Iconify
                    icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
          <InputLabel id="Scheme">Scheme</InputLabel>
          <Select
            required
            labelId="Scheme"
            id="Scheme"
            value={scheme}
            label="scheme"
            onChange={handleChangeScheme}
          >
            <MenuItem value="None">
              <em>None</em>
            </MenuItem>
            <MenuItem value={"CB"}>CB</MenuItem>
            <MenuItem value={"HRA"}>HRA</MenuItem>
          </Select>
        </FormControl>
        {scheme === "None" ? (
          ""
        ) : (
          <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
            <InputLabel id="demo-select-small-label">Role</InputLabel>
            <Select
              required
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={role}
              label="role"
              onChange={handleChangeRole}
            >
              {(scheme === "CB"
                ? [
                    { value: "None", label: "None" },
                    { value: "Admin", label: "Admin" },
                    { value: "Sales", label: "Sales" },
                    { value: "CSO", label: "CSO" },
                    { value: "Reviewer", label: "Reviewer" },
                    { value: "Planner", label: "Planner" },
                    { value: "Auditor", label: "Auditor" },
                    { value: "Account", label: "Account" },
                  ]
                : [
                    { value: "None", label: "None" },
                    { value: "Operation-Manager", label: "Operation Manager" },
                    { value: "Sales-HRA", label: "Sales" },
                    {
                      value: "Process-Associate-MIS",
                      label: "Process Associate / MIS",
                    },
                    {
                      value: "Subject-Matter-Expert",
                      label: "Subject Matter Expert",
                    },
                    { value: "Auditor-HRA", label: "Auditor" },
                    { value: "Accounts-HRA", label: "Account" },
                  ]
              ).map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </Stack>
      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        sx={{ my: 2 }}
        onClick={handleClick}
      >
        Login
      </LoadingButton>
    </>
  );
}
