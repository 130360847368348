import { createSlice } from "@reduxjs/toolkit";

const NC = createSlice({
  name: "NC",
  initialState: {
    Nc: "",
  },
  reducers: {
    setNC: (state, action) => {
      state.Nc = action.payload;
    },
  },
});

export const { setNC } = NC.actions;
export default NC.reducer;
