import { Helmet } from "react-helmet-async";
import { faker } from "@faker-js/faker";
import { useState, useEffect } from "react";

// @mui
import { useTheme } from "@mui/material/styles";
import { Grid, Container, Card } from "@mui/material";
// sections
import {
  AppOrderTimeline,
  AppCurrentVisits,
  AppWidgetSummary,
} from "../sections/@dashboard/app";
import { getData } from "../webService/webService";
import USERSROUTES from "src/API_Routes/User/User_Routers";
import StatusColumnChart from "src/components/ChartDashboard/Chart";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Upcomingclient from "../components/UpcomingClient/Upcomingclient";
import FeedbackChart from "../components/ChartDashboard/FeedbackChart";
import StatusColumnChartHRA from "../components/ChartDashboard/ChartHRA";
import FeedbackChartHRA from "../components/ChartDashboard/FeedbackChartHRA";
import FeedbackFormList from "../components/CSO/FeedbackForm/FeedbackForm-list";
// ----------------------------------------------------------------------

export default function DashboardAppPage() {
  const scheme = sessionStorage.getItem("scheme");
  const accessToken = sessionStorage.getItem("Token");
  const [counts, setCounts] = useState({});
  const getDataForRole = async (role) => {
    const params = {
      page: 1,
      limit: 10,
    };

    try {
      const res = await getData(USERSROUTES.ROLE + role, params, accessToken);
      if (res.status === 200) {
        console.log(res);
        // Check if the role is an HRA role
        if (role.endsWith("-HRA")) {
          // Update the counts for HRA roles
          setCounts((prevCounts) => ({
            ...prevCounts,
            [role.toLowerCase()]: res.data.totalItems,
          }));
        } else {
          // Extract the non-HRA role from the HRA role
          const nonHRARole = `${role}HRA`; // Append "HRA" suffix
          // Update the counts for non-HRA roles
          setCounts((prevCounts) => ({
            ...prevCounts,
            [nonHRARole.toLowerCase()]: res.data.totalItems,
          }));
        }
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("An error occurred. Please try again later.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  useEffect(() => {
    if (scheme === "HRA") {
      getDataForRole("Sales-HRA");
      getDataForRole("Process-Associate-MIS");
      getDataForRole("Subject-Matter-Expert");
      getDataForRole("Auditor");
      // getDataForRole("Account-HRA");
    } else {
      getDataForRole("Sales");
      getDataForRole("Auditor");
      getDataForRole("Planner");
      getDataForRole("Reviewer");
      getDataForRole("CSO");
      getDataForRole("Account");
    }
  }, []);
  console.log("counts", counts);
  const theme = useTheme();
  return (
    <>
      <Helmet>
        <title> Dashboard </title>
      </Helmet>

      <Container maxWidth="xl">
        {scheme === "HRA" ? (
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={3}>
              <AppWidgetSummary
                title="Total Sales Lead"
                total={counts["sales-hra"]}
                icon={"carbon:sales-ops"}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <AppWidgetSummary
                title="Total Auditor"
                total={counts["auditorhra"]}
                color="info"
                icon={"guidance:auditorium"}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <AppWidgetSummary
                title="Total Process Associate / MIS"
                total={counts["process-associate-mishra"]}
                color="warning"
                icon={"fluent-mdl2:review-response-solid"}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <AppWidgetSummary
                title="Total Subject Matter Expert"
                total={counts["subject-matter-experthra"]}
                color="error"
                icon={"mdi:planet-plus"}
              />
            </Grid>

            {/* <Grid item xs={12} sm={6} md={4}>
              <AppWidgetSummary
                title="Total Account"
                total={counts["account-hra"]}
                color="error"
                icon={"mdi:account-cog"}
              />
            </Grid> */}
          </Grid>
        ) : (
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={2}>
              <AppWidgetSummary
                title="Total Sales Lead"
                total={counts.saleshra}
                icon={"carbon:sales-ops"}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={2}>
              <AppWidgetSummary
                title="Total Auditor"
                total={counts.auditorhra}
                color="info"
                icon={"guidance:auditorium"}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={2}>
              <AppWidgetSummary
                title="Total Reviewer"
                total={counts.reviewerhra}
                color="warning"
                icon={"fluent-mdl2:review-response-solid"}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={2}>
              <AppWidgetSummary
                title="Total Planners"
                total={counts.plannerhra}
                color="error"
                icon={"mdi:planet-plus"}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={2}>
              <AppWidgetSummary
                title="Total Account"
                total={counts.accounthra}
                color="error"
                icon={"mdi:account-cog"}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={2}>
              <AppWidgetSummary
                title="Total CSO"
                total={counts.csohra}
                color="error"
                icon={"material-symbols:switch-account"}
              />
            </Grid>
          </Grid>
        )}
        {scheme !== "HRA" && (
          <>
            <Card className="my-3">
              <h5 className="ps-3 mt-3">Client Count Based On Status</h5>
              <StatusColumnChart />
            </Card>
            {/* <FeedbackChart /> */}
            <FeedbackFormList/>
            <Upcomingclient />
          </>
        )}
        {scheme === "HRA" && (
          <>
            <Card className="my-3">
              <h5 className="ps-3 mt-3">Client Count Based On Status</h5>
              <StatusColumnChartHRA />
            </Card>
            <div className="d-flex flex-wrap">
              <div className="col-4 p-2">
                <FeedbackChartHRA />
              </div>
              <div className="col-8 p-2" style={{ height: "453px" }}>
                <Upcomingclient />
              </div>
            </div>
          </>
        )}
      </Container>
      {/* <Grid item xs={12} md={6} lg={6}>
            <AppCurrentVisits
              title="Auditor Reports" 
              chartData={[
                { label: "Pending", value: 4344 },
                { label: "Inprogress", value: 5435 },
                { label: "Completed", value: 1443 },
                { label: "Waiting", value: 4443 },
              ]}
              chartColors={[
                theme.palette.primary.main,
                theme.palette.info.main,
                theme.palette.warning.main,
                theme.palette.error.main,
              ]}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={6}>
            <AppOrderTimeline
              title="Order Timeline"
              list={[...Array(5)].map((_, index) => ({
                id: faker.datatype.uuid(),
                title: [
                  "1983, orders, $4220",
                  "12 Invoices have been paid",
                  "Order #37745 from September",
                  "New order placed #XF-2356",
                  "New order placed #XF-2346",
                ][index],
                type: `order${index + 1}`,
                time: faker.date.past(),
              }))}
            />
          </Grid> */}
    </>
  );
}
