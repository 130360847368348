let UserId = sessionStorage.getItem("UserId") ? sessionStorage.getItem("UserId") : "";

const CLIENTROUTES = {
  GETSALESCLIENTBASIC:  ( USER_ID ) => `clients/client/sales/${USER_ID}/basic`,
  GETCLIENTBASICUPDATE: `clients/client/basic`,
  ADD_CLIENT_BASIC: 'clients/client/basic',
  ADD_CLIENT_ADVANCED: ( CLIENT_BASIC_ID ) => `clients/client/${CLIENT_BASIC_ID}/advanced`,
  UPDATE_CLIENT_BASIC: ( CLIENT_BASIC_ID ) => `clients/client/${CLIENT_BASIC_ID}/basic`,
  UPDATE_CLIENT_ADVANCED: ( CLIENT_BASIC_ID ) => `clients/client/${CLIENT_BASIC_ID}/advanced`,
  GETCLIENTBASIC: `clients/client`,
  GETCLIENTADVANCE: `clients/client/`,
  GET_CLIENT_BASIC_AND_ADVANCE: ( USER_ID ) => 
                            `clients/client/basic/sales/${USER_ID}/advanced/all`,
  GET_CLIENT_BASIC_AND_ADVANCED_ALL: `clients/client/basic/advanced/all`,
  GETSPECIFICUSERBASIC: `clients/client/`,
  GETSPECIFICUSERADVANCE: `clients/client/`,
  DELETE_CLIENT_BASIC: ( CLIENT_BASIC_ID ) => `clients/client/${CLIENT_BASIC_ID}/basic`,
  DELETE_CLIENT_ADVANCED: ( CLIENT_BASIC_ID ) => `clients/client/${CLIENT_BASIC_ID}/advanced`,
};

export default CLIENTROUTES;
