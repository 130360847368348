import React from "react";
import TableList from "src/components/Admin/TableList/TableList";

export default function Admin() {
  return (
    <>
      <div>
        <TableList Role="Admin" />
      </div>
    </>
  );
}
