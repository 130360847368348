import React, { useState } from "react";
import { Tabs, Tab, Box, Typography } from "@mui/material";
import ClientDataAdd from "./ClientDataAdd";
import OperationManagerClients from "./Clients";
import ProcessAssociateAudit from "../Process-Associate-MIS/Audit";
import ProcessAssociateClients from "../Process-Associate-MIS/Clients";
import SubjectMatterExpertAudit from "../Subject-Matter-Expert/Audit";
import SubjectmatterexpertClients from "../Subject-Matter-Expert/Clients";
import SubjectmatterexpertReviewAudit from "../Subject-Matter-Expert/ReviewAudit";


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function OperationmanagerNew() {
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <div>
            <Tabs
                value={value}
                onChange={handleChange}
                aria-label="admin client tabs"
                variant="fullWidth" // Ensures all tabs are displayed in the header
                sx={{
                    marginTop: "9px",
                    marginBottom: "10px",
                    backgroundColor: "#fcdf8b",
                    "& .MuiTab-root": {
                        color: "black !important",
                        fontWeight: "bold",
                    },
                    "& .MuiTabs-indicator": {
                        backgroundColor: "transparent", // Removes the underline for active tab
                    },
                    "& .Mui-selected": {
                        backgroundColor: "#d66d09 !important",
                        color: "white !important",
                    },
                }}
            >
                <Tab label="Sale" />
                <Tab label="Process Associate Client" />
                <Tab label="Process Associate Audit" />
                <Tab label="Subject Matter Expert Audit" />
                <Tab label="Subject Matter Expert Review Audit" />
            </Tabs>
            <TabPanel value={value} index={0}>
                <OperationManagerClients />
            </TabPanel>
            <TabPanel value={value} index={1}>
                <ProcessAssociateAudit />
            </TabPanel>
            <TabPanel value={value} index={2}>
                <ProcessAssociateClients />
            </TabPanel>
            <TabPanel value={value} index={3}>
                <SubjectMatterExpertAudit />
            </TabPanel>
            <TabPanel value={value} index={4}>
                <SubjectmatterexpertReviewAudit />
            </TabPanel>
        </div>
    );
}

export default OperationmanagerNew;
