import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { useDispatch, useSelector } from "react-redux";
import { setTransferRAData } from "../../redux/slices/FeeSlice";

const TransferRAForm = () => {
  const dispatch = useDispatch();
  const selectedTransferRA = useSelector((state) => state.Fee.TransferRA);

  const [transferRA, setTransferRA] = useState({
    administrationFee: "",
    totalContractFee: "",
    surveillanceAudit: [
      {
        surveillanceAuditNumber: 1,
        manDay: {
          onSite: "",
          offSite: "",
          fee: "",
          totalManDays: "",
        },
        totalFee: "",
      },
      {
        surveillanceAuditNumber: 2,
        manDay: {
          year: 2,
          onSite: "",
          offSite: "",
          fee: "",
          totalManDays: "",
        },
        totalFee: "",
      },
      {
        surveillanceAuditNumber: 3,
        manDay: {
          year: 3,
          onSite: "",
          offSite: "",
          fee: "",
          totalManDays: "",
        },
        totalFee: "",
      },
    ],
    transferCertification: [
      {
        year: 1,
        fee: "",
      },
    ],
    re_Certification: [
      {
        manDay: {
          year: 1,
          onSite: "",
          offSite: "",
          fee: "",
          totalManDays: "",
        },
      },
    ],
    auditTypeStates: [
      {
        year: 1,
        name: "Transfer Certification in Re-Certification",
      },
    ],
  });

  useEffect(() => {
    if (selectedTransferRA) {
      setTransferRA(selectedTransferRA);
    }
  }, [selectedTransferRA]);

  const calculateTotalManDays = (onSite, offSite) => {
    const onSiteDays = parseFloat(onSite) || 0;
    const offSiteDays = parseFloat(offSite) || 0;
    return (onSiteDays + offSiteDays).toFixed(2);
  };

  const handleChange = (field, value, type, auditNumber = null) => {
    setTransferRA((prevState) => {
      let updatedState = JSON.parse(JSON.stringify(prevState));

      if (type === "administrationFee" || type === "totalContractFee") {
        updatedState[type] = value;
      } else if (type === "transferCertification") {
        updatedState.transferCertification[0].fee = value;
      } else if (type === "re_Certification") {
        updatedState.re_Certification[0].manDay[field] = value;
        updatedState.re_Certification[0].manDay.totalManDays =
          calculateTotalManDays(
            updatedState.re_Certification[0].manDay.onSite,
            updatedState.re_Certification[0].manDay.offSite
          );
      } else if (type === "surveillanceAudit") {
        updatedState.surveillanceAudit = updatedState.surveillanceAudit.map(
          (audit) => {
            if (audit.surveillanceAuditNumber === auditNumber) {
              audit.manDay[field] = value;
              audit.manDay.totalManDays = calculateTotalManDays(
                audit.manDay.onSite,
                audit.manDay.offSite
              );
            }
            return audit;
          }
        );
      }

      const administrationFee = parseFloat(updatedState.administrationFee) || 0;
      const transferCertificationFee =
        parseFloat(updatedState.transferCertification[0].fee) || 0;
      const reCertificationFee =
        parseFloat(updatedState.re_Certification[0].manDay.fee) || 0;

      updatedState.surveillanceAudit[0].totalFee =
        administrationFee + transferCertificationFee + reCertificationFee;

      updatedState.surveillanceAudit = updatedState.surveillanceAudit.map(
        (audit) => {
          if (audit.surveillanceAuditNumber !== 1) {
            audit.totalFee = parseFloat(audit.manDay.fee) || 0;
          }
          return audit;
        }
      );

      updatedState.totalContractFee = updatedState.surveillanceAudit.reduce(
        (total, audit) => total + parseFloat(audit.totalFee || 0),
        0
      );

      dispatch(setTransferRAData(updatedState));

      return updatedState;
    });
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} className="d-flex flex-wrap align-items-center">
        <Grid item xs={12} sm={5}>
          <p className="mb-0">Re-Certification</p>
        </Grid>
        <Grid item xs={12} sm={7} className="d-flex flex-wrap">
          <Grid item xs={12} sm={3}>
            <TextField
              label="On-Site"
              size="small"
              value={transferRA.re_Certification[0].manDay.onSite}
              onChange={(e) =>
                handleChange("onSite", e.target.value, "re_Certification")
              }
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              size="small"
              label="Off-Site"
              value={transferRA.re_Certification[0].manDay.offSite}
              onChange={(e) =>
                handleChange("offSite", e.target.value, "re_Certification")
              }
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              label="Fee"
              size="small"
              value={transferRA.re_Certification[0].manDay.fee}
              onChange={(e) =>
                handleChange("fee", e.target.value, "re_Certification")
              }
              fullWidth
            />
          </Grid>
          {/* <Grid item xs={12} sm={3}>
            <TextField
              label="Total Man Days"
              size="small"
              value={transferRA.re_Certification[0].manDay.totalManDays}
              fullWidth
              disabled
            />
          </Grid> */}
        </Grid>
      </Grid>

      {transferRA.surveillanceAudit.map((audit) => (
        <React.Fragment key={audit.surveillanceAuditNumber}>
          {audit.surveillanceAuditNumber > 1 && (
            <>
              <Grid
                item
                xs={12}
                className="d-flex flex-wrap align-items-center"
              >
                <Grid item xs={12} sm={5}>
                  <p className="mb-0">{`Surveillance Audit ${
                    audit.surveillanceAuditNumber - 1
                  }`}</p>
                </Grid>
                <Grid item xs={12} sm={7} className="d-flex flex-wrap">
                  <Grid item xs={12} sm={3}>
                    <TextField
                      label="On-Site"
                      size="small"
                      value={audit.manDay.onSite}
                      onChange={(e) =>
                        handleChange(
                          "onSite",
                          e.target.value,
                          "surveillanceAudit",
                          audit.surveillanceAuditNumber
                        )
                      }
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <TextField
                      size="small"
                      label="Off-Site"
                      value={audit.manDay.offSite}
                      onChange={(e) =>
                        handleChange(
                          "offSite",
                          e.target.value,
                          "surveillanceAudit",
                          audit.surveillanceAuditNumber
                        )
                      }
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <TextField
                      size="small"
                      label="Fee Year"
                      value={audit.manDay.fee}
                      onChange={(e) =>
                        handleChange(
                          "fee",
                          e.target.value,
                          "surveillanceAudit",
                          audit.surveillanceAuditNumber
                        )
                      }
                      fullWidth
                    />
                  </Grid>
                  {/* <Grid item xs={12} sm={3}>
                    <TextField
                      label="Total Man Days"
                      size="small"
                      value={audit.manDay.totalManDays}
                      fullWidth
                      disabled
                    />
                  </Grid> */}
                </Grid>
              </Grid>
            </>
          )}
        </React.Fragment>
      ))}

      <Grid item xs={12} sm={4}>
        <TextField
          size="small"
          label="Administration Fee"
          value={transferRA.administrationFee}
          onChange={(e) =>
            handleChange(
              "administrationFee",
              e.target.value,
              "administrationFee"
            )
          }
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextField
          size="small"
          label="Total Contract Fee"
          value={transferRA.totalContractFee}
          onChange={(e) =>
            handleChange("totalContractFee", e.target.value, "totalContractFee")
          }
          fullWidth
          disabled
        />
      </Grid>

      <Grid item xs={12} sm={4}>
        <TextField
          size="small"
          label="Transfer Certification Fee"
          value={transferRA.transferCertification[0].fee}
          onChange={(e) =>
            handleChange("fee", e.target.value, "transferCertification")
          }
          fullWidth
        />
      </Grid>
    </Grid>
  );
};

export default TransferRAForm;
