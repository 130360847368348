import React, { useEffect, useState } from "react";
import "../Client/Client-list.css";
import {
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Popover,
  Stack,
  Select,
  Table,
  TextField,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Checkbox,
} from "@mui/material";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import SendIcon from "@mui/icons-material/Send";
import Tooltip from "@mui/material/Tooltip";
import Iconify from "../../iconify/Iconify";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import html2pdf from "html2pdf.js";
import HRA_Agreement from "src/Config/HRA_Agreement";
import HRA_Proposal from "src/Config/HRA_proposal";
import {
  getData,
  postData,
  patchData,
  FileUpload,
} from "src/webService/webService";

import { useDispatch, useSelector } from "react-redux";
import { setClientBasicandAdvance } from "src/redux/slices/CSOSlice";

import PROCESSASSOCIATEROUTES from "src/API_Routes/ProcessAssociate/ProcessAssociateRoutes";
import CLIENTROUTES from "src/API_Routes/Client/Client_Routers";
import FEEDBACK_FORM_ROUTES from "src/API_Routes/FeedbackForm/FeedbackForm_Routers";
import EMAIL_ROUTES from "src/API_Routes/Email/Email_Routers";
import FILEROUTES from "src/API_Routes/File/File_Router";

import FEEDBACK_EMAIL_HTML from "src/Config/templates/html/Feedback_Email";
import { HRA_FEEDBACK_FORM_DATA } from "src/Config/feedbackForm/index";
import CommonEmail from "src/Config/templates/html/CommonEmailTem";
import AuditConfirmationEmail from "src/Config/templates/html/AuditConfirmationEmail";
import ProcessAssociateFilter from "src/components/Filter/ProcessAssociateFilter";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import ClientBasicAdvance from "src/components/ClientBasic_Advance/ClientBasic_Advance";
import { getFormattedIndiaTime } from "../../../utils/timeutils";
import SERVER_URL from "../../../utils/serverURL";
import CommonEmailHRA from "src/Config/templates/html/CommonEmailHRA";

export default function ClientList() {
  const dispatch = useDispatch();
  const PROCESSASSOCIATEVIEWCLIENTLIST = useSelector(
    (state) => state?.CSO?.ClientBasicandAdvance[0]?.paginatedResults
  );
  const UserId = sessionStorage.getItem("UserId");
  const SalesNameList = useSelector((state) => state?.SalesList.SalesNameList);

  console.log(PROCESSASSOCIATEVIEWCLIENTLIST, "clientlist");
  const accessToken = sessionStorage.getItem("Token");
  const [open, setOpen] = useState(null);
  const handleCloseMenu = () => {
    setOpen(null);
  };
  // Table Conditions
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit, setLimit] = useState(5);
  const [uploadFileShow, setUploadfileShow] = useState([]);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };
  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const handleLimitChange = (event) => {
    setCurrentPage(1);
    setLimit(event.target.value);
  };
  // Table Conditions
  const GETPROCESSASSOCIATE = async (page) => {
    const params = {
      scheme: "HRA",
      page: page,
      limit: limit,
    };
    try {
      const res = await getData(
        PROCESSASSOCIATEROUTES.GETPROCESSASSOCIATEROUTESCLIENTBASICANDADVANCE,
        params,
        accessToken
      );
      if (res.status === 200) {
        dispatch(setClientBasicandAdvance(res.data));
        setTotalPages(res.data[0].totalPages);
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("An error occurred. Please try again later.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  const [selectedValues, setSelectedValues] = useState({});
  useEffect(() => {
    GETPROCESSASSOCIATE(currentPage);
  }, [currentPage, limit, selectedValues]);

  //Get SubjectMatterExpert List
  const [SubjectMatterExpert, setSubjectMatterExpert] = useState();
  const GETSubjectMatterExpert = async () => {
    try {
      const res = await getData(
        `masters/user/basic/nameandid/Subject-Matter-Expert`,
        null,
        accessToken
      );
      if (res.status === 200) {
        setSubjectMatterExpert(res.data);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  useEffect(() => {
    GETSubjectMatterExpert();
  }, []);
  // find assigned SubjectMatterExpert
  const findUserById = (id) => {
    if (SubjectMatterExpert && id) {
      const user = SubjectMatterExpert.find((user) => user._id === id);
      if (user) {
        return { firstName: user.firstName, lastName: user.lastName };
      }
    }
    return { firstName: "-", lastName: "" }; // Return empty values if no match found
  };

  //assign subject matter export
  const handleChange = (event, data) => {
    event.preventDefault();

    // Update selected values state
    const selectedValue = event.target.value;
    setSelectedValues((prevState) => ({
      ...prevState,
      [data]: selectedValue,
    }));

    // Prepare data for patch request
    const Params = {
      subjectMatterExpertId: selectedValue,
      status: "subject matter expert assigned",
    };

    // Make the patch request
    const endpoint = `${CLIENTROUTES.GETCLIENTADVANCE}${data}/basic`;
    patchData(endpoint, Params, accessToken, dispatch);
    findUserById(selectedValue);
    setSelectedValues({});
  };

  const handleSignAndSealFileChange = async (event, row) => {
    const file = event?.target?.files[0];
    const formData = new FormData();
    formData.append("file", file);
    try {
      const UserId = sessionStorage.getItem("UserId");
      const response = await FileUpload(FILEROUTES.UPLOAD + UserId, formData, dispatch);
      console.log("File upload successful:", response);
      await handleSendFeedbackForm(row, response?.data);
    } catch (error) {
      console.error("Sign And Seal File upload error:", error);
      toast.error("Signature And Seal File upload error", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handleSendFeedbackForm = async (
    rowData,
    signatureAndSealFileObject
  ) => {
    try {
      console.log("Client Data", rowData);
      const feedbackFormData = HRA_FEEDBACK_FORM_DATA({
        clientName: rowData?.companyName,
        clientAddress: `${rowData?.address}
                        ${rowData?.state}
                        ${rowData?.country}`,
        clientRepresentativeName: rowData?.contactPerson,
        contactNumber: rowData?.phoneNumber,
        email: rowData?.email,
        signatureAndSeal: signatureAndSealFileObject,
      });
      const feedbackFormCreadtedResponse = await postData(
        FEEDBACK_FORM_ROUTES.CREATE_FEEDBACK_FORM("HRA"),
        feedbackFormData,
        accessToken,
        dispatch
      );
      console.log("Feed back form data response", feedbackFormCreadtedResponse);

      const HTML_DATA = {
        clientName: rowData?.companyName,
        contactNumber: rowData?.phoneNumber,
        clientId: rowData?.clientId,
        link: FEEDBACK_FORM_ROUTES.GET_FEEDBACK_FORM_HRA_PAGE(
          feedbackFormCreadtedResponse?.data?.token
        ),
      };
      console.log("HTML_DATA", HTML_DATA);
      const emailRes = await postData(
        EMAIL_ROUTES.SEND_EMAIL_TEXT,
        {
          to: rowData?.email,
          subject: "Feedback form for your recent Audit at CAUSSA",
          html: FEEDBACK_EMAIL_HTML(HTML_DATA),
        },
        accessToken,
        dispatch
      );
      console.log("emailRes", emailRes);
    } catch (err) {
      console.error(err);
    }
  };

  const [HRApopup, setHRApopup] = useState(false);
  const [HRApopup1, setHRApopup1] = useState(false);
  const [HygieneRatingAudit, setHygieneRatingAudit] = useState("");
  const [GrandTotal, setGrandTotal] = useState("");
  const [GST, setGST] = useState("");
  const [HRASelectedid, setHRASelectedId] = useState("");
  const [img, setImg] = useState();
  const HRApopupOpen = (data) => {
    setHRASelectedId(data);
    setHRApopup(true);
  };

  // const handleHygieneRatingAuditChange = (e) => {
  //   const value = Number(e.target.value);
  //   setHygieneRatingAudit(value);
  //   setGrandTotal(value + GST);
  // };

  const handleHygieneRatingAuditChange = (e) => {
    const value = parseFloat(e.target.value) || 0;
    setHygieneRatingAudit(value);
    const calculatedGST = value * 0.18;
    setGST(calculatedGST);
    setGrandTotal(value + calculatedGST);
  };

  // const handleGSTChange = (e) => {
  //   const value = Number(e.target.value);
  //   setGST(value);
  //   setGrandTotal(HygieneRatingAudit + value);
  // };


  const handleGSTChange = (e) => {
    const value = parseFloat(e.target.value) || 0;
    setGST(value);
    setGrandTotal(HygieneRatingAudit + value);
  };

  const HRApopupOpen1 = (data) => {
    setHRASelectedId(data);
    setHRApopup1(true);
  };

  const handleChanges = (e) => {
    console.log(e.target.files);
    const data = new FileReader();
    data.addEventListener("load", () => {
      setImg(data.result);
    });
    data.readAsDataURL(e.target.files[0]);
  };

  console.log(img);

  const handleSignedFileUploadHRA = async (event, data, fileType) => {
    event.preventDefault();
    try {
      const file = event.target.files[0];
      const formData = new FormData();
      formData.append("file", file);
      const uploadRes = await FileUpload(
        `${FILEROUTES.UPLOAD}${UserId}`,
        formData,
        dispatch
      );

      // Determine which field to set based on the fileType parameter
      // const fieldName =
      //   fileType === "signedproposal"
      //     ? "signedProposalFileObject"
      //     : "signedAgreementFileObject";

      let fieldName;
      if (fileType === "signedproposal") {
        fieldName = "signedProposalFileObject";
      } else if (fileType === "signedagreement") {
        fieldName = "signedAgreementFileObject";
      } else if (fileType === "invoice") {
        fieldName = "invoiceFileObject";
      } else {
        fieldName = "defaultFileObject"; // Optional: default case if none of the conditions are met
      }


      // Update status type based on fileType
      const statusType =
        fileType === "signedproposal"
          ? "proposal updated"
          : "agreement updated";


      const formattedDate = getFormattedIndiaTime();
      const sendparams = {
        status: {
          type: statusType,
          updatedDate: formattedDate,
        },
      };

      // Patch basic data
      await patchData(
        `${CLIENTROUTES.GETCLIENTBASIC}/${data}/basic`,
        sendparams,
        accessToken,
        dispatch
      );

      // Prepare payload for advanced data patching
      const patchDataPayload = {
        [fieldName]: uploadRes?.data,
      };

      // Patch advanced data
      await patchData(
        `clients/client/${data}/advanced`,
        patchDataPayload,
        accessToken,
        dispatch
      );
      GETPROCESSASSOCIATE(currentPage);
    } catch (error) {
      console.log(error);
    }
  };

  const convertToPDFAndSendToAPI = async (props) => {
    try {
      const pdfBlob = await html2pdf()
        .from(props)
        .set({ margin: [10, 20, 10, 20] })
        .output("blob");
      return pdfBlob;
    } catch (error) {
      console.error("Error while converting PDF or sending it to API:", error);
      throw error;
    }
  };

  const HRApopupClose = async (data) => {




    if ((HygieneRatingAudit === "" && GrandTotal === "" && GST === "" && data === "proposal")) {
      toast.error("Update All The Required Field", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (img === "") {
      toast.error("Update All The Required Field", {
        position: toast.POSITION.TOP_RIGHT,
      });

    }





    else {
      const HRA_ProposalData = localStorage.getItem("HRA_ProposalData");
      const parsedFormData = JSON.parse(HRA_ProposalData);

      const HRA_AgreementData = localStorage.getItem(
        "HRA_AgreementLocalStorage"
      );
      const parsedFormDataAgreement = JSON.parse(HRA_AgreementData);

      const GetSpecificUser = PROCESSASSOCIATEVIEWCLIENTLIST.find(
        (item) => item._id === HRASelectedid
      );

      console.log(GetSpecificUser, "GetSpecificUser");

      // let htmlString;

      if (GetSpecificUser.standardsSchemeType === "HRA") {
        let htmlString, proposalFileObjectKey, statusType;
        if (data === "proposal") {
          htmlString = HRA_Proposal({
            GetSpecificUser: GetSpecificUser,
            parsedFormData: parsedFormData,
          });
          proposalFileObjectKey = "proposalFileObject";
          statusType = "proposal generated"; // Default status type
        } else {
          htmlString = HRA_Agreement({
            GetSpecificUser: GetSpecificUser,
            parsedFormDataAgreement: parsedFormDataAgreement,
          });
          proposalFileObjectKey = "agreementFileObject";
          statusType = "agreement generated"; // Update status type
        }

        // Convert HTML string to PDF
        const pdfBlob = await convertToPDFAndSendToAPI(htmlString);

        // Upload PDF to server
        const formData = new FormData();
        formData.append("file", pdfBlob, "generated.pdf");
        const uploadedFile = await FileUpload(
          FILEROUTES.UPLOAD + UserId,
          formData,
          dispatch
        );

        const patchDataPayload = {
          [proposalFileObjectKey]: uploadedFile?.data,
        };

        const params = {
          serviceCharges: {
            hygieneRatingAuditFee: HygieneRatingAudit,
            GST_Charges: GST,
            grandTotal: GrandTotal
          }
        };

        // Check if proposalFileObjectKey is "proposal" and merge params accordingly
        if (proposalFileObjectKey === "proposalFileObject") {
          // Merge params into patchDataPayload
          Object.assign(patchDataPayload, {
            serviceCharges: {
              hygieneRatingAuditFee: params.serviceCharges.hygieneRatingAuditFee,
              GST_Charges: params.serviceCharges.GST_Charges,
              grandTotal: params.serviceCharges.grandTotal
            }
          });
        }

        // Use the payload as needed
        const ClientAdvance = await patchData(
          `${CLIENTROUTES.GETCLIENTADVANCE}${HRASelectedid}/advanced`,
          patchDataPayload,
          accessToken,
          dispatch
        );


        console.log(params, "params")

        const formattedDate = getFormattedIndiaTime();
        const basicPatchParams = {
          status: {
            type: statusType,
            updatedDate: formattedDate,
          },
        };

        // Patch basic data
        await patchData(
          `${CLIENTROUTES.GETCLIENTBASIC}/${HRASelectedid}/basic`,
          basicPatchParams,
          accessToken,
          dispatch
        );

      } else {
        console.log("standardsSchemeType is not HRA");
      }

      setHRApopup(false);



      localStorage.removeItem("HRA_ProposalData");
      localStorage.removeItem("HRA_AgreementLocalStorage");
      GETPROCESSASSOCIATE(currentPage);

      setHRApopup(false);
      setHRApopup1(false);
    }
  };


  const handleSendMail = (Id) => {

    const ClientData = PROCESSASSOCIATEVIEWCLIENTLIST.find(e => e._id === Id);
    console.log(ClientData);

    const HTML_DATA = {
      ClientName: ClientData?.companyName,
      ContactPerson: ClientData?.contactPerson,
      ClientID: ClientData?.clientId,
      Message: "Kindly Check the proposal pdf",
    };

    postData(
      EMAIL_ROUTES.SEND_EMAIL_FILE,
      {
        to: ClientData?.email,
        subject: "HRA Proposal",
        html: CommonEmailHRA(HTML_DATA),
        attachments: [
          {
            filename: ClientData?.ClientFormHRA_Data[0]?.proposalFileObject?.filename,
            path: ClientData?.ClientFormHRA_Data[0]?.proposalFileObject?.path,
          },
          {
            filename: ClientData?.ClientFormHRA_Data[0]?.agreementFileObject?.filename,
            path: ClientData?.ClientFormHRA_Data[0]?.agreementFileObject?.path,
          },
        ],
      },
      accessToken,
      dispatch
    );
  }



  const HRALocalStorage = () => {
    const dataToStore = {
      HygieneRatingAudit,
      GrandTotal,
      GST,
    };
    const jsonData = JSON.stringify(dataToStore);
    localStorage.setItem("HRA_ProposalData", jsonData);
  };
  HRALocalStorage();

  const HRA_AgreementLocalStorage = () => {
    const dataToStore = {
      img,
    };
    const jsonData = JSON.stringify(dataToStore);
    localStorage.setItem("HRA_AgreementLocalStorage", jsonData);
  };
  HRA_AgreementLocalStorage();

  const handleExport = () => {
    const headers = [
      "Client Id",
      "Company Name",
      "Location",
      "State",
      "Status",
      "PhoneNumber",
      "Email",
      "Address",
      "Scheme",

    ].filter(Boolean);
    // Filter checked items
    const checkedItems = PROCESSASSOCIATEVIEWCLIENTLIST.filter(
      (item) => isChecked[item._id]

    );
    let data;
    data = checkedItems.map((item) => {
      return [
        item.clientId,
        item.companyName,
        item.address,
        item.state,
        item.status.type,
        item.phoneNumber,
        item.email,
        item.address,
        item.standardsSchemeType
      ]
    });

    const worksheet = XLSX.utils.aoa_to_sheet([headers, ...data]);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Client List Data");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    saveAs(
      new Blob([excelBuffer], { type: "application/octet-stream" }),
      "client_list_data.xlsx"
    );

  }


  const [filter, SetFilter] = useState(false);
  const ChangeFilter = () => {
    SetFilter(!filter);
  };
  useEffect(() => {
    if (!filter) {
      GETPROCESSASSOCIATE(currentPage);
    }
  }, [filter]);



  const [isChecked, setIsChecked] = useState({});
  const [selectAllChecked, setSelectAllChecked] = useState(false);

  // Function to handle checkbox change
  const handleCheckboxChange = (event, id) => {
    setIsChecked({ ...isChecked, [id]: event.target.checked });
  };
  // Function to handle select all checkbox change
  const handleSelectAllCheckboxChange = (event) => {
    const { checked } = event.target;
    const updatedIsChecked = {};

    // Update isChecked object with all checkboxes' states
    PROCESSASSOCIATEVIEWCLIENTLIST.forEach((row) => {
      updatedIsChecked[row._id] = checked;
    });

    setIsChecked(updatedIsChecked);
    setSelectAllChecked(checked);
  };

  const [viewClient, setViewClient] = useState(false);
  const handleViewClient = () => {
    setViewClient(!viewClient);
    setSpecificClientId(null);
    GETPROCESSASSOCIATE(currentPage);
  };

  const [specificClientId, setSpecificClientId] = useState(null);

  const [specificClientData, setSpecificClientData] = useState();
  useEffect(() => {
    if (specificClientId !== null) {
      // Find the specific client data based on specificClientId
      const client = PROCESSASSOCIATEVIEWCLIENTLIST.find(
        (client) => client._id === specificClientId
      );
      // If client is found, set it in the state
      if (client) {
        setSpecificClientData(client);
      }
      setViewClient(true);
    }
  }, [specificClientId]);


  return (
    <>
      <ToastContainer />
      {!viewClient ? (
        <div>
          <Stack direction="row" alignItems="center" justifyContent="end">
            <Dialog
              open={HRApopup}
              onClose={(e) => HRApopupClose("proposal")}
              BackdropProps={{ onClick: (event) => event.stopPropagation() }}
            >
              <DialogTitle>Additional Info </DialogTitle>
              <DialogContent className="d-flex flex-wrap justify-content-start pt-3">
                <div className="col-4 ">
                  <TextField
                    type="number"
                    className="m-1"
                    name=""
                    label="Hygiene Rating Audit"
                    value={HygieneRatingAudit}
                    size="small"
                    onChange={handleHygieneRatingAuditChange}
                  />
                </div>
                <div className="col-4 ">
                  <TextField
                    type="number"
                    className="m-1"
                    name=""
                    label="GST 18%"
                    value={GST}
                    size="small"
                    onChange={handleGSTChange}
                  />
                </div>
                <div className="col-4 ">
                  <TextField
                    type="number"
                    className="m-1"
                    name=""
                    label="Grand Total"
                    value={GrandTotal}
                    size="small"
                    onChange={(e) => setGrandTotal(e.target.value)}
                  />
                </div>
              </DialogContent>
              <DialogActions>
                <div className="d-flex mt-5">
                  <Button
                    variant="contained"
                    className="mx-3"
                    onClick={(e) => HRApopupClose("proposal")}
                  >
                    Submit
                  </Button>
                </div>
              </DialogActions>
            </Dialog>
          </Stack>

          <Stack direction="row" alignItems="center" justifyContent="end">
            <Dialog
              open={HRApopup1}
              onClose={(e) => HRApopupClose()}
              BackdropProps={{ onClick: (event) => event.stopPropagation() }}
            >
              <DialogTitle>Additional Info </DialogTitle>
              <DialogContent className="d-flex flex-wrap justify-content-start pt-3">
                <div>
                  <input type="file" onChange={handleChanges} />
                  <br />
                  <img src={img} height="200px" width="200px" />
                </div>
              </DialogContent>
              <DialogActions>
                <div className="d-flex mt-5">
                  <Button
                    variant="contained"
                    className="mx-3"
                    onClick={(e) => HRApopupClose()}
                  >
                    Submit
                  </Button>
                </div>
              </DialogActions>
            </Dialog>
          </Stack>
          <div className="cso-clientlist-border p-5">
            <div className="px-2 add-title">
              <span className="">Client List</span>
            </div>
            <div className="d-flex justify-content-end mb-3">
              <>
                <Button
                  variant="contained"
                  className="btn-orange text-dark"
                  onClick={handleExport}
                >
                  Export
                </Button>
                <Button className="btn-green text-white" onClick={ChangeFilter}>
                  {filter ? "Close Filter" : "Show Filter"}
                </Button>
              </>
            </div>
            {filter && <ProcessAssociateFilter />}
            {/* <div className="d-flex justify-content-end mb-3">
          <Button variant="contained" className="btn-orange text-dark me-0">
            Export
          </Button>
        </div> */}

            <Card>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        className="cl-green text-dark"
                        style={{ padding: "5px" }}
                      >
                        <Checkbox
                          checked={selectAllChecked}
                          onChange={handleSelectAllCheckboxChange}
                        />
                      </TableCell>
                      <TableCell className="cl-green text-dark">
                        Client Id
                      </TableCell>
                      <TableCell className="cl-green text-dark">
                        Company Name
                      </TableCell>
                      <TableCell className="cl-green text-dark">Scheme</TableCell>
                      <TableCell className="cl-green text-dark">Location</TableCell>

                      <TableCell className="cl-green text-dark" align="center">
                        Status
                      </TableCell>
                      <TableCell className="cl-green text-dark" align="center">
                        Proposal
                      </TableCell>
                      <TableCell className="cl-green text-dark" align="center">
                        Proposal upload / view
                      </TableCell>
                      <TableCell className="cl-green text-dark" align="center">
                        Agreement
                      </TableCell>
                      <TableCell className="cl-green text-dark" align="center">
                        Agreement upload / view
                      </TableCell>

                      {/* <TableCell className="cl-green text-dark" align="center">
                    Invoice
                  </TableCell> */}
                      <TableCell className="cl-green text-dark" align="center">
                        Invoice view
                      </TableCell>
                      <TableCell className="cl-green text-dark" align="center">
                        Feedback
                      </TableCell>
                      <TableCell className="cl-green text-dark" align="center">
                        Action
                      </TableCell>
                      <TableCell className="cl-green text-dark" align="center">
                        Send Mail
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {PROCESSASSOCIATEVIEWCLIENTLIST &&
                      PROCESSASSOCIATEVIEWCLIENTLIST.map((row, index) => {
                        const {
                          _id,
                          clientId,
                          ClientFormHRA_Data,
                          companyName,
                          technicalCode,
                          standardsSchemeType,
                          state,
                          country,
                          status,
                        } = row;
                        return (
                          <TableRow key={_id}>
                            <TableCell padding="checkbox">
                              <Checkbox
                                checked={isChecked[_id] || false}
                                onChange={(event) =>
                                  handleCheckboxChange(event, _id)
                                }
                              />
                            </TableCell>
                            <TableCell>{clientId}</TableCell>
                            <TableCell>{companyName}</TableCell>
                            {/* <TableCell>{technicalCode.mainCode}</TableCell>
                        <TableCell>{technicalCode.subCode}</TableCell> */}
                            <TableCell>{standardsSchemeType}</TableCell>
                            <TableCell>
                              {state}/{country}
                            </TableCell>
                            <TableCell align="center">
                              {typeof status === "object" ? status.type : status}
                            </TableCell>

                            <TableCell>
                              <Button
                                className={`btn ${status !== "client advance completed"
                                  ? "btn-green-disable"
                                  : "btn-green"
                                  }`}
                                key={_id}
                                id={`${_id}`}
                                onClick={
                                  status.type === "client advance completed"
                                    ? () => HRApopupOpen(_id)
                                    : undefined
                                }
                                disabled={
                                  status.type !== "client advance completed"
                                }
                              >
                                Generate
                              </Button>
                            </TableCell>

                            <TableCell className="d-flex justify-content-center align-items-center">
                              <div className="d-flex justify-content-center">
                                {ClientFormHRA_Data &&
                                  ClientFormHRA_Data[0] &&
                                  ClientFormHRA_Data[0].proposalFileObject &&
                                  ClientFormHRA_Data[0].proposalFileObject ? (
                                  <div key={_id} id={`${_id}`}>
                                    <label
                                      htmlFor={`fileInputProposal${_id}`}
                                      className="text-success p-4 fw-bold"
                                      style={{ cursor: "pointer" }}
                                    >
                                      <Iconify icon={"material-symbols:upload-rounded"} />
                                    </label>
                                    <input
                                      id={`fileInputProposal${_id}`}
                                      style={{ display: "none" }}
                                      type="file"
                                      onChange={(event) =>
                                        handleSignedFileUploadHRA(
                                          event,
                                          _id,
                                          "signedproposal"
                                        )
                                      }
                                    />
                                  </div>
                                ) : (

                                  <Tooltip
                                    title={"Fill The Advance Details"}
                                  >
                                    <Button
                                      sx={{ m: 1 }}
                                      className="text-danger"
                                    >
                                      <Iconify icon={"material-symbols:file-upload-off-rounded"} />
                                    </Button>
                                  </Tooltip>
                                )}
                              </div>

                              <div className="d-flex justify-content-center">
                                {ClientFormHRA_Data &&
                                  ClientFormHRA_Data[0] &&
                                  ClientFormHRA_Data[0].signedProposalFileObject &&
                                  ClientFormHRA_Data[0].signedProposalFileObject ? (
                                  <a
                                    rel="noreferrer"
                                    href={`${SERVER_URL}/${ClientFormHRA_Data[0].signedProposalFileObject.uri}`}
                                    target="_blank"
                                  >
                                    <Iconify icon={"basil:eye-solid"} />
                                  </a>
                                ) : (
                                  <Iconify icon={"basil:eye-closed-solid"} />
                                )}
                              </div>
                            </TableCell>
                            <TableCell>
                              <Button
                                className={`btn ${status !== "client advance completed"
                                  ? "btn-green-disable"
                                  : "btn-green"
                                  }`}
                                key={_id}
                                id={`${_id}`}
                                onClick={
                                  status.type === "proposal updated"
                                    ? () => HRApopupOpen1(_id)
                                    : undefined
                                }
                                disabled={status.type !== "proposal updated"}
                              >
                                Generate
                              </Button>
                            </TableCell>

                            <TableCell className="d-flex justify-content-center align-items-center">
                              <div className="d-flex justify-content-center">
                                {ClientFormHRA_Data &&
                                  ClientFormHRA_Data[0] &&
                                  ClientFormHRA_Data[0].agreementFileObject &&
                                  ClientFormHRA_Data[0].agreementFileObject ? (
                                  <div key={_id} id={`${_id}`}>
                                    <label
                                      htmlFor={`fileInputAgreement${_id}`}
                                      className="text-success p-4 fw-bold"
                                      style={{ cursor: "pointer" }}
                                    >
                                      <Iconify icon={"material-symbols:upload-rounded"} />
                                    </label>
                                    <input
                                      id={`fileInputAgreement${_id}`}
                                      style={{ display: "none" }}
                                      type="file"
                                      onChange={(event) =>
                                        handleSignedFileUploadHRA(
                                          event,
                                          _id,
                                          "signedagreement"
                                        )
                                      }
                                    />
                                  </div>
                                ) : (

                                  <Tooltip
                                    title={"Fill The Advance Details"}
                                  >
                                    <Button
                                      sx={{ m: 1 }}
                                      className="text-danger"
                                    >
                                      <Iconify icon={"material-symbols:file-upload-off-rounded"} />
                                    </Button>
                                  </Tooltip>
                                )}
                              </div>

                              <div className="d-flex justify-content-center">
                                {ClientFormHRA_Data &&
                                  ClientFormHRA_Data[0] &&
                                  ClientFormHRA_Data[0].signedAgreementFileObject &&
                                  ClientFormHRA_Data[0].signedAgreementFileObject ? (
                                  <a
                                    rel="noreferrer"
                                    href={`${SERVER_URL}/${ClientFormHRA_Data[0].signedAgreementFileObject.uri}`}
                                    target="_blank"
                                  >
                                    <Iconify icon={"basil:eye-solid"} />
                                  </a>
                                ) : (
                                  <Iconify icon={"basil:eye-closed-solid"} />
                                )}
                              </div>
                            </TableCell>
                            {/* <TableCell>
                              <div className="d-flex align-items-center">
                                <div className="d-flex justify-content-center">
                                  {ClientFormHRA_Data &&
                                    ClientFormHRA_Data[0] &&
                                    ClientFormHRA_Data[0].invoiceFileObject &&
                                    ClientFormHRA_Data[0].invoiceFileObject ? (
                                    <div key={_id} id={`${_id}`}>
                                      <label
                                        htmlFor={`fileInputAgreement${_id}`}
                                        className="text-success p-4 fw-bold"
                                        style={{ cursor: "pointer" }}
                                      >
                                        <Iconify icon={"material-symbols:upload-rounded"} />
                                      </label>
                                      <input
                                        id={`fileInputAgreement${_id}`}
                                        style={{ display: "none" }}
                                        type="file"
                                        onChange={(event) =>
                                          handleSignedFileUploadHRA(
                                            event,
                                            _id,
                                            "invoice"
                                          )
                                        }
                                      />
                                    </div>
                                  ) : (

                                    <Tooltip
                                      title={"Fill The Advance Details"}
                                    >
                                      <Button
                                        sx={{ m: 1 }}
                                        className="text-danger"
                                      >
                                        <Iconify icon={"material-symbols:file-upload-off-rounded"} />
                                      </Button>
                                    </Tooltip>
                                  )}
                                </div>

                                <div className="d-flex justify-content-center">
                                  {ClientFormHRA_Data &&
                                    ClientFormHRA_Data[0] &&
                                    ClientFormHRA_Data[0].invoiceFileObject &&
                                    ClientFormHRA_Data[0].invoiceFileObject ? (
                                    <a
                                      rel="noreferrer"
                                      href={`${SERVER_URL}/${ClientFormHRA_Data[0].invoiceFileObject.uri}`}
                                      target="_blank"
                                    >
                                      <Iconify icon={"basil:eye-solid"} />
                                    </a>
                                  ) : (
                                    <Iconify icon={"basil:eye-closed-solid"} />
                                  )}
                                </div>

                              </div>
                            </TableCell> */}
                            <TableCell>
                              <div className="d-flex align-items-center">
                                <div className="d-flex justify-content-center">
                                  <div key={_id} id={`${_id}`}>
                                    <label
                                      htmlFor={`fileInputAgreementInvoice${_id}`}
                                      className="text-success p-4 fw-bold"
                                      style={{ cursor: "pointer" }}
                                    >
                                      <Iconify icon={"material-symbols:upload-rounded"} />
                                    </label>
                                    <input
                                      id={`fileInputAgreementInvoice${_id}`}
                                      style={{ display: "none" }}
                                      type="file"
                                      onChange={(event) =>
                                        handleSignedFileUploadHRA(event, _id, "invoice")
                                      }
                                    />
                                  </div>
                                </div>

                                <div className="d-flex justify-content-center">
                                  {ClientFormHRA_Data &&
                                    ClientFormHRA_Data[0] &&
                                    ClientFormHRA_Data[0].invoiceFileObject ? (
                                    <a
                                      rel="noreferrer"
                                      href={`${SERVER_URL}/${ClientFormHRA_Data[0].invoiceFileObject.uri}`}
                                      target="_blank"
                                    >
                                      <Iconify icon={"basil:eye-solid"} />
                                    </a>
                                  ) : (
                                    <Iconify icon={"basil:eye-closed-solid"} />
                                  )}
                                </div>
                              </div>
                            </TableCell>



                            <TableCell>
                              <div className="col-4 d-flex">
                                <div className="ms-4 mb-0 col-4">
                                  <label
                                    htmlFor="file-input-1"
                                    className="d-flex justify-content-between w-100 py-1"
                                  >
                                    <span>
                                      {" "}
                                      <Iconify icon={"material-symbols:upload-rounded"} />
                                    </span>

                                  </label>
                                  <input
                                    id="file-input-1"
                                    type="file"
                                    className="Upload-file"
                                    onChange={(e) => handleSignAndSealFileChange(e, row)}
                                  />
                                </div>
                              </div>
                            </TableCell>

                            <TableCell align="center">
                              {row.status !== "pending" ||
                                row.status.type !== "pending" ? (
                                <Iconify
                                  style={{ cursor: "pointer" }}
                                  className="cursor-pointer"
                                  icon={"raphael:view"}
                                  onClick={() => {
                                    setSpecificClientId(row._id);
                                  }}
                                />
                              ) : (
                                "-"
                              )}
                            </TableCell>
                            <TableCell>
                              <Button onClick={(e) => handleSendMail(row._id)}>Send mail</Button>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <div className="d-flex flex-wrap justify-content-end m-3">
                <FormControl variant="outlined" size="small">
                  <InputLabel id="limit-label">Limit</InputLabel>
                  <Select
                    labelId="limit-label"
                    id="limit-select"
                    value={limit}
                    onChange={handleLimitChange}
                  >
                    <MenuItem value={5}>5</MenuItem>
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={15}>15</MenuItem>
                  </Select>
                </FormControl>
                <Button onClick={handlePrevPage} disabled={currentPage === 1}>
                  <Iconify icon={"grommet-icons:previous"} />
                </Button>
                <Button
                  onClick={handleNextPage}
                  disabled={currentPage === totalPages}
                >
                  <Iconify icon={"grommet-icons:next"} />
                </Button>
              </div>
            </Card>
            <Popover
              open={Boolean(open)}
              anchorEl={open}
              onClose={handleCloseMenu}
              anchorOrigin={{ vertical: "top", horizontal: "left" }}
              transformOrigin={{ vertical: "top", horizontal: "right" }}
              PaperProps={{
                sx: {
                  p: 1,
                  width: 140,
                  "& .MuiMenuItem-root": {
                    px: 1,
                    typography: "body2",
                    borderRadius: 0.75,
                  },
                },
              }}
            >
              <MenuItem>
                <Iconify icon={"eva:edit-fill"} sx={{ mr: 2 }} />
                Edit
              </MenuItem>
              <MenuItem>
                <Iconify icon={"raphael:view"} sx={{ mr: 2 }} />
                View
              </MenuItem>

              <MenuItem sx={{ color: "error.main" }}>
                <Iconify icon={"eva:trash-2-outline"} sx={{ mr: 2 }} />
                Delete
              </MenuItem>
            </Popover>
          </div>
        </div>
      ) : (
        <ClientBasicAdvance
          handleViewClient={handleViewClient}
          specificClientData={specificClientData}
        />
      )}
    </>
  );
}
