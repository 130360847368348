import { configureStore } from "@reduxjs/toolkit";
import LogInSlice from "../redux/slices/LoginSlice";
import UserDetailReducer from "../redux/slices/UserDetailSlice";
import ClientSliceReducer from "../redux/slices/ClientSlice";
import SpecificClientSliceReducer from "../redux/slices/SpecificClient";
import CSOReducer from "../redux/slices/CSOSlice";
import ReviewerReducer from "../redux/slices/ReviewerSlice";
import PlannerReducer from "../redux/slices/PlannerSlice";
import CertificateReducer from "../redux/slices/CertificateSlice";
import FeeReducer from "../redux/slices/FeeSlice";
import UpcomingReducer from "./slices/UpcomingSlice";
import SelectedClientReducer from "./slices/SelectedClientSlice";
import SalesNameListReducer from "./slices/SalesListSlice";
import PlannerNameListReducer from "./slices/PlannerListSlice";
import ReviewerNameListReducer from "./slices/ReviewerListSlice";
import AuditorNameListReducer from "./slices/AuditorListSlice";
import LoaderReducer from "../redux/slices/LoaderSlice";
import NcReducer from "../redux/slices/NC";
import CheckListReducer from "../redux/slices/CheckList"
export const store = configureStore({
  reducer: {
    project: LogInSlice,
    userDetail: UserDetailReducer,
    client: ClientSliceReducer,
    SpecificClient: SpecificClientSliceReducer,
    CSO: CSOReducer,
    Reviewer: ReviewerReducer,
    Planner: PlannerReducer,
    certificate: CertificateReducer,
    Fee: FeeReducer,
    Upcoming: UpcomingReducer,
    SelectedClient: SelectedClientReducer,
    SalesList: SalesNameListReducer,
    AuditorList: AuditorNameListReducer,
    PlannerList: PlannerNameListReducer,
    ReviewerList: ReviewerNameListReducer,
    NC: NcReducer,
    Loader: LoaderReducer,
    CHECKLIST: CheckListReducer,
  },
});
