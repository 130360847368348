import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";

import Iconify from "../../iconify/Iconify";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getData } from "src/webService/webService";
import CSOROUTES from "src/API_Routes/CSO/CSO_Routers";
import { useDispatch, useSelector } from "react-redux";
import { setClientBasicandAdvance } from "src/redux/slices/CSOSlice";
import TableData from "../TableData";
import SpecificUserBasic from "../../../CommonFunction/GetClientBasic";
import SpecificUserAdvance from "../../../CommonFunction/GetClientAdvance";
import AddClientForm from "../../Sales/Clients-list/Form/Form";
export default function ClientList() {
  const dispatch = useDispatch();
  const CSOVIEWCLIENTLIST = useSelector(
    (state) => state?.CSO?.ClientBasicandAdvance[0]?.paginatedResults
  );
  const accessToken = sessionStorage.getItem("Token");
  const [open, setOpen] = useState(false);
  // Table Conditions
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit, setLimit] = useState(5);
  const [selectedId, setSelectedId] = useState();
  const [specificClient, setSpecificClient] = useState();
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };
  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const handleLimitChange = (event) => {
    setCurrentPage(1);
    setLimit(event.target.value);
  };
  // Table Conditions
  const GETCSO = async (page) => {
    const params = {
      page: page,
      limit: limit,
    };
    try {
      const res = await getData(
        CSOROUTES.GETCSOCLIENTBASICANDADVANCE,
        params,
        accessToken
      );
      if (res.status === 200) {
        dispatch(setClientBasicandAdvance(res.data));
        setTotalPages(res.data[0].totalPages);
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("An error occurred. Please try again later.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  const handleOpenMenu = (event, id) => {
    const SelectClientdata = CSOVIEWCLIENTLIST.find(
      (client) => client._id === id
    );
    setSelectedId(id);
    setSpecificClient(SelectClientdata);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setSelectedId();
  };
  useEffect(() => {
    GETCSO(currentPage);
  }, [currentPage, limit]);

  const [viewClient, setViewClient] = useState(false);
  const handleViewClient = (data) => {
    SpecificUserBasic(data, dispatch);
    SpecificUserAdvance(data, dispatch);
    setViewClient(true);
  };
  const CloseclientView = () => {
    setViewClient(false);
  };

  return (
    <>
      <ToastContainer />
      {viewClient && (
        <>
          <div className="d-flex justify-content-end">
            <Button
              onClick={CloseclientView}
              variant="contained"
              className="btn-orange"
            >
              Close
            </Button>
          </div>
          <AddClientForm action="View" />
        </>
      )}
      {!viewClient && (
        <div className="cso-clientlist-border p-5">
          <div className="px-2 add-title">
            <span className="">
              {selectedId ? specificClient.clientId : "Client List"}
            </span>
          </div>
          {selectedId ? (
            <>
              <div className="d-flex justify-content-end">
                <Button
                  onClick={() => handleClose()}
                  variant="contained"
                  className="btn-orange text-white"
                >
                  Close
                </Button>
              </div>

              <TableData
                specificClient={specificClient}
                selectedId={selectedId}
              />
            </>
          ) : (
            <Card>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell className="cl-green text-dark">
                        Client Id
                      </TableCell>
                      <TableCell className="cl-green text-dark">
                        Company Name
                      </TableCell>
                      <TableCell className="cl-green text-dark">
                        Technical Main Code
                      </TableCell>
                      <TableCell className="cl-green text-dark">
                        Technical Sub Code
                      </TableCell>
                      <TableCell className="cl-green text-dark">
                        Scheme
                      </TableCell>
                      <TableCell className="cl-green text-dark">
                        Location
                      </TableCell>
                      <TableCell className="cl-green text-dark" align="center">
                        Status
                      </TableCell>
                      <TableCell className="cl-green text-dark" align="center">
                        Edit
                      </TableCell>
                      <TableCell className="cl-green text-dark" align="center">
                        View
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(!CSOVIEWCLIENTLIST || CSOVIEWCLIENTLIST.length === 0) && (
                      <p className="mx-3">No Data</p>
                    )}
                    {CSOVIEWCLIENTLIST &&
                      CSOVIEWCLIENTLIST.map((row) => {
                        const {
                          _id,
                          companyName,
                          technicalCode,
                          standardsSchemeType,
                          state,
                          country,
                          status,
                          clientId,
                        } = row;
                        return (
                          <TableRow key={_id}>
                            <TableCell>{clientId}</TableCell>
                            <TableCell>{companyName}</TableCell>
                            <TableCell>{technicalCode.mainCode}</TableCell>
                            <TableCell>{technicalCode.subCode}</TableCell>
                            <TableCell>{standardsSchemeType}</TableCell>
                            <TableCell>
                              {state}/{country}
                            </TableCell>
                            <TableCell align="center">{status.type}</TableCell>
                            <TableCell align="center">
                              <Iconify
                                style={{ cursor: "pointer" }}
                                onClick={(event) => handleOpenMenu(event, _id)}
                                icon={"eva:edit-fill"}
                                sx={{ mr: 2 }}
                              />
                            </TableCell>
                            <TableCell align="center">
                              <Iconify
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  handleViewClient(_id);
                                }}
                                icon={"raphael:view"}
                                sx={{ mr: 2 }}
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <div className="d-flex flex-wrap justify-content-end m-3">
                <FormControl variant="outlined" size="small">
                  <InputLabel id="limit-label">Limit</InputLabel>
                  <Select
                    labelId="limit-label"
                    id="limit-select"
                    value={limit}
                    onChange={handleLimitChange}
                  >
                    <MenuItem value={5}>5</MenuItem>
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={15}>15</MenuItem>
                  </Select>
                </FormControl>
                <Button onClick={handlePrevPage} disabled={currentPage === 1}>
                  <Iconify icon={"grommet-icons:previous"} />
                </Button>
                <Button
                  onClick={handleNextPage}
                  disabled={currentPage === totalPages}
                >
                  <Iconify icon={"grommet-icons:next"} />
                </Button>
              </div>
            </Card>
          )}
        </div>
      )}
    </>
  );
}
