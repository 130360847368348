import React, { useEffect, useState } from "react";

import DialogActions from "@mui/material/DialogActions";
import { Button } from "@mui/material";
import Iconify from "../iconify";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getData } from "src/webService/webService";
import EVENT_TRACKING_ROUTES from "src/API_Routes/EventTracking/EventTracking_Routers";
function Event_tracking({ ClientBasic }) {
  const accessToken = sessionStorage.getItem("Token");
  const [Events, setEvents] = useState();
  const getEvent = async () => {
    const res = await getData(
      EVENT_TRACKING_ROUTES.CREATE_EVENT_HISTORY(ClientBasic?._id),
      null,
      accessToken
    );
    setEvents(res.data);
    console.log(res);
  };
  useEffect(() => {
    getEvent();
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString(undefined, {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true,
    });
  };

  return (
    <div>
      <ToastContainer />
      <div className="TrainingCertifications-border p-5">
        <div className="px-2 add-title">
          <span className="">Event tracking</span>
        </div>
        <div>
          {Events && Events.length !== 0 ? (
            <ul>
              {Events.map((item, index) => (
                <li key={index} className="mb-2">
                  <p style={{ fontWeight: "bold" }} className="mb-0">
                    {item.description}
                  </p>
                  <span>{item.performedBy}</span> -{" "}
                  <span>{formatDate(item.performedOn)}</span>
                </li>
              ))}
            </ul>
          ) : (
            <p>No Events</p>
          )}
        </div>
      </div>
    </div>
  );
}

export default Event_tracking;
