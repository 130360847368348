import { useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
// @mui
import { styled } from "@mui/material/styles";
//
import Header from "./header";
import Nav from "./nav";

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const StyledRoot = styled("div")({
  display: "flex",
  minHeight: "100%",
  overflow: "hidden",
});

const Main = styled("div")(({ theme }) => ({
  flexGrow: 1,
  overflow: "auto",
  minHeight: "100%",
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up("lg")]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
  },
}));

// ----------------------------------------------------------------------

export default function DashboardLayout({ onLogout }) {
  const location = useLocation();
  // Check if the current path does not end with "admin/clients"
  const shouldHaveMargin = !location.pathname.endsWith("/admin/clients");
  const [open, setOpen] = useState(false);

  return (
    <>
      <StyledRoot>
        <Header onLogout={onLogout} onOpenNav={() => setOpen(true)} />
        {/* <Nav openNav={open} onCloseNav={() => setOpen(false)} /> */}
        <Main className={shouldHaveMargin ? "mt-5" : ""}>
          <Outlet />
        </Main>
      </StyledRoot>
    </>
  );
}
