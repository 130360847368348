import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import moment from "moment-timezone";

const EventDialog = ({ open, handleClose, events }) => {
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString(undefined, {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true,
    });
  };
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Event List</DialogTitle>
      <DialogContent className="d-flex flex-wrap justify-content-between">
        <div>
          {events && events.length !== 0 ? (
            <ul>
              {events.map((item, index) => (
                <li key={index} className="mb-2">
                  <p style={{ fontWeight: "bold" }} className="mb-0">
                    {item.description}
                  </p>
                  <span>{item.performedBy}</span> -{" "}
                  <span>
                    {moment(item.performedOn)
                      .tz("Asia/Kolkata")
                      .format("YYYY-MM-DD / HH:mm:ss")}
                  </span>
                </li>
              ))}
            </ul>
          ) : (
            <p>No Events</p>
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <div className="d-flex mt-5">
          <Button variant="contained" className="mx-3" onClick={handleClose}>
            Close
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default EventDialog;
