import React, { useState } from "react";
import { Tabs, Tab, Box, Typography } from "@mui/material";
import Clients from "../Sale/Clients";
import CSOClients from "../CSO/Clients";
import AccountClients from "../Account/Clients";
import CSOCertificate from "../CSO/Certificate";
import CSOAudit from "../CSO/Audit";
import PlannerAudit from "../Planner/Audit";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function AdminClient() {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="admin client tabs"
        variant="fullWidth" // Ensures all tabs are displayed in the header
        sx={{
          marginTop: "9px",
          marginBottom: "10px",
          backgroundColor: "#fcdf8b",
          "& .MuiTab-root": {
            color: "black !important",
            fontWeight: "bold",
          },
          "& .MuiTabs-indicator": {
            backgroundColor: "transparent", // Removes the underline for active tab
          },
          "& .Mui-selected": {
            backgroundColor: "#d66d09 !important",
            color: "white !important",
          },
        }}
      >
        <Tab label="Sales" />
        <Tab label="CSO" />
        <Tab label="Planner / Audit / Reviewer" />
        <Tab label="Accounts" />
        <Tab label="Certificate" />
      </Tabs>
      <TabPanel value={value} index={0}>
        <Clients />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <CSOClients />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <PlannerAudit />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <AccountClients />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <CSOCertificate />
      </TabPanel>
    </div>
  );
}

export default AdminClient;
