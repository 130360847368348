import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import { getData } from "../../webService/webService";

const StatusColumnChart = () => {
  const accessToken = sessionStorage.getItem("Token");
  const [statusCounts, setStatusCounts] = useState({
    pending: 0,
    "client details filled": 0,
    "reviewer assigned": 0,
    "need revision": 0,
    "revision resolved": 0,
    approved: 0,
    rejected: 0,
    "auditor assigned": 0,
    "technical review completed": 0,
    // "certificate completed": 0,
  });
  const getStatusCount = async () => {
    try {
      const response = await getData(
        `clients/status/count?scheme=CB`,
        null,
        accessToken
      );
      // Update status count based on API response
      const newStatusCounts = { ...statusCounts };
      response.data.forEach((item) => {
        newStatusCounts[item.status] = item.count;
      });
      setStatusCounts(newStatusCounts);
    } catch (error) {
      console.error("Error fetching status counts:", error);
    }
  };
  useEffect(() => {
    getStatusCount();
  }, []);

  // Extract status names and counts for chart categories and series data
  const categories = Object.keys(statusCounts);
  const seriesData = Object.values(statusCounts);

  // Format y-axis labels to display as is
  const formatYAxisLabel = (value) => {
    return value;
  };

  // State for options
  const options = {
    chart: {
      type: "bar",
    },
    xaxis: {
      categories: categories,
    },
    yaxis: {
      labels: {
        formatter: formatYAxisLabel,
      },
    },
  };

  // State for series
  const series = [
    {
      name: "Count",
      data: seriesData,
    },
  ];

  return (
    <div className="chart">
      <Chart options={options} series={series} type="bar" height={350} />
    </div>
  );
};

export default StatusColumnChart;
