// ----------------------------------------------------------------------


const account = {
  displayName: "UserName",
  role: "Administrator",
  email: "UserMail",
  photoURL: "/assets/images/avatars/avatar_default.jpg",
};

export default account;
