import CAMS_URL from '../../Config/webApp/URL';

const FEEDBACK_FORM_ROUTES = {
    CREATE_FEEDBACK_FORM: ( SCHEME ) => `feedbackform/${SCHEME}`,
    GET_FEEDBACK_FORM_BY_SCHEME_TOKEN: ( SCHEME, TOKEN ) => `feedbackform/${SCHEME}?token=${TOKEN}`,
    GET_FEEDBACK_FORM_CB_PAGE: ( TOKEN ) => `${CAMS_URL}form/feedback/cb?token=${TOKEN}`,
    GET_FEEDBACK_FORM_HRA_PAGE: ( TOKEN ) => `${CAMS_URL}form/feedback/hra?token=${TOKEN}`,
    
    GET_FEEDBACK_FORMS_CB: ( SCHEME ) => `feedbackform/all/${SCHEME}`,
    GET_FEEDBACK_FORMS_HRA: ( SCHEME ) => `feedbackform/all/${SCHEME}`,

    GET_FEEDBACK_FORM_BY_SCHEME_ID: ( SCHEME, ID ) => `feedbackform/feedbackform/${SCHEME}/${ID}`,

    UPDATE_FEEDBACK_FORM_BY_SCHEME_TOKEN: ( SCHEME, TOKEN )  => `feedbackform/${SCHEME}?token=${TOKEN}`
};

export default FEEDBACK_FORM_ROUTES;
