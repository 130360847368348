const HRA_FEEDBACK_FORM_DATA = ( format ) => {
    return {
        evaluation: [
            {
                particulars: 'Were you satisfied with the notice given for the dates of audit?',
                yesOrNo: ''
            },
            {
                particulars: 'Were you provided with an audit plan at the opening meeting?',
                yesOrNo: ''
            },
            {
                particulars: 'Was the purpose of the audit and the auditing process explained to you?',
                yesOrNo: ''
            },
            {
                particulars: 'Were you provided with explanations by the auditors of their observations?',
                yesOrNo: ''
            },
            {
                particulars: 'Quality of the report',
                yesOrNo: ''
            },
            {
                particulars: 'Competences of the auditor/inspector',
                yesOrNo: ''
            },
            {
                particulars: 'Any delay in receiving the report/certificate',
                yesOrNo: ''
            }
        ],
        complaintsAndGrievances: {
            detailsOfTheComplaint: '',
            evidencesInSupportForTheComplaintFileObjects: {}
        },
        declaration: '',
        dateOfAudit: '',
        auditorsName: '',

        clientName: format?.clientName,
        clientAddress: format?.clientAddress,
        clientRepresentativeName: format?.clientRepresentativeName,
        contactNumber: format?.contactNumber,
        email: format?.email,

        signatureAndSeal: format?.signatureAndSeal,
    }
};

export default HRA_FEEDBACK_FORM_DATA;