const data = [
  {
    id: "1",
    ClientName: "Aswin Kumar",
    TechinicalName: "9001",
    Scheme: "IMS",
    Location: "TamilNadu/India",
    S1: "12",
    S2: "10",
    SA1: "20",
    SA2: "12",
    Status: "Pending",
  },
  {
    id: "2",
    ClientName: "Krishna Kumar",
    TechinicalName: "9002",
    Scheme: "IMS",
    Location: "TamilNadu/India",
    S1: "12",
    S2: "10",
    SA1: "20",
    SA2: "12",
    Status: "Approved",
  },
  {
    id: "3",
    ClientName: "Dany",
    TechinicalName: "9003",
    Scheme: "IMS",
    Location: "TamilNadu/India",
    S1: "12",
    S2: "10",
    SA1: "20",
    SA2: "12",
    Status: "Need Revision",
  },
  {
    id: "4",
    ClientName: "Mari vignesh",
    TechinicalName: "9004",
    Scheme: "IMS",
    Location: "TamilNadu/India",
    S1: "12",
    S2: "10",
    SA1: "20",
    SA2: "12",
    Status: "Reject",
  },
  {
    id: "5",
    ClientName: "Selvam",
    TechinicalName: "9005",
    Scheme: "IMS",
    Location: "TamilNadu/India",
    S1: "12",
    S2: "10",
    SA1: "20",
    SA2: "12",
    Status: "Waiting for Reviewer Report",
  },
  {
    id: "6",
    ClientName: "Aswin Kumar",
    TechinicalName: "9001",
    Scheme: "IMS",
    Location: "TamilNadu/India",
    S1: "12",
    S2: "10",
    SA1: "20",
    SA2: "12",
    Status: "Pending",
  },
  {
    id: "7",
    ClientName: "Krishna Kumar",
    TechinicalName: "9002",
    Scheme: "IMS",
    Location: "TamilNadu/India",
    S1: "12",
    S2: "10",
    SA1: "20",
    SA2: "12",
    Status: "Approved",
  },
  {
    id: "8",
    ClientName: "Dany",
    TechinicalName: "9003",
    Scheme: "IMS",
    Location: "TamilNadu/India",
    S1: "12",
    S2: "10",
    SA1: "20",
    SA2: "12",
    Status: "Need Revision",
  },
  {
    id: "9",
    ClientName: "Mari vignesh",
    TechinicalName: "9004",
    Scheme: "IMS",
    Location: "TamilNadu/India",
    S1: "12",
    S2: "10",
    SA1: "20",
    SA2: "12",
    Status: "Reject",
  },
  {
    id: "10",
    ClientName: "Selvam",
    TechinicalName: "9005",
    Scheme: "IMS",
    Location: "TamilNadu/India",
    S1: "12",
    S2: "10",
    SA1: "20",
    SA2: "12",
    Status: "Waiting for Reviewer Report",
  },
  {
    id: "11",
    ClientName: "Selvam",
    TechinicalName: "9005",
    Scheme: "IMS",
    Location: "TamilNadu/India",
    S1: "12",
    S2: "10",
    SA1: "20",
    SA2: "12",
    Status: "Waiting for Reviewer Report",
  },
];
const SaleClient = data.map((item, index) => ({
  id: item.id,
  ClientName: item.ClientName,
  TechinicalName: item.TechinicalName,
  Scheme: item.Scheme,
  Location: item.Location,
  S1: item.S1,
  S2: item.S2,
  SA1: item.SA1,
  SA2: item.SA2,
  Status: item.Status,
}));

export default SaleClient;
