import { Navigate, Routes, Route } from "react-router-dom";
// layouts
import DashboardLayout from "./layouts/dashboard";
//
import UserPage from "./pages/UserPage";
import LoginPage from "./pages/LoginPage";
import Page404 from "./pages/Page404";
import DashboardAppPage from "./pages/DashboardAppPage";
import Skillset from "./pages/Sale/Skillset";
import Clients from "./pages/Sale/Clients";
import SalesDocument from "./pages/Sale/Documents";
import TrainingCertification from "./pages/Sale/TrainingCertification";
import ClientList from "./components/Client/Client-list";
import Sales from "./pages/Admin/Sales";
import CSO from "./pages/Admin/CSO";
import Reviewer from "./pages/Admin/Reviewer";
import Planner from "./pages/Admin/Planner";
import Account from "./pages/Admin/Account";
import Auditor from "./pages/Admin/Auditor";
import ReviewerSkillSet from "./pages/Reviewer/Skillset";
import ReviewerDocument from "./pages/Reviewer/Documents";
import ReviewerTrainingCertification from "./pages/Reviewer/TrainingCertification";
import ReviewerClients from "./pages/Reviewer/Client";
import ReviewerAudit from "./pages/Reviewer/Audit";
import CSODocument from "./pages/CSO/Documents";
import CSOSkillSet from "./pages/CSO/Skillset";
import CSOTrainingCertification from "./pages/CSO/TrainingCertification";
import CSOClients from "./pages/CSO/Clients";
import CSOAudit from "./pages/CSO/Audit";
import AuditorSkillset from "./pages/Auditor/Skillset";
import AuditorCalendar from "./pages/Auditor/Calendar";
import AuditorClients from "./pages/Auditor/Clients";
import AuditorDocument from "./pages/Auditor/Documents";
import AuditorTrainingCertification from "./pages/Auditor/TrainingCertification";
import PlannerSkillset from "./pages/Planner/Skillset";
import PlannerClients from "./pages/Planner/Clients";
import PlannerDocument from "./pages/Planner/Documents";
import PlannerTrainingCertification from "./pages/Planner/TrainingCertification";
import AccountSkillset from "./pages/Account/Skillset";
import AccountClients from "./pages/Account/Clients";
import AccountDocument from "./pages/Account/Documents";
import AccountTrainingCertification from "./pages/Account/TrainingCertification";
import PlannerAudit from "./pages/Planner/Audit";
import SalesHRA from "./pages/OperationManager/Sales";
import ProcessAssociate from "./pages/OperationManager/Processassociate";
import Subjectmatterexpert from "./pages/OperationManager/Subjectmatterexpert";
import AuditHRA from "./pages/OperationManager/Auditor";
import OperationManagerClients from "./pages/OperationManager/Clients";
import SalesHRAClients from "./pages/SalesHRA/Clients";
import SalesHRADocument from "./pages/SalesHRA/Documents";
import TrainingCertificationHRA from "./pages/SalesHRA/TrainingCertification";
import SkillSetHRA from "./pages/SalesHRA/Skillset";
import ProcessAssociateClients from "./pages/Process-Associate-MIS/Clients";
import ProcessAssociateSkillset from "./pages/Process-Associate-MIS/Skillset";
import ProcessAssociateDocument from "./pages/Process-Associate-MIS/Documents";
import ProcessAssociateTrainingCertification from "./pages/Process-Associate-MIS/TrainingCertification";
import SubjectmatterexpertClients from "./pages/Subject-Matter-Expert/Clients";
import SubjectmatterexpertSkillset from "./pages/Subject-Matter-Expert/Skillset";
import SubjectmatterexpertDocument from "./pages/Subject-Matter-Expert/Documents";
import SubjectmatterexpertCertification from "./pages/Subject-Matter-Expert/TrainingCertification";
import CBFeedbackFormPage from "./pages/FeedbackForms/CB-FeedbackForm";

import CB_FeedbackFormById from "./components/CSO/FeedbackForm/FeedbackForm";
import HRA_FeedbackFormById from "./components/Process-Associate-MIS/FeedbackForm/FeedbackForm";

import HRAFeedbackFormPage from "./pages/FeedbackForms/HRA-FeedbackForm";
import SubjectMatterExpertAudit from "./pages/Subject-Matter-Expert/Audit";
import CSOCertificate from "./pages/CSO/Certificate";
import CSOFeedbackForms from "./pages/CSO/FeedbackForm";
import ProcessAssociateFeedbackForms from "./pages/Process-Associate-MIS/FeedbackForm";
// import AdminClients from "./pages/Admin/Clients";
import AuditorSkillSetHRA from "./pages/AuditorHRA/Skillset";
import AuditorCalendarSkillSetHRA from "./pages/AuditorHRA/Calendar";
import AuditorClientsHRA from "./pages/AuditorHRA/Clients";
import AuditorDocumentHRA from "./pages/AuditorHRA/Documents";
import AuditorTrainingCertificationHRA from "./pages/AuditorHRA/TrainingCertification";
import ProcessAssociateAudit from "./pages/Process-Associate-MIS/Audit";
import UpcomingClients from "./pages/Planner/UpcomingClients";
import AdminClient from "./pages/Admin/ClientNew";
import SubjectmatterexpertReviewAudit from "./pages/Subject-Matter-Expert/ReviewAudit";
import OperationmanagerNew from "./pages/OperationManager/OperationManagerNew";
import Admin from "./pages/Admin/Admin";
// ----------------------------------------------------------------------

// export default function Router() {
//   const routes = useRoutes([
//     {
//       path: '/dashboard',
//       element: <DashboardLayout />,
//       children: [
//         { path: 'app', element: <DashboardAppPage /> },
//         { path: 'user', element: <UserPage /> },
//       ],
//     },
//     {
//       path: 'login',
//       element: <LoginPage />,
//     },
//     {
//       element: <SimpleLayout />,
//       children: [
//         { element: <Navigate to="/login" />, index: true },
//         { path: '404', element: <Page404 /> },
//         { path: '*', element: <Navigate to="/404" /> },
//       ],
//     },
//     {
//       path: '*',
//       element: <Navigate to="/404" replace />,
//     },
//   ]);

//   return routes;
// }
const Router = ({ isAuthenticated, onLogin, onLogout }) => {
  return (
    <Routes>
      {isAuthenticated === "Admin" ? (
        <>
          <Route path="*" element={<Navigate to="/admin" />} />
          <Route
            path="/admin"
            element={<DashboardLayout onLogout={onLogout} />}
          >
            <Route path="dashboard" element={<DashboardAppPage />} />
            <Route path="updateadmin" element={<Admin />} />
            <Route path="sales" element={<Sales />} />
            <Route path="cso" element={<CSO />} />
            <Route path="reviewer" element={<Reviewer />} />
            <Route path="planner" element={<Planner />} />
            <Route path="auditor" element={<Auditor />} />
            <Route path="account" element={<Account />} />
            <Route path="clients" element={<AdminClient />} />
          </Route>
        </>
      ) : isAuthenticated === "Sales" ? (
        <>
          <Route path="*" element={<Navigate to="/sale" />} />
          <Route path="/sale" element={<DashboardLayout onLogout={onLogout} />}>
            <Route path="skillset" element={<Skillset />} />
            <Route path="clients" element={<Clients />} />
            <Route path="documents" element={<SalesDocument />} />
            <Route
              path="training-certification"
              element={<TrainingCertification />}
            />
          </Route>
        </>
      ) : isAuthenticated === "CSO" ? (
        <>
          <Route path="*" element={<Navigate to="/cso" />} />
          <Route path="/cso" element={<DashboardLayout onLogout={onLogout} />}>
            <Route path="skillset" element={<CSOSkillSet />} />
            <Route path="clients" element={<CSOClients />} />
            <Route path="audit" element={<CSOAudit />} />
            <Route path="documents" element={<CSODocument />} />
            <Route path="certificate" element={<CSOCertificate />} />
            <Route path="feedbackForms" element={<CSOFeedbackForms />} />
            <Route
              path="training-certification"
              element={<CSOTrainingCertification />}
            />
          </Route>
          <Route path="/feedbackForm/cb" element={<CB_FeedbackFormById />} />
        </>
      ) : isAuthenticated === "Reviewer" ? (
        <>
          <Route path="*" element={<Navigate to="/reviewer" />} />
          <Route
            path="/reviewer"
            element={<DashboardLayout onLogout={onLogout} />}
          >
            <Route path="skillset" element={<ReviewerSkillSet />} />
            <Route path="clients" element={<ReviewerClients />} />
            <Route path="audit" element={<ReviewerAudit />} />
            <Route path="documents" element={<ReviewerDocument />} />
            <Route
              path="training-certification"
              element={<ReviewerTrainingCertification />}
            />
          </Route>
        </>
      ) : isAuthenticated === "Auditor" ? (
        <>
          <Route path="*" element={<Navigate to="/auditor" />} />
          <Route
            path="/auditor"
            element={<DashboardLayout onLogout={onLogout} />}
          >
            <Route path="skillset" element={<AuditorSkillset />} />
            <Route path="calendar" element={<AuditorCalendar />} />
            <Route path="clients" element={<AuditorClients />} />
            <Route path="documents" element={<AuditorDocument />} />
            <Route
              path="training-certification"
              element={<AuditorTrainingCertification />}
            />
          </Route>
        </>
      ) : isAuthenticated === "Auditor-HRA" ? (
        <>
          <Route path="*" element={<Navigate to="/auditor-hra" />} />
          <Route
            path="/auditor-hra"
            element={<DashboardLayout onLogout={onLogout} />}
          >
            <Route path="skillset" element={<AuditorSkillSetHRA />} />
            <Route path="calendar" element={<AuditorCalendarSkillSetHRA />} />
            <Route path="clients" element={<AuditorClientsHRA />} />
            <Route path="audit" element={<SubjectmatterexpertReviewAudit />} />
            <Route path="documents" element={<AuditorDocumentHRA />} />
            <Route
              path="training-certification"
              element={<AuditorTrainingCertificationHRA />}
            />
          </Route>
        </>
      ) : isAuthenticated === "Planner" ? (
        <>
          <Route path="*" element={<Navigate to="/planner" />} />
          <Route
            path="/planner"
            element={<DashboardLayout onLogout={onLogout} />}
          >
            <Route path="skillset" element={<PlannerSkillset />} />
            <Route path="clients" element={<PlannerClients />} />
            <Route path="documents" element={<PlannerDocument />} />
            <Route path="audit" element={<PlannerAudit />} />
            <Route path="upcoming-client" element={<UpcomingClients />} />
            <Route
              path="training-certification"
              element={<PlannerTrainingCertification />}
            />
          </Route>
        </>
      ) : isAuthenticated === "Account" ? (
        <>
          <Route path="*" element={<Navigate to="/account" />} />
          <Route
            path="/account"
            element={<DashboardLayout onLogout={onLogout} />}
          >
            <Route path="skillset" element={<AccountSkillset />} />
            <Route path="clients" element={<AccountClients />} />
            <Route path="documents" element={<AccountDocument />} />
            <Route
              path="training-certification"
              element={<AccountTrainingCertification />}
            />
          </Route>
        </>
      ) : isAuthenticated === "Operation-Manager" ? (
        <>
          <Route path="*" element={<Navigate to="/operationmanager" />} />
          <Route
            path="/operationmanager"
            element={<DashboardLayout onLogout={onLogout} />}
          >
            <Route path="dashboard" element={<DashboardAppPage />} />
            <Route path="saleshra" element={<SalesHRA />} />
            <Route path="processassociate" element={<ProcessAssociate />} />
            <Route
              path="subjectmatterexpert"
              element={<Subjectmatterexpert />}
            />
            <Route path="hra-auditor" element={<AuditHRA />} />
            <Route path="client/list" element={<OperationmanagerNew />} />
          </Route>
        </>
      ) : isAuthenticated === "Sales-HRA" ? (
        <>
          <Route path="*" element={<Navigate to="/saleshra" />} />
          <Route
            path="/saleshra"
            element={<DashboardLayout onLogout={onLogout} />}
          >
            <Route path="skillset" element={<SkillSetHRA />} />
            <Route path="clients" element={<SalesHRAClients />} />
            <Route path="documents" element={<SalesHRADocument />} />
            <Route
              path="training-certification"
              element={<TrainingCertificationHRA />}
            />
          </Route>
        </>
      ) : isAuthenticated === "Process-Associate-MIS" ? (
        <>
          <Route path="*" element={<Navigate to="/processassociate" />} />
          <Route
            path="/processassociate"
            element={<DashboardLayout onLogout={onLogout} />}
          >
            <Route path="skillset" element={<ProcessAssociateSkillset />} />
            <Route path="clients" element={<ProcessAssociateClients />} />
            <Route path="audit" element={<ProcessAssociateAudit />} />
            <Route path="documents" element={<ProcessAssociateDocument />} />
            <Route
              path="feedbackForms"
              element={<ProcessAssociateFeedbackForms />}
            />
            <Route
              path="training-certification"
              element={<ProcessAssociateTrainingCertification />}
            />
          </Route>
          <Route path="/feedbackForm/hra" element={<HRA_FeedbackFormById />} />
        </>
      ) : isAuthenticated === "Subject-Matter-Expert" ? (
        <>
          <Route path="*" element={<Navigate to="/subjectmatterexpert" />} />
          <Route
            path="/subjectmatterexpert"
            element={<DashboardLayout onLogout={onLogout} />}
          >
            <Route path="skillset" element={<SubjectmatterexpertSkillset />} />
            <Route path="clients" element={<SubjectmatterexpertClients />} />
            <Route path="documents" element={<SubjectmatterexpertDocument />} />
            <Route path="audit" element={<SubjectMatterExpertAudit />} />
            <Route
              path="reviewaudit"
              element={<SubjectmatterexpertReviewAudit />}
            />
            <Route
              path="training-certification"
              element={<SubjectmatterexpertCertification />}
            />
          </Route>
        </>
      ) : (
        <>
          <Route path="*" element={<Navigate to="/login" />} />
          <Route path="/login" element={<LoginPage onLogin={onLogin} />} />
          <Route path="/form/feedback/cb" element={<CBFeedbackFormPage />} />
          <Route path="/form/feedback/hra" element={<HRAFeedbackFormPage />} />
        </>
      )}
    </Routes>
  );
};

export default Router;
