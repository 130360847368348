export const ClientTemplateData = {
    "companyName": "",
    "contactPerson": "",
    "email": "",
    "phoneNumber": "",
    "address": "",
    "state": "",
    "technicalCode": {
        "mainCode": "",
        "subCode": ""
    },
    "noOfSites": null,
    "country": "",
    "standardsSchemeType": "HRA",
    "status": {
        "type": "",
        "updatedDate": ""
    },
    "leadGeneratedBy": "",
    "ClientFormHRA_Data": [
        {

            "cCode": "",
            "typeOfServices": "",
            "auditType": "",
            "kob": "",
            "noOfFoodHandlers": null,
            "FoSTaC_TrainedFoodSafetySupervisors": [""],
            "uniqueCertificateNoAndCourseName": "",
            "FSSAI_LicenseNumber": "",
            "validityOfLicense": "",
            "signatureFileObject": {
                "originalname": "",
                "mimetype": "",
                "size": "",
                "filename": "",
                "destination": "",
                "path": "",
                "uri": "",
                "createdAt": "",
                "updatedAt": "",
            },
            "authorisedSignatoryOfFoodServiceEstablishmentFileObject": [
                {
                    "originalname": "",
                    "mimetype": "",
                    "size": "",
                    "filename": "",
                    "destination": "",
                    "path": "",
                    "uri": "",
                    "createdAt": "",
                    "updatedAt": "",
                }
            ],
            "site": [
                {
                    "name": "",
                    "location": "",
                }
            ],
            "totalNoOfEmployees": null,
            "headOffice": "",
            "clientType": "",
            "GSTNumber": "",
            "serviceCharges": {
                "hygieneRatingAuditFee": 0,
                "GST_Charges": 0,
                "grandTotal": 0
            },
            "reviewPointsAndDocuments": [
                {
                    "reviewQuestion": "Whether the Client is having Valid FSSAI License",
                    "reviewAnswer": false,
                    "reviewEvidenceDocumentFileObject": {
                        "originalname": "",
                        "mimetype": "",
                        "size": "",
                        "filename": "",
                        "destination": "",
                        "path": "",
                        "uri": "",
                        "_id": "",
                        "createdAt": "",
                        "updatedAt": "",
                        "__v": null
                    },
                },
                {
                    "reviewQuestion": "Whether the Client has FSS for the premises",
                    "reviewAnswer": false,
                    "reviewEvidenceDocumentFileObject": {
                        "originalname": "",
                        "mimetype": "",
                        "size": "",
                        "filename": "",
                        "destination": "",
                        "path": "",
                        "uri": "",
                        "_id": "",
                        "createdAt": "",
                        "updatedAt": "",
                        "__v": null
                    },
                },
                {
                    "reviewQuestion": "Whether the FSS has done the appropriate course pertaining to the products mentioned in the FSSAI",
                    "reviewAnswer": false,
                    "reviewEvidenceDocumentFileObject": {
                        "originalname": "",
                        "mimetype": "",
                        "size": "",
                        "filename": "",
                        "destination": "",
                        "path": "",
                        "uri": "",
                        "_id": "",
                        "createdAt": "",
                        "updatedAt": "",
                        "__v": null
                    },
                },
                {
                    "reviewQuestion": "Food Safety Display Boards are displayed at the premises",
                    "reviewAnswer": false,
                    "reviewEvidenceDocumentFileObject": {
                        "originalname": "",
                        "mimetype": "",
                        "size": "",
                        "filename": "",
                        "destination": "",
                        "path": "",
                        "uri": "",
                        "_id": "",
                        "createdAt": "",
                        "updatedAt": "",
                        "__v": null
                    },
                },
                {
                    "reviewQuestion": "Whether the water testing is periodically done for potability",
                    "reviewAnswer": false,
                    "reviewEvidenceDocumentFileObject": {
                        "originalname": "",
                        "mimetype": "",
                        "size": "",
                        "filename": "",
                        "destination": "",
                        "path": "",
                        "uri": "",

                        "createdAt": "",
                        "updatedAt": "",
                        "__v": null
                    },
                },
                {
                    "reviewQuestion": "Whether the Food Samples testing is periodically done as FSSAI norms",
                    "reviewAnswer": false,
                    "reviewEvidenceDocumentFileObject": {
                        "originalname": "",
                        "mimetype": "",
                        "size": "",
                        "filename": "",
                        "destination": "",
                        "path": "",
                        "uri": "",
                        "createdAt": "",
                        "updatedAt": "",
                        "__v": null
                    },
                },
            ],
            "remarks": "",
            "proposalFileObject": {
                "originalname": "",
                "mimetype": "",
                "size": "",
                "filename": "",
                "destination": "",
                "path": "",
                "uri": "",
                "_id": "",
                "createdAt": "",
                "updatedAt": "",
                "__v": null
            },
            "signedProposalFileObject": {
                "originalname": "",
                "mimetype": "",
                "size": "",
                "filename": "",
                "destination": "",
                "path": "",
                "uri": "",
                "_id": "",
                "createdAt": "",
                "updatedAt": "",
                "__v": null
            },
            "agreementFileObject": {
                "originalname": "",
                "mimetype": "",
                "size": "",
                "filename": "",
                "destination": "",
                "path": "",
                "uri": "",
                "_id": "",
                "createdAt": "",
                "updatedAt": "",
                "__v": null
            },
            "signedAgreementFileObject": {
                "originalname": "",
                "mimetype": "",
                "size": "",
                "filename": "",
                "destination": "",
                "path": "",
                "uri": "",
                "_id": "",
                "createdAt": "",
                "updatedAt": "",
                "__v": null
            }
        }
    ]
}
