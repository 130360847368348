import { Helmet } from "react-helmet-async";
// @mui
import { Container } from "@mui/material";
import Skillset from "src/components/Skillset/Skillset";

// ----------------------------------------------------------------------

export default function CSOSkillSet() {
  
  return (
    <>
      <Helmet>
        <title> Skill-set </title>
      </Helmet>

      <Container maxWidth="xl">
        <Skillset />
      </Container>
    </>
  );
}
