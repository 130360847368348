import { createSlice } from "@reduxjs/toolkit";

const ReviewerSlice = createSlice({
  name: "Reviewer",
  initialState: {
    ClientBasicandAdvance: "",
  },
  reducers: {
    setClientBasicandAdvance: (state, action) => {
      state.ClientBasicandAdvance = action.payload;
    },
  },
});

export const { setClientBasicandAdvance } = ReviewerSlice.actions;
export default ReviewerSlice.reducer;
