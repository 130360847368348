import React from "react";
import TableList from "src/components/Admin/TableList/TableList";

export default function Auditor() {
  return (
    <>
      <div>
        <TableList Role="Auditor" />
      </div>
    </>
  );
}
