const USERSROUTES = {
  LOGIN: "users/login",
  SIGNOUT: "users/signout",
  SIGNUP: "users/basic/user/signup",
  ROLE: "users/basic/users/role/",
  USERID: "users/basic/user/",
  EDITUSER: "users/basic/user/",
  DELETEUSER: "users/basic/admin/user/",

  AUDITORSIGNUP: (BASIC_ID) =>
    `users/admin/user/${BASIC_ID}/auditor/specific/signup`,
  AUDITORGET: (BASIC_ID) => `users/user/${BASIC_ID}/auditor/specific`,
  AUDITOREDIT: (BASIC_ID) => `users/user/${BASIC_ID}/auditor/specific`,
};

export default USERSROUTES;
