const checkListObject = {
    topic: "Hygiene Rating Checklist Food Service Establishments/Bakery/Restaurants",
    totalScore: 118,
    totalObtainedScore: 0,
    checkList: [
        {
            topic: 'Basic',
            question: 'The FSSAI license/Registration and Food Safety Display Board (FSDB) both are displayed at a prominent location.',
            answer: {
                majorNC: false,
                yes: false,
                minorNC: false,
                notApplicable: false,
            },
            score: 2,
            obtainedScore: 0
        },
        {
            topic: 'Basic',
            question: 'The Premise should be located in hygienic environment. The design of Premise provides adequate working space permit maintenance & cleaning to prevent the entry of dirt, dust & pests.',
            answer: {
                majorNC: false,
                yes: false,
                minorNC: false,
                notApplicable: false,
            },
            score: 2,
            obtainedScore: 0
        },
        {
            topic: 'Basic',
            question: 'internal structure & fittings are made of non- toxic and impermeable material.',
            answer: {
                majorNC: false,
                yes: false,
                minorNC: false,
                notApplicable: false,
            },
            score: 2,
            obtainedScore: 0
        },
        {
            topic: 'Basic',
            question: 'Walls, ceilings & doors are free from flaking paint or plaster,condensation & shedding particles.',
            answer: {
                majorNC: false,
                yes: false,
                minorNC: false,
                notApplicable: false,
            },
            score: 2,
            obtainedScore: 0
        },
        {
            topic: 'Basic',
            question: 'Floors are non- absorbent, non-slippery & sloped appropriately.',
            answer: {
                majorNC: false,
                yes: false,
                minorNC: false,
                notApplicable: false,
            },
            score: 2,
            obtainedScore: 0
        },
        {
            topic: 'Basic',
            question: 'Windows are kept closed & fitted with insect proof screen when opening to external environment',
            answer: {
                majorNC: false,
                yes: false,
                minorNC: false,
                notApplicable: false,
            },
            score: 2,
            obtainedScore: 0
        },
        {
            topic: 'Basic',
            question: 'Doors are smooth and non-absorbent. Suitable precautions have been taken prevent entry of pests.',
            answer: {
                majorNC: false,
                yes: false,
                minorNC: false,
                notApplicable: false,
            },
            score: 2,
            obtainedScore: 0
        },
        {
            topic: 'Basic',
            question: 'Potable water (meeting standards of IS:10500 & tested semi-annually with records maintained thereof) is used as product ingredient or in contact with food or food contact surface.',
            answer: {
                majorNC: false,
                yes: false,
                minorNC: false,
                notApplicable: false,
            },
            score: 4,
            obtainedScore: 0
        },
        {
            topic: 'Basic',
            question: 'Equipment and containers are made of non-toxic, impervious, non- corrosive material which is easy to clean & disinfect.',
            answer: {
                majorNC: false,
                yes: false,
                minorNC: false,
                notApplicable: false,
            },
            score: 2,
            obtainedScore: 0
        },
        {
            topic: 'Basic',
            question: 'Adequate facilities for heating, cooling, refrigeration and freezing food & facilitate monitoring of temperature.',
            answer: {
                majorNC: false,
                yes: false,
                minorNC: false,
                notApplicable: false,
            },
            score: 2,
            obtainedScore: 0
        },
        {
            topic: 'Basic',
            question: 'Premise has sufficient lighting. Lighting fixtures are protected to prevent contamination on breakage.',
            answer: {
                majorNC: false,
                yes: false,
                minorNC: false,
                notApplicable: false,
            },
            score: 2,
            obtainedScore: 0
        },
        {
            topic: 'Basic',
            question: 'Adequate ventilation is provided within the premises',
            answer: {
                majorNC: false,
                yes: false,
                minorNC: false,
                notApplicable: false,
            },
            score: 2,
            obtainedScore: 0
        },
        {
            topic: 'Basic',
            question: 'An adequate storage facility for food, packaging materials, chemicals, personnel items etc. is available.',
            answer: {
                majorNC: false,
                yes: false,
                minorNC: false,
                notApplicable: false,
            },
            score: 2,
            obtainedScore: 0
        },
        {
            topic: 'Basic',
            question: 'Personnel hygiene facilities are available including adequate number of hand washing facilities, toilets, and change rooms for employees.',
            answer: {
                majorNC: false,
                yes: false,
                minorNC: false,
                notApplicable: false,
            },
            score: 2,
            obtainedScore: 0
        },
        {
            topic: 'Basic',
            question: 'Food material is tested either through internal laboratory or through an accredited lab. Check for records.',
            answer: {
                majorNC: false,
                yes: false,
                minorNC: false,
                notApplicable: false,
            },
            score: 2,
            obtainedScore: 0
        },
        {
            topic: 'Control of operation: Safe Handling Practices',
            question: 'Incoming material is procured as per internally laid down specification from approved vendors. Check for records (like certificate of analysis, Form E, specifications, name and address of the supplier, batch no., mfg., use by/expiry date, quantity procured etc.)',
            answer: {
                majorNC: false,
                yes: false,
                minorNC: false,
                notApplicable: false,
            },
            score: 2,
            obtainedScore: 0
        },
        {
            topic: 'Control of operation: Safe Handling Practices',
            question: 'Raw materials are inspected at the time of receiving for food safety hazards.(Farm produce like vegetables, fruits, eggs etc. must be checked for spoilage and accepted only in good condition)',
            answer: {
                majorNC: false,
                yes: false,
                minorNC: false,
                notApplicable: false,
            },
            score: 2,
            obtainedScore: 0
        },
        {
            topic: 'Control of operation: Safe Handling Practices',
            question: '(Foods of animal origin are stored at a temperature less than or equal to 4oC )',
            answer: {
                majorNC: false,
                yes: false,
                minorNC: false,
                notApplicable: false,
            },
            score: 2,
            obtainedScore: 0
        },
        {
            topic: 'Control of operation: Safe Handling Practices',
            question: 'All raw materials is cleaned thoroughly before food preparation.',
            answer: {
                majorNC: false,
                yes: false,
                minorNC: false,
                notApplicable: false,
            },
            score: 2,
            obtainedScore: 0
        },
        {
            topic: 'Control of operation: Safe Handling Practices',
            question: 'Proper segregation of raw, cooked vegetarian and non-vegetarian food is done.',
            answer: {
                majorNC: false,
                yes: false,
                minorNC: false,
                notApplicable: false,
            },
            score: 2,
            obtainedScore: 0
        },
        {
            topic: 'Control of operation: Safe Handling Practices',
            question: 'All the equipment is adequately sanitized before and after food preparation.',
            answer: {
                majorNC: false,
                yes: false,
                minorNC: false,
                notApplicable: false,
            },
            score: 2,
            obtainedScore: 0
        },
        {
            topic: 'Control of operation: Safe Handling Practices',
            question: 'Frozen food is thawed hygienically. No thawed food is stored for later use. (Meat, Fish and poultry is thawed in refrigerator at 5 oC or below or in microwave. Shellfish/seafood is thawed in cold potable running water at 15 oC or below within 90 minutes.',
            answer: {
                majorNC: false,
                yes: false,
                minorNC: false,
                notApplicable: false,
            },
            score: 4,
            obtainedScore: 0
        },
        {
            topic: 'Control of operation: Safe Handling Practices',
            question: 'Vegetarian items are cooked to a minimum of 60oC for 10 minutes or65oC for 2 minutes core food temperature. Non vegetarian items are cooked for   minimum of 65oC for 10 minutes or 70oC for 2 minutes or 75oC for 15 seconds core food temperature.',
            answer: {
                majorNC: false,
                yes: false,
                minorNC: false,
                notApplicable: false,
            },
            score: 4,
            obtainedScore: 0
        },
        {
            topic: 'Control of operation: Safe Handling Practices',
            question: 'Cooked food intended for refrigeration is cooled appropriately. (High risk food is cooled from 60o C to 21oC within 2 hours or less and further cooled to 5o C within two hours or less.)',
            answer: {
                majorNC: false,
                yes: false,
                minorNC: false,
                notApplicable: false,
            },
            score: 4,
            obtainedScore: 0
        },
        {
            topic: 'Control of operation: Safe Handling Practices',
            question: 'Food portioning is done in hygienic conditions. High risk food is portioned in a refrigerated area or portioned and refrigerated within 30 minutes. Large amount of food is portioned below 15 o C.',
            answer: {
                majorNC: false,
                yes: false,
                minorNC: false,
                notApplicable: false,
            },
            score: 2,
            obtainedScore: 0
        },
        {
            topic: 'Control of operation: Safe Handling Practices',
            question: 'Hot food intended for consumption is held at 65o C and non- vegetarian food intended for consumption is held at 70oC. Cold foods are maintained at 5oC or below and frozen products are held at -18o C or below. *Hot food is kept above 65oC and cold food is kept below5OC but below 10 oC upto 42 hours for not more than two hours only once.)',
            answer: {
                majorNC: false,
                yes: false,
                minorNC: false,
                notApplicable: false,
            },
            score: 4,
            obtainedScore: 0
        },
        {
            topic: 'Control of operation: Safe Handling Practices',
            question: 'Reheating is done appropriately and no indirect of reheating such as adding hot water or reheating under bain- marie or reheating under lamp are being used. (The core temperature of food reaches 75oC and is reheated for at least 2 minutes at this temperature.)',
            answer: {
                majorNC: false,
                yes: false,
                minorNC: false,
                notApplicable: false,
            },
            score: 4,
            obtainedScore: 0
        },
        {
            topic: 'Control of operation: Safe Handling Practices',
            question: 'Oil being used is suitable for cooking purposes is being used. Periodic verification of fat and oil by checking the color, the flavour and floated elements is being done.',
            answer: {
                majorNC: false,
                yes: false,
                minorNC: false,
                notApplicable: false,
            },
            score: 2,
            obtainedScore: 0
        },
        {
            topic: 'Control of operation: Safe Handling Practices',
            question: 'Unused/fresh vegetable oil or fat with not more than 15% Total Polar Compounds (TPC) and used vegetable oil or fat with not more than 25% TPC is being used for food preparation',
            answer: {
                majorNC: false,
                yes: false,
                minorNC: false,
                notApplicable: false,
            },
            score: 2,
            obtainedScore: 0
        },
        {
            topic: 'Control of operation: Safe Handling Practices',
            question: 'Appropriate records are maintained if oil consumption is more than 50 L/day.',
            answer: {
                majorNC: false,
                yes: false,
                minorNC: false,
                notApplicable: false,
            },
            score: 2,
            obtainedScore: 0
        },
        {
            topic: 'Control of operation: Safe Handling Practices',
            question: 'Vehicle intended for food transportation are kept clean and maintained in good repair & are maintain required temperature. (Hot foods are held at 65o C, cold foods at 5o C and frozen item -18oCduring transportation or transported within 2 hours of food preparation).',
            answer: {
                majorNC: false,
                yes: false,
                minorNC: false,
                notApplicable: false,
            },
            score: 4,
            obtainedScore: 0
        },
        {
            topic: 'Control of operation: Safe Handling Practices',
            question: 'Food and non-food products transported at same time in the same vehicle are separated adequately to avoid any risk to food.',
            answer: {
                majorNC: false,
                yes: false,
                minorNC: false,
                notApplicable: false,
            },
            score: 2,
            obtainedScore: 0
        },
        {
            topic: 'Control of operation: Safe Handling Practices',
            question: 'Cutlery, crockery used for serving and dinner accompaniments at dining service are clean and sanitized free form unhygienic matters.',
            answer: {
                majorNC: false,
                yes: false,
                minorNC: false,
                notApplicable: false,
            },
            score: 2,
            obtainedScore: 0
        },
        {
            topic: 'Control of operation: Safe Handling Practices',
            question: 'Packaging and wrapping material coming in contact with food is cleanand of food grade quality.',
            answer: {
                majorNC: false,
                yes: false,
                minorNC: false,
                notApplicable: false,
            },
            score: 2,
            obtainedScore: 0
        },
        {
            topic: 'Control of operation: Safe Handling Practices',
            question: 'Labelling of food items as per the FSSAI norms. Shelf life of food products indicated properly',
            answer: {
                majorNC: false,
                yes: false,
                minorNC: false,
                notApplicable: false,
            },
            score: 2,
            obtainedScore: 0
        },
        {
            topic: 'Maintenance & sanitation',
            question: 'Cleaning of equipment, food premises is done as per cleaning schedule & cleaning programme. There should be no stagnation of water in food zones.',
            answer: {
                majorNC: false,
                yes: false,
                minorNC: false,
                notApplicable: false,
            },
            score: 2,
            obtainedScore: 0
        },
        {
            topic: 'Maintenance & sanitation',
            question: 'Preventive maintenance of equipment and machinery are carried out regularly as per the instructions of the manufacturer. Check for records.',
            answer: {
                majorNC: false,
                yes: false,
                minorNC: false,
                notApplicable: false,
            },
            score: 2,
            obtainedScore: 0
        },
        {
            topic: 'Maintenance & sanitation',
            question: 'Measuring & monitoring devices are calibrated periodically.',
            answer: {
                majorNC: false,
                yes: false,
                minorNC: false,
                notApplicable: false,
            },
            score: 2,
            obtainedScore: 0
        },
        {
            topic: 'Maintenance & sanitation',
            question: 'Pest control program is available & pest control activities are carried out by trained and experienced personnel. Check for records.',
            answer: {
                majorNC: false,
                yes: false,
                minorNC: false,
                notApplicable: false,
            },
            score: 2,
            obtainedScore: 0
        },
        {
            topic: 'Maintenance & sanitation',
            question: 'No signs of pest activity or infestation in premises (eggs, larvae, faeces etc.)',
            answer: {
                majorNC: false,
                yes: false,
                minorNC: false,
                notApplicable: false,
            },
            score: 4,
            obtainedScore: 0
        },
        {
            topic: 'Maintenance & sanitation',
            question: 'Drains are designed to meet expected flow loads and equipped withgrease and cockroach traps to capture contaminants and pests.',
            answer: {
                majorNC: false,
                yes: false,
                minorNC: false,
                notApplicable: false,
            },
            score: 2,
            obtainedScore: 0
        },
        {
            topic: 'Maintenance & sanitation',
            question: 'Food waste and other refuse are removed periodically from food handling areas to avoid accumulation.',
            answer: {
                majorNC: false,
                yes: false,
                minorNC: false,
                notApplicable: false,
            },
            score: 2,
            obtainedScore: 0
        },
        {
            topic: 'Personal Hygiene of Food Handlers',
            question: 'Annual medical examination & inoculation of food handlers against theenteric group of diseases as per recommended schedule of the vaccineis done. Check for records.',
            answer: {
                majorNC: false,
                yes: false,
                minorNC: false,
                notApplicable: false,
            },
            score: 2,
            obtainedScore: 0
        },
        {
            topic: 'Personal Hygiene of Food Handlers',
            question: 'No person suffering from a disease or illness or with open wounds or burns is involved in handling of food or materials which come in contact with food.',
            answer: {
                majorNC: false,
                yes: false,
                minorNC: false,
                notApplicable: false,
            },
            score: 2,
            obtainedScore: 0
        },
        {
            topic: 'Personal Hygiene of Food Handlers',
            question: 'Food handlers maintain personal cleanliness (clean clothes, trimmed nails &water proof bandage etc.) and personal behaviour (hand washing, no loose jewelry, no smoking, no spitting etc.)',
            answer: {
                majorNC: false,
                yes: false,
                minorNC: false,
                notApplicable: false,
            },
            score: 4,
            obtainedScore: 0
        },
        {
            topic: 'Personal Hygiene of Food Handlers',
            question: 'Food handlers are equipped with suitable aprons, gloves, headgear, etc. wherever necessary.',
            answer: {
                majorNC: false,
                yes: false,
                minorNC: false,
                notApplicable: false,
            },
            score: 2,
            obtainedScore: 0
        },
        {
            topic: 'Training & records keeping',
            question: 'Internal / External audit of the system is done periodically. Check for records.',
            answer: {
                majorNC: false,
                yes: false,
                minorNC: false,
                notApplicable: false,
            },
            score: 2,
            obtainedScore: 0
        },
        {
            topic: 'Training & records keeping',
            question: 'Food Business has an effective consumer complaints redressal mechanism.',
            answer: {
                majorNC: false,
                yes: false,
                minorNC: false,
                notApplicable: false,
            },
            score: 2,
            obtainedScore: 0
        },
        {
            topic: 'Training & records keeping',
            question: 'Food handlers have the necessary knowledge and skills & trained to handle food safely. Check for training records.',
            answer: {
                majorNC: false,
                yes: false,
                minorNC: false,
                notApplicable: false,
            },
            score: 2,
            obtainedScore: 0
        },
        {
            topic: 'Training & records keeping',
            question: 'Appropriate documentation & records are available and retained for a period of one year, whichever is more.',
            answer: {
                majorNC: false,
                yes: false,
                minorNC: false,
                notApplicable: false,
            },
            score: 4,
            obtainedScore: 0
        }
    ],
};

export default checkListObject;