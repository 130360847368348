import { createSlice } from "@reduxjs/toolkit";

const PlannerNameList = createSlice({
  name: "PlannerNameList",
  initialState: {
    PlannerNameList: "",
  },
  reducers: {
    setPlannerNameList: (state, action) => {
      state.PlannerNameList = action.payload;
    },
  },
});

export const { setPlannerNameList } = PlannerNameList.actions;
export default PlannerNameList.reducer;
