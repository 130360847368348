import React, { useEffect, useState } from "react";
import { Button, Card, Checkbox, FormControlLabel } from "@mui/material";
import Iconify from "../iconify";
import {
  FileUpload,
  getData,
  patchData,
  postData,
} from "src/webService/webService";
import AUDITROUTES from "src/API_Routes/Audit/Audit_routes";
import FILEROUTES from "src/API_Routes/File/File_Router";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import EVENT_TRACKING_ROUTES from "src/API_Routes/EventTracking/EventTracking_Routers";
import EMAIL_ROUTES from "../../API_Routes/Email/Email_Routers";
import CommonEmail from "../../Config/templates/html/CommonEmailTem";
import { getFormattedIndiaTime } from "../../utils/timeutils";
import SERVER_URL from "../../utils/serverURL";
import { useDispatch } from "react-redux";

function Documents({ AuditId, ClientBasic, auditData, BackToCalender }) {
  const dispatch = useDispatch();
  console.log(auditData, "auditData1232");
  const accessToken = sessionStorage.getItem("Token");
  const UserId = sessionStorage.getItem("UserId");
  const [auditDocuments, setAuditDocuments] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isSelecting, setIsSelecting] = useState(false);
  const [buttonText, setButtonText] = useState("Upload File");
  const [AuditFile, setAuditFile] = useState();

  useEffect(() => {
    GetAuditDocument();
  }, []);

  const handleFileChangeAuditPlan = async (event) => {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append("file", file);
    try {
      const response = await FileUpload(
        FILEROUTES.UPLOAD + UserId,
        formData,
        dispatch
      );
      setAuditFile(response.data);
      postData(
        EVENT_TRACKING_ROUTES.CREATE_EVENT_HISTORY(ClientBasic._id),
        {
          description:
            auditData.isSpecialAudit === "true"
              ? `${auditData.auditType} Special Audit Plan updated`
              : `${auditData.auditType} Audit Plan updated`,
          performedBy: `${sessionStorage.getItem(
            "firstName"
          )} ${sessionStorage.getItem("lastName")}`,
        },
        accessToken,
        dispatch
      );
    } catch (error) {
      console.error("File upload error:", error);
    }
  };
  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append("file", file);
    try {
      const response = await FileUpload(
        FILEROUTES.UPLOAD + UserId,
        formData,
        dispatch
      );
      setAuditDocuments([...auditDocuments, response.data]);
      setUploadedFiles([...uploadedFiles, response.data]);
    } catch (error) {
      console.error("File upload error:", error);
    }
  };

  const removeFile = (file) => {
    const updatedAuditDocuments = auditDocuments.filter(
      (document) => document.filename !== file.filename
    );
    const updatedUploadedFiles = uploadedFiles.filter(
      (uploadedFile) => uploadedFile.filename !== file.filename
    );
    setAuditDocuments(updatedAuditDocuments);
    setUploadedFiles(updatedUploadedFiles);
  };

  const handleFileSelection = (file) => {
    if (selectedFiles.includes(file)) {
      setSelectedFiles(selectedFiles.filter((selected) => selected !== file));
    } else {
      setSelectedFiles([...selectedFiles, file]);
    }
  };

  const AuditDocument = async () => {
    const params = {
      auditDocuments: uploadedFiles,
    };

    try {
      const res = await patchData(
        AUDITROUTES.SCHEDULEPATCH(AuditId),
        params,
        accessToken,
        dispatch
      );
      postData(
        EVENT_TRACKING_ROUTES.CREATE_EVENT_HISTORY(ClientBasic._id),
        {
          description:
            auditData.isSpecialAudit === "true"
              ? `${auditData.auditType} Special Audit Document updated`
              : `${auditData.auditType} Audit Document updated`,

          performedBy: `${sessionStorage.getItem(
            "firstName"
          )} ${sessionStorage.getItem("lastName")}`,
        },
        accessToken,
        dispatch
      );
      // const HTML_DATA = {
      //   ClientName: ClientBasic.companyName,
      //   ContactPerson: ClientBasic.contactPerson,
      //   ClientID: ClientBasic.clientId,
      //   AuditType: auditData.auditType,
      //   Message: `The above client audit plan is attached.
      //   Please ensure the plant is operational during the audit period and for any further inquiries, please contact the undersigned.`,
      // };
      // const newArray = auditData.auditDocuments.map((item) => ({
      //   filename: item.filename,
      //   path: item.path,
      // }));
      // await postData(
      //   EMAIL_ROUTES.SEND_EMAIL_FILE,
      //   {
      //     to: ClientBasic.email,
      //     subject: "Audit Plan",
      //     html: CommonEmail(HTML_DATA),
      //     attachments: newArray,
      //   },
      //   accessToken
      // );
    } catch (error) {
      console.error("Audit document error:", error);
    }
  };

  const SendAuditPlanToClient = async () => {
    try {
      const HTML_DATA = {
        ClientName: ClientBasic.companyName,
        ContactPerson: ClientBasic.contactPerson,
        ClientID: ClientBasic.clientId,
        AuditType: auditData.auditType,
        Message: `The above client audit plan is attached. 
        Please ensure the plant is operational during the audit period and for any further inquiries, please contact the undersigned.`,
      };
      await postData(
        EMAIL_ROUTES.SEND_EMAIL_FILE,
        {
          to: ClientBasic.email,
          subject: "Audit Plan",
          html: CommonEmail(HTML_DATA),
          attachments: [{ filename: AuditFile.filename, path: AuditFile.path }],
        },
        accessToken,
        dispatch
      );
      const CurrentTime = getFormattedIndiaTime();
      const params = {
        auditPlanFile: AuditFile,
        reportStatus: {
          name: "Audit Plan Sent",
          date: CurrentTime,
        },
        activityStep: "2",
      };
      const res = await patchData(
        AUDITROUTES.SCHEDULEPATCH(AuditId),
        params,
        accessToken,
        dispatch
      );
      postData(
        EVENT_TRACKING_ROUTES.CREATE_EVENT_HISTORY(ClientBasic._id),
        {
          description:
            auditData.isSpecialAudit === "true"
              ? `${auditData.auditType} Special Audit Plan Send To Client`
              : `${auditData.auditType} Audit Plan Send To Client`,
          performedBy: `${sessionStorage.getItem(
            "firstName"
          )} ${sessionStorage.getItem("lastName")}`,
        },
        accessToken,
        dispatch
      );
      toast.success("Files sent to client successfully!");
    } catch (error) {
      console.error("Error sending files to client:", error);
      toast.error("Failed to send files to client.");
    }
  };

  const SendToClient = async () => {
    try {
      const HTML_DATA = {
        ClientName: ClientBasic.companyName,
        ContactPerson: ClientBasic.contactPerson,
        ClientID: ClientBasic.clientId,
        AuditType: auditData.auditType,
        Message: `Refer the attached document Audit Draft Report.`,
      };
      const newArray = selectedFiles.map((item) => ({
        filename: item.filename,
        path: item.path,
      }));
      await postData(
        EMAIL_ROUTES.SEND_EMAIL_FILE,
        {
          to: ClientBasic.email,
          subject: "Audit Draft Report",
          html: CommonEmail(HTML_DATA),
          attachments: newArray,
        },
        accessToken,
        dispatch
      );
      const CurrentTime = getFormattedIndiaTime();
      const params = {
        reportStatus: {
          name: "Audit Draft Report Sent",
          date: CurrentTime,
        },
        activityStep: "3",
      };
      const res = await patchData(
        AUDITROUTES.SCHEDULEPATCH(AuditId),
        params,
        accessToken,
        dispatch
      );
      postData(
        EVENT_TRACKING_ROUTES.CREATE_EVENT_HISTORY(ClientBasic._id),
        {
          description:
            auditData.isSpecialAudit === "true"
              ? `${auditData.auditType} Special Audit Draft Send To Client`
              : `${auditData.auditType} Audit Draft Send To Client`,

          performedBy: `${sessionStorage.getItem(
            "firstName"
          )} ${sessionStorage.getItem("lastName")}`,
        },
        accessToken,
        dispatch
      );
      setIsSelecting(false);
      setSelectedFiles([]);
      setButtonText("Upload File");
      // BackToCalender();
      toast.success("Files sent to client successfully!");
    } catch (error) {
      console.error("Error sending files to client:", error);
      toast.error("Failed to send files to client.");
    }
  };

  const GetAuditDocument = async () => {
    try {
      const res = await getData(
        AUDITROUTES.SCHEDULEPATCH(AuditId),
        null,
        accessToken
      );
      setUploadedFiles(res.data.auditDocuments);
      setAuditFile(res.data.auditPlanFile);
    } catch (error) {
      console.error("Audit document error:", error);
    }
  };

  const handleSelectFileClick = () => {
    setIsSelecting(true);
    setButtonText("Send to Client");
  };

  const handleBackClick = () => {
    setIsSelecting(false);
    setSelectedFiles([]);
    setButtonText("Upload File");
  };

  const handleButtonClick = () => {
    if (isSelecting) {
      SendToClient();
    } else {
      AuditDocument();
    }
  };

  return (
    <div>
      <ToastContainer />
      <div className="TrainingCertifications-border p-5">
        <div className="px-2 add-title">
          <span className="">Upload Documents</span>
        </div>
        <div className="d-flex flex-wrap justify-content-between">
          <Card className="col-5 p-3">
            <h5 className="fw-bold my-3 ps-2">Audit Plan</h5>
            <div className="">
              <input
                type="file"
                onChange={handleFileChangeAuditPlan}
                className="d-none"
                id="fileInput"
              />
              <label htmlFor="fileInput">
                <Button component="span" className="cl-green text-white">
                  Upload
                </Button>
              </label>
              <p className="my-3">
                {AuditFile && (
                  <a
                    rel="noreferrer"
                    href={`${SERVER_URL}/${AuditFile?.uri}`}
                    target="_blank"
                  >
                    {AuditFile.filename}
                  </a>
                )}
              </p>
              {AuditFile && (
                <Button
                  onClick={SendAuditPlanToClient}
                  variant="contained"
                  color="primary"
                >
                  Send To Client
                </Button>
              )}
            </div>
          </Card>
          <Card className="col-5 p-3">
            <h5 className="fw-bold my-3 ps-2">
              Audit Draft & Other Audit Documents
            </h5>
            <div className="mt-2 ps-2">
              <span className="d-block mb-1" style={{ color: "red" }}>
                Step 1 : Attach File *
              </span>
              <span className="d-block mb-1" style={{ color: "red" }}>
                Step 2 : Press Upload File Button *
              </span>
              <span className="d-block mb-1" style={{ color: "red" }}>
                Step 3 : Select Files *
              </span>
              <span className="d-block mb-1" style={{ color: "red" }}>
                Step 4 : Send To Client *
              </span>
            </div>
            <div className="mt-2 px-4 ps-2">
              <input
                type="file"
                onChange={handleFileChange}
                className="d-none"
                id="fileInput-1"
              />
              <label htmlFor="fileInput-1">
                <Button component="span" className="cl-green text-white">
                  Attach file
                </Button>
              </label>
              {/* {auditData?.auditDocuments.length !== 0 && ( */}
              <Button
                component="span"
                className="cl-green text-white ms-3"
                onClick={handleSelectFileClick}
              >
                Select File
              </Button>
              {/* )} */}
            </div>
            {uploadedFiles.map((file, index) => (
              <div className="mt-3 ps-2 d-grid" key={index}>
                <div className="d-flex align-items-center">
                  {isSelecting && (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selectedFiles.includes(file)}
                          onChange={() => handleFileSelection(file)}
                        />
                      }
                      label=""
                    />
                  )}
                  <Iconify className="me-3" icon={"bxs:file"} />
                  <a
                    rel="noreferrer"
                    href={`${SERVER_URL}/${file?.uri}`}
                    target="_blank"
                  >
                    {file.filename}
                  </a>
                  <Button
                    className="ms-3"
                    variant="outlined"
                    color="secondary"
                    onClick={() => removeFile(file)}
                  >
                    Remove
                  </Button>
                </div>
              </div>
            ))}
            <div className="mt-3 px-4 ps-2">
              {isSelecting && (
                <Button
                  onClick={handleBackClick}
                  variant="contained"
                  color="secondary"
                  className="me-3"
                >
                  Back
                </Button>
              )}
              <Button
                onClick={handleButtonClick}
                variant="contained"
                color="primary"
              >
                {buttonText}
              </Button>
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
}

export default Documents;
