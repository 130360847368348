import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from "@mui/material";
import { getData } from "../../webService/webService";
import { setBasicandAdvance } from "../../redux/slices/ClientSlice";
import { useDispatch } from "react-redux";
import CLIENTROUTES from "../../API_Routes/Client/Client_Routers";

const SalesFilter = () => {
  const isAuthenticated = sessionStorage.getItem("isAuthenticated");
  const dispatch = useDispatch();
  const scheme_session = sessionStorage.getItem("scheme");
  const accessToken = sessionStorage.getItem("Token");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [
    accountsActivityTypePaymentStatus,
    setaccountsActivityTypePaymentStatus,
  ] = useState("");
  const [statusType, setstatusType] = useState("");
  const [accountsYear, setaccountsYear] = useState("");
  const [accountsActivityTypeName, setaccountsActivityTypeName] = useState("");
  const [limit, setlimit] = useState("");
  const handleStartDateChange = (e) => {
    const selectedStartDate = e.target.value;
    if (endDate && endDate < selectedStartDate) {
      setEndDate("");
    }
    setStartDate(selectedStartDate);
  };
  const handleEndDateChange = (e) => {
    const selectedEndDate = e.target.value;
    if (startDate && startDate > selectedEndDate) {
      setStartDate("");
    }
    setEndDate(selectedEndDate);
  };
  const handleaccountsActivityTypePaymentStatusChange = (event) => {
    setaccountsActivityTypePaymentStatus(event.target.value);
  };
  const handlestatusTypeChange = (event) => {
    setstatusType(event.target.value);
  };
  const handleaccountsYearChange = (event) => {
    setaccountsYear(event.target.value);
  };
  const handleaccountsActivityTypeNameChange = (event) => {
    setaccountsActivityTypeName(event.target.value);
  };

  const ClientBasicandadvance = () => {
    const params = {
      scheme: scheme_session === "HRA" ? "HRA" : "CB",
      page: 1,
      limit: limit,
      statusstartdate: startDate,
      statusenddate: endDate,
      statustype: statusType,
      accountsyear: accountsYear,
      accountsactivitytypename: accountsActivityTypeName,
      accountsactivitytypepaymentstatus: accountsActivityTypePaymentStatus,
    };
    const USER_ID = sessionStorage.getItem("UserId");
    const endPoint =
      isAuthenticated === "Admin"
        ? `clients/client/basic/advanced/all`
        : CLIENTROUTES.GET_CLIENT_BASIC_AND_ADVANCE(USER_ID);
    getData(endPoint, params, accessToken)
      .then((res) => {
        dispatch(setBasicandAdvance(res.data));
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const handleSearch = () => {
    ClientBasicandadvance();
  };

  return (
    <div
      style={{
        width: "100%",
        justifyContent: "end",
        display: "flex",
        margin: "10px 0px",
        alignItems: "center",
      }}
    >
      <TextField
        style={{ width: "12%" }}
        size="small"
        label="Start Date"
        type="date"
        className="m-2"
        value={startDate}
        onChange={handleStartDateChange}
        InputLabelProps={{
          shrink: true,
        }}
      />
      <TextField
        style={{ width: "12%" }}
        size="small"
        label="End Date"
        type="date"
        className="m-2"
        value={endDate}
        onChange={handleEndDateChange}
        InputLabelProps={{
          shrink: true,
        }}
      />
      {isAuthenticated === "Admin" && (
        <>
          <FormControl
            style={{ width: "12%" }}
            variant="outlined"
            className="m-2"
            size="small"
          >
            <InputLabel id="accountsActivityTypePaymentStatus">
              Payment Status
            </InputLabel>
            <Select
              labelId="accountsActivityTypePaymentStatus"
              id="accountsActivityTypePaymentStatus"
              value={accountsActivityTypePaymentStatus}
              onChange={handleaccountsActivityTypePaymentStatusChange}
              label="accountsActivityTypePaymentStatus"
            >
              <MenuItem value="PAID">PAID</MenuItem>
              <MenuItem value="NOT PAID">NOT PAID</MenuItem>
              <MenuItem value="INPROGRESS">INPROGRESS</MenuItem>
              <MenuItem value="REJECTED">REJECTED</MenuItem>
            </Select>
          </FormControl>
          <FormControl
            style={{ width: "12%" }}
            variant="outlined"
            className="m-2"
            size="small"
          >
            <InputLabel id="accountsYear">Accounts Year</InputLabel>
            <Select
              labelId="accountsYear"
              id="accountsYear"
              value={accountsYear}
              onChange={handleaccountsYearChange}
              label="accountsYear"
            >
              <MenuItem value="1">1</MenuItem>
              <MenuItem value="2">2</MenuItem>
              <MenuItem value="3">3</MenuItem>
            </Select>
          </FormControl>
          <FormControl
            style={{ width: "12%" }}
            variant="outlined"
            className="m-2"
            size="small"
          >
            <InputLabel id="accountsActivityTypeName">
              accounts Activity Type Name
            </InputLabel>
            <Select
              labelId="accountsActivityTypeName"
              id="accountsActivityTypeName"
              value={accountsActivityTypeName}
              onChange={handleaccountsActivityTypeNameChange}
              label="accountsActivityTypeName"
            >
              <MenuItem value="INITIAL_ACCREDITATION_AND_ADMIN_COST">
                INITIAL_ACCREDITATION_AND_ADMIN_COST
              </MenuItem>
              <MenuItem value="STAGE_1">STAGE_1</MenuItem>
              <MenuItem value="STAGE_2">STAGE_2</MenuItem>
              <MenuItem value="SA_1">SA_1</MenuItem>
              <MenuItem value="SA_2">SA_2</MenuItem>
              <MenuItem value="RA">RA</MenuItem>
              <MenuItem value="TRANSFER_AUDIT">TRANSFER_AUDIT</MenuItem>
              <MenuItem value="CERTIFICATE_COMPLETED">
                CERTIFICATE_COMPLETED
              </MenuItem>
            </Select>
          </FormControl>
        </>
      )}

      <FormControl
        style={{ width: "12%" }}
        variant="outlined"
        className="m-2"
        size="small"
      >
        <InputLabel id="Status">Status</InputLabel>
        <Select
          labelId="Status"
          id="Status"
          value={statusType}
          onChange={handlestatusTypeChange}
          label="Status"
        >
          <MenuItem value="pending">Client Basic Pending</MenuItem>
          <MenuItem value="client details filled">
            Client Details Filled
          </MenuItem>
          <MenuItem value="reviewer assigned">
            Client Basic Reviewer Assigned
          </MenuItem>
          <MenuItem value="need revision">
            Client Basic Reviewer Need Revision
          </MenuItem>
          <MenuItem value="approved">Client Basic Reviewer Approved</MenuItem>
          <MenuItem value="rejected">Client Basic Reviewer Rejected</MenuItem>
          <MenuItem value="auditor assigned">Auditor Assigned</MenuItem>
          <MenuItem value="technical review completed">
            Technical Review Completed
          </MenuItem>

          <MenuItem value="stage 1 completed">Stage 1 Completed</MenuItem>
          <MenuItem value="stage 2 completed">Stage 2 Completed</MenuItem>
          <MenuItem value="SA 1 Completed">SA 1 Completed</MenuItem>
          <MenuItem value="SA 2 Completed">SA 2 Completed</MenuItem>
          <MenuItem value="re-certification completed">
            Recertification Completed
          </MenuItem>
          <MenuItem value="cancelled">Cancelled</MenuItem>
        </Select>
      </FormControl>

      <TextField
        style={{ width: "12%" }}
        size="small"
        label="Limit"
        className="m-2"
        type="number"
        onChange={(e) => setlimit(e.target.value)}
        InputLabelProps={{
          shrink: true,
        }}
      />

      <Button
        style={{ width: "12%" }}
        className="btn-green m-2"
        onClick={handleSearch}
      >
        Search
      </Button>
    </div>
  );
};

export default SalesFilter;
