import React, { useState, useEffect } from "react";
import "../Client/Client-list.css";
import {
  Button,
  Card,
  MenuItem,
  FormControl,
  InputLabel,
  Popover,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
} from "@mui/material";
import SaleClient from "src/_mock/sale-client";
import Iconify from "../iconify/Iconify";
import CSOROUTES from "src/API_Routes/CSO/CSO_Routers";
import { FileUpload, getData, patchData } from "src/webService/webService";
import { setClientBasicandAdvance } from "src/redux/slices/CSOSlice";
import { ToastContainer, toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import FILEROUTES from "src/API_Routes/File/File_Router";
import SERVER_URL from "../../utils/serverURL";

export default function ClientList({ Role }) {
  const dispatch = useDispatch();
  const accessToken = sessionStorage.getItem("Token");
  const CSOVIEWCLIENTLIST = useSelector(
    (state) => state?.CSO?.ClientBasicandAdvance[0]?.paginatedResults
  );
  const UserId = sessionStorage.getItem("UserId");

  console.log(CSOVIEWCLIENTLIST);

  // Table Conditions
  const [open, setOpen] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setOpen(null);
  };
  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Table Conditions
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit, setLimit] = useState(5);
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };
  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const handleLimitChange = (event) => {
    setCurrentPage(1);
    setLimit(event.target.value);
  };
  // Table Conditions
  const GETCSO = async (page) => {
    const params = {
      page: page,
      limit: limit,
    };
    try {
      const res = await getData(
        CSOROUTES.GETCSOCLIENTBASICANDADVANCE,
        params,
        accessToken
      );
      if (res.status === 200) {
        dispatch(setClientBasicandAdvance(res.data));
        setTotalPages(res.data[0].totalPages);
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("An error occurred. Please try again later.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  const [selectedValues, setSelectedValues] = useState({});
  useEffect(() => {
    GETCSO(currentPage);
  }, [currentPage, limit, selectedValues]);

  // const handleCIFAFileUpload = async (event, data) => {
  //   event.preventDefault();
  //   try {
  //     const file = event.target.files[0];
  //     const formData = new FormData();
  //     formData.append("file", file);
  //     const uploadRes = await FileUpload(`${FILEROUTES.UPLOAD}${UserId}`, formData);

  //     // Prepare the data object to be sent in the PATCH request
  //     const FileObject = {
  //       accounts: {
  //         invoices: [{ file: uploadRes?.data }]
  //       }
  //     };

  //     await patchData(`clients/client/${data}/basic`, FileObject, accessToken);
  //   }
  //   catch (error) {
  //     console.log(error);
  //   }
  // }

  const handleCIFAFileUpload = async (event, data, index) => {
    event.preventDefault();
    try {
      const file = event.target.files[0];
      const formData = new FormData();
      formData.append("file", file);
      const uploadRes = await FileUpload(
        `${FILEROUTES.UPLOAD}${UserId}`,
        formData,
        dispatch
      );
      const client = CSOVIEWCLIENTLIST.find((client) => client._id === data);
      let FileObject;
      if (client) {
        // Prepare the data object to be sent in the PATCH request
        FileObject = {
          accounts: {
            invoices: client.accounts.invoices.map((invoice, i) => {
              // If index is provided, update the invoice at that index, otherwise, push a new invoice
              if (i === index) {
                return { file: uploadRes?.data };
              } else {
                return invoice;
              }
            }),
          },
        };
      }

      // Prepare the data object to be sent in the PATCH request
      // const FileObject = {
      //   accounts: {
      //     invoices: [], // Assuming you want to store the uploaded file data in an array under 'invoices'
      //   },
      // };

      // If there's already data in the invoices array, retain it
      if (index === undefined || index === null) {
        FileObject.accounts.invoices.push({ file: uploadRes?.data });
      } else {
        FileObject.accounts.invoices[index] = { file: uploadRes?.data };
      }

      await patchData(`clients/client/${data}/basic`, FileObject, accessToken,dispatch);
      GETCSO(currentPage);
    } catch (error) {
      console.log(error);
    }
  };

  const [paymentStatus, setPaymentStatus] = useState();
  // const handlePaymentStatus = async (event, rowId) => {
  //   const value = event.target.value;
  //   setPaymentStatus((prevStatuses) => ({
  //     ...prevStatuses,
  //     [rowId]: value,
  //   }));
  //   try {
  //     const PaymentStatus = {
  //       accounts: {
  //         activityType: {
  //           paymentStatus: value,
  //         },
  //       },
  //     };
  //     await patchData(
  //       `clients/client/${rowId}/basic`,
  //       PaymentStatus,
  //       accessToken
  //     );
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  return (
    <>
      <ToastContainer />
      <div className="cso-clientlist-border p-5">
        <div className="px-2 add-title">
          <span className="">Client List</span>
        </div>
        {/* <div className="d-flex justify-content-end mb-3">
          <Button variant="contained" className="btn-orange text-dark me-0">
            Export
          </Button>
        </div> */}
        <Card>
          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className="cl-green text-dark">
                    Company Name
                  </TableCell>
                  <TableCell className="cl-green text-dark">Email</TableCell>
                  <TableCell className="cl-green text-dark">Scheme</TableCell>
                  <TableCell className="cl-green text-dark" align="center">
                    Status
                  </TableCell>
                  <TableCell className="cl-green text-dark" align="center">
                    Sale Confirmation
                  </TableCell>
                  <TableCell className="cl-green text-dark" align="center">
                    Auditor Allocation
                  </TableCell>
                  <TableCell className="cl-green text-dark" align="center">
                    Certificate generation
                  </TableCell>
                  <TableCell className="cl-green text-dark" align="center">
                    View ( Sales/Auditor/Certificates )
                  </TableCell>
                  <TableCell className="cl-green text-dark" align="center">
                    PaymentStatus
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {CSOVIEWCLIENTLIST &&
                  CSOVIEWCLIENTLIST.map((row, index) => (
                    
                    <TableRow key={index}>
                      <TableCell>{row.companyName}</TableCell>
                      <TableCell>{row.email}</TableCell>
                      <TableCell>{row.standardsSchemeType}</TableCell>
                      <TableCell align="center">{row.status.type}</TableCell>
                      <TableCell>
                        <div key={row._id} id={`${row._id}`}>
                          <label
                            htmlFor={`fileInput-1-${row._id}`}
                            className="text-success p-4 fw-bold"
                            style={{ cursor: "pointer" }}
                          >
                            Upload
                          </label>
                          <input
                            id={`fileInput-1-${row._id}`}
                            style={{ display: "none" }}
                            type="file"
                            onChange={(event) =>
                              handleCIFAFileUpload(event, row._id, 0)
                            }
                          />
                        </div>
                        {/* {row.accounts &&
                        row.accounts?.activityType?.name ===
                          "INITIAL_ACCREDITATION_AND_ADMIN_COST" ? (
                          <div key={row._id} id={`${row._id}`}>
                            <label
                              htmlFor={`fileInput${row._id}`}
                              className="text-success p-4 fw-bold"
                              style={{ cursor: "pointer" }}
                            >
                              Upload
                            </label>
                            <input
                              id={`fileInput${row._id}`}
                              style={{ display: "none" }}
                              type="file"
                              onChange={(event) =>
                                handleCIFAFileUpload(event, row._id, 0)
                              }
                            />
                          </div>
                        ) : (
                          <Tooltip title={"Fill The Advance Details"}>
                            <Button sx={{ m: 1 }} className="text-danger">
                              Can't Upload
                            </Button>
                          </Tooltip>
                        )} */}
                      </TableCell>
                      <TableCell>
                        <div key={row._id} id={`${row._id}`}>
                          <label
                            htmlFor={`fileInput-2-${row._id}`}
                            className="text-success p-4 fw-bold"
                            style={{ cursor: "pointer" }}
                          >
                            Upload
                          </label>
                          <input
                            id={`fileInput-2-${row._id}`}
                            style={{ display: "none" }}
                            type="file"
                            onChange={(event) =>
                              handleCIFAFileUpload(event, row._id, 1)
                            }
                          />
                        </div>
                        {/* {row.accounts && row.accounts ? (
                          (row.accounts?.activityType?.name === "SA1" ||
                            row.accounts?.activityType?.name === "SA2" ||
                            row.accounts?.activityType?.name === "RA" ||
                            row.accounts?.activityType?.name ===
                              "TRANSFER_AUDIT" ||
                            row.accounts?.activityType?.name === "STAGE_2" ||
                            row.accounts?.activityType?.name === "STAGE_1") &&
                          row.accounts.invoices &&
                          row.accounts.invoices.length !== 0 ? (
                            <div key={row._id} id={`${row._id}`}>
                              <label
                                htmlFor={`fileInput${row._id}`}
                                className="text-success p-4 fw-bold"
                                style={{ cursor: "pointer" }}
                              >
                                Upload
                              </label>
                              <input
                                id={`fileInput${row._id}`}
                                style={{ display: "none" }}
                                type="file"
                                onChange={(event) =>
                                  handleCIFAFileUpload(event, row._id, 1)
                                }
                              />
                            </div>
                          ) : (
                            <Tooltip title={"Fill The Advance Details"}>
                              <Button sx={{ m: 1 }} className="text-danger">
                                Can't Upload
                              </Button>
                            </Tooltip>
                          )
                        ) : (
                          <Tooltip title={"Fill The Advance Details"}>
                            <Button sx={{ m: 1 }} className="text-danger">
                              Can't Upload
                            </Button>
                          </Tooltip>
                        )} */}
                      </TableCell>

                      <TableCell>
                        <div key={row._id} id={`${row._id}`}>
                          <label
                            htmlFor={`fileInput-3-${row._id}`}
                            className="text-success p-4 fw-bold"
                            style={{ cursor: "pointer" }}
                          >
                            Upload
                          </label>
                          <input
                            id={`fileInput-3-${row._id}`}
                            style={{ display: "none" }}
                            type="file"
                            onChange={(event) =>
                              handleCIFAFileUpload(event, row._id, 2)
                            }
                          />
                        </div>
                        {/* {row &&
                        row.accounts &&
                        row.status === "certificate completed" &&
                        row.accounts.invoices &&
                        row.accounts.invoices[0] &&
                        row.accounts.invoices[1] ? (
                          <div key={row._id} id={`${row._id}`}>
                            <label
                              htmlFor={`fileInput${row._id}`}
                              className="text-success p-4 fw-bold"
                              style={{ cursor: "pointer" }}
                            >
                              Upload
                            </label>
                            <input
                              id={`fileInput${row._id}`}
                              style={{ display: "none" }}
                              type="file"
                              onChange={(event) =>
                                handleCIFAFileUpload(event, row._id, 2)
                              }
                            />
                          </div>
                        ) : (
                          <Tooltip title={"Fill The Advance Details"}>
                            <Button sx={{ m: 1 }} className="text-danger">
                              Can't Upload
                            </Button>
                          </Tooltip>
                        )} */}
                      </TableCell>

                      <TableCell>
                        <div className="d-flex">
                          {/* View for signedProposalFileObject */}
                          {row &&
                          row.accounts &&
                          row.accounts.invoices &&
                          row.accounts.invoices[0] &&
                          row.accounts.invoices[0].file ? (
                            <a
                              rel="noreferrer"
                              href={`${SERVER_URL}/${row.accounts.invoices[0]?.file?.uri}`}
                              target="_blank"
                              style={{ marginRight: "10px" }}
                            >
                              View
                            </a>
                          ) : (
                            <p>No View </p>
                          )}

                          {/* View for signedAgreementFileObject */}
                          {row &&
                          row.accounts &&
                          row.accounts.invoices &&
                          row.accounts.invoices[1] &&
                          row.accounts.invoices[1].file ? (
                            <a
                              rel="noreferrer"
                              href={`${SERVER_URL}/${row.accounts.invoices[1]?.file?.uri}`}
                              target="_blank"
                              style={{ marginRight: "10px" }}
                            >
                              / View /
                            </a>
                          ) : (
                            <p> / No View /</p>
                          )}

                          {row &&
                          row.accounts &&
                          row.accounts.invoices &&
                          row.accounts.invoices[2] &&
                          row.accounts.invoices[2].file ? (
                            <a
                              rel="noreferrer"
                              href={`${SERVER_URL}/${row.accounts.invoices[2]?.file?.uri}`}
                              target="_blank"
                              style={{ marginRight: "10px" }}
                            >
                              View
                            </a>
                          ) : (
                            <p>No View </p>
                          )}
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className="d-flex align-items-center">
                          <p className="mb-0 me-2">
                            {row &&
                              row.accounts &&
                              row.accounts.activityType &&
                              row.accounts.activityType.paymentStatus}
                          </p>
                          <FormControl size="small">
                            <InputLabel id={`payment-status-label-${row._id}`}>
                              Payment Status
                            </InputLabel>
                            <Select
                              labelId={`payment-status-label-${row._id}`}
                              id={`payment-status-select-${row._id}`}
                              value={paymentStatus}
                              // onChange={(event) =>
                              //   handlePaymentStatus(event, row._id)
                              // }
                              label="Payment Status"
                            >
                              <MenuItem value="">Select Status</MenuItem>
                              <MenuItem value="PAID">Paid</MenuItem>
                              <MenuItem value="UNPAID">Unpaid</MenuItem>
                              <MenuItem value="INPROGRESS">Inprogress</MenuItem>
                              <MenuItem value="REJECTED">Rejected</MenuItem>
                            </Select>
                          </FormControl>
                        </div>
                      </TableCell>
                    </TableRow>
                    // );
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <div className="d-flex flex-wrap justify-content-end m-3">
            <FormControl variant="outlined" size="small">
              <InputLabel id="limit-label">Limit</InputLabel>
              <Select
                labelId="limit-label"
                id="limit-select"
                value={limit}
                onChange={handleLimitChange}
              >
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={15}>15</MenuItem>
              </Select>
            </FormControl>
            <Button onClick={handlePrevPage} disabled={currentPage === 1}>
              <Iconify icon={"grommet-icons:previous"} />
            </Button>
            <Button
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
            >
              <Iconify icon={"grommet-icons:next"} />
            </Button>
          </div>
        </Card>
        <Popover
          open={Boolean(open)}
          anchorEl={open}
          onClose={handleCloseMenu}
          anchorOrigin={{ vertical: "top", horizontal: "left" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
          PaperProps={{
            sx: {
              p: 1,
              width: 140,
              "& .MuiMenuItem-root": {
                px: 1,
                typography: "body2",
                borderRadius: 0.75,
              },
            },
          }}
        >
          <MenuItem>
            <Iconify icon={"eva:edit-fill"} sx={{ mr: 2 }} />
            Edit
          </MenuItem>
          <MenuItem>
            <Iconify icon={"raphael:view"} sx={{ mr: 2 }} />
            View
          </MenuItem>

          <MenuItem sx={{ color: "error.main" }}>
            <Iconify icon={"eva:trash-2-outline"} sx={{ mr: 2 }} />
            Delete
          </MenuItem>
        </Popover>
      </div>
    </>
  );
}
