import React from "react";
import TableList from "src/components/Admin/TableList/TableList";


export default function Sales() {
  return (
    <>
     
      <div>
        <TableList Role="Sale" />
      </div>
    </>
  );
}
