import React, { useEffect, useState } from "react";
import {
  TextField,
  Radio,
  RadioGroup,
  InputLabel,
  FormControlLabel,
  FormControl,
  FormLabel,
  Stack,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation } from "react-router-dom";

import { getData } from "src/webService/webService";
import FEEDBACK_FORM_ROUTES from "src/API_Routes/FeedbackForm/FeedbackForm_Routers";

export default function CB_FeedbackFormById() {
  const [feedBackFormData, setFeedbackFormData] = useState(null);
  const accessToken = sessionStorage.getItem("Token");
  const location = useLocation();

  useEffect(() => {
    console.log("feedBackFormData", feedBackFormData);
  }, [feedBackFormData]);

  const GetFeedBackFormData = async () => {
    const searchParams = new URLSearchParams(location.search);
    const feedBackFormId = searchParams.get("id");
    // Now `id` contains the value of the 'id' query parameter
    console.log(feedBackFormId);
    const feedBackFormData = await getData(
      FEEDBACK_FORM_ROUTES.GET_FEEDBACK_FORM_BY_SCHEME_ID("cb", feedBackFormId),
      "",
      accessToken
    );
    console.log(feedBackFormData);
    if (feedBackFormData?.status === 404) {
      toast.error("feedback form doesn't exist", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    setFeedbackFormData(feedBackFormData?.data);
  };
  useEffect(() => {
    GetFeedBackFormData();
  }, [location.search]);

  return (
    <Stack
      direction="row"
      justifyContent="start"
      alignItems="start"
      spacing={2}
    >
      <ToastContainer />
      <form className="p-4">
        <div
          style={{
            background: "white",
            width: "96%",
            borderRadius: "10px",
            padding: "20px",
            margin: "auto",
          }}
        >
          <h2 className="pt-3">Feedback Form</h2>
          <div className="d-flex mt-4 ml-5">
            <div>
              <InputLabel>Client Name</InputLabel>
              <TextField
                name="clientName"
                value={feedBackFormData?.clientName}
                disabled
              />
            </div>
            <div className="ms-5">
              <InputLabel>Name Of The Contact</InputLabel>
              <TextField
                name="nameOfTheContact"
                value={feedBackFormData?.nameOfTheContact}
                disabled
              />
            </div>
            <div className="ms-5">
              <InputLabel>Standard Applied</InputLabel>
              <TextField
                name="standardApplied"
                value={feedBackFormData?.standardApplied}
                disabled
              />
            </div>
            <div className="ms-5">
              <InputLabel>Feedback Date</InputLabel>

              <TextField
                name="feedbackDate"
                value={
                  feedBackFormData?.feedbackDate
                    ? new Date(
                        feedBackFormData?.feedbackDate
                      )?.toLocaleDateString()
                    : ""
                }
                disabled
              />
            </div>
          </div>
          <div className="d-flex mt-4">
            <div>
              <InputLabel>Position</InputLabel>
              <TextField
                name="position"
                value={feedBackFormData?.position}
                disabled
              />
            </div>
            {/* Comments and Suggestions of Improvement */}
            <div className="ms-5">
              <InputLabel>Elements to Improve</InputLabel>
              <TextField
                name="elementsToImprove"
                value={feedBackFormData?.elementsToImprove}
                disabled
              />
            </div>
            <div className="ms-5">
              <InputLabel>Strong Points</InputLabel>
              <TextField
                name="strongPoints"
                value={feedBackFormData?.strongPoints}
                disabled
              />
            </div>
            <div className="ms-5">
              <InputLabel>Remarks</InputLabel>
              <TextField
                name="remarks"
                value={feedBackFormData?.remarks}
                disabled
              />
            </div>
          </div>
        </div>

        {/* Evaluation */}
        <div className="d-flex flex-wrap">
          <div className="col-6 p-3">
            <div
              style={{
                background: "white",
                borderRadius: "10px",
                margin: "10px",
                padding: "10px",
              }}
            >
              <h2 className="my-3">Evaluation</h2>
              {feedBackFormData &&
                feedBackFormData?.evaluation.map((item, index) => (
                  <div className="">
                    <FormControl component="fieldset" className="mt-3">
                      <FormLabel component="legend" id={"evaluation" + index}>
                        {item.question}
                      </FormLabel>
                      <RadioGroup
                        style={{ display: "flex", flexDirection: "row" }}
                        aria-labelledby={"evaluation" + index}
                        value={
                          Object.keys(item.checkList).find(
                            (key) => item.checkList[key]
                          ) || ""
                        }
                      >
                        <FormControlLabel
                          value="veryDissatisfied"
                          control={<Radio />}
                          label="Very Dissatisfied"
                          disabled
                        />
                        <FormControlLabel
                          value="dissatisfied"
                          control={<Radio />}
                          label="Dissatisfied"
                          disabled
                        />
                        <FormControlLabel
                          value="satisfied"
                          control={<Radio />}
                          label="Satisfied"
                          disabled
                        />
                        <FormControlLabel
                          value="verySatisfied"
                          control={<Radio />}
                          label="Very Satisfied"
                          disabled
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                ))}
            </div>
          </div>
          {/* Commercial */}
          <div className="p-3 col-6">
            <div
              style={{
                background: "white",
                borderRadius: "10px",
                margin: "10px",
                padding: "10px",
              }}
            >
              <h3 className="my-3">Commercial</h3>
              {feedBackFormData &&
                feedBackFormData?.commercial.map((item, index) => (
                  <div>
                    <FormControl component="fieldset">
                      <div className="mt-4">
                        <FormLabel component="legend">
                          {item.question}
                        </FormLabel>
                        <RadioGroup
                          style={{ display: "flex", flexDirection: "row" }}
                          value={
                            Object.keys(item.checkList).find(
                              (key) => item.checkList[key]
                            ) || ""
                          }
                        >
                          <FormControlLabel
                            value="veryDissatisfied"
                            control={<Radio />}
                            label="Very Dissatisfied"
                            disabled
                          />
                          <FormControlLabel
                            value="dissatisfied"
                            control={<Radio />}
                            label="Dissatisfied"
                            disabled
                          />
                          <FormControlLabel
                            value="satisfied"
                            control={<Radio />}
                            label="Satisfied"
                            disabled
                          />
                          <FormControlLabel
                            value="verySatisfied"
                            control={<Radio />}
                            label="Very Satisfied"
                            disabled
                          />
                        </RadioGroup>
                      </div>
                    </FormControl>
                  </div>
                ))}
            </div>

            {/* Complaints And Appeals */}
          </div>
          <div className="col-6 p-3">
            <div
              style={{
                background: "white",
                borderRadius: "10px",
                margin: "10px",
                padding: "10px",
              }}
            >
              <h3 className="my-3">Complaints And Appeals</h3>
              {feedBackFormData &&
                feedBackFormData?.complaintsAndAppeals.map((item, index) => (
                  <div className="mt-4">
                    <FormControl component="fieldset">
                      <FormLabel component="legend">{item.question}</FormLabel>
                      <RadioGroup
                        style={{ display: "flex", flexDirection: "row" }}
                        value={
                          Object.keys(item.checkList).find(
                            (key) => item.checkList[key]
                          ) || ""
                        }
                      >
                        <FormControlLabel
                          value="veryDissatisfied"
                          control={<Radio />}
                          label="Very Dissatisfied"
                          disabled
                        />
                        <FormControlLabel
                          value="dissatisfied"
                          control={<Radio />}
                          label="Dissatisfied"
                          disabled
                        />
                        <FormControlLabel
                          value="satisfied"
                          control={<Radio />}
                          label="Satisfied"
                          disabled
                        />
                        <FormControlLabel
                          value="verySatisfied"
                          control={<Radio />}
                          label="Very Satisfied"
                          disabled
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                ))}
            </div>
          </div>
          {/* Overall Satisfaction */}
          <div className="col-6 p-3">
            <div
              style={{
                background: "white",
                borderRadius: "10px",
                margin: "10px",
                padding: "10px",
              }}
            >
              <h3 className="my-3">Overall Satisfaction</h3>
              {feedBackFormData &&
                feedBackFormData?.overallSatisfaction.map((item, index) => (
                  <div className="mt-4">
                    <FormControl component="fieldset">
                      <FormLabel component="legend">{item.question}</FormLabel>
                      <RadioGroup
                        style={{ display: "flex", flexDirection: "row" }}
                        value={
                          Object.keys(item.checkList).find(
                            (key) => item.checkList[key]
                          ) || ""
                        }
                      >
                        <FormControlLabel
                          value="veryDissatisfied"
                          control={<Radio />}
                          label="Very Dissatisfied"
                          disabled
                        />
                        <FormControlLabel
                          value="dissatisfied"
                          control={<Radio />}
                          label="Dissatisfied"
                          disabled
                        />
                        <FormControlLabel
                          value="satisfied"
                          control={<Radio />}
                          label="Satisfied"
                          disabled
                        />
                        <FormControlLabel
                          value="verySatisfied"
                          control={<Radio />}
                          label="Very Satisfied"
                          disabled
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </form>
    </Stack>
  );
}
