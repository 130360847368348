import {
  DialogActions,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SERVER_URL from "../../utils/serverURL";
function Activity_details({ ClientBasic, ClientAdvance, AuditId, auditData }) {
  console.log(auditData, "auditData12345");
  const [open, setOpen] = useState(false);
  const lastcifa =
    ClientAdvance?.CIFA_FileObjects[
      ClientAdvance?.CIFA_FileObjects?.length - 1
    ];
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  //view scopeextension cifa
  const [scopeextensionopen, setscopeextensionOpen] = useState(false);
  const scopeextensionhandleClickOpen = () => {
    setscopeextensionOpen(true);
  };
  const scopeextensionhandleClose = () => {
    setscopeextensionOpen(false);
  };
  return (
    <div>
      <Dialog
        open={scopeextensionopen}
        onClose={scopeextensionhandleClose}
        maxWidth="xl"
        fullWidth={true}
      >
        <DialogTitle>Scope Extension Cifa</DialogTitle>
        <DialogContent>
          <iframe
            src={`${SERVER_URL}/${ClientAdvance?.ScopeExtension_CIFA_FileObject?.uri}`}
            style={{ width: "100%", height: "500px" }}
          ></iframe>
        </DialogContent>
        <DialogActions>
          <Button onClick={scopeextensionhandleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={open} onClose={handleClose} maxWidth="xl" fullWidth={true}>
        <DialogTitle>Approved Cifa</DialogTitle>
        <DialogContent>
          <iframe
            src={`${SERVER_URL}/${lastcifa?.uri}`}
            style={{ width: "100%", height: "500px" }}
          ></iframe>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <ToastContainer />
      <div className="d-flex">
        {/* <div className="col-6">
          <div className="d-flex flex-wrap justify-content-between">
            <div className="col-4">
              <p>Due Date </p>
            </div>
            <div className="col-2">
              <p>:</p>
            </div>
            <div className="col-6">
              <p></p>
            </div>
          </div>
          <div className="d-flex flex-wrap justify-content-between">
            <div className="col-4">
              <p>Onsite Man-days</p>
            </div>
            <div className="col-2">
              <p>:</p>
            </div>
            <div className="col-6">
              <p></p>
            </div>
          </div>
          <div className="d-flex flex-wrap justify-content-between">
            <div className="col-4">
              <p>Offsite Man-days </p>
            </div>
            <div className="col-2">
              <p>:</p>
            </div>
            <div className="col-6">
              <p></p>
            </div>
          </div>
          <div className="d-flex flex-wrap justify-content-between">
            <div className="col-4">
              <p>Date Requested</p>
            </div>
            <div className="col-2">
              <p>:</p>
            </div>
            <div className="col-6">
              <p></p>
            </div>
          </div>
        </div>
        <div className="col-6">
          <div className="d-flex flex-wrap justify-content-between">
            <div className="col-4">
              <p>Unannounced</p>
            </div>
            <div className="col-2">
              <p>:</p>
            </div>
            <div className="col-6">
              <p></p>
            </div>
          </div>
          <div className="d-flex flex-wrap justify-content-between">
            <div className="col-4">
              <p>Remote Audit</p>
            </div>
            <div className="col-2">
              <p>:</p>
            </div>
            <div className="col-6">
              <p></p>
            </div>
          </div>
          <div className="d-flex flex-wrap justify-content-between">
            <div className="col-4">
              <p>Activity Status</p>
            </div>
            <div className="col-2">
              <p>:</p>
            </div>
            <div className="col-6">
              <p></p>
            </div>
          </div>
        </div> */}
      </div>
      <div className="col-6 mt-5">
        <div className="d-flex flex-wrap justify-content-between">
          <div className="col-12">
            <p style={{ fontWeight: "bold" }}>
              Client Id : {ClientBasic?.clientId}
            </p>
          </div>
          <div className="col-12">
            <p style={{ fontWeight: "bold" }}>
              Company Name : {ClientBasic?.companyName}
            </p>
          </div>
        </div>
      </div>
      <div className="d-flex">
        <div className="col-6">
          {/* <div className="d-flex flex-wrap justify-content-between">
            <div className="col-4">
              <p>Audit Status</p>
            </div>
            <div className="col-2">
              <p>:</p>
            </div>
            <div className="col-6">
              <p>
                {auditData &&
                auditData.reportStatus.name === "Technical review completed"
                  ? "completed"
                  : "pending"}
              </p>
            </div>
          </div> */}
          <div className="d-flex flex-wrap justify-content-between">
            <div className="col-4">
              <p>Standards</p>
            </div>
            <div className="col-2">
              <p>:</p>
            </div>
            <div className="col-6">
              {ClientAdvance?.standards &&
                ClientAdvance?.standards.map((data) => <p>{data}</p>)}
            </div>
          </div>
          <div className="d-flex flex-wrap justify-content-between">
            <div className="col-4">
              <p>Phone</p>
            </div>
            <div className="col-2">
              <p>:</p>
            </div>
            <div className="col-6">
              <p>{ClientBasic?.phoneNumber}</p>
            </div>
          </div>
          <div className="d-flex flex-wrap justify-content-between">
            <div className="col-4">
              <p>Address</p>
            </div>
            <div className="col-2">
              <p>:</p>
            </div>
            <div className="col-6">
              <p>{ClientBasic?.address}</p>
            </div>
          </div>
          <div className="d-flex flex-wrap justify-content-between">
            <div className="col-4">
              <p>State</p>
            </div>
            <div className="col-2">
              <p>:</p>
            </div>
            <div className="col-6">
              <p>{ClientBasic?.state}</p>
            </div>
          </div>
          <div className="d-flex flex-wrap justify-content-between">
            <div className="col-4">
              <p>Country</p>
            </div>
            <div className="col-2">
              <p>:</p>
            </div>
            <div className="col-6">
              <p>{ClientBasic?.country}</p>
            </div>
          </div>
          {/* <div className="d-flex flex-wrap justify-content-between">
            <div className="col-4">
              <p>Report Review Status</p>
            </div>
            <div className="col-2">
              <p>:</p>
            </div>
            <div className="col-6">
              <p>
                {" "}
                {auditData &&
                auditData.reportStatus === "Technical review completed"
                  ? "completed"
                  : "pending"}
              </p>
            </div>
          </div> */}
        </div>
        <div className="col-6">
          {/* <div className="d-flex flex-wrap justify-content-between">
            <div className="col-4">
              <p>Site Type</p>
            </div>
            <div className="col-2">
              <p>:</p>
            </div>
            <div className="col-6">
              <p></p>
            </div>
          </div> */}
          {/* <div className="d-flex flex-wrap justify-content-between">
            <div className="col-4">
              <p>Total Man-days</p>
            </div>
            <div className="col-2">
              <p>:</p>
            </div>
            <div className="col-6">
              <p></p>
            </div>
          </div> */}
          <div className="d-flex flex-wrap justify-content-between">
            <div className="col-4">
              <p>Activity Status</p>
            </div>
            <div className="col-2">
              <p>:</p>
            </div>
            <div className="col-6">
              <p>{ClientBasic?.status.type}</p>
            </div>
          </div>
          <div className="d-flex flex-wrap justify-content-between">
            <div className="col-4">
              <p>Email</p>
            </div>
            <div className="col-2">
              <p>:</p>
            </div>
            <div className="col-6">
              <p>{ClientBasic?.email}</p>
            </div>
          </div>
          <div className="d-flex flex-wrap justify-content-between">
            <div className="col-4">
              <p>Technical Main Code</p>
            </div>
            <div className="col-2">
              <p>:</p>
            </div>
            <div className="col-6">
              <p>{ClientBasic?.technicalCode.mainCode}</p>
            </div>
          </div>
          <div className="d-flex flex-wrap justify-content-between">
            <div className="col-4">
              <p>Technical Sub Code</p>
            </div>
            <div className="col-2">
              <p>:</p>
            </div>
            <div className="col-6">
              <p>{ClientBasic?.technicalCode.subCode}</p>
            </div>
          </div>
          <div className="d-flex flex-wrap justify-content-between">
            <div className="col-4">
              <p>Contact Person</p>
            </div>
            <div className="col-2">
              <p>:</p>
            </div>
            <div className="col-6">
              <p>{ClientBasic?.contactPerson}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex flex-wrap">
        <Button
          variant="contained"
          className="btn-green text-white"
          onClick={handleClickOpen}
        >
          View Cifa
        </Button>
        {auditData?.auditType === "Scope Extension" && (
          <Button
            variant="contained"
            className="btn-green text-white mx-3"
            onClick={scopeextensionhandleClickOpen}
          >
            Scope Extension View Cifa
          </Button>
        )}
      </div>
    </div>
  );
}

export default Activity_details;
