import { getData } from "../webService/webService";
import CLIENTROUTES from "../API_Routes/Client/Client_Routers";
import { setBasic } from "../redux/slices/SpecificClient";

const SpecificUserBasic = (id, dispatch) => {
  const accessToken = sessionStorage.getItem("Token");
  getData(`${CLIENTROUTES.GETSPECIFICUSERBASIC}${id}/basic`, null, accessToken)
    .then((res) => {
      dispatch(setBasic(res.data));
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};

export default SpecificUserBasic;
