import { Helmet } from "react-helmet-async";
// @mui
import { Container } from "@mui/material";
import FeedbackFormList from "src/components/CSO/FeedbackForm/FeedbackForm-list";

// ----------------------------------------------------------------------

export default function CSOFeedbackForms() {
  return (
    <>
      <Helmet>
        <title> Feedback Forms </title>
      </Helmet>

      <Container maxWidth="xl">
        <FeedbackFormList />
      </Container>
    </>
  );
}
