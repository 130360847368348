const ReviewComplete = (data) => {
    return `<!DOCTYPE html>
        <html lang="en">
        <head>
            <meta charset="UTF-8">
            <meta name="viewport" content="width=device-width, initial-scale=1.0">
            <title>Assign Review</title>
            <style>
                body {
                    font-family: Arial, sans-serif;
                    margin: 0;
                    padding: 0;
                    background-color: #f4f4f4;
                }
                .container {
                    max-width: 600px;
                    margin: 20px auto;
                    padding: 20px;
                    background-color: #fff;
                    border-radius: 5px;
                    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
                }
                h2 {
                    color: #333;
                }
                p {
                    margin-bottom: 10px;
                }
                .info {
                    border: 1px solid #ccc;
                    border-radius: 5px;
                    padding: 10px;
                    background-color: #f9f9f9;
                }
            </style>
        </head>
        <body>
            <div class="container">
                <div class="info">
                    <p><strong>Client Name:</strong> ${data.ClientName}</p>
                    <p><strong>Contact Person:</strong> ${data.ContactPerson}</p>
                    <p><strong>Client ID:</strong> ${data.ClientID}</p>
                    <p><strong>Client ID:</strong> ${data.AuditDate}</p>
                </div>
              <p>${data.Message}</p>
            </div>
        </body>
        </html>
        `;
  };
  export default ReviewComplete;
  